import React, { useContext, useEffect, useMemo } from "react";
import { useSpace } from "./SpaceContext";
import {
  useQuery,
  getEntity,
  // useSpacePost,
  usePost,
  useSpaceQuery
} from "../Helpers/IOClient";
import {
  accountSchema,
  accountSummarySpaceSchema,
  currencySchema
} from "../config/schema";
import { useAccount, useAccountSpace } from "../Hooks/EntityHooks";
import { useHistory } from "react-router-dom";
import {
  Intl18nContext,
  possibleLanguages
} from "../Containers/Intl18n/Intl18nProvider";
import { UseOneSignalPlayerId } from "../Helpers/OneSignalHelper";
import { getAlterEgo, logout } from "../Helpers/AuthHelper";
import { ASSIGN_SIGNAL_R } from "../Redux/Actions/AuthActions";
import { connect } from "react-redux";

const UserContext = React.createContext();
const UserRefetchContext = React.createContext();
const UserLoadingContext = React.createContext();

export const useUserRefetch = () => {
  const refetch = useContext(UserRefetchContext);
  return refetch;
};
export const useUserLoading = () => {
  const loading = useContext(UserLoadingContext);
  return loading;
};

// const setGlobalUserObject = ({ Id, Name }) => {
//   const resolvedUser = {
//     Id,
//     Name
//   };

//   // window.user = resolvedUser;

//   if ("onUserFetch" in window && typeof window.onUserFetch === "function") {
//     window.onUserFetch(resolvedUser);
//   }
// };

// class UserProvider extends Component {
//   componentDidMount() {
//     const { getCurrentUser } = this.props;
//     getCurrentUser();

//     //   if()
//   }

//   render() {
//     return this.props.children;
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     getCurrentUser: () => dispatch(getCurrentUser())
//   };
// };

const UserProvider = ({ children, assignSignalRToken }) => {
  const oneSignalPlayerId = UseOneSignalPlayerId();

  const state = useQuery("account/summary", accountSchema, {
    onError: ({ error }) => {
      if (error.status === 401) {
        logout();
      }
    }
  });

  const { changeLanguage } = useContext(Intl18nContext);
  // eslint-disable-next-line no-unused-vars
  const { data, refetch, loading: userLoading, error: userError } = state;

  const signalUrl = useMemo(() => {
    const isInAlterEgo = Boolean(getAlterEgo());
    if (isInAlterEgo) return null;
    return oneSignalPlayerId
      ? `account/onesignal/${oneSignalPlayerId}/1`
      : null;
  }, [oneSignalPlayerId]);

  const [postSignal] = usePost(signalUrl, null);

  const value = useMemo(() => {
    const mappedAccountSpaces = {};

    if (data) {
      const account = getEntity(accountSchema, data);
      if (!mappedAccountSpaces[data]) mappedAccountSpaces[data] = {};
      for (const accountSpaceId of account.AccountSpaces) {
        const accountSpace = getEntity(
          accountSummarySpaceSchema,
          accountSpaceId
        );
        mappedAccountSpaces[data][accountSpace.Space] = accountSpaceId;
      }
    }
    return { data, mappedAccountSpaces };
  }, [data]);

  useEffect(() => {
    if (oneSignalPlayerId) {
      postSignal();
    }
  }, [oneSignalPlayerId, postSignal]);

  const account = useAccount(data);

  useEffect(() => {
    if (account && account.ConnectionId && assignSignalRToken) {
      assignSignalRToken(account.ConnectionId);
    }
  }, [account, assignSignalRToken]);

  const accountLanguageCode = account?.Language.Code;

  useEffect(() => {
    if (accountLanguageCode) {
      const index = possibleLanguages.findIndex((e) => {
        return accountLanguageCode.split("-")[0] === e.Value;
      });
      changeLanguage(possibleLanguages[index]);
    }
    return () => {};
  }, [accountLanguageCode, changeLanguage]);

  return (
    <UserContext.Provider value={value}>
      <UserRefetchContext.Provider value={refetch}>
        <UserLoadingContext.Provider value={userLoading}>
          {children}
        </UserLoadingContext.Provider>
      </UserRefetchContext.Provider>
    </UserContext.Provider>
  );
};

export const useCurrentAccount = () => {
  const { data } = useContext(UserContext);

  return useAccount(data);
};

export const useCurrentAccountSpace = () => {
  const space = useSpace();
  const { data: userId, mappedAccountSpaces } = useContext(UserContext) || {};
  const accountSpaceId = useMemo(() => {
    if (!userId || !space) return null;
    return mappedAccountSpaces[userId][space.Id];
  }, [userId, space, mappedAccountSpaces]);
  return useAccountSpace(accountSpaceId);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    assignSignalRToken: (token) => {
      dispatch({ type: ASSIGN_SIGNAL_R, signalRToken: token });
    }
  };
};

export default connect(null, mapDispatchToProps)(UserProvider);

export const withHistory = (WrappedComponent) => {
  function WithCurrentHistory(rest) {
    const history = useHistory();
    return <WrappedComponent History={history} {...rest} />;
  }

  return WithCurrentHistory;
};

export const withUser = (WrappedComponent) => {
  function WithCurrentUser(rest) {
    const user = useCurrentAccount();
    return <WrappedComponent user={user} {...rest} />;
  }

  return WithCurrentUser;

  // const mapStateToProps = (state, ownProps) => {
  //   const { accountId } = state.CurrentUser;

  //   return {
  //     user: state.Entities.Accounts[accountId]
  //   };
  // };

  // return connect(mapStateToProps)(({ children, ...passThroughProps }) => (
  //   <UserContext.Consumer>
  //     {({ user }) => <WrappedComponent user={user} {...passThroughProps} />}
  //   </UserContext.Consumer>
  // ));
};

export const withSpatialUser = (WrappedComponent) => {
  // const mapStateToProps = (state, ownProps) => {
  //   if (!ownProps.space) {
  //     return {
  //       userAccountSpace: null
  //     };
  //   }
  //   const spaceId = ownProps.space.Id;
  //   const { accountId } = state.CurrentUser;
  //   const accountSpaceId = state.AccountSpaceMapper[spaceId][accountId];

  //   return {
  //     userAccountSpace: state.Entities.AccountSpaces[accountSpaceId]
  //   };
  // };

  function WithSpatialUser({ children, ...rest }) {
    const space = useSpace();

    const { data: userId, mappedAccountSpaces } = useContext(UserContext);

    const accountSpaceId = mappedAccountSpaces[userId][space.Id];

    const accountSpace = useAccountSpace(accountSpaceId);

    const value = useMemo(() => {
      return {
        ...accountSpace,
        Space: space
      };
    }, [accountSpace, space]);

    return (
      <WrappedComponent userAccountSpace={value} space={space} {...rest}>
        {children}
      </WrappedComponent>
    );
  }
  return WithSpatialUser;
  // return withSpace(
  //   connect(mapStateToProps)(
  //     ({ children, space, userAccountSpace, ...passThroughProps }) => (
  //       <WrappedComponent
  //         userAccountSpace={{ ...userAccountSpace, Space: space }}
  //         space={space}
  //         {...passThroughProps}
  //       >
  //         {children}
  //       </WrappedComponent>
  //     )
  //   )
  // );
};
