import React, {
  useCallback,
  useMemo,
  useLayoutEffect,
  useContext
} from "react";
import { createNewCondition } from "./AvancedFilter";
import {
  stringOperatorsOptions,
  numberOperatorsOptions,
  dateOperatorsOptions,
  generalOperatorsOptions,
  FilterOperatorDropdown,
  FilterOperatorsEnum
} from "./AdvancedFilterOperators";
import {
  FilterConditionsEnum,
  FilterConditionsDropdown
} from "./AdvancedFilterConditions";
import ArDatePicker, { ArOnlyDatePicker } from "../../DatePicker/ArDatePicker";
import FormInput from "../../Forms/FormInput/FormInput";
import { FilterParametersDropdown } from "./AdvancedFilterParameters";
import classnames from "classnames";
import Button from "../../Button/Button";
import DeleteButton from "../../Button/DeleteButton";
import { useIntl, FormattedMessage } from "react-intl";
import {
  FilterOptionTypeEnum,
  getDefaultParameterValues,
  IFilter,
  useAvailableFilters
} from "./AdvancedFilterHelper";
import KeyedDropdown from "../../Dropdown/KeyedDropdown";
import Input from "../../Input/Input";

interface IAdvancedFilterProps {
  value: IFilter;
  updateGroup: any;
  removeGroup: any;
  index: number;
  canUpdateCondition?: boolean;
  depth: number;
  errorRef: any;
  disabled: boolean;
}

export const AdvancedFilterButtons = ({
  addCondition,
  addGroup,
  disabled
}: any) => {
  return (
    <>
      <Button
        disabled={disabled}
        className="mr-2"
        vType="minimal-primary"
        size="sm"
        type="button"
        onClick={addCondition}
      >
        + <FormattedMessage id="CONDITION" />
      </Button>
      <Button
        disabled={disabled}
        size="sm"
        type="button"
        vType="minimal-primary"
        onClick={addGroup}
      >
        + <FormattedMessage id="GROUP" />
      </Button>
    </>
  );
};

export const AdvancedFilterGroup = ({
  value,
  index,
  depth,
  removeGroup,
  updateGroup,
  errorRef,
  disabled
}: IAdvancedFilterProps) => {
  const { Groups } = value;

  const setInternalGroup = useCallback(
    (updateFunc: any) => {
      updateGroup(index, (g: IFilter) => {
        return {
          ...g,
          ...updateFunc(g)
        };
      });
    },
    [index, updateGroup]
  );

  const { availableFilters } = useAvailableFilters();

  const addCondition = useCallback(() => {
    setInternalGroup((g: IFilter) => {
      const newCondition = createNewCondition(availableFilters);
      newCondition.Condition = g.Groups[0].Condition;
      const newGroups = [...g.Groups, newCondition];

      return {
        Groups: newGroups
      };
    });
  }, [setInternalGroup, availableFilters]);

  const addGroup = useCallback(() => {
    setInternalGroup((g: IFilter) => {
      const newCondition = createNewCondition(availableFilters);

      const Condition =
        g.Groups.length > 0 ? g.Groups[0].Condition : FilterConditionsEnum.And;

      const newGroup = {
        Condition,
        Groups: [newCondition]
      };

      const newGroups = [...g.Groups, newGroup];
      return {
        Groups: newGroups
      };
    });
  }, [setInternalGroup, availableFilters]);

  const updateInternalGroup = useCallback(
    (index: number, newGroupsFunc: any) => {
      setInternalGroup((g: IFilter) => {
        const newGroups = [...g.Groups];
        newGroups[index] = newGroupsFunc(newGroups[index]);
        return {
          Groups: newGroups
        };
      });
    },
    [setInternalGroup]
  );

  const updateCondition = useCallback(
    (newCondition: FilterConditionsEnum) => {
      setInternalGroup((g: IFilter) => {
        const { Groups } = g;
        const newGroups = [...Groups];

        for (const g of newGroups) {
          g.Condition = newCondition;
        }

        return {
          Groups: newGroups
        };
      });
    },
    [setInternalGroup]
  );

  const GroupsLength = Groups.length;

  const handleConditionRemoval = useCallback(
    (conditionIndex: number) => {
      //;
      if (GroupsLength === 1) {
        removeGroup(index);
        return;
      }

      setInternalGroup((g: IFilter) => {
        const { Groups } = g;
        const newGroups = [...Groups];

        newGroups.splice(conditionIndex, 1);

        return {
          Groups: newGroups
        };
      });
    },
    [removeGroup, GroupsLength, index, setInternalGroup]
  );

  const isEven = Boolean(depth % 2);

  return (
    <div
      className={classnames("p-3 rounded", {
        "bg-primary-light": isEven,
        "bg-white": !isEven
      })}
    >
      <AdvancedFilterItemList
        disabled={disabled}
        errorRef={errorRef}
        Groups={Groups}
        depth={depth}
        updateGroup={updateInternalGroup}
        removeGroup={handleConditionRemoval}
        updateCondition={updateCondition}
      />
      <div className="mt-3">
        <AdvancedFilterButtons
          disabled={disabled}
          addCondition={addCondition}
          addGroup={addGroup}
        />
      </div>
    </div>
  );
};

interface IAdvancedFilterListProps {
  Groups: IFilter[];
  updateGroup: any;
  removeGroup: any;
  updateCondition: any;
  depth?: number;
  errorRef: any;
  disabled: boolean;
}

export const AdvancedFilterhasErrorSetterContext =
  React.createContext<any>(undefined);

export const useAdvancedFilterhasErrorSetter = () =>
  useContext(AdvancedFilterhasErrorSetterContext);

export const AdvancedFilterItemList = ({
  Groups,
  updateGroup,
  updateCondition,
  removeGroup,
  depth = 0,
  errorRef,
  disabled
}: IAdvancedFilterListProps) => {
  return (
    <>
      {Groups.map((g, i) => {
        const isGroup = Boolean(!g.Parameter);
        return (
          <React.Fragment key={i}>
            {isGroup ? (
              <AdvancedFilterGroup
                disabled={disabled}
                errorRef={errorRef}
                index={i}
                value={g}
                depth={depth + 1}
                updateGroup={updateGroup}
                removeGroup={removeGroup}
              />
            ) : (
              <AdvancedFilterCondition
                disabled={disabled}
                errorRef={errorRef}
                index={i}
                value={g}
                depth={depth}
                updateGroup={updateGroup}
                removeGroup={removeGroup}
                canUpdateCondition={
                  Groups.length > 1 && i !== Groups.length - 1
                }
              />
            )}

            {Groups.length > 1 && i !== Groups.length - 1 && (
              <FilterConditionsDropdown
                disabled={disabled}
                auto
                className="my-3"
                value={g.Condition}
                onChange={updateCondition}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export const AdvancedFilterCondition = ({
  value: filter,
  updateGroup,
  index,
  removeGroup,
  depth,
  errorRef,
  disabled
}: IAdvancedFilterProps) => {
  const handleParameterChange = useCallback(
    (e: any) => {
      const newParam = e.target.value;
      updateGroup(index, (f: IFilter) => {
        let values;
        if (f.Parameter && f.Parameter.type !== newParam.type) {
          values = getDefaultParameterValues(newParam);
        }

        return {
          ...f,
          ...values,
          Parameter: newParam
        };
      });
    },
    [index, updateGroup]
  );
  const { Parameter, Operator, Value, TemplateValue } = filter;

  const handleOperatorChange = useCallback(
    (e: any) => {
      const newOperator = e.target.value;
      updateGroup(index, (f: IFilter) => {
        if (
          newOperator === FilterOperatorsEnum.Next ||
          newOperator === FilterOperatorsEnum.Last ||
          newOperator === FilterOperatorsEnum.NotNext ||
          newOperator === FilterOperatorsEnum.NotLast
        ) {
          return {
            ...f,
            Operator: newOperator,
            Value: 7
          };
        }

        if (
          (f.Operator === FilterOperatorsEnum.Next ||
            f.Operator === FilterOperatorsEnum.Last ||
            f.Operator === FilterOperatorsEnum.NotNext ||
            f.Operator === FilterOperatorsEnum.NotLast) &&
          (newOperator !== FilterOperatorsEnum.Next ||
            newOperator !== FilterOperatorsEnum.Last ||
            newOperator !== FilterOperatorsEnum.NotNext ||
            newOperator !== FilterOperatorsEnum.NotLast)
        ) {
          return {
            ...f,
            Value: new Date(),
            Operator: newOperator
          };
        }
        if (
          (f.Operator === FilterOperatorsEnum.Next ||
            f.Operator === FilterOperatorsEnum.Last) &&
          (newOperator !== FilterOperatorsEnum.Next ||
            newOperator !== FilterOperatorsEnum.Last)
        ) {
          return {
            ...f,
            Value: new Date(),
            Operator: newOperator
          };
        }

        return {
          ...f,
          Operator: newOperator
        };
      });
    },
    [index, updateGroup]
  );

  const handleValueChange = useCallback(
    (e: any) => {
      const newValue = e.target.value;

      updateGroup(index, (f: IFilter) => {
        if (f.Parameter?.createAdvanvedFilterValue) {
          return {
            ...f,
            Value: f.Parameter?.createAdvanvedFilterValue(newValue),
            TemplateValue: newValue
          };
        }
        return {
          ...f,
          Value: newValue
        };
      });
    },
    [index, updateGroup]
  );

  const isValueInValid = Value === null || Value === "";
  const setHasErrors = useAdvancedFilterhasErrorSetter() as any;
  useLayoutEffect(() => {
    if (isValueInValid) {
      const r = errorRef;
      if (
        r.current === 0 &&
        Operator !== FilterOperatorsEnum.IsEmpty &&
        Operator !== FilterOperatorsEnum.IsNotEmpty
      )
        setHasErrors(true);
      r.current++;
      return () => {
        r.current--;
        if (r.current === 0) setHasErrors(false);
      };
    }
  }, [isValueInValid, errorRef, setHasErrors, Operator]);

  const operatorOptions = useMemo(() => {
    if (!Parameter) return null;
    const { type } = Parameter;

    switch (type) {
      case FilterOptionTypeEnum.string:
        return stringOperatorsOptions;

      case FilterOptionTypeEnum.number:
        return numberOperatorsOptions;

      case FilterOptionTypeEnum.datetime:
        return dateOperatorsOptions;

      default:
        return generalOperatorsOptions;
    }
  }, [Parameter]);

  const valueInputType = useMemo(() => {
    if (!Parameter) return undefined;

    const { type, component } = Parameter;

    if (!type) {
      return component;
    }

    if (
      type === FilterOptionTypeEnum.datetime ||
      type === FilterOptionTypeEnum.date
    ) {
      if (
        Operator === FilterOperatorsEnum.Next ||
        Operator === FilterOperatorsEnum.Last ||
        Operator === FilterOperatorsEnum.NotLast ||
        Operator === FilterOperatorsEnum.NotNext
      ) {
        return Input;
      }
      if (type === FilterOptionTypeEnum.date) {
        return ArOnlyDatePicker;
      }
      // else if (
      //   Operator === FilterOperatorsEnum.NextCustom ||
      //   Operator === FilterOperatorsEnum.LastCustom
      // )
      //   return undefined;
      else {
        return ArOnlyDatePicker;
      }
    }
    return undefined;
  }, [Operator, Parameter]);

  const { availableFilters } = useAvailableFilters();
  const intl = useIntl();
  return (
    <div className={classnames("rounded")}>
      <div className={"d-flex align-items-end"}>
        <FormInput
          disabled={disabled}
          disableAutomation
          className="mr-2 flex-1"
          text={intl.formatMessage({ id: "RESOURCE" })}
          inputType={FilterParametersDropdown}
          options={availableFilters}
          value={Parameter}
          onChange={handleParameterChange}
        />

        <FormInput
          disabled={disabled}
          className="mr-2 flex-1 mw-20"
          disableAutomation
          text={intl.formatMessage({ id: "OPERATOR" })}
          inputType={FilterOperatorDropdown}
          options={operatorOptions}
          value={Operator}
          onChange={handleOperatorChange}
        />

        <FormInput
          disabled={disabled}
          disableAutomation={
            Operator === FilterOperatorsEnum.Next ||
            Operator === FilterOperatorsEnum.Last ||
            Operator === FilterOperatorsEnum.NotLast ||
            Operator === FilterOperatorsEnum.NotNext
          }
          type={
            ((Operator === FilterOperatorsEnum.Next ||
              Operator === FilterOperatorsEnum.Last ||
              Operator === FilterOperatorsEnum.NotLast ||
              Operator === FilterOperatorsEnum.NotNext) &&
              "number") ||
            Parameter?.type
          }
          min={
            (Operator === FilterOperatorsEnum.Next ||
              Operator === FilterOperatorsEnum.Last ||
              Operator === FilterOperatorsEnum.NotLast ||
              Operator === FilterOperatorsEnum.NotNext) &&
            "1"
          }
          className="mr-2 flex-1 mw-40"
          text={intl.formatMessage({ id: "VALUE" })}
          inputType={valueInputType}
          value={TemplateValue || Value}
          onChange={handleValueChange}
        />

        <DeleteButton
          disabled={disabled}
          onClick={() => {
            ////;
            removeGroup(index);
          }}
        />
      </div>
    </div>
  );
};

const NextDateOptions = [7, 15, 30, 45, 60, 90, 120, 180, 365];

const CombineValueComponent = ({ ...props }) => {
  const { item } = props;
  if (!item) return <div></div>;
  return <div>{item}</div>;
};

const NextDateInputDropdown = ({ ...props }) => {
  const { value, onChange } = props;
  return (
    <KeyedDropdown
      value={value}
      onChange={onChange}
      options={NextDateOptions}
      closeOnSelect
      valueComponent={CombineValueComponent}
      {...props}
    />
  );
};
