import React, { useState, useCallback, useMemo, useRef } from "react";
import { useSpace } from "../../../Contexts/SpaceContext";
import {
  PageContainer,
  PageContent
} from "../../../Components/PageUtilities/PageUtilities";
import {
  useSpaceQuery,
  useSpacePost,
  useQuery
} from "../../../Helpers/IOClient";
import LoaderSpinner from "../../../Components/Loader/LoaderSpinner/LoaderSpinner";
import FormInput from "../../../Components/Forms/FormInput/FormInput";
import LoadableButton from "../../../Components/Button/LoadableButton";
import { useToast } from "../../../Components/Toast/ToastProvider";
import { spaceSchema } from "../../../config/schema";
import {
  CountryDropdown,
  CurrencyDropdown,
  TimezoneDropdown
} from "../../AdvancedMultiInputs";
import { findWindows } from "windows-iana";
import {
  //   useCurrentAccount,
  useCurrentAccountSpace,
  useCurrentAccount
} from "../../../Contexts/UserContext";
import classnames from "classnames";
import { isAdmin, isDeveloper } from "../../../Helpers/ModulesHelper";
import { useRouteMatch } from "react-router-dom";
import TabStrip from "../../../Components/TabStrip/TabStrip";
import Exports from "../Exports/Exports";
import { useIntl, FormattedMessage } from "react-intl";
import SpaceDomainInput from "./SpaceDomainInput";
import "./Space.css";

import { handleError } from "../../../Helpers/MiscHelper";
import DangerZoneOptions from "../../WorkspaceSubscription/DangerZoneOptions";
import { Languages } from "../../Intl18n/Intl18nProvider";
import { CountryProfileImg } from "../../../Components/Img/Img";
import KeyedDropdown from "../../../Components/Dropdown/KeyedDropdown";
import TSSExport from "./TSSExport";

const Space = () => {
  const { loading, error } = useSpaceQuery("settings/space", spaceSchema, {
    cache: false
  });

  if (loading || error)
    return (
      <LoaderSpinner
        size="sm"
        className="text-secondary d-flex align-items-center justify-content-center h-100"
      />
    );

  return <SpaceContent />;
};

const SpaceContent = () => {
  const intl = useIntl();
  const space = useSpace();
  const [form, setForm] = useState(() => {
    return { ...space };
  });

  const { Name, DomainName, AvailableCurrencies, TimeZone, Country, Language } =
    form;

  const handleChange = useCallback((e) => {
    const { value, name } = e.target;

    setForm((f) => {
      return {
        ...f,
        [name]: value
      };
    });
  }, []);

  const handleNameChange = useCallback((e) => {
    //;
    const v = e.target.value;
    setForm((f) => {
      return {
        ...f,
        Name: v
      };
    });
  }, []);

  const handleCountryChange = useCallback((e) => {
    //;
    const v = e.target.value;
    setForm((f) => {
      return {
        ...f,
        Country: v
      };
    });
  }, []);

  const handleAvailableCurrenciesChange = useCallback((e) => {
    const v = e.target.value;
    setForm((f) => {
      return {
        ...f,
        AvailableCurrencies: v
      };
    });
  }, []);

  const handleDomainNameChange = useCallback((e) => {
    const v = e.target.value;
    setForm((f) => {
      return {
        ...f,
        DomainName: v
      };
    });
  }, []);

  const createToast = useToast();
  const [post, { loading }] = useSpacePost("settings/space", spaceSchema, {
    onSuccess: () => {
      createToast({
        pos: "tm",
        type: "success",
        title: `${intl.formatMessage({ id: "SPACE_UPDATE" })}`,
        description: `${intl.formatMessage({
          id: "SPACE_UPDATE_MSG"
        })}`
      });
    },
    onError: ({ error }) => {
      const { status } = error;

      if (status === 409) {
        createToast({
          pos: "tm",
          type: "danger",
          description: `${intl.formatMessage({
            id: "SPACE_UPDATE_DOMAIN_CONFLICT"
          })}`
          // title: `Erro`,
        });
      } else {
        handleError(createToast, error);
      }
    }
  });

  const submit = () => {
    const formatedForm = formatSpaceForm(form);
    post(formatedForm);
  };

  const user = useCurrentAccountSpace();
  const admin = isAdmin(user);

  return (
    <PageContainer style={{ marginTop: "-10px" }}>
      <PageContent className="d-flex flex-column">
        <div className="w-450px">
          <FormInput
            preview={!admin}
            text={intl.formatMessage({ id: "NAME" })}
            className="mb-3"
            componentClassName="bg-white"
            value={Name}
            textInputClassname="bg-white"
            onChange={handleNameChange}
          />

          <FormInput
            disabled={!admin}
            text={intl.formatMessage({ id: "DOMAIN" })}
            inputType={SpaceDomainInput}
            className="mb-3"
            componentClassName="bg-white"
            value={DomainName}
            textInputClassname="bg-white"
            onChange={handleDomainNameChange}
          />

          <FormInput
            disabled={!admin}
            preview={!admin}
            text={intl.formatMessage({ id: "DEFAULT_LANGUAGE" })}
            inputType={SpaceLanguagenDropdown}
            className="mb-3"
            name="Language"
            componentClassName="bg-white"
            value={Language}
            textInputClassname="bg-white"
            onChange={handleChange}
          />
          <hr className="mt-4" />
          <FormInput
            preview={!admin}
            text={intl.formatMessage({ id: "COUNTRY" })}
            className="mb-3"
            componentClassName="bg-white"
            inputType={CountryDropdown}
            value={Country}
            onChange={handleCountryChange}
          />

          <FormInput
            preview={!admin}
            disabled={!Country}
            text={intl.formatMessage({ id: "TIMEZONE" })}
            className="mb-3"
            name="TimeZone"
            TimeZone
            Country={Country}
            Language={Language}
            componentClassName="bg-white"
            inputType={TimezoneDropdown}
            value={TimeZone}
            onChange={handleChange}
          />

          <hr className="mt-4" />
          <FormInput
            preview={!admin}
            text={intl.formatMessage({ id: "CURRENCY" })}
            className="mb-3"
            componentClassName="bg-white"
            inputType={CurrencyDropdown}
            multiple
            value={AvailableCurrencies}
            onChange={handleAvailableCurrenciesChange}
          />

          <LoadableButton
            disabled={!admin}
            vType="primary-ghost"
            isLoading={loading}
            onClick={submit}
          >
            <div>
              <FormattedMessage id={"SAVE"} />
            </div>
          </LoadableButton>
        </div>
        <DangerZoneOptions />
      </PageContent>
    </PageContainer>
  );
};

const SpaceSettingsContext = React.createContext({});

const SpaceProvider = ({ children, value }) => {
  return (
    <SpaceSettingsContext.Provider value={value}>
      {children}
    </SpaceSettingsContext.Provider>
  );
};

const NewSpace = React.memo((props) => {
  const { disableTabs, onMouseDown, settings, type, ...rest } = props;
  const currentAccount = useCurrentAccount();
  const match = useRouteMatch();
  const space = useSpace();

  const path = match.url;

  const tabItems = useMemo(() => {
    const isDeve = isDeveloper(currentAccount);
    if (disableTabs) return null;
    let r = [
      {
        default: true,
        exact: true,
        name: <FormattedMessage id={"GENERAL_INFORMATION"} />,
        component: Space
      },
      {
        exact: true,
        name: <FormattedMessage id={"EXPORTATIONS"} />,
        to: "exports",
        component: Exports
      }
    ];
    if (isDeve && space.Id === 1) {
      r.push({
        exact: true,
        name: "Relatórios TSS",
        to: "tss-export",
        component: TSSExport
      });
    }
    return r;
  }, [currentAccount, disableTabs, space]);
  const pageRef = useRef();
  const { containerClassName } = settings || {};
  return (
    <div className="pt-2 h-100 w-100 of-hidden">
      <SpaceSettingsContext.Provider value={settings}>
        <div
          onMouseDown={onMouseDown}
          ref={pageRef}
          className={containerClassName || "flex-1 px-4 py-3 cursor-auto h-100"}
        >
          <div className="text-black fw-regular fs-22 mb-2">
            <FormattedMessage id={"GERAL"} />
          </div>
          <SpaceProvider value={{ ...rest, pageRef }}>
            {disableTabs ? (
              <Space key={type} {...rest} />
            ) : (
              <TabStrip
                defaultPath={path}
                alignLeft
                items={tabItems}
                className="ar-details-tabstrip h-100"
              />
            )}
          </SpaceProvider>
        </div>
      </SpaceSettingsContext.Provider>
    </div>
  );
});

const GiveKey = (item) => {
  return item.Lang;
};

const LanguageItem = ({ item, className }) => {
  if (!item) return null;

  const { Lang, Value } = item;

  const resolvedItem = Languages[Lang || Value];

  return (
    <div className={classnames("d-flex align-items-center", className)}>
      <CountryProfileImg size="sm" country={resolvedItem.country} />
      <div>{resolvedItem.Name}</div>
    </div>
  );
};

export const SpaceLanguagenDropdown = React.memo(
  ({ className, value, onChange, disabled, preview }) => {
    const intl = useIntl();
    const { data, loading } = useQuery(`Query/Languages`);

    const handleChange = async (lang) => {
      const { Lang, Id } = lang;
      const item = Languages[Lang];
      item.Id = Id;
      onChange && onChange(item);
    };

    // const resolvedValue = useMemo(() => {
    //   if (!value) return null;
    //   const { Value } = value;
    //   return {
    //     Lang: Value
    //   };
    // }, [value]);

    return (
      <KeyedDropdown
        className={className}
        valueComponent={LanguageItem}
        searchPlaceHolder={intl.formatMessage({ id: "SEARCH" })}
        showImage={false}
        value={value}
        getKey={GiveKey}
        closeOnSelect={true}
        disabled={disabled || preview || loading}
        options={data || []}
        onChange={handleChange}
      />
    );
  }
);

const formatSpaceForm = (form) => {
  const { Language, Country, TimeZone, ...rest } = form;
  const TimezoneWid = findWindows(TimeZone?.Id) || [];
  return {
    ...rest,
    LanguageId: Language.Id,
    CountryId: Country,
    TimeZone: JSON.stringify({
      Id: TimeZone?.Id,
      Wid: TimezoneWid[0]
    })
  };
};

export default NewSpace;
