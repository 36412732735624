import React, { Component } from "react";
import OnboardingHeader from "../Headers/OnboardingHeader";
import { isEmail, isPasswordValid } from "../../Helpers/MiscHelper";
import { injectIntl, FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import {
  registerUser,
  removeRegisterError
} from "../../Redux/Actions/AuthActions";
import LoadableButton from "../../Components/Button/LoadableButton";
import { AuthErrors } from "../../Redux/Actions/UserActions";
import FormErrorAlert from "../../Components/Forms/FormErrorAlert/FormErrorAlert";
import withOptionalInvite from "../../Hoc/withOptionalInvite";
import { Redirect } from "react-router-dom";
import LabeledInput from "../../Components/Input/LabeledInput";
import { Intl18nDropdown } from "../Intl18n/Intl18nDropdown";
import Checkbox from "../../Components/Checkbox/Checkbox";
import { useTermsAndConditionsUrl } from "../../Helpers/CopyrightHelper";
import ReactPasswordStrength from "./PasswordStrength/PasswordStrenghComponent";
import InformationComponent from "../../Components/InformationComponent/InformationComponent";
import { CountryDropdown } from "../AdvancedMultiInputs";
import OnboardingContainer from "../Headers/OnboardingContainer";

const RegisterErrorEnum = {
  AlreadyAccepted: "1", // Invite was already accepted
  InvitationNotFound: "2", // Invitation not found
  EmailDoesNotMatchInvitedEmail: "3", // Email does not match the invitation email
  NotValid: "4", // Provided data is not valid. Please make sure to provide a valid name, password and email.
  EmailAlreadyExists: "5", // This email already exists
  ReservedName: "6", // Reserved name
  MembershipError: "7"
};

const RegisterErrorText = ({ error }) => {
  const intl = useIntl();
  const code = error && error.data && error.data.Message;

  switch (code) {
    case RegisterErrorEnum.AlreadyAccepted:
      return <FormattedMessage id="REGISTER_ERROR_ALREADY_ACCEPTED" />;

    case RegisterErrorEnum.InvitationNotFound:
      return <FormattedMessage id="REGISTER_ERROR_INVITE_NOT_FOUND" />;

    case RegisterErrorEnum.EmailDoesNotMatchInvitedEmail:
      return <FormattedMessage id="REGISTER_ERROR_EMAIL_MISMATCH" />;

    case RegisterErrorEnum.NotValid:
      return <FormattedMessage id="REGISTER_ERROR_NOT_VALID" />;

    case RegisterErrorEnum.EmailAlreadyExists:
      return <FormattedMessage id="REGISTER_ERROR_EMAIL_EXISTS" />;

    case RegisterErrorEnum.ReservedName:
      return <FormattedMessage id="REGISTER_ERROR_RESERVED_NAME" />;

    case RegisterErrorEnum.MembershipError:
      return <FormattedMessage id="REGISTER_ERROR_MEMBERSHIP_ERROR" />;

    default:
      return (
        <>
          <span>{intl.formatMessage({ id: "REGISTER_UNKNOWN_ERROR" })}</span>
          <br />
          <span>{intl.formatMessage({ id: "TRY_AGAIN_IN_SOME_TIME" })}</span>
        </>
      );
  }
};

class Register extends Component {
  constructor(props) {
    super(props);

    const { invite } = props;

    this.state = {
      form: {
        name: "",
        CountryId: "",
        email: invite ? invite.Email : "",
        password: "",
        language: null
      },
      errors: {
        name: false,
        email: false,
        password: false
      },
      hasErrors: false,
      acceptTerms: false
    };
  }

  handleInputChange = (e) => {
    const { hasErrors } = this.state;

    let newState = {
      ...this.state,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    };

    if (hasErrors) {
      newState.hasErrors = false;
      newState.errors = {
        name: false,
        email: false,
        password: false
      };
    }

    if (this.props.registerPage.error) this.props.removeRegisterError();

    this.setState(newState);
  };

  handleCountryInputChange = (e) => {
    const { hasErrors } = this.state;

    let newState = {
      ...this.state,
      form: {
        ...this.state.form,
        CountryId: e
      }
    };

    if (hasErrors) {
      newState.hasErrors = false;
      newState.errors = {
        name: false,
        email: false,
        password: false
      };
    }

    if (this.props.registerPage.error) this.props.removeRegisterError();

    this.setState(newState);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let newState = {
      ...this.state
    };

    const addError = (error) => {
      newState.errors[error] = true;
      newState.hasErrors = true;
    };

    const { name, email, password, language, countryId } = this.state.form;

    let isNameValid = name.length > 0;
    let isEmailValid = isEmail(email);

    if (!isNameValid) addError("name");

    if (!isPasswordValid(password)) addError("password");

    if (!isEmailValid) addError("email");

    if (newState.hasErrors) return this.setState(newState);

    const { registerUser, inviteId } = this.props;
    const { os, browser } = getUserData();
    const resolvedForm = {
      name,
      email,
      password,
      countryId,
      LanguageId: language,
      inviteId: inviteId ? inviteId : undefined,
      Browser: browser,
      OpSystem: os
    };

    registerUser(resolvedForm);
  };

  render() {
    const {
      // form,
      errors,
      hasErrors,
      acceptTerms
    } = this.state;
    const { intl, registerPage, invite, isAuthenticated } = this.props;

    const toggleAcceptTerms = () => {
      this.setState((s) => {
        return {
          acceptTerms: !s.acceptTerms
        };
      });
    };

    if (isAuthenticated) {
      if (invite) return <Redirect to={`/s/${invite.Space.Id}`} />;
      else return <Redirect to={`/s`} />;
    }

    const { error, isProcessing } = registerPage;
    const { name, email, password, CountryId } = this.state.form;

    let {
      name: nameError,
      email: emailError,
      password: passwordError
    } = errors;

    nameError =
      nameError && intl.formatMessage({ id: "REGISTER_INVALID_NAME" });
    emailError =
      (emailError && intl.formatMessage({ id: "REGISTER_INVALID_EMAIL" })) ||
      (error === AuthErrors.EMAIL_EXISTS &&
        intl.formatMessage({ id: "REGISTER_EMAIL_EXISTS" }));
    passwordError =
      passwordError && intl.formatMessage({ id: "REGISTER_INVALID_PASSWORD" });

    let hasApiError = error && !emailError;

    let canSubmit =
      !hasErrors && Boolean(name) && Boolean(email) && Boolean(password);
    return (
      <OnboardingContainer>
        <OnboardingHeader disableProgress={true} />
        <div className="mx-4">
          <form
            onSubmit={this.handleSubmit}
            className="ar-onboarding-basic-form mx-auto py-4"
          >
            <div className="fs-28 mb-2 text-black">
              <FormattedMessage id={"REGISTER_WELCOME"} />
            </div>
            <div className="mb-4">
              <FormattedMessage id={"REGISTER_DESCRIPTION"} />
            </div>
            <div className="mb-3 fw-medium text-black">
              <FormattedMessage id={"PERSONAL_DATA"} />
            </div>

            <LabeledInput
              className="mb-3"
              compclassname="bg-white"
              text={<FormattedMessage id={"FULL_NAME"} />}
              name="name"
              value={name}
              onChange={this.handleInputChange}
              error={nameError}
            />

            <LabeledInput
              className="mb-3 disable-selection"
              compclassname="bg-white"
              name={"CountryId"}
              value={CountryId}
              onChange={this.handleCountryInputChange}
              text={<FormattedMessage id="COUNTRY" />}
              InputComponent={CountryDropdown}
            />

            <LabeledInput
              className="mb-3"
              name="language"
              onChange={(item, lang) => {
                this.setState((s) => {
                  return {
                    ...s,
                    form: {
                      ...s.form,
                      language: lang.Id
                    }
                  };
                });
              }}
              compclassname="bg-white"
              text={<FormattedMessage id={"SELECT_LANGUAGE"} />}
              InputComponent={Intl18nDropdown}
            />

            <LabeledInput
              className="mb-3"
              compclassname="bg-white"
              text={
                <div className="d-flex align-items-center">
                  <span className="mr-2">
                    <FormattedMessage id={"EMAIL"} />
                  </span>
                  <InformationComponent
                    text={<FormattedMessage id="EMAIL_INFO_DESC" />}
                  />
                </div>
              }
              name="email"
              type="email"
              value={email}
              onChange={this.handleInputChange}
              error={emailError}
              disabled={Boolean(invite)}
            />
            <LabeledInput
              compclassname="bg-white"
              text={<FormattedMessage id={"PASSWORD"} />}
              name="password"
              type="password"
              value={password}
              onChange={this.handleInputChange}
              error={passwordError}
            />
            <ReactPasswordStrength Password={password} />

            <Checkbox
              className="secondary border-black-invite my-4 register"
              checked={acceptTerms}
              onChange={toggleAcceptTerms}
            >
              <div className="text-black ">
                <TermsText />
              </div>
            </Checkbox>

            {hasApiError && (
              <FormErrorAlert className="mb-4">
                <RegisterErrorText error={error} />
              </FormErrorAlert>
            )}
            <LoadableButton
              vType="secondary"
              isLoading={isProcessing}
              // size="lg"
              disabled={!canSubmit || !acceptTerms}
              className="w-100"
            >
              <FormattedMessage id={"CONTINUE"} />
            </LoadableButton>
          </form>
        </div>
      </OnboardingContainer>
    );
  }
}

const TermsText = () => {
  const termsUrl = useTermsAndConditionsUrl();
  return (
    <FormattedMessage
      id={"ACCEPT_TERMS_AND_CONDITIONS"}
      values={{
        a: (...chunks) => (
          <a
            className="link-secondary"
            target="_blank"
            rel="noopener noreferrer"
            href={termsUrl}
          >
            {chunks}
          </a>
        )
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    registerPage: state.Pages.Register,
    isAuthenticated: state.Auth.isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (form) => dispatch(registerUser(form)),
    removeRegisterError: (form) => dispatch(removeRegisterError(form))
  };
};

export default withOptionalInvite(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(Register))
);

function getUserData() {
  var unknown = "-";
  // screen

  // browser
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var browser = navigator.appName;
  var version = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var nameOffset, verOffset, ix;
  // Opera
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browser = "Opera";
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf("OPR")) !== -1) {
    browser = "Opera";
    version = nAgt.substring(verOffset + 4);
  }
  // Legacy Edge
  else if ((verOffset = nAgt.indexOf("Edge")) !== -1) {
    browser = "Microsoft Legacy Edge";
    version = nAgt.substring(verOffset + 5);
  }
  // Edge (Chromium)
  else if ((verOffset = nAgt.indexOf("Edg")) !== -1) {
    browser = "Microsoft Edge";
    version = nAgt.substring(verOffset + 4);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browser = "Microsoft Internet Explorer";
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browser = "Chrome";
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browser = "Safari";
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browser = "Firefox";
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf("Trident/") !== -1) {
    browser = "Microsoft Internet Explorer";
    version = nAgt.substring(nAgt.indexOf("rv:") + 3);
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() === browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }
  // trim the version string
  if ((ix = version.indexOf(";")) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(" ")) !== -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(")")) !== -1) version = version.substring(0, ix);
  majorVersion = parseInt("" + version, 10);
  if (isNaN(majorVersion)) {
    version = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  // mobile version
  // var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);
  // cookie
  var cookieEnabled = navigator.cookieEnabled ? true : false;
  if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1 ? true : false;
  }
  // system
  var os = unknown;
  var clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Chrome OS", r: /CrOS/ },
    { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }
  ];
  for (var id in clientStrings) {
    var cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  var osVersion = unknown;
  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = "Windows";
  }
  // eslint-disable-next-line default-case
  switch (os) {
    case "Mac OS":
    case "Mac OS X":
    case "Android":
      osVersion =
        /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
          nAgt
        )[1];
      break;
    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
      break;
  }

  return { os, browser, osVersion };
}
