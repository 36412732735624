import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./Reports.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileSpreadsheet,
  faFileChartLine,
  faFilePowerpoint,
  faFileContract,
  faFileChartPie,
  faUserClock,
  faMemoCircleCheck,
  faFileDownload,
  faFileInvoice
} from "@fortawesome/pro-solid-svg-icons";
import { useSpace } from "../../Contexts/SpaceContext";
import { NavLink } from "react-router-dom";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import {
  hasInterventionReportAccess,
  hasTasksReportAccess,
  hasTicketsReportAccess,
  hasProjectsReportAccess,
  hasContractsReportAccess,
  hasDealsReportAccess,
  hasReportsClassificationAccess,
  hasDealFinancialPermission,
  hasContractFinancialPermission,
  hasModuleAccess,
  moduleTypes,
  isAdmin,
  hasReportsProductivityAccess
} from "../../Helpers/ModulesHelper";
import { useAirdeskTitle } from "../../Hooks/MiscHooks";

const InterventionsButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();

  if (!hasInterventionReportAccess(accountSpace)) {
    return null;
  }

  return (
    <NavLink
      to={`/s/${spaceId}/reports`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileSpreadsheet} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="TIMESHEET" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="TIMESHEET_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

// const CallsButton = ({ spaceId }) => {
//   return (
//     <NavLink to={`/s/${spaceId}/`} className="air-Report-button d-inline-block">
//       <div className="d-flex">
//         <div className="mr-3 flex-0-0-auto air-report-icon">
//           <FontAwesomeIcon size="lg" icon={faPhone} />
//         </div>
//         <div>
//           <div className="fs-18 text-black fw-medium">
//             <FormattedMessage id="CALLS" />
//           </div>
//           <div className="fs-16 text-black">
//             <FormattedMessage id="CALLS_DESCRIPTION" />
//           </div>
//         </div>
//       </div>
//     </NavLink>
//   );
// };

const TasksButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasTasksReportAccess(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsTasks`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileChartLine} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="TASKS" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="TASKS_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const TicketsButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasTicketsReportAccess(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsTickets`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileChartLine} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="CASES" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="TICKETS_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const ProjectsButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasProjectsReportAccess(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsProjects`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFilePowerpoint} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="PROJECTS" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="PROJECT_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const ContractsButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasContractsReportAccess(accountSpace)) return null;
  if (!hasContractFinancialPermission(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsContracts`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileContract} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="CONTRACTS" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="CONTRACTS_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const DealsButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasDealsReportAccess(accountSpace)) return null;
  if (!hasDealFinancialPermission(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsDeals`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileChartPie} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="DEALS" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="DEALS_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};
const ExportsButton = ({ spaceId }) => {
  return (
    <NavLink
      to={`/s/${spaceId}/spaceExports`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileDownload} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="EXPORTS" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="EXPORTS_REPORTS_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const ClassificationsButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasReportsClassificationAccess(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsClassification`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faMemoCircleCheck} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="CLASSIFICATIONS" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="CLASSIFICATION_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};
const ProductivityButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasReportsProductivityAccess(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsProductivity`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileInvoice} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="PRODUCTIVITY" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="PRODUCTIVITY_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const PersonalProductivityButton = ({ spaceId }) => {
  return (
    <NavLink
      to={`/s/${spaceId}/reportsPersonalProductivity`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faFileInvoice} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="PRODUCTIVITY" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="PERSONAL_PRODUCTIVITY_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const TravelReportButton = ({ spaceId }) => {
  const accountSpace = useCurrentAccountSpace();
  if (!hasReportsProductivityAccess(accountSpace)) return null;
  return (
    <NavLink
      to={`/s/${spaceId}/reportsTravel`}
      className="air-Report-button d-inline-block"
    >
      <div className="d-flex">
        <div className="mr-3 flex-0-0-auto air-report-icon">
          <FontAwesomeIcon size="lg" icon={faUserClock} />
        </div>
        <div>
          <div className="fs-18 text-black fw-medium">
            <FormattedMessage id="TRAVEL_TIMES" />
          </div>
          <div className="fs-16 text-black">
            <FormattedMessage id="TRAVEL_TIMES_DESCRIPTION" />
          </div>
        </div>
      </div>
    </NavLink>
  );
};

const ReportingOverviewComp = () => {
  const { Id } = useSpace();
  const accountSpace = useCurrentAccountSpace();
  const intl = useIntl();
  const { Modules, Space } = accountSpace;

  const hasExportPermission =
    isAdmin(accountSpace) ||
    hasModuleAccess(Modules[moduleTypes.settingsSpace]);

  const hasComercialPermission =
    hasContractsReportAccess(accountSpace) ||
    hasDealsReportAccess(accountSpace) ||
    hasReportsClassificationAccess(accountSpace);

  const hasServicesPermission =
    hasInterventionReportAccess(accountSpace) ||
    hasTasksReportAccess(accountSpace) ||
    hasTicketsReportAccess(accountSpace) ||
    hasProjectsReportAccess(accountSpace);

  useAirdeskTitle(intl.formatMessage({ id: "REPORTING" }));

  return (
    <div className="of-y-auto px-3">
      <div className="airReportingContainer">
        <div className="fs-22 text-black fw-medium">
          <FormattedMessage id="REPORTING" />
        </div>
        <div className="my-3 border-bottom"></div>
        {Space === 1 ? (
          <></>
        ) : (
          <>
            {hasServicesPermission && (
              <div className="fs-20 text-black mb-2">
                <FormattedMessage id="PERSONAL" />
              </div>
            )}
            <div
              style={{ margin: "-10px -10px" }}
              className="align-items-center d-flex flex-wrap justify-content-between mb-3"
            >
              <PersonalProductivityButton spaceId={Id} />
            </div>
          </>
        )}

        {hasServicesPermission && (
          <div className="fs-20 text-black mb-2">
            <FormattedMessage id="SERVICES" />
          </div>
        )}
        <div
          style={{ margin: "-10px -10px" }}
          className="align-items-center d-flex flex-wrap justify-content-between mb-3"
        >
          <InterventionsButton spaceId={Id} />
          {/* <CallsButton spaceId={Id} /> */}
          <TasksButton spaceId={Id} />
          <TicketsButton spaceId={Id} />
          <ProjectsButton spaceId={Id} />
        </div>
        {hasComercialPermission && (
          <div className="fs-20 text-black mb-2">
            <FormattedMessage id="COMERCIAL" />
          </div>
        )}
        <div
          style={{ margin: "-10px -10px" }}
          className="align-items-center d-flex flex-wrap justify-content-between mb-3"
        >
          <ContractsButton spaceId={Id} />
          <DealsButton spaceId={Id} />
          <ClassificationsButton spaceId={Id} />
          <ProductivityButton spaceId={Id} />
          <TravelReportButton spaceId={Id} />
        </div>
        {hasExportPermission && (
          <>
            <div className="fs-20 text-black mb-2">
              <FormattedMessage id="EXPORTS" />
            </div>
            <div
              style={{ margin: "-10px -10px" }}
              className="align-items-center d-flex flex-wrap justify-content-between mb-3"
            >
              <ExportsButton spaceId={Id} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportingOverviewComp;
