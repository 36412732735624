import React, { useEffect, useRef } from "react";
import SuspenseErrorBoundary from "../../Components/Suspense/SuspenseErrorBoundary";
import { useSpaceEntitiesHub } from "../RealTime/RealTimeSpace";

import {
  spaceEmailConversationSchema,
  spaceEmailSchema
} from "../../config/schema";
import { normalize } from "../../Helpers/SchemaHelper";
import {
  updateNormalizedSchemaCache,
  client,
  getEntity
} from "../../Helpers/IOClient";
import { useSpace } from "../../Contexts/SpaceContext";
import { useConnectionId } from "../RealTime/RealTime";
import { ReceptionSchema } from "./ReceptionComp";
// import {
//   RealTimePageProvider,
//   RealTimePageEnum
// } from "../RealTime/RealTimePages";
import { moduleTypes } from "../../Helpers/ModulesHelper";
import { ServerAwareProvider } from "../../Components/CGrid/ServerGrid";
import { getInboxFilterSetting } from "./ReceptionFilterSettings";
import { InboxMatchTypeEnum, ReceptionFilterColumns } from "./ReceptionHelpers";
import { useRouteMatch } from "react-router-dom";
import {
  useEnterViewSignalR,
  PageTypeProvider
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { RealTimePageEnum } from "../RealTime/RealTimePages";

const ReceptionOverviewComp = React.lazy(() =>
  import(/* webpackChunkName: "inbox" */ "./ReceptionComp")
);

export const useConversationSync = (updateConversations, isDetaisView) => {
  const entitiesHub = useSpaceEntitiesHub();

  // const url = useMemo(() => {
  //   return `${schema.getEndpoint(spaceId)}/${Id}/Read`;
  // }, [Id, schema, spaceId]);

  // const fetch = useUnreadEntitiesFetcher();

  // const [post] = usePost(url, schema, {
  //   onSuccess: () => {
  //     fetch();
  //   },
  // });

  const space = useSpace();

  useEffect(() => {
    if (!entitiesHub) return;

    const listener = (type, conversationId, messageId, data) => {
      if (!isDetaisView) {
        const parseData = JSON.parse(data);

        const normalized = normalize(
          parseData.EmailConversation,
          spaceEmailConversationSchema
        );

        const update = normalized.entities;

        updateNormalizedSchemaCache(update);

        updateConversations((c) => {
          const newArr = [...c];
          const index = c.findIndex((e) => {
            return e.EmailConversation === conversationId;
          });
          const value = newArr.splice(index, 1);
          newArr.unshift(value[0]);
          return newArr;
        });
      }

      // if (data.EmailConversation.IsFollowing)
      //   client.post(
      //     `${spaceEmailConversationSchema.getEndpoint(
      //       space.Id
      //     )}/${conversationId}/read`,
      //     null,
      //     {
      //       schema: spaceEmailConversationSchema,
      //     }
      //   );
    };

    entitiesHub.on("NewConversationMessage", listener);

    return () => entitiesHub.off("NewConversationMessage", listener);
  }, [entitiesHub, isDetaisView, space.Id, updateConversations]);
};

// const initialParams = () => {
//   return {
//     filterColumns: [
//       createReadFilter({
//         icon: <FontAwesomeIcon icon={faEye} />,
//         baseField: "Conversation",
//       }),
//       createUnreadFilter({
//         icon: <FontAwesomeIcon icon={faEyeSlash} />,
//         baseField: "Conversation",
//       }),
//     ],
//   };
// };

export const useConversationCreationSync = (
  updateConversations,
  isDetaisView
) => {
  const entitiesHub = useSpaceEntitiesHub();
  const space = useSpace();
  const conId = useConnectionId();
  const match = useRouteMatch();
  const detailMatch = useRouteMatch(`${match.url}/:type/:id`);
  const detailMatchRef = useRef();
  useEffect(() => {
    detailMatchRef.current = detailMatch;
  }, [detailMatch]);

  useEffect(() => {
    if (!entitiesHub) return;
    if (isDetaisView) return;
    const listener = async (conversationType, entityId, entityType) => {
      const filterSettings = getInboxFilterSetting(
        conversationType,
        entityType
      );

      if (!filterSettings) return;

      const {
        conversationTypeFlag,
        entityNoteTypeFlag,
        filterKey,
        entityKey,
        schema
      } = filterSettings;

      let url = `/spaces/${space.Id}/conversations?$top=1&$skip=0&$filter=((${filterKey} eq '${entityId}'))`;

      if (entityNoteTypeFlag) url += `&entityNoteType=${entityNoteTypeFlag}`;

      url += `&conversationType=${conversationTypeFlag}`;
      const dMatch = detailMatchRef.current;
      const response = await client.get(url, {
        headers: { ConnectionID: conId },
        schema: [ReceptionSchema]
      });
      const { data, error } = response;
      if (error) {
      } else {
        if (data.length !== 1) return;
        const response = data[0];
        const connectedVal = getEntity(schema, response[entityKey]);

        const { IsRead } = connectedVal;

        let resolvedIsRead = IsRead;
        if (dMatch) {
          const { type, id } = dMatch.params;
          const numType = Number(type);

          if (
            InboxMatchTypeEnum[numType] === entityKey &&
            id === String(response[entityKey])
          ) {
            const update = {
              [schema.name]: {
                [response[entityKey]]: {
                  IsRead: true,
                  IsMessageRead: true
                }
              }
            };
            updateNormalizedSchemaCache(update);
            resolvedIsRead = true;
            // return;
          }
        }

        // if (resolvedIsRead) {
        //   return;
        // }

        updateConversations((c) => {
          const newArr = [...c];

          const index = newArr.findIndex((v) => v[entityKey] === entityId);

          if (index !== -1) {
            newArr.splice(index, 1);
          }
          newArr.unshift(response);
          return newArr;
        });
      }
    };

    entitiesHub.on("NewConversation", listener);

    return () => entitiesHub.off("NewConversation", listener);
  }, [conId, entitiesHub, isDetaisView, space.Id, updateConversations]);
};

export const useEmailStatusSync = () => {
  const entitiesHub = useSpaceEntitiesHub();
  const space = useSpace();
  const conId = useConnectionId();
  useEffect(() => {
    if (!entitiesHub) return;

    const listener = async (emailId, Status) => {
      const email = getEntity(spaceEmailSchema, emailId);

      if (!email) return;

      const update = {
        [spaceEmailSchema.name]: {
          [emailId]: {
            Status
          }
        }
      };

      updateNormalizedSchemaCache(update);
    };

    entitiesHub.on("EmailStatusUpdate", listener);

    return () => entitiesHub.off("EmailStatusUpdate", listener);
  }, [conId, entitiesHub, space.Id]);
};

const Reception = ({ detailsView }) => {
  // const { user } = useCurrentAccount();
  // useEnterViewSignalR(RealTimePageEnum.Reception);
  return (
    <SuspenseErrorBoundary>
      <PageTypeProvider pageType={RealTimePageEnum.Reception}>
        <ServerAwareProvider
          moduleType={moduleTypes.receptionMessages}
          schema={spaceEmailConversationSchema}
          filterColumns={ReceptionFilterColumns}
          listenAllMatches
        >
          <ReceptionOverviewComp detailsView={detailsView} />
        </ServerAwareProvider>
      </PageTypeProvider>
    </SuspenseErrorBoundary>
  );
};

export default Reception;
