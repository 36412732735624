import React, { useCallback, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  spaceAutomationSchema,
  spaceBillingSchema,
  spaceCallsSchema,
  spaceClassificationSchema,
  spaceClientSchema,
  spaceColorFilterSchema,
  spaceContactSchema,
  spaceContractSchema,
  spaceDealsSchema,
  spaceEmailSignatureSchema,
  spaceImportsSchema,
  spaceInterventionsSchema,
  spaceProjectsSchema,
  spaceSocialPostsSchema,
  spaceSubscriptionSchema,
  spaceTasksSchema,
  spaceTicketsSchema
} from "../../../../config/schema";
import { UserDropdown } from "../../../../Containers/AdvancedMultiInputs";
import { useSpace } from "../../../../Contexts/SpaceContext";
import {
  getEntity,
  useSpaceDelete,
  useSpacePost,
  useSpaceQuery
} from "../../../../Helpers/IOClient";
import { handleError } from "../../../../Helpers/MiscHelper";
import { useColorFilter } from "../../../../Hooks/EntityHooks";
import Button from "../../../Button/Button";
import LoadableButton from "../../../Button/LoadableButton";
import FormInput from "../../../Forms/FormInput/FormInput";
import { ColorInput } from "../../../Input/ColorInput/ColorInput";
import LoaderSpinner from "../../../Loader/LoaderSpinner/LoaderSpinner";
import { useSidebar } from "../../../Sidebar/SidebarV2";
import {
  BaseSidebarContainer,
  BaseSidebarContent,
  BaseSidebarHeader
} from "../../../Sidebar/SidebarV2Helper";
import Switch from "../../../Switch/Switch";
import { useToast } from "../../../Toast/ToastProvider";
import { FilterConditionsEnum } from "../AdvancedFilterConditions";
import {
  AdvancedFilterButtons,
  AdvancedFilterhasErrorSetterContext,
  AdvancedFilterItemList
} from "../AdvancedFilterGroup";
import {
  AvailableFiltersContext,
  getDefaultParameterValues
} from "../AdvancedFilterHelper";
import { FilterOperatorsEnum } from "../AdvancedFilterOperators";
import {
  removeFilterBySchema,
  updateFilterBySchema
} from "./AdvancedFilterColorsLs";

const useResolvedAvailableFilters = (filters) => {
  return useMemo(() => {
    const arr = filters.reduce((val, originalFilter) => {
      if (originalFilter.propField || originalFilter.propFields)
        val.push(originalFilter);
      return val;
    }, []);
    return arr;
  }, [filters]);
};

const useResolvedAvailableFiltersDict = (filters) => {
  return useMemo(() => {
    const dict = filters.reduce((val, originalFilter) => {
      val[originalFilter.field] = originalFilter;

      return val;
    }, {});
    return dict;
  }, [filters]);
};

const createNewCondition = (resolvedAvailableFilters) => {
  const firstFilter = resolvedAvailableFilters[0];

  const { Operator, Value } = getDefaultParameterValues(firstFilter);

  const newCondition = {
    Condition: FilterConditionsEnum.And,
    Parameter: firstFilter,
    Operator: Operator,
    Value: Value,
    Groups: []
  };
  return newCondition;
};

const resetFilterParameters = (filter) => {
  if (filter.Parameter) {
    filter.Parameter = filter.Parameter.field;
  }
  if (filter.Groups && filter.Groups.length > 0) {
    filter.Groups = [...filter.Groups];
    for (let i = 0; i < filter.Groups.length; i++) {
      const newGroup = { ...filter.Groups[i] };
      resetFilterParameters(newGroup);
      filter.Groups[i] = newGroup;
    }
  }
};

const getFormFromColorFilter = (colorFilter, filterDict) => {
  const { Parameter, Groups } = colorFilter;

  const newColorFilter = { ...colorFilter };

  if (Parameter) newColorFilter.Parameter = filterDict[Parameter];

  if (Groups) {
    newColorFilter.Groups = [...newColorFilter.Groups];
    for (let i = 0; i < newColorFilter.Groups.length; i++) {
      newColorFilter.Groups[i] = getFormFromColorFilter(
        newColorFilter.Groups[i],
        filterDict
      );
    }
  }

  return newColorFilter;
};

export const ColorFilterViewTypeEnum = {
  None: 0,
  [spaceCallsSchema.name]: 1,
  [spaceTicketsSchema.name]: 2,
  [spaceProjectsSchema.name]: 3,
  [spaceTasksSchema.name]: 4,
  [spaceInterventionsSchema.name]: 5,
  [spaceClientSchema.name]: 6,
  [spaceContactSchema.name]: 7,
  [spaceContractSchema.name]: 8,
  [spaceClassificationSchema.name]: 9,
  [spaceBillingSchema.name]: 10,
  [spaceDealsSchema.name]: 11,
  [spaceImportsSchema.name]: 12,
  [spaceSocialPostsSchema.name]: 13,
  [spaceSubscriptionSchema.name]: 14,
  [spaceEmailSignatureSchema.name]: 15,
  [spaceAutomationSchema.name]: 16,
  Documents: 17,
  ErrorLogs: 22,
  SpaceList: 23,
  ApiKeys: 24,
  ApiLogs: 25,
  FeedbackQueue: 26,
  PermissionQueue: 27,
  BreadcrumbQueue: 28,
  AutomationTriggerQueue: 29,
  AutomationEnrollQueue: 30,
  EnrollManagerQueue: 31,
  WidgetEmailQueue: 32,
  NotificationsQueue: 33
};

const ColorFilter = ({
  availableFilters,
  unresolvedColorFilter,
  onSuccess: handleSuccess,
  onRemoval,
  schema
}) => {
  const colorFilter = useColorFilter(unresolvedColorFilter) || null;

  const filterDict = useResolvedAvailableFiltersDict(availableFilters);
  const [filter, setFilter] = useState(() => {
    //;
    if (colorFilter) return getFormFromColorFilter(colorFilter, filterDict);
    const firstFilter = availableFilters[0];

    const { Operator, Value } = getDefaultParameterValues(firstFilter);

    const newGroup = {
      Condition: FilterConditionsEnum.And,
      Parameter: firstFilter,
      Operator: Operator,
      Value: Value,
      Groups: []
    };

    const newGroups = [newGroup];

    return {
      Name: "",
      Color: "#000000",
      Groups: newGroups,
      CanDelete: false,
      CanEdit: true
    };
  });

  // const [isPublic, setIsPublic] = useState(
  //   advancedfilter ? advancedfilter.IsPublic : false
  // );
  const color = filter.Color;
  const setColor = useCallback((e) => {
    setFilter((f) => ({ ...f, Color: e.target.value }));
  }, []);

  const { CanDelete, CanEdit } = filter;
  // const availableFiltersDict = useMemo(() => {
  //   const dict: [filter: string] = {}

  //   for (const f of availableFilters) {
  //     dict[f.field] = f;
  //   }

  // }, [])

  // const [filterName, setFilterName] = useState(
  //   advancedfilter ? advancedfilter.Name : ""
  // );
  const filterName = filter.Name;
  const setFilterName = useCallback((n) => {
    setFilter((f) => ({ ...f, Name: n }));
  }, []);

  // const editId = advancedfilter ? advancedfilter.Id : undefined;

  const addCondition = useCallback(() => {
    const newCondition = createNewCondition(availableFilters);

    setFilter((f) => {
      const Condition =
        f.Groups.length > 0 ? f.Groups[0].Condition : FilterConditionsEnum.And;
      newCondition.Condition = Condition;
      const newGroups = [...f.Groups, newCondition];
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, [availableFilters]);

  const addGroup = useCallback(() => {
    setFilter((f) => {
      const newCondition = createNewCondition(availableFilters);

      const Condition =
        f.Groups.length > 0 ? f.Groups[0].Condition : FilterConditionsEnum.And;

      const newGroup = {
        Condition,
        Groups: [newCondition]
      };

      const newGroups = [...f.Groups, newGroup];
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, [availableFilters]);

  const updateGroup = useCallback((index, newGroupsFunc) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];
      newGroups[index] = newGroupsFunc(newGroups[index]);
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);

  const removeGroup = useCallback((index) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];
      newGroups.splice(index, 1);
      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);

  const updateCondition = useCallback((newCondition) => {
    setFilter((f) => {
      const newGroups = [...f.Groups];

      for (const g of newGroups) {
        g.Condition = newCondition;
      }

      return {
        ...f,
        Groups: newGroups
      };
    });
  }, []);

  // const [, closeSidebar] = useSidebar();
  // let endpoint = editId ? `CustomFilters/${editId}` : "CustomFilters";
  // const [post, { loading }]: any = useSpacePost(
  //   endpoint,
  //   spaceAdvancedFilterSchema,
  //   {
  //     onSuccess: ({ data }: any) => {
  //       handleSuccess(data);
  //     },
  //   }
  // );

  // const resetParameters = (filter) => {
  //   //;
  //   if (filter.Parameter) {
  //     const { mappedFields, getResolvedValue, getField } = filter.Parameter;
  //     if (mappedFields) {
  //       const uris = getField(filter.Value);

  //       const { resolvedField } = uris;

  //       filter.Parameter = replaceFieldSlash(resolvedField);

  //       filter.Value = getResolvedValue(filter.Value);
  //     } else {
  //       filter.Parameter = filter.Parameter.field;
  //     }
  //   }
  //   if (filter.Groups && filter.Groups.length > 0) {
  //     filter.Groups = [...filter.Groups];
  //     for (let i = 0; i < filter.Groups.length; i++) {
  //       const newGroup = { ...filter.Groups[i] };
  //       resetParameters(newGroup);
  //       filter.Groups[i] = newGroup;
  //     }
  //   }

  //   // for (let i = 0; i < filter.Groups.length; i++) {
  //   //   const g = { ...filter.Groups[i] };
  //   //   if (g.Parameter) g.Parameter = g.Parameter.field;
  //   //   if (g.Groups && g.Groups.length > 0){
  //   //     for (const cGroup of g.Groups) {
  //   //       resetParameters(cGroup);
  //   //     }

  //   //   }
  //   //   filter.Groups[i] = g;
  //   // }
  // };

  const space = useSpace();
  const url = useMemo(() => {
    if (colorFilter?.Id) return `ColorFilter/${colorFilter.Id}`;
    else return "ColorFilter";
  }, [colorFilter]);
  const [, closeSidebar] = useSidebar();
  const [deleteColorFilter, { loading: deleteLoading }] = useSpaceDelete(
    url,
    null,
    {
      onSuccess: () => {
        createToast({
          pos: "tm",
          type: "success",
          description: <FormattedMessage id="SUCCESS_DELETED" />
        });
        removeFilterBySchema(schema, colorFilter.Id, space.Id);
        onRemoval(colorFilter);
        closeSidebar();
      },
      onError: (error) => {
        handleError(createToast, error);
      }
    }
  );

  const handleRemoval = () => {
    deleteColorFilter();
  };
  const createToast = useToast();
  const [handleColorPost, { loading }] = useSpacePost(
    url,
    spaceColorFilterSchema,
    {
      onSuccess: ({ data }) => {
        const resolvedData = getEntity(spaceColorFilterSchema, data);
        updateFilterBySchema(schema, resolvedData, space.Id);
        handleSuccess(resolvedData);
        closeSidebar();
      },
      onError: (error) => {
        handleError(createToast, error);
      }
    }
  );

  const handleSubmit = () => {
    let isRelative = checkForRelatives(filter.Groups);
    let resolvedFilter = {
      ...filter,
      Type: ColorFilterViewTypeEnum[schema.name],
      IsPublic: isPublic,
      Accounts: accounts,
      HasRelative: isRelative
    };

    resetFilterParameters(resolvedFilter);
    // if (colorFilter) {
    //   resolvedFilter = updateFilterBySchema(schema, resolvedFilter, space.Id);
    // } else {
    //   resolvedFilter = addFilterBySchema(schema, resolvedFilter, space.Id);
    // }
    const { Groups, ...rest } = resolvedFilter;
    const postBody = {
      ...rest,
      Data: {
        Groups
      }
    };

    handleColorPost(postBody);
    //   post({
    //     Name: filterName,
    //     Type: filterType,
    //     IsPublic: isPublic,
    //     Accounts: isPublic ? undefined : accounts,
    //     Data: resolvedFilter,
    //   });
  };

  const errorRef = useRef(0);

  const [hasErrors, setHasErrors] = useState(false);

  // useLayoutEffect(() => {
  //   if (errorRef.current === 0) setHasErrors(false);
  //   else setHasErrors(true);
  // });

  // const { loadingName, isNameValid, handleBlur } = useNameValidator(
  //   filterName,
  //   filterType,
  //   advancedfilter ? advancedfilter.Id : undefined
  // );

  const { Groups } = filter;
  const canSubmit =
    //   isNameValid &&
    filterName.length > 0 && filter.Groups.length > 0 && !hasErrors;

  const [isPublic, setIsPublic] = useState(
    colorFilter ? colorFilter.IsPublic : false
  );
  const [accounts, setAccounts] = useState(
    colorFilter && colorFilter.Accounts ? colorFilter.Accounts : []
  );

  const [isCancelling, setIsCancelling] = useState(false);

  const disabled = !CanEdit;
  const intl = useIntl();
  return (
    <BaseSidebarContainer width={540}>
      <BaseSidebarHeader>
        {colorFilter ? (
          <FormattedMessage id="EDIT_COLOR_FILTER" />
        ) : (
          <FormattedMessage id="CREATE_COLOR_FILTER" />
        )}
      </BaseSidebarHeader>
      <BaseSidebarContent>
        <div className="d-flex flex-column h-100">
          <div className="px-3 py-3 flex-1 of-y-auto">
            {isCancelling && (
              <div className="a-advanced-filter-canceling"></div>
            )}
            <FormInput
              className="mb-3"
              text={intl.formatMessage({ id: "FILTER_NAME" })}
              value={filterName}
              // onBlur={handleBlur}
              disabled={disabled}
              onChange={(e) => setFilterName(e.target.value)}
            />

            <div className="mt-2 mb-3 d-flex align-items-center">
              <Switch
                disabled={disabled}
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              ></Switch>
              <span className="ml-2 text-black fs-14 fw-medium">
                <FormattedMessage id="PUBLIC" />
              </span>
            </div>
            {!isPublic && (
              <FormInput
                disabled={disabled}
                className="mb-3"
                text={intl.formatMessage({ id: "USERS" })}
                value={accounts}
                multiple
                inputType={UserDropdown}
                onChange={(e) => setAccounts(e.target.value)}
              />
            )}

            <FormInput
              inputType={ColorInput}
              className="mb-3"
              text={intl.formatMessage({ id: "COLOR" })}
              value={color}
              disabled={disabled}
              onChange={setColor}
            />

            {/* {!isNameValid && !loadingName && (
                <div>
                  <ErrorAlert className="mb-3">
                    <FormattedMessage id="FILTER_NAME_NOT_VALID" />
                  </ErrorAlert>
                </div>
              )} */}

            {/* <div className="mt-2 mb-3 d-flex align-items-center">
              <Switch
                disabled={disabled}
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              ></Switch>
              <span className="ml-2 text-black fs-14 fw-medium">
                <FormattedMessage id="PUBLIC" />
              </span>
            </div> */}

            {/* {!isPublic && (
                <FormInput
                  disabled={disabled}
                  className="mb-3"
                  text={intl.formatMessage({ id: "USERS" })}
                  value={accounts}
                  multiple
                  inputType={UserDropdown}
                  excludeMe
                  onChange={(e: any) => setAccounts(e.target.value)}
                />
              )} */}

            <AdvancedFilterhasErrorSetterContext.Provider value={setHasErrors}>
              <AdvancedFilterItemList
                disabled={disabled}
                errorRef={errorRef}
                Groups={Groups}
                removeGroup={removeGroup}
                updateGroup={updateGroup}
                updateCondition={updateCondition}
              />
            </AdvancedFilterhasErrorSetterContext.Provider>

            <div className="mt-3">
              <AdvancedFilterButtons
                disabled={disabled}
                addCondition={addCondition}
                addGroup={addGroup}
              />
            </div>
          </div>

          {isCancelling ? (
            <RemovalFooter
              loading={deleteLoading}
              onSuccess={() => handleRemoval()}
              onCancel={() => setIsCancelling(false)}
            />
          ) : (
            <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
              {CanDelete ? (
                <Button
                  vType="link-danger"
                  className="mr-3"
                  onClick={() => setIsCancelling(true)}
                >
                  <FormattedMessage id="DELETE_FILTER" />
                </Button>
              ) : (
                <div></div>
              )}
              {!disabled && (
                <LoadableButton
                  disabled={!canSubmit}
                  isLoading={loading}
                  onClick={handleSubmit}
                >
                  <FormattedMessage id="APPLY" />
                </LoadableButton>
              )}
            </div>
          )}
        </div>
      </BaseSidebarContent>
    </BaseSidebarContainer>
  );
};
export const ColorFilterForm = (props) => {
  const { schema, availableFilters, colorFilter } = props;
  const { Id } = colorFilter || {};
  const editUrl = Id ? `ColorFilter/${Id}` : null;

  const { data, loading } = useSpaceQuery(editUrl, spaceColorFilterSchema, {
    cache: false
  });

  const resolvedAvailableFilters =
    useResolvedAvailableFilters(availableFilters);

  //   const { fieldDict, mappedFieldDict } = useFiltersDict(
  //     resolvedAvailableFilters
  //   );

  //   const availableFiltersValue = useMemo(() => {
  //     return {
  //       availableFilters: resolvedAvailableFilters,
  //       mappedFieldDict,
  //       fieldDict,
  //     };
  //   }, [fieldDict, mappedFieldDict, resolvedAvailableFilters]);

  //   const { loading: loadingDep, value } = useResolvedValue(
  //     availableFiltersValue,
  //     data
  //   );

  const availableFiltersValue = useMemo(() => {
    return {
      availableFilters: resolvedAvailableFilters
      // mappedFieldDict,
      // fieldDict,
    };
  }, [resolvedAvailableFilters]);

  if (Id && (!data || loading))
    return (
      <BaseSidebarContainer>
        <LoaderSpinner size="sm" className="text-primary" center />
      </BaseSidebarContainer>
    );

  return (
    <AvailableFiltersContext.Provider value={availableFiltersValue}>
      <ColorFilter
        schema={schema}
        availableFilters={resolvedAvailableFilters}
        key={colorFilter?.Id}
        // advancedfilter={id ? value : undefined}
        {...props}
        unresolvedColorFilter={data}
      />
    </AvailableFiltersContext.Provider>
  );
};

const RemovalFooter = ({ onSuccess, onCancel, loading }) => {
  return (
    <div className="shadow-top px-3 py-3 of-y-auto d-flex align-items-center justify-content-between">
      <div className="text-black">
        <FormattedMessage id="DELETE_FILTER_WARNING" />
      </div>

      <div className="d-flex align-items-center">
        <Button
          disabled={loading}
          type="button"
          vType="outline-danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FormattedMessage id="BACK" />
        </Button>

        <LoadableButton
          isLoading={loading}
          type="button"
          vType="danger"
          onClick={() => onSuccess()}
        >
          <FormattedMessage id="DELETE" />
        </LoadableButton>
      </div>
    </div>
  );
};

const checkForRelatives = (filters) => {
  let result = false;

  for (const filter of filters) {
    const { Operator, Groups } = filter;
    if (
      Operator === FilterOperatorsEnum.Next ||
      Operator === FilterOperatorsEnum.Last
    ) {
      result = true;
      break;
    }
    if (Groups.length > 0) result = checkForRelatives(Groups);
  }

  return result;
};
