import React from "react";
import Modal from "../Modal/Modal";
import classnames from "classnames";
import classes from "./SendNotification.module.css";
import { FormattedMessage, useIntl } from "react-intl";
import { useTicket, useContact } from "../../Hooks/EntityHooks";
import Button from "../Button/Button";
import { useSpacePost } from "../../Helpers/IOClient";
import { spaceTicketsSchema } from "../../config/schema";
import { useToast } from "../Toast/ToastProvider";
import { handleError } from "../../Helpers/MiscHelper";
import LoadableButton from "../Button/LoadableButton";

const SendNotificationModal = ({
  modalState,
  toggle,
  entity,
  stopProp,
  onSuccess
}) => {
  const ticket = useTicket(entity.Id);
  const ticketContact = useContact(ticket.Contact);
  const createToast = useToast();
  const intl = useIntl();
  const [post, { loading }] = useSpacePost(`Tickets/EmailClientReport`, null, {
    onSuccess: ({ data }) => {
      createToast({
        pos: "tm",
        type: "info",
        description: `${intl.formatMessage({
          id: "API_TICKET_EMAIL_REPORT_STARTED"
        })}`
      });
      toggle();
      if (onSuccess) onSuccess();
    },
    onError: ({ error }) => {
      let msgKey = error?.data;
      if (msgKey === "TICKET_ERROR_NO_CONTACT")
        createToast({
          pos: "tm",
          type: "danger",
          description: intl.formatMessage({ id: "TICKET_ERROR_NO_CONTACT" }),
          title: `${intl.formatMessage({ id: "CASE" })} nº ${entity.Id}`
        });
      else handleError(createToast, error);
      toggle();
    }
  });

  const click = (e) => {
    post({ Data: [entity.Id] });
    if (stopProp) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <Modal isOpen={modalState} onClose={toggle}>
      <div
        className={classnames(classes.SendNotificationModalContainer, "p-4")}
      >
        <div className="text-black fw-medium justify-content-center flex-1 fs-16 mb-3">
          <FormattedMessage id="SEND_NOTIFICATION_WARNING" />
        </div>
        <div className="d-flex justify-content-between">
          <Button vType={"outline-danger"} onClick={toggle}>
            <FormattedMessage id="NO" />
          </Button>
          <LoadableButton
            isLoading={loading}
            vType={"primary-ghost"}
            onClick={click}
          >
            <FormattedMessage id="YES" />
          </LoadableButton>
        </div>
      </div>
    </Modal>
  );
};

export default SendNotificationModal;
