import React, { useRef, useEffect, useState, useMemo } from "react";
import {
  TaskFormButton,
  TicketFormButton,
  InterventionFormButton,
  CallFormButton,
  ContractFormButton
} from "../Form/FormButtons";
import { FormattedMessage } from "react-intl";
import { ContractStatusTypes } from "../../Components/Contract/ContractProfile";
import {
  ContractPdfButton,
  SendContractNotificationButton
} from "../../Components/Contract/Contracts";
import { CovenantValue } from "../../Components/CovenantSelector/CovenantSelector";
import {
  createFormData,
  toIsoString,
  formatDurationHMS,
  originTypes
} from "../../Helpers/MiscHelper";
import { EntityPopup } from "../../Components/EntityPopup/EntityPopup";
import moment from "moment";
import {
  convertInterventionTarget,
  getInterventionTarget
} from "../../Helpers/FormFormmaterHelper";
import { LabelTitle } from "../../Helpers/GridHelper";
import "./HourControll.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/pro-light-svg-icons";

import classnames from "classnames";
import {
  faArrowAltFromRight,
  faArrowAltFromLeft
} from "@fortawesome/pro-solid-svg-icons";
import Popup from "../../Components/Popup/Popup";
import { useHoverEvents } from "../Origin/Origin";
import { useSpaceQuery, getEntity } from "../../Helpers/IOClient";
import {
  spaceInterventionsSchema,
  spaceContractSchema,
  spaceContractTagsSchema
} from "../../config/schema";
import { OriginPopupLoader } from "../../Components/Origin/Origin";
import { ConnectedInterventionOrigin } from "../Origin/InterventionOrigin";
import { useCurrentAccountSpace } from "../../Contexts/UserContext";
import { moduleTypes, canUserEditModule } from "../../Helpers/ModulesHelper";
import { ContractsClipboardButton } from "../../Helpers/HooksHelper";
import EntityReadButton from "../../Components/EntityRead/EntityReadButton";
import { DeleteButtonContextMenu } from "../../Components/CGrid/DeleteMultipleModal";
import {
  InnerColorFilterBars,
  TriggeredColoredFilterProvider,
  useTriggeredColorFilters
} from "../../Components/FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";
import { TimeTrackingGridFormButton } from "../TimeTracking/TimeTracking";
import { useColumnsSize } from "../../Components/VirtualGrid/VirtualGridResizer";
import BreadcrumbsPopup from "../../Components/Breadcrumbs/BreadcrumbsPopup";
import SendContractRenovationModal from "../../Components/SendContractRenovationButton/SendContractRenovationModal";
import { useModalStatusReducer } from "../../Components/Modal/ModalHelperFunctions";
import { useSpace } from "../../Contexts/SpaceContext";
import { SendNotificationButton } from "../../Components/SendNotificationButton/SendNotificationButton";
import { useCurrency } from "../../Hooks/EntityHooks";
import { toCurrencyFromDecimal } from "../../Helpers/CurrencyHelper";

export const defaultContractForm = ({ currencies }) => {
  const EstimatedCostsDict = {};
  const EstimatedCostsHourDict = {};
  for (const currency of currencies) {
    EstimatedCostsDict[currency] = {
      Cost: 0,
      CurrencyId: currency
    };
    EstimatedCostsHourDict[currency] = {
      Cost: 0,
      CurrencyId: currency
    };
  }
  return {
    Deslocations: 0,
    ContractTarget: null,
    EstimatedCostsDict,
    EstimatedCostsHourDict,
    Covenant: new CovenantValue(),
    Description: "",
    Contact: "",
    SupportEmail: "",
    TicketLimit: 0,
    EstimatedCost: 3600000000, // 1000 horas
    BeginDate: new Date(),
    EndDate: moment().add(1, "d").toDate(),
    SalesOpportunity: "",
    Manager: null,
    ContractType: null,
    InternalDescription: "",
    Files: null,
    Departments: [],
    TeamCollab: [],
    Schedules: [],
    Followers: [],
    Status: 1, // ativo

    PeriodTime: 0,
    BillingPeriod: new CovenantValue()
  };
};

export const formatContractForm = (form, currencies) => {
  const {
    Files,
    ContractTarget,
    Contact,
    Manager,
    Contract,
    ContractType,
    Covenant,
    TeamCollab,
    BeginDate,
    EndDate,
    Company,
    Pipeline,
    PipelineStatus,
    AssignedAssociations,
    AssociatedFiles,
    Schedules,
    StatusDetail,
    InboundEmail,
    InboundEmails,
    StatusDetailId,
    IsSigned,
    BillingPeriod,
    CurrentInboundEmails,
    Tags,
    EstimatedCostsDict,
    EstimatedCostsHourDict,
    DefaultEstimatedCost,
    PeriodTime,
    ...formRest
  } = form;
  const contactId = Contact;
  const managerId = Manager;
  const contractId = Contract;
  const organizationid = Company;
  let accounts = [],
    teams = [];

  for (const item of TeamCollab) {
    if (item.type === "Account") accounts.push(item.data);
    else if (item.type === "Team") teams.push(item.data);
  }

  const { TimeValue, TimeUnit } = Covenant;

  const createDealEstimatedCost = (dict, currencies) => {
    const arr = [];

    for (const curr of currencies) {
      arr.push(dict[curr]);
    }
    return JSON.stringify(arr);
  };

  const resolvedFiles = Array.isArray(Files) ? [...Files] : [];
  if (Array.isArray(AssignedAssociations) && AssignedAssociations.length > 0) {
    for (const e of AssignedAssociations) {
      const asfile = AssociatedFiles.find((t) => t.Id === e);
      if (asfile) resolvedFiles.push(asfile);
    }
  }

  let tagsIds = [];
  if (Tags && Array.isArray(Tags))
    for (const e of Tags) {
      const { TempId } = getEntity(spaceContractTagsSchema, e.data);
      if (TempId) tagsIds.push(TempId);
    }

  return createFormData(
    {
      ...formRest,
      ...convertInterventionTarget(ContractTarget),
      contactId,
      tagsIds,
      PipelineStatusId: PipelineStatus.Id ? PipelineStatus.Id : PipelineStatus,
      PipelineId: Pipeline.Id ? Pipeline.Id : Pipeline,
      contractId,
      managerId,
      IsSigned,
      ContractTypeId: ContractType,
      TimeValue,
      TimeUnit,
      InboundEmailId: Array.isArray(CurrentInboundEmails)
        ? CurrentInboundEmails.join(",")
        : InboundEmail,
      accounts,
      StatusDetailId: StatusDetail,
      teams,
      Schedules: JSON.stringify(Schedules),
      organizationid,
      BeginDate: toIsoString(BeginDate),
      EndDate: toIsoString(EndDate),
      EstimatedCosts: createDealEstimatedCost(EstimatedCostsDict, currencies),
      EstimatedCostsHour: createDealEstimatedCost(
        EstimatedCostsHourDict,
        currencies
      ),
      BillingPeriodTimeValue: BillingPeriod.TimeValue,
      BillingPeriodTimeUnit: BillingPeriod.TimeUnit,
      PeriodTime: PeriodTime || 0
    },
    resolvedFiles
  );
};

export const OutsideContractHours = ({
  LeftDurationNotInRange,
  RightDurationNotInRange,
  Entity,
  placeholder
}) => {
  if (!LeftDurationNotInRange && !RightDurationNotInRange) {
    return placeholder || <div>―</div>;
  }

  return (
    <>
      <div className="HoursOutside-Container p-1">
        {LeftDurationNotInRange && (
          <LeftAnchor
            LeftDurationNotInRange={LeftDurationNotInRange}
            Entity={Entity}
          />
        )}
        {RightDurationNotInRange && (
          <RightAnchor
            RightDurationNotInRange={RightDurationNotInRange}
            Entity={Entity}
          />
        )}
      </div>
    </>
  );
};

const LeftAnchor = ({ LeftDurationNotInRange, Entity }) => {
  const anchorLeftRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorLeftRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <LeftPopup Entity={Entity} />
      </Popup>
      <div
        ref={anchorLeftRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className="HoursOutside-Component text-truncate mr-2 d-flex rounded align-items-center justify-content-center"
      >
        <FontAwesomeIcon className="mr-2" icon={faArrowAltFromRight} />
        <span>{formatDurationHMS(LeftDurationNotInRange)}</span>
      </div>
    </>
  );
};

const LeftPopup = ({ Entity }) => {
  const {
    data
    // loading,
    // error
  } = useSpaceQuery(
    `interventions?$orderby=TimeCharge/Id%20asc&$filter=((DirectIndirectContract/Id eq '${Entity.Id}') and (TimeCharge/BeginDateNoHours lt '${Entity.BeginDate}'))&$top=10`,
    [spaceInterventionsSchema]
  );

  if (!data) return <OriginPopupLoader />;

  return (
    <div className="bg-white" style={{ maxWidth: 300, maxHeight: 500 }}>
      <div className="of-y-auto h-100 position-relative">
        {data.map((e, i) => (
          <div key={i} className="ar-treeview-item">
            <div className="flex-1 d-flex align-items-center ar-treeview-item-content h-100 justify-content-start">
              <ConnectedInterventionOrigin intervention={e} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const RightAnchor = ({ RightDurationNotInRange, Entity }) => {
  const anchorRightRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorRightRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <RightPopup Entity={Entity} />
      </Popup>
      <div
        ref={anchorRightRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className="HoursOutside-Component text-truncate d-flex rounded align-items-center justify-content-center"
      >
        <FontAwesomeIcon className="mr-2" icon={faArrowAltFromLeft} />
        <span>{formatDurationHMS(RightDurationNotInRange)}</span>
      </div>
    </>
  );
};

const RightPopup = ({ Entity }) => {
  const {
    data
    // loading,
    // error
  } = useSpaceQuery(
    `interventions?$orderby=TimeCharge/Id%20asc&$filter=((DirectIndirectContract/Id eq '${Entity.Id}') and (TimeCharge/BeginDateNoHours gt '${Entity.EndDate}'))&$top=10`,
    [spaceInterventionsSchema]
  );

  if (!data) return <OriginPopupLoader />;

  return (
    <div className="bg-white" style={{ maxWidth: 300, maxHeight: 500 }}>
      <div className="of-y-auto h-100 position-relative">
        {data.map((e, i) => (
          <div key={i} className="ar-treeview-item">
            <div className="flex-1 d-flex align-items-center ar-treeview-item-content h-100 justify-content-start">
              <ConnectedInterventionOrigin intervention={e} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const HourControll = ({
  TimeChargesDurationByRenovation = [],
  PeriodTime,
  Entity,
  placeholder
}) => {
  const HoursContainerRef = useRef();
  const PeriodsArr = [];
  const GridWider = useColumnsSize();

  const [currenteWidth, setCurrentWidth] = useState(
    GridWider
      ? GridWider["Contract/HourControl"]
        ? GridWider["Contract/HourControl"]
        : 250
      : 250
  );
  const [indexOfObjInView, setIndexArr] = useState(null);
  useEffect(() => {
    const handleRerender = () => {
      const resolvedGridWider = GridWider || {};
      const GridWidth = resolvedGridWider["Contract/HourControl"]
        ? resolvedGridWider["Contract/HourControl"]
        : 250;
      if (GridWidth !== currenteWidth) {
        setCurrentWidth(GridWidth);
        setIndexArr(null);
      }
    };
    const timeout = setTimeout(() => {
      handleRerender();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [currenteWidth, GridWider]);
  //////;

  const numberOfSpotsAvailable = useMemo(
    () => Math.floor((currenteWidth - 35) / 60),
    [currenteWidth]
  );

  if (
    !TimeChargesDurationByRenovation ||
    TimeChargesDurationByRenovation.length === 0
  ) {
    return placeholder || <div>―</div>;
  }

  TimeChargesDurationByRenovation.forEach((e) => {
    if (e && e.Begin && e.End) {
      PeriodsArr.push(e);
    }
  });

  if (PeriodsArr.length === 0) return placeholder || <div>―</div>;

  const HoursComponents = PeriodsArr.map((e, i) => {
    return (
      <HourComponent
        key={i}
        Entity={Entity}
        Value={e}
        PeriodTime={PeriodTime}
      />
    );
  });

  let resolvedItems;
  if (indexOfObjInView === null) {
    if (numberOfSpotsAvailable >= HoursComponents.length) {
      resolvedItems = HoursComponents;
    } else {
      resolvedItems = HoursComponents.slice(
        HoursComponents.length - numberOfSpotsAvailable
      );
    }
  } else {
    resolvedItems = indexOfObjInView.map((e) => {
      return HoursComponents[e];
    });
  }

  const leftClick = () => {
    const tempArr = [];
    let startIndex =
      indexOfObjInView !== null
        ? indexOfObjInView[0] - 1
        : HoursComponents.length - numberOfSpotsAvailable - 1;
    for (let i = 0; i < numberOfSpotsAvailable; i++) {
      tempArr.push(startIndex);
      startIndex++;
    }
    setIndexArr(tempArr);
  };

  const rightClick = () => {
    const tempArr = [];
    let startIndex =
      indexOfObjInView !== null
        ? indexOfObjInView[0] + 1
        : HoursComponents.length - numberOfSpotsAvailable + 1;
    for (let i = 0; i < numberOfSpotsAvailable; i++) {
      tempArr.push(startIndex);
      startIndex++;
    }
    setIndexArr(tempArr);
  };

  let LeftButton, RightButton;
  //////;
  if (numberOfSpotsAvailable >= HoursComponents.length) {
    LeftButton = (
      <div className="HoursControll-controller disable-selection visibility-none mr-1 p-1 d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div className="HoursControll-controller disable-selection px-1 visibility-none d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else if (indexOfObjInView === null) {
    LeftButton = (
      <div
        onClick={leftClick}
        className="HoursControll-controller disable-selection cursor-pointer p-1 mr-1 d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div className="HoursControll-controller disable-selection px-1 visibility-none d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else if (indexOfObjInView[0] === 0) {
    LeftButton = (
      <div className="HoursControll-controller disable-selection visibility-none mr-1 px-1 d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div
        onClick={rightClick}
        className="HoursControll-controller disable-selection p-1 cursor-pointer d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else if (
    indexOfObjInView[indexOfObjInView.length - 1] ===
    HoursComponents.length - 1
  ) {
    LeftButton = (
      <div
        onClick={leftClick}
        className="HoursControll-controller disable-selection cursor-pointer p-1 mr-1 d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div className="HoursControll-controller disable-selection px-1 visibility-none d-flex align-items-center h-100">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  } else {
    LeftButton = (
      <div
        onClick={leftClick}
        className="HoursControll-controller disable-selection cursor-pointer p-1 mr-1 d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
    RightButton = (
      <div
        onClick={rightClick}
        className="HoursControll-controller disable-selection p-1 cursor-pointer d-flex align-items-center h-100"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
      className="HoursControll-Container disable-selection d-flex flex-row h-100 w-100"
    >
      {LeftButton}
      <div
        ref={HoursContainerRef}
        className="HoursControll-Hours w-100 of-hidden"
      >
        {resolvedItems}
      </div>
      {RightButton}
    </div>
  );
};

const HourComponent = ({ Entity, Value, PeriodTime, ...rest }) => {
  const Begin = new Date(Value.Begin);
  const End = new Date(Value.End);
  const Today = new Date();
  const Current = Today > Begin && Today < End;
  const FormatedTime = moment(Begin).format("DD, MMM");

  const anchorRef = useRef();

  const {
    onPopupMouseEnter,
    onPopupMouseLeave,
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen
  } = useHoverEvents();

  return (
    <>
      <Popup
        anchorEl={anchorRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        <HourComponentPopup
          Entity={Entity}
          Begin={Value.Begin}
          End={Value.End}
        />
      </Popup>
      <div
        ref={anchorRef}
        onMouseOver={onAnchorMouseEnter}
        onMouseOut={onAnchorMouseLeave}
        className={classnames(
          "mr-1 rounded pb-1 disable-selection",
          { "HoursControll-HourComponent-basic": Value.Duration < PeriodTime },
          {
            "HoursControll-HourComponent-OverDone": Value.Duration >= PeriodTime
          },
          { "HoursControll-Current": Current }
        )}
        {...rest}
      >
        <div className="fs-12">{FormatedTime}</div>
        <div style={{ textTransform: "none" }} className="fw-medium fs-14">
          {formatDurationHMS(Value.Duration)}
        </div>
      </div>
    </>
  );
};

const HourComponentPopup = ({ Entity, Begin, End }) => {
  const {
    data
    // loading,
    // error
  } = useSpaceQuery(
    `interventions?$orderby=TimeCharge/Id%20asc&$filter=((DirectIndirectContract/Id eq '${Entity.Id}') and (TimeCharge/BeginDateNoHours gt '${Begin}') and (TimeCharge/BeginDateNoHours lt '${End}'))&$top=10`,
    [spaceInterventionsSchema]
  );

  if (!data) return <OriginPopupLoader />;

  return (
    <div className="bg-white" style={{ maxWidth: 300, maxHeight: 500 }}>
      <div className="of-y-auto h-100 position-relative">
        {data.map((e, i) => (
          <div key={i} className="ar-treeview-item">
            <div className="flex-1 d-flex align-items-center ar-treeview-item-content h-100 justify-content-start">
              <ConnectedInterventionOrigin intervention={e} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const validateContractForm = (
  {
    ContractTarget,
    Contact,
    EstimatedCost,
    BeginDate,
    EndDate,
    Manager,
    ContractType,
    InternalDescription,
    Description,
    Company,
    Pipeline,
    PipelineStatus
  },
  addError
) => {
  if (!Company) addError("Company");
  if (!ContractTarget) addError("ContractTarget");
  // if (!Contact) addError("Contact");
  if (!Pipeline) addError("Pipeline");
  if (!PipelineStatus) addError("PipelineStatus");
  if (!ContractType) addError("ContractType");
  if (!EstimatedCost) addError("EstimatedCost");
  if (!BeginDate) addError("BeginDate");
  if (!EndDate) addError("EndDate");
  if (BeginDate && EndDate && EndDate < BeginDate)
    addError(
      "EndDate",
      <FormattedMessage id="FORM_END_DATA_BIGGER_THAN_START_DATA" />
    );

  if (!Manager) addError("Manager");
};

export const convertContractEntityToForm = (entity) => {
  const { Collaborators, Tags, ...entityRest } = entity;

  const form = { ...entityRest, ContractTarget: getInterventionTarget(entity) };

  const { Accounts, Teams } = Collaborators || {};

  if (Accounts || Teams) {
    let data = [];

    if (Accounts && Array.isArray(Accounts)) {
      for (let i = 0; i < Accounts.length; i++) {
        if (Accounts[i].type === "Account" && Accounts[i].data)
          data.push(Accounts[i]);
        else data.push({ type: "Account", data: Accounts[i] });
      }
    }
    if (Teams && Array.isArray(Teams)) {
      for (let i = 0; i < Teams.length; i++) {
        if (Teams[i].type === "Team" && Teams[i].data) data.push(Teams[i]);
        else data.push({ type: "Team", data: Teams[i] });
      }
    }
    form.TeamCollab = data;
  }

  if (Array.isArray(Tags) && Tags.length > 0) {
    const resolvedTags = [];
    for (const t of Tags) {
      resolvedTags.push({
        data: t,
        type: "ContractTag"
      });
    }
    form.Tags = resolvedTags;
  }

  return form;
};

export const ContractEntityButtons = ({ entity, skeleton, onSuccess }) => {
  const isPositiveStatus = entity.Status !== ContractStatusTypes.closed;

  return (
    <React.Fragment>
      <ContractFormButton
        canEdit={entity.CanEdit}
        id={entity.Id}
        skeleton={skeleton}
        className="mr-2"
        onSuccess={onSuccess}
      />
      {isPositiveStatus && (
        <CallFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          formProps={{ contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <TicketFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          formProps={{ contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}

      {isPositiveStatus && (
        <TaskFormButton
          className="mr-2"
          skeleton={skeleton}
          alt
          formProps={{ contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      {isPositiveStatus && (
        <InterventionFormButton
          alt
          className="mr-2"
          skeleton={skeleton}
          formProps={{ taskOrigin: entity, contractId: entity.Id }}
          onSuccess={onSuccess}
        />
      )}
      <ContractFormButton
        alt
        className="mr-2"
        skeleton={skeleton}
        id={entity.Id}
        duplicate
      />
      <ContractPdfButton
        alt
        className="mr-2"
        skeleton={skeleton}
        id={entity.Id}
      />
      <ContractPdfButton
        alt
        className="mr-2"
        skeleton={skeleton}
        id={entity.Id}
        excel={true}
      />
    </React.Fragment>
  );
};

export const ContractEntityActionsChildren = ({
  entity,
  minimal,
  sidebar,
  sendNotificationToggler,
  detailsView
}) => {
  const isPositiveStatus = entity.Status !== ContractStatusTypes.closed;
  const accountSpace = useCurrentAccountSpace();
  const space = useSpace();
  return (
    <>
      {!sidebar && !detailsView && (
        <ContractFormButton contextIcons id={entity.Id} />
      )}
      {!detailsView && <ContractFormButton globalEntityView id={entity.Id} />}
      {(canUserEditModule(accountSpace, moduleTypes.calls) ||
        canUserEditModule(accountSpace, moduleTypes.tickets) ||
        canUserEditModule(accountSpace, moduleTypes.tasks) ||
        canUserEditModule(accountSpace, moduleTypes.interventions)) &&
        isPositiveStatus && (
          <LabelTitle title={<FormattedMessage id={"SERVICES"} />} />
        )}
      {canUserEditModule(accountSpace, moduleTypes.calls) && (
        <CallFormButton
          hasAccess={isPositiveStatus}
          formProps={{ contractId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tickets) && (
        <TicketFormButton
          hasAccess={isPositiveStatus}
          formProps={{ contractId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.tasks) && (
        <TaskFormButton
          hasAccess={isPositiveStatus}
          formProps={{ contractId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <InterventionFormButton
          hasAccess={isPositiveStatus}
          formProps={{ taskOrigin: entity, contractId: entity.Id }}
        />
      )}
      {canUserEditModule(accountSpace, moduleTypes.interventions) && (
        <TimeTrackingGridFormButton
          origin={{
            type: "Contract",
            data: entity.Id
          }}
        />
      )}
      <LabelTitle title={<FormattedMessage id={"ACTIONS"} />} />
      {!sidebar && entity.CanEdit && (
        <ContractFormButton
          id={entity.Id}
          canEdit={entity.CanEdit}
          contextIcons
        />
      )}
      <ContractFormButton id={entity.Id} duplicate className="mr-2" />
      <ContractFormButton id={entity.Id} renovate className="mr-2" />
      <ContractPdfButton id={entity.Id} />
      <ContractPdfButton id={entity.Id} excel={true} />
      <SendContractNotificationButton link id={entity.Id} />
      {entity.CanEdit && (space.Id === 1 || space.Id === 3) && (
        <SendNotificationButton
          Schema={spaceContractSchema}
          toggler={sendNotificationToggler}
          Id={entity.Id}
          textId={"SEND_CONTRACT_RENOVATION_BUTTON"}
        />
      )}
      <ContractsClipboardButton id={entity.Id} />
      <EntityReadButton value={entity} schema={spaceContractSchema} />
      <DeleteButtonContextMenu entity={entity} />
    </>
  );
};

export const ContractEntityActionsButton = ({ minimal, entity, ...props }) => {
  const [modalState, toggle] = useModalStatusReducer();

  return (
    <>
      <SendContractRenovationModal
        modalState={modalState}
        entity={entity}
        toggle={toggle}
      />
      <EntityPopup minimal={minimal}>
        <ContractEntityActionsChildren
          sendNotificationToggler={toggle}
          minimal={minimal}
          entity={entity}
          {...props}
        />
      </EntityPopup>
    </>
  );
};

export const ContractEntityGridButtons = ({
  entity,
  minimal,
  noColors,
  hideBreadCrumbs
}) => {
  const conds = useTriggeredColorFilters(entity);
  return (
    <TriggeredColoredFilterProvider conds={conds}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="d-flex align-items-center"
      >
        {!hideBreadCrumbs && (
          <BreadcrumbsPopup
            className="mr-2 fs-14 d-inline-flex text-primary"
            originType={originTypes.contract}
            minimal
            id={entity.Id}
          />
        )}
        <ContractFormButton
          minimal={minimal}
          canEdit={entity.CanEdit}
          id={entity.Id}
          className={classnames("mr-2 FormButtonClassID", {
            "ml-1": conds?.length > 0
          })}
        />
        <ContractEntityActionsButton entity={entity} minimal={minimal} />
        {!noColors && <InnerColorFilterBars />}
      </div>
    </TriggeredColoredFilterProvider>
  );
};

export const ResolvedCurrencyWithActualCost = ({ currencies }) => {
  const CurrencyWithValue = currencies.find((e) => e.Cost > 0);

  const Currency = useCurrency(CurrencyWithValue?.CurrencyId);
  if (!Currency)
    return (
      <div className="text-truncate w-100 d-flex justify-content-end">
        <span>―</span>
      </div>
    );

  const value = toCurrencyFromDecimal(CurrencyWithValue.Cost);

  return (
    <div className="text-truncate w-100 d-flex justify-content-end">
      <span>{value === 0 ? "―" : Currency?.Symbol + " " + value}</span>
    </div>
  );
};
