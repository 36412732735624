import React, { useEffect, useRef, useState } from "react";
import Popup, { usePopupOpenState } from "../../Popup/Popup";
import { SketchPicker } from "react-color";
import FormInput from "../../Forms/FormInput/FormInput";
import classes from "./ColorInput.module.css";
import classnames from "classnames";

export const ColorInput = ({
  value,
  onChange,
  colorInputClasses,
  disabled
}) => {
  const [color, setColor] = useState(value);

  useEffect(() => {
    if (!color) return;

    const timeout = setTimeout(() => {
      onChange(color);
    }, 300);

    return () => clearTimeout(timeout);
  }, [color, onChange]);

  const popupRef = useRef();

  const CategoryExtraActionButtonRef = useRef();
  const [isOpen, toggleIsOpen] = usePopupOpenState(
    popupRef,
    CategoryExtraActionButtonRef
  );

  const changeColor = (e) => {
    setColor(e.hex);
  };

  const changeValueColor = (e) => {
    setColor(e.target.value);
  };
  return (
    <div className="d-flex align-items-center">
      <Popup
        domRef={popupRef}
        anchorEl={CategoryExtraActionButtonRef.current}
        modifiers={{
          preventOverflow: { boundariesElement: "viewport" }
        }}
        isOpen={Boolean(isOpen)}
      >
        <SketchPicker
          disableAlpha={true}
          color={color}
          onChange={changeColor}
        />
      </Popup>
      <FormInput
        onChange={changeValueColor}
        value={color}
        disabled={disabled}
        className="Widget-Color-Code-Input ml-1 mr-2"
        disableAutomation
      />
      <span
        ref={CategoryExtraActionButtonRef}
        onClick={() => (disabled ? 0 : toggleIsOpen())}
        style={{ backgroundColor: `${color || "#ffffff"}` }}
        className={classnames(classes.colorCircle, colorInputClasses)}
      ></span>
    </div>
  );
};
