export const pt = {
  "14_DAYS_TRIAL": " dias avaliação gratuita",
  "180DAYS": "180 dias",
  "24H": "24H",
  ACCEPT_INVITATION: "O convite já foi aceite.",
  ACCEPT_TERMS_AND_CONDITIONS:
    "Aceito os <a>termos e condições</a> do software Airdesk.",
  ACCESS: "Acesso",
  ACCESSES: "Acessos",
  ACCOUNT: "Conta",
  ACCOUNT_ASSIGNED: "atribuiu-lhe a conta",
  ACCOUNT_DELETED: "apagou o cliente",
  ACCOUNT_IS: "Conta é",
  ACCOUNT_NR: "Nº conta",
  ACCOUNT_PROFILE: "Conta e perfil",
  ACCOUNT_STATUS: "Estado conta",
  ACCOUNT_TYPE: "Tipo de conta",
  ACCOUNT_UPDATE: "atualizou a conta",
  ACCOUNT_UPDATED: "atualizou a conta",
  ACCOUNTING: "Contabilidade",
  ACCOUNTS: "Contas",
  ACTIONS: "Ações",
  ACTIVATE_TRIGGER_ON_CREATION_OR_EDITION: "Ativar trigger em",
  ACTIVE: "Ativo",
  ACTIVE_NOTIFICATIONS: "Notificações ativas",
  ACTIVE_NOW: "Ativo/a agora",
  ACTIVE_WORKSPACE: "Workspace",
  ACTIVITY: "Atividade",
  ADD: "Adicionar",
  ADD_ACCOUNT: "Adicionar conta",
  ADD_ADDRESS: "Adicionar endereço",
  ADD_ANOTHER_PUBLICATION: "Adicionar outra publicação",
  ADD_API_KEY: "Adicionar API key",
  ADD_BLOCK: "Adicionar novo bloco",
  ADD_BOOLEAN: "Adicionar verdadeiro ou falso",
  ADD_CALL: "Adicionar chamada",
  ADD_CANCELATION_REASON: "Adicionar razão de cancelamento",
  ADD_CASE: "Adicionar caso",
  ADD_CHECKLIST_TEMPLATE: "Adicionar template de checklist",
  ADD_CLIENT_TYPE: "Adicionar tipo conta",
  ADD_CLIENTS_WORKSPACE: "Adicione clientes ao seu espaço de trabalho",
  ADD_COMPANY: "Adicionar empresa",
  ADD_CONDITION: "Adicionar condição",
  ADD_CONTACT: "Adicionar contacto",
  ADD_CONTACTS: "Adicionar contactos",
  ADD_CONTACTS_WORKSPACE: "Associe os contatos dos seus clientes",
  ADD_CONTRACT: "Adicionar contrato",
  ADD_CONTRACTS: "Adicionar contratos",
  ADD_CONTRACTS_WORKSPACE: "Insira e atualize os seus contratos",
  ADD_COUPON: "Adicionar cupão",
  ADD_CRON_TIME: "Adicionar cron",
  ADD_DATE: "Adicionar data",
  ADD_DEAL: "Adicionar negócio",
  ADD_DEALS_WORKSPACE: "Criar um negócio ajuda-o a tomar as melhores decisões",
  ADD_DEPARTMENT: "Adicionar departamento",
  ADD_EMAIL_DOMAIN: "Adicionar domínio de email",
  ADD_EMAIL_SIGNATURE: "Adicionar assinatura de email",
  ADD_FAVORITE: "Adicionar favorito",
  ADD_FILTER: "Adicionar filtro",
  ADD_FOLDER: "Adicionar pasta",
  ADD_INEFICIENT_TYPE: "Adicionar razão de tempo não imputável",
  ADD_ITEM: "Adicionar item",
  ADD_LOSS_REASON: "Adicionar razão de perda",
  ADD_LOST_REASON: "Adicionar razão de perda",
  ADD_NEW_ACCOUNT: "Adicionar nova conta",
  ADD_NEW_BILLING_CONTACT: "Atualizar contacto(s) de faturação",
  ADD_NEW_BLOCK: "Adicionar novo bloco",
  ADD_NEW_CONTACT: "Adicionar novo contato",
  ADD_NEW_COUPON: "Adicionar cupão novo",
  ADD_NEW_DETAIL_VIEW_APP_BAR: "Adicionar vista 360",
  ADD_NEW_DETAILS_APP_BAR_DESC:
    "Selecione o elemento a adicionar aos favoritos",
  ADD_NEW_DETAILS_APP_BAR_TITLE: "Adicionar vista 360 a favoritos",
  ADD_NEW_EMAIL_INPUT: "Adicionar outro convite",
  ADD_NEW_PERIOD: "Adicionar novo período",
  ADD_NEW_VIEW_APP_BAR: "Adicionar nova vista",
  ADD_NEW_VIEW_APP_BAR_DESC:
    "Selecione o tipo de vista a adicionar aos favoritos",
  ADD_NEW_VIEW_APP_BAR_TITLE: "Adicionar vista a favoritos",
  ADD_NODES: "Adicionar nós",
  ADD_NOTE: "Adicionar nota",
  ADD_NUMBER: "Adicionar número",
  ADD_PIPELINE: "Adicionar pipeline",
  ADD_PROJECT: "Adicionar projeto",
  ADD_PUBLICATION: "Adicionar publicação",
  ADD_RECEPTION: "Adicionar inbox",
  ADD_SERVICES_WORKSPACE:
    "Todo um leque de funcionalidades ao serviço da sua empresa",
  ADD_SOCIAL_POST: "Adicionar publicação",
  ADD_SOCIAL_SCHEDULE: "Adicionar horário de publicação",
  ADD_SPACE: "Adicionar espaço",
  ADD_STATUS: "Adicionar estado",
  ADD_STRING: "Adicionar texto",
  ADD_SUBJECT: "Adicionar assunto",
  ADD_SUBSCRIPTION: "Adicionar subscrição",
  ADD_TASK: "Adicionar tarefa",
  ADD_TEAM: "Adicionar equipa",
  ADD_TIME: "Adicionar tempo",
  ADD_TIME_TYPE: "Adicionar tipo de tempo",
  ADD_TIMER: "Adicionar temporizador",
  ADD_TIMERS: "Adicionar temporizadores",
  ADD_TYPE_OF_CONTRACT: "Adicionar tipo de contrato",
  ADD_TYPE_OF_PROJECTS: "Adicionar tipo de projeto",
  ADD_TYPE_OF_SUBSCRIPTION: "Adicionar tipo de subscrição",
  ADD_USER: "Adicionar utilizador",
  ADD_VIEW: "Adicionar vista",
  ADD_WIDGET: "Adicionar widget",
  ADD_WORKFLOW: "Adicionar workflow",
  ADDRESS: "Morada",
  ADDRESS_1: "Morada 1",
  ADDRESS_2: "Morada 2",
  ADITION: "Adição",
  ADMIN: "Admin",
  ADMIN_ACCESS: "Acesso administrador",
  ADMINISTRATION_DEPARTMENT: "Administração",
  ADVANCE_TO_AIRDESK: "Avançar para Airdesk",
  ADVANCED_FILTER: "Filtro avançado",
  ADVANCED_OPTIONS: "Opções avançadas",
  ADVANCED_SETTINGS: "Configurações avançadas",
  AGROUP: "Agrupar",
  AIRDESK_UPDATE_DESCRIPTION: "Por favor, faça refresh á pagina.",
  AIRDESK_UPDATE_TITLE: "Existe uma nova versão do Airdesk!",
  AIRLINES_OR_AVIATION: "Companhias aéreas / Aviação",
  ALIGN_CENTER: "Alinhar ao centro",
  ALIGN_JUSTIFY: "Justificar",
  ALIGN_LEFT: "Alinhar à esquerda",
  ALIGN_RIGHT: "Alinhar à direita",
  ALL: "Todas",
  ALL_CONVERSATIONS: "Todas as conversas",
  ALLOWED_DOMAINS: "Domínios permitidos",
  ALMOST_EXPIRED_CONTRACTS: "Contratos quase expirados",
  ALMOST_EXPIRED_PROJECTS: "Projetos quase expirados",
  ALTERNATIVE_DISPUTE_RESOLUTION: "Resolução alternativa de disputa",
  ALTERNATIVE_MEDICINE: "Medicina alternativa",
  AMOUNT: "Valor fixo",
  ANALYSIS: "Análise",
  ANALYSIS_DEALS: "Análise dos negócios",
  AND: "e",
  ANIMATION: "Animação",
  ANNUALY: "Anual",
  ANSWER_SIGNATURE: "Resposta",
  API_ALREADY_FOLLOWING: "Já está a seguir este item.",
  API_ALREADY_NOT_FOLLOWING: "Não está a seguir este item.",
  API_CANNOT_CLOSE_DEPENDENCIES_OPEN:
    "O item não pode ser fechado/cancelado porque tem dependências ainda com o estado aberto.",
  API_CANNOT_REMOVE_HAS_CONVERSATIONS:
    "O item não pode ser eliminado porque tem emails associado.",
  API_CANNOT_REMOVE_HAS_CONVERSATIONS_MULTIPLE:
    "Os itens {Ids} não podem ser eliminados porque têm emails associados.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES:
    "O item não pode ser eliminado porque tem dependências associadas.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES_DATA:
    "O item não pode ser eliminado porque tem as seguintes dependências associadas: {Depends}.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES_MULTIPLE:
    "Os itens {Ids} não podem ser eliminados porque têm dependências associadas.",
  API_CANNOT_REMOVE_HAS_TIMECHARGES:
    "O item não pode ser eliminado porque tem tempos associados.",
  API_CANNOT_REMOVE_HAS_TIMECHARGES_MULTIPLE:
    "Os itens {Ids} não podem ser eliminados porque têm tempos associados.",
  API_CANNOT_REMOVE_IS_CLASSIFIED:
    "O item não pode ser eliminado porque já se encontra numa classificação.",
  API_CANNOT_REMOVE_IS_CLASSIFIED_MULTIPLE:
    "Os itens {Ids} não podem ser eliminados porque já se encontram em classificações.",
  API_DATA_IS_EQUAL: "Os dados são iguais.",
  API_EXPORT_COMPLETED_SUCCESSFULLY:
    "A exportação foi finalizada com sucesso. Já pode ver o seu ficheiro na vista pessoal de exportações.",
  API_EXPORT_STARTED: "A exportação foi iniciada com sucesso.",
  API_FILTER_ALREADY_EXISTS: "O nome do filtro já existe.",
  API_IMPORT_CLIENT_END: "A importação de contas terminou.",
  API_IMPORT_CONTACT_END: "A importação de contactos terminou.",
  API_KEY: "Chave de API",
  API_KEY_ID: "Chave API Id",
  API_KEYS: "API",
  API_MARKED_AS_READ: "Já está marcado como lido.",
  API_MARKED_AS_UNREAD: "Já está marcado como não lido.",
  API_NO_SOCIAL_COMPANY_PAGE_ASSOCIATED:
    "Não existem contas empresariais associadas a sua conta.",
  API_NOT_FOUND: "Item não encontrado",
  API_NOT_FOUND_OR_NO_PERMISSIONS_EDIT:
    "Item não encontrado ou não tem permissões suficientes para editá-lo.",
  API_NOT_FOUND_OR_NO_PERMISSIONS_REMOVE:
    "Item não encontrado ou não tem permissões suficientes para removê-lo.",
  API_NOT_FOUND_OR_NO_PERMISSIONS_VIEW:
    "Item não encontrado ou não tem permissões suficientes para vê-lo.",
  API_REFERENCE: "Especificação da API",
  APPAREL_OR_FASHION: "Vestuário / Moda",
  APPLY: "Aplicar",
  APPLY_ALL: "Aplicar a todos",
  APPLY_TO: "Aplicar a",
  APRIL: "Abril",
  APROVED: "Aprovado",
  APROVING: "Em aprovação",
  ARCHITECTURE_OR_PLANNING: "Arquitetura / Planejamento",
  ARTS_OR_CRAFTS: "Artes e Ofícios",
  ASSIGN_TO: "Associar a",
  ASSIGNED_THE_DEAL: "atribuiu o negócio",
  ASSIGNED_TO: "Atribuído a",
  ASSOCIATE_CASE: "Associar caso a",
  ASSOCIATE_CONTRACT_TO: "Associar contrato a",
  ASSOCIATE_DEAL_TO: "Associar negócio a",
  ASSOCIATE_PROJECT_TO: "Associar projeto a",
  ASSOCIATE_SUBSCRIPTION: "Associar subscrição a",
  ASSOCIATE_TASK_TO: "Associar tarefa a",
  ASSOCIATE_TIME: "Associar registo de tempo a",
  ASSOCIATE_TIME_RECORD: "Associar registo de tempo a",
  ASSOCIATE_TO: "Associar a",
  ASSOCIATED: "Associado",
  ASSOCIATED_ACCOUNTS: "Contas associados",
  ASSOCIATED_CONTRACTS: "Contratos associados",
  ASSOCIATED_PROJECTS: "Projetos associados",
  ASSOCIATED_TO: "Associado a",
  ASSOCIATIONS: "Associações",
  ASSOCIATIONS_CONTACT_DATA: "Associação dos dados do contato",
  ATTACHMENTS: "Anexos",
  ATTRIBUTED_TO: "Atribuído a",
  ATTRIBUTES: "Atributos",
  AUGUST: "Agosto",
  AUTO_REFRESH: "Atualização automática",
  AUTOMATION: "Automação",
  AUTOMOTIVE: "Automotivo",
  AVERAGE: "Neutro",
  AVERAGE_CLOSE_CASE: "Tempo médio de fecho de casos",
  AVERAGE_CLOSE_TASKS: "Tempo médio de fecho de tarefas",
  AVERAGE_CLOSE_TIME: "Tempo médio de Fecho",
  AVERAGE_STATUS: "Tempo médio por Estado",
  AVIATION_OR_AEROSPACE: "Aviação / Aeroespacial",
  BACK: "Voltar",
  BACKGROUND: "Fundo",
  BACKGROUND_JOB_CANCELED_TITLE: "Processo foi cancelado",
  BAD: "Mau",
  BANKING_OR_MORTGAGE: "Banco / hipoteca",
  BANNER: "Banner",
  BANNER_LINK: "Link do banner",
  BASIC: "Básico",
  BASIS_CUSTOMER: "Interesse legítimo - cliente existente",
  BASIS_OTHER: "Interesse legítimo - outro",
  BASIS_POSSIBLE: "Interesse legítimo - possível / potencial",
  BEGINS_WITH: "Começa com",
  BILLED: "Faturado",
  BILLED_NR: "Nº Faturação",
  BILLING: "Faturação",
  BILLING_CONTACTS_ADD_SUCCESS: "Contatos de faturação adicionados com sucesso",
  BILLING_CONTACTS_UPDATED_SUCCESS:
    "Contatos de faturação atualizados com sucesso",
  BILLING_DETAILS: "Detalhes de faturação",
  BILLING_EMAIL: "Contacto de faturação",
  BILLING_EMAILS: "Email de contacto de faturação",
  BILLING_HISTORY: "Atividades de faturação",
  BILLING_INFORMATION_OPTIONAL: "Informação para faturação",
  BILLING_NR: "Nº faturação",
  BILLING_STATUS: "Estado faturação",
  BILLING_STATUS_CUSTOM: "Empresa",
  BILLINGS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar faturações",
  BILLINGS_DELETE_DELAY_TITLE: "Eliminação de faturação",
  BILLINGS_DELETE_DESCRIPTION: "A eliminar faturação",
  BILLINGS_DELETE_TITLE: "Eliminação de faturação",
  BIOTECHNOLOGY_OR_GREENTECH: "Biotecnologia / Greentech",
  BIRTH_DATE: "Data de aniversário",
  BIRTHDAY: "Aniversário",
  BIRTHDAY_TODAY: "Descobre quem faz anos esta semana.",
  BLOCK: "Bloco",
  BLOCK_USER: "Bloquear utilizador",
  BLOCKED: "Bloqueado",
  BLOCKS: "Blocos",
  BODY: "Body",
  BOLD: "Bold",
  BOTH: "Ambas",
  BRANDING: "Branding",
  BROADCAST_MEDIA: "Broadcast media",
  BROKEN_SOCIAL_CONNECTION: "Ligação quebrada, por favor reconecte a conta.",
  BUDGET_RISK: "Budget risk",
  BUILDING_MATERIALS: "Materiais de construção",
  BULLET_LIST: "Lista de marcas",
  BUSINESS_SUPPLIES_OR_EQUIPMENT: "Suprimentos / equipamentos comerciais",
  BUTTONS: "Ações",
  CALENDAR_GENERAL_ERROR: "Houve um erro ao carregar o calendário.",
  CALENDAR_MORE_ITEMS: "mais {num}",
  CALL: "Chamada",
  CALL_ASSIGNED: "atribuiu-lhe a chamada",
  CALL_CREATION: "Criação de chamada",
  CALL_DELETED: "apagou a chamada",
  CALL_IS: "Chamada é",
  CALL_NR: "Nº chamada",
  CALL_UPDATE: "atualizou a chamada",
  CALLS: "Chamadas",
  CALLS_CHANGED: "chamadas alteradas",
  CALLS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar chamadas",
  CALLS_DELETE_DELAY_TITLE: "Eliminação de chamadas",
  CALLS_DELETE_DESCRIPTION: "A eliminar chamadas",
  CALLS_DELETE_TITLE: "Eliminação de chamadas",
  CALLS_DESCRIPTION:
    "Acompanhe a contagem das chamadas da sua empresa, o tempo de resposta e muito mais.",
  CALLS_FAILED_CHANGED: "falhou ao alterar",
  CALLS_MULTIPLE: "Chamadas(s)",
  CALLS_NOT_CHANGED: "chamadas inalterados",
  CANCEL: "Cancelar",
  CANCEL_BILLING_ANYTIME: "Cancele em qualquer altura",
  CANCEL_PLAN_MODAL_DESCRIPTION:
    "Antes de partir, entre em contato connosco e informe-nos o motivo pelo qual você está a sair. Nós poderíamos ajudá-lo antes de você ir. Entre em contato connosco via",
  CANCEL_PLAN_MODAL_DESCRIPTION_WARNING:
    "Você irá perder o acesso às informações do espaço de trabalho, certifique-se de exportar antes de cancelar.",
  CANCEL_PLAN_MODAL_TITLE: "Cancelar subscrição",
  CANCEL_PLAN_SUBSCRIPTION: "Cancelar subscrição",
  CANCEL_SUBSCRIPTION: "Cancelar subscrição",
  CANCEL_SUBSCRIPTION_AIRDESK: "Cancelar a sua subscrição no Airdesk",
  CANCEL_SUBSCRIPTION_AIRDESK_UNDER:
    "Cancelar a sua subscrição suspende o serviço e os dados são apagados em 15 dias.",
  CANCELATION_DESCRIPTION: "Descrição de cancelamento",
  CANCELATION_REASON: "Motivo de cancelamento",
  CANCELATION_REASONS: "Motivos de cancelamento",
  CANCELATION_REASONS_SUCCESS: "Razão de cancelamento gravada com sucesso",
  CANCELED_SUBSCRIPTION: "Subscrição Cancelada",
  CANCELLED: "Cancelado",
  CANCELLED_PROJECTS: "Projetos Cancelados",
  CANT_REPLY_CLOSED_CONVERSATION:
    "Só é possível responder a conversas com o estado Aberto.",
  CAPITAL_MARKETS_OR_HEDGE_FUND_OR_PRIVATE_EQUITY:
    "Mercado de capitais / hedge fund / private equity",
  CARD_CANCELED_SUCCESS: "Cartão removido com sucesso",
  CARD_NAME: "Nome no cartão",
  CARD_NUMBER: "Número do cartão",
  CARD_SECURITY_CODE: "Código de segurança",
  CASE: "Caso",
  CASE_ASSIGNED: "atribuiu-lhe o caso",
  CASE_ASSIGNED_TO: "atribuiu o caso",
  CASE_DELETED: "apagou o caso",
  CASE_FORM: "Formulário de caso",
  CASE_IS: "Caso é",
  CASE_NR: "Nº caso",
  CASE_UPDATE: "atualizou o caso",
  CASE_WAS_CLOSED: "foi encerrado o caso <b>{caseName}</b>",
  CASES: "Casos",
  CASES_STATUS: "Casos por estado",
  CATEGORY: "Categoria",
  CATEGORY_IS: "Categoria é",
  CELLPHONE_CODES: "Alterar indicativos de telemóvel",
  CHANGE_ACTIVE_PLAN: "Alterar plano",
  CHANGE_ASSIGNED: "Alterar atribuição",
  CHANGE_BILLING_ANYTIME: "Altere de plano quando quiser",
  CHANGE_GDPR: "Alterar GDPR",
  CHANGE_MANAGER: "Alterar responsável",
  CHANGE_NOTE: "Alterar nota",
  CHANGE_PASSWORD: "Alterar",
  CHANGE_PASSWORD_BLOCKED_DESC:
    "Falhou a password atual mais de 3 vezes por favor tente a alteração partir de",
  CHANGE_PASSWORD_BLOCKED_TITLE: "Alteração de password bloqueada",
  CHANGE_PIPELINE: "Alterar pipeline",
  CHANGE_STATUS: "Alterar estados",
  CHANGE_WORKSPACE: "Mudar espaço de trabalho",
  CHANGED_BY: "Alterado por",
  CHANGES: "Alterações",
  CHANGES_SAVED_WITH_SUCCESS: "Alterações guardadas com Sucesso",
  CHAT_CONNECTION_ERROR_DESCRIPTION: "Existiu um problema ao conectar ao chat",
  CHAT_NO_MESSAGES: "Não existem mensagens",
  CHAT_NO_NOTES: "Não existem notas",
  CHAT_ONLINE: "Chat online",
  CHAT_RECONNECTING: "A reconectar...",
  CHECK_LIST: "Check list",
  CHECKLIST: "Checklist",
  CHECKLIST_TEMPLATE: "Templates de checklist",
  CHEMICALS: "Produtos químicos",
  CHOOSE_A_SPACE_NAME: "Qual o nome da sua empresa/grupo?",
  CHOOSE_A_SPACE_NAME_EXPLANATION:
    "Os dados serão utilizados para configurar o seu espaço de trabalho. Poderá sempre alterá-los mais tarde.",
  CHOOSE_A_TEMPLATE_TO_USE: "Escolha o template a utilizar",
  CHOOSE_BILLING_PLAN: "Escolha o plano que mais se adequa á sua empresa",
  CHOOSE_COLUMNS: "Escolha as colunas a exibir",
  CHOOSE_DEPARTMENT: "Escolha o departamento",
  CHOOSE_MODULES: "Escolha os módulos a exibir",
  CHOOSE_PAGE: "Escolha uma folha",
  CHOOSE_PIPELINE_STATUS: "Escolha os estados de pipeline a exibir",
  CHOOSE_STATUS: "Escolha os estados a exibir",
  CHOOSE_THE_DROPDOWN_ORDER: "Escolha a ordem no dropdown",
  CHOSEN_PLAN: "Plano escolhido:",
  CITY: "Cidade",
  CIVIC_OR_SOCIAL_ORGANIZATION: "Organização cívica / Social",
  CIVIL_ENGINEERING: "Engenharia civil",
  CLASS_NR: "Nº Class",
  CLASSIFICATION: "Classificação",
  CLASSIFICATION_CONFIRMATION_TEXT: "Deseja efetuar esta ação?",
  CLASSIFICATION_DESCRIPTION:
    "Consulte as classificações dos tempos lançados pelos seus colaboradores.",
  CLASSIFICATION_ERROR:
    "Houve um erro ao processar o seu pedido. Se o erro persistir contacte a administração",
  CLASSIFICATION_IDENTIFIER_ERROR:
    "Nº de faturação já existe. Por fazor selecione outro",
  CLASSIFICATION_INTEGRITY_AFFECTED:
    "O sistema detetou que ao alterar a origem irão ser afetados",
  CLASSIFICATION_LIST_EMPTY: "Listagem de classificações vazia.",
  CLASSIFICATION_NR: "Nº classificação",
  CLASSIFICATION_ORIGIN: "Origem de classificação",
  CLASSIFICATION_ORIGIN_ERROR: "Origem é obrigatória",
  CLASSIFICATION_ORIGIN_RECOMMENDATION:
    "Consoante os registos de tempo abaixo, o sistema recomenda a seguinte origem:",
  CLASSIFICATION_REPORT: "Relatório classificação",
  CLASSIFICATION_SELECT_ORIGIN: "Por favor, selecione uma origem",
  CLASSIFICATION_SELECT_ORIGIN_QUESTION: "Pretende selecionar?",
  CLASSIFICATION_STATUS_ERROR: "Estado deve ser classificado",
  CLASSIFICATION_TIMECHARGE_ADDITION_SUCCESS:
    "Intervenções adicionadas com Sucesso",
  CLASSIFICATION_TIMECHARGE_ERROR:
    "O sistema detetou que existe(m) registo(s) de tempo que iria(m) ser afetado(s), já classificado(s) e com outra origem. Para prosseguir com esta classificação, corrija o(s) seguinte(s):",
  CLASSIFICATION_TO_NEW_ORIGIN: "Para nova origem:",
  CLASSIFICATION_TOTAL_OF: "ficando com o total de",
  CLASSIFICATION_UPDATE_SUCCESS: "Classificação atualizada com Sucesso",
  CLASSIFICATION_WILL_BE_ADDED_TO: "serão adicionados ao",
  CLASSIFICATION_WILL_CHANGE: "Serão alterados:",
  CLASSIFICATIONS: "Classificações",
  CLASSIFIED: "Classificado",
  CLASSIFIED_BUT_OTHER_ENTITY: "Associado, mas classificado a outra entidade",
  CLASSIFIED_BY: "Classificado por",
  CLASSIFY: "Classificar",
  CLEAN: "Limpar",
  CLEAN_ALL: "Limpar todos",
  CLEAN_BOARD: "Limpar tela",
  CLIENT_CREATION: "Criação de conta",
  CLIENT_DATA_IMPORT_AIRDESK:
    "Importação de dados de clientes a associar ao Airdesk",
  CLIENT_SIGNATURE: "Assinatura de cliente",
  CLIENT_STATUS: "Estado de conta",
  CLIENT_STATUS_IS: "Estado de conta é",
  CLIENT_TYPE_EQUALS: "Tipo de cliente é igual a",
  CLIENT_TYPE_LIST_EMPTY: "Listagem de tipos de conta vazia.",
  CLIENT_TYPE_LIST_NO: "Não existem registos de tipo de conta associados.",
  CLIENTS_CHANGED: "contas alteradas",
  CLIENTS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar contas",
  CLIENTS_DELETE_DELAY_TITLE: "Eliminação de contas",
  CLIENTS_DELETE_DESCRIPTION: "A eliminar contas",
  CLIENTS_DELETE_TITLE: "Eliminação de contas",
  CLIENTS_FAILED_CHANGED: "falhou ao alterar",
  CLIENTS_NOT_CHANGED: "contas inalteradas",
  CLOSE_ACCOUNTS: "Casos fechados",
  CLOSE_DATE: "Data de fecho",
  CLOSED: "Fechado",
  CLOSED_CASES: "Casos fechados",
  CLOSED_CONTRACTS: "Contratos encerrados",
  CLOSED_PROJECTS: "Projetos fechados",
  CLOSED_RECEPTION: "Fechado",
  CLOSED_TASKS: "Tarefas fechadas",
  CODE: "Código",
  CODE_CONTAINER: "Caixa de código",
  CODE_VIEW: "Estilo de código",
  COLABORATORS: "Colaboradores",
  COLLABORATOR: "Colaborador",
  COLLABORATOR_NR: "Nº colaborador",
  COLLABORATORS: "Colaboradores",
  COLOR: "Cor",
  COLOR_FILTER: "Cor",
  COLUMN: "Coluna",
  COLUMNS: "Colunas",
  COMBINE: "Combinar",
  COMERCIAL: "Comercial",
  COMMENT: "Comentar",
  COMMERCIAL: "Comercial",
  COMMERCIAL_DEPARTMENT: "Comercial",
  COMMERCIAL_REAL_ESTATE: "Imóveis comerciais",
  COMPANIES: "Empresas",
  COMPANIES_SUBSIDIARIES: "Empresas/Filiais",
  COMPANY: "Empresa",
  COMPANY_ADDRESS1: "Morada 1 da empresa",
  COMPANY_ADDRESS2: "Morada 2 da empresa",
  COMPANY_AVATAR: "Avatar da empresa",
  COMPANY_DOMAIN: "Domínio da empresa",
  COMPANY_EMAIL: "Email da empresa",
  COMPANY_FACEBOOK_URL: "Facebook da empresa",
  COMPANY_GROUP_NAME: "Nome da empresa/grupo",
  COMPANY_INDUSTRY: "Indústria da empresa",
  COMPANY_INFORMATION: "Informação da empresa",
  COMPANY_INSTAGRAM_URL: "Instagram da empresa",
  COMPANY_IS: "Empresa é",
  COMPANY_LINKEDIN_URL: "Linkedin da empresa",
  COMPANY_LOGO: "Logo da empresa",
  COMPANY_NAME: "Nome da empresa ou grupo de empresas",
  COMPANY_PHONE: "Número de telefone da empresa",
  COMPANY_SIGNATURE_NAME: "Nome da empresa",
  COMPANY_TWITTER_URL: "Twitter da empresa",
  COMPANY_VAT: "NIF empresa",
  COMPANY_YOUTUBE_URL: "Youtube da empresa",
  COMPARE_OUR_PLANS: "Compare as nossas ofertas",
  COMPARE_THE_PRICE_OF_PLANS: "Compare os preços dos nossos planos",
  COMPLETE: "Completo",
  COMPLETED: "Completo",
  COMPRESS_ALL: "Minimizar todos",
  COMPUTER_GAMES: "Jogos de computador",
  COMPUTER_HARDWARE: "Hardware de computador",
  COMPUTER_NETWORKING: "Rede de computadores",
  COMPUTER_OR_NETWORK_SECURITY: "Segurança de computador / rede",
  COMPUTER_SOFTWARE_OR_ENGINEERING: "Software / Engenharia de computador",
  CONCLUDE: "Sim",
  CONCLUDE_CALL: "Alterar estado de chamada",
  CONCLUDE_CALL_ASK: "Deseja alterar o estado desta chamada?",
  CONCLUDE_ENTITY: "Alterar estado de {entityText}",
  CONCLUDE_TASK: "Alterar estado de tarefa",
  CONCLUDE_TASK_ASK: "Deseja alterar o estado desta tarefa?",
  CONCLUDE_TICKET: "Alterar estado de caso",
  CONCLUDE_TICKET_ASK: "Deseja alterar o estado deste caso?",
  CONCLUDED: "Concluído",
  CONCLUSION: "Conclusão",
  CONDITION: "Condição",
  CONFIG_TITLE: "Configurações - Airdesk",
  CONFIGURE_BUTTON: "Configurar botão",
  CONFIGURE_SUBJECTS: "Configurar assuntos - atribuição de casos",
  CONFIGURE_WORK_START: "Comece por configurar o seu espaço de trabalho",
  CONFIRM: "Confirmar",
  CONFIRM_CHANGES: "Deseja guardar as alterações feitas às notificações?",
  CONFIRM_CONTACT_INFORMATION: "Confirmação de dados",
  CONFIRM_CONTACT_INFORMATION_DESCRIPTION:
    "Os dados agregados aos contatos que foram agregados irão estar associados a este contato.",
  CONFIRM_PASSWORD: "Confirme a password",
  CONNECT_WORKFLOW: "Fluxo de trabalho de conexão",
  CONNECTED_BY: "Conectado por",
  CONSENT_CONTACT: "Consentimento dado pelo contacto",
  CONSTRUCTION: "Construção",
  CONSUMER_ELECTRONICS: "Eletrônicos de consumo",
  CONSUMER_GOODS: "Bens de consumo",
  CONSUMER_SERVICES: "Serviços do Consumidor",
  CONTACT: "Contacto",
  CONTACT_ACCESS: "Acesso de contactos",
  CONTACT_CREATED: "criou o contato",
  CONTACT_CREATION: "Criação de contatos",
  CONTACT_DATA_IMPORT_AIRDESK:
    "Importação de dados de contatos a associar ao Airdesk",
  CONTACT_IS: "Contato é",
  CONTACT_NOTIFICATIONS: "Notificações ao contacto",
  CONTACT_NR: "Nº de contacto",
  CONTACT_UPDATED: "atualizou o contato",
  CONTACT_US: "Contacte-nos",
  CONTACTED_DELETED: "apagou o contato",
  CONTACTS: "Contactos",
  CONTACTS_CHANGED: "contatos alterados",
  CONTACTS_DEFAULT_DESCRIPTION_UNDER_TABLES:
    "Todos os e-mails relacionados faturação serão enviados para esta lista de contatos.",
  CONTACTS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar contactos",
  CONTACTS_DELETE_DELAY_TITLE: "Eliminação de contactos",
  CONTACTS_DELETE_DESCRIPTION: "A eliminar contactos",
  CONTACTS_DELETE_TITLE: "Eliminação de contactos",
  CONTACTS_DUPLICATE_DESCRIPTION:
    "O sistema detetou {count} contacto(s) com os dados inseridos acima, recomendamos que utilize um contacto existente para prevenir duplicados.",
  CONTACTS_FAILED_CHANGED: "falhou ao alterar",
  CONTACTS_MERGE: "Agregar contactos",
  CONTACTS_MERGED_FAILURE:
    "Ocorreu um erro ao agregar os contatos. Tente novamente.",
  CONTACTS_MERGED_SUCCESS: "Os contatos foram agregados com sucesso.",
  CONTACTS_NOT_CHANGED: "contatos inalterados",
  CONTACTS_NR: "Nº de contactos",
  CONTAINS: "Contém",
  CONTATCS: "Contatos",
  CONTINUE: "Continuar",
  CONTINUE_ON_FAIL: "Continuar em caso de falha",
  CONTRACT: "Contrato",
  CONTRACT_ASSIGNED: "atribuiu-lhe o contrato",
  CONTRACT_CREATION: "Criação de contratos",
  CONTRACT_DELETED: "apagou o contrato",
  CONTRACT_END: "Fim do contrato",
  CONTRACT_IS: "Contrato é",
  CONTRACT_NR: "Nº contrato",
  CONTRACT_STATUS: "Estados de contratos",
  CONTRACT_TYPE: "Tipo de contrato",
  CONTRACT_TYPES: "Tipos de contrato",
  CONTRACT_TYPES_EMPTY_LIST: "Listagem de motivos de empresas vazia.",
  CONTRACT_TYPES_EMPTY_LIST_DESCRIPTION:
    "Não existem registos de empresas associadas.",
  CONTRACT_UPDATE: "atualizou o contrato",
  CONTRACTS: "Contratos",
  CONTRACTS_ACTIVITY: "Atividades dos contratos",
  CONTRACTS_ALMOST_EXCEEDED: "Contratos - tempo quase excedido",
  CONTRACTS_BUDGET_RISK: "Contratos - budget Risk",
  CONTRACTS_CHANGED: "contratos alteradas",
  CONTRACTS_DEADLINE_RISK: "Contratos - deadline risk",
  CONTRACTS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar contratos",
  CONTRACTS_DELETE_DELAY_TITLE: "Eliminação de contratos",
  CONTRACTS_DELETE_DESCRIPTION: "A eliminar contratos",
  CONTRACTS_DELETE_TITLE: "Eliminação de contratos",
  CONTRACTS_DESCRIPTION:
    "Tenha uma visão global do desenvolvimento dos contratos da sua empresa.",
  CONTRACTS_EXCEEDED_HOURS: "Contratos - tempo excedido",
  CONTRACTS_FAILED_CHANGED: "falhou ao alterar",
  CONTRACTS_NOT_CHANGED: "contratos inalterados",
  CONTRACTS_PROGRESS: "Evolução contratos",
  CONTRACTS_REPORT: "Relatório contratos",
  CONTROL_HOURS: "Controlo de tempo",
  CONVERSATION: "Conversa",
  COPIED: "Copiado",
  COPY: "Copiar",
  COPY_HYPERLINK: "Copiar hiperligação",
  COPYRIGHT: "Todos os direitos reservados.",
  COPYRIGHT_FOOTER:
    "© {date} Airdesk. Todos os direitos reservados. <a>Política de Privacidade</a>",
  CORRESPOND_CONTACT_INFORMATION: "Corresponda a informação",
  CORRESPOND_CONTACT_INFORMATION_DESCRIPTION:
    "Antes de continuar com esta ação confirme os contatos que quer agregar. Esta ação não pode ser desfeita e todos os registos irão permanecer associados ao contato final.",
  CORRESPONDENCE: "Corresponder",
  COSMETICS: "Cosméticos",
  COUNTRY: "País",
  COUNTRY_TAX: "IVA",
  COUPON_ALREDY_EXISTS: "Já existe um cupão com o mesmo nome",
  CREATE: "Criar",
  CREATE_ACCOUNT: "Criar Conta",
  CREATE_ADVANCED_FILTER: "Criar filtro avançado",
  CREATE_CALL: "Criar chamada",
  CREATE_CASE: "Criar caso",
  CREATE_COLOR_FILTER: "Criar cor",
  CREATE_CONTACT: "Criar contacto",
  CREATE_CONTRACT: "Criar contrato",
  CREATE_DEAL: "Criar negócio",
  CREATE_DEPARTMENTS: "Criar departamentos",
  CREATE_DEPARTMENTS_DESC:
    "Adicione os seus departamentos ao seu espaço de trabalho",
  CREATE_DEPARTMENTS_DESC_INFO_DESC:
    "Adicione os departamentos da sua empresa ao Airdesk (por exemplo, Departamento Comercial, Departamento Financeiro, Departamento de Design",
  CREATE_EMAIL: "Criar email",
  CREATE_INTERNAL_NOTE: "Adicionar nota interna",
  CREATE_NEW_WIDGET: "Criar novo Widget",
  CREATE_NEW_WORKSPACE: "Criar novo espaço de trabalho",
  CREATE_PROJECT: "Criar projeto",
  CREATE_RECURRENCE: "Criar recorrência",
  CREATE_SUBSCRIPTION: "Criar subscrição",
  CREATE_TASK: "Criar tarefa",
  CREATE_TEAMS: "Criar equipas",
  CREATE_TEAMS_DESC:
    "Adicione as suas equipas ao seu espaço de trabalho e associe a um departamento",
  CREATE_TIME_RECORD: "Criar registo de tempo",
  CREATE_TIME_REPORT: "Enviar relatório de tempos",
  CREATE_USER: "Criar utilizador",
  CREATE_WORKFLOW: "Criar workflow",
  CREATE_WORKSPACE: "Criar espaço de trabalho",
  CREATED_ACCOUNT: "Criou a conta",
  CREATED_BY: "Criado por",
  CREATED_CALL: "Criou a chamada",
  CREATED_CASE: "Criou o caso",
  CREATED_CLASSIFICATION: "Criou a classificação",
  CREATED_CONTACT: "Criou o contato",
  CREATED_CONTRACT: "Criou o contrato",
  CREATED_CONVERSATION: "Criou a conversa",
  CREATED_DEAL: "Criou o negócio",
  CREATED_NOTE: "Criou a nota",
  CREATED_PROJECT: "Criou o projeto",
  CREATED_SUBSCRIPTION: "Criou a subscrição",
  CREATED_TASK: "Criou a tarefa",
  CREATED_THE_NOTE: "criou a nota",
  CREATED_TIME: "Criou o tempo",
  CREATED_VIEWS: "Vistas criadas",
  CREATION: "Criação",
  CREATION_DATE: "Data de criação",
  CREDIT_CARD: "Cartão de crédito",
  CRON: "Cron",
  CUPON: "Cupão",
  CURRENCY: "Moeda(s)",
  CURRENT_BILLING_PLAN: "O seu plano atual é",
  CURRENT_CHOSEN_PLAN: "O seu plano atual é",
  CURRENT_ITEM_WAS_DELETED: "O item que estava a consultar foi eliminado.",
  CURRENT_NEW_PASSWORD_SAME: "Nova password igual à password atual",
  CURRENT_PASSWORD_INVALID: "Password atual incorreta",
  CURRENT_PLAN: "Plano corrente",
  CUSTOM: "Customizado",
  CUSTOM_DASHBOARDS: "Custom dashboards",
  CUSTOM_DOMAIN: "Domínio customizado",
  CUSTOM_NODE_COLOR: "Cor customizada",
  CYCLE: "Lote",
  DAILY: "Diariamente",
  DAIRY: "Laticínios",
  DANGER_ZONE: "Zona de perigo",
  DASHBOARD: "Dashboard",
  DASHBOARDS: "Dashboards padrão",
  DATA_TO_EXPORT: "Dados para exportar",
  DATE: "Data",
  DATE_CREATION: "Data de criação",
  DATE_LAST_CHANGE: "Data última alteração",
  DAY: "Dia",
  DAY_AGO: "Há dia",
  DAY_OF_MONTH: "Dia do mês",
  DAYS: "Dias",
  DAYS_AGO: "Há dias",
  DEADLINE: "Prazo de entrega",
  DEAL: "Negócio",
  DEAL_ASSIGNED: "atribuiu-lhe o negócio",
  DEAL_ASSIGNED_TO: "Negócio atribuído a",
  DEAL_CREATION: "Criação de negócio",
  DEAL_DELETED: "apagou o negócio",
  DEAL_IS: "Negócio é",
  DEAL_NR: "Negócio nº",
  DEAL_OVERIMPOSED_FOUND: "Existe sobreposição de datas de negócios.",
  DEAL_PROGRESS: "Evolução dos negócios",
  DEAL_STATUS: "Estado de negócio",
  DEAL_UPDATE: "atualizou o negócio",
  DEAL_UPDATED: "atualizou o negócio",
  DEALS: "Negócios",
  DEALS_ANALYSIS: "Análise dos negócios",
  DEALS_CHANGED: "Negócios alterados",
  DEALS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar negócios",
  DEALS_DELETE_DELAY_TITLE: "Eliminação de negócios",
  DEALS_DELETE_DESCRIPTION: "A eliminar negócios",
  DEALS_DELETE_TITLE: "Eliminação de negócios",
  DEALS_DESCRIPTION:
    "Acompanhe as oportunidades criadas pela sua equipa de vendas, fecho de negócios e muito mais.",
  DEALS_DEVELOPMENT: "Evolução dos negócios",
  DEALS_FAILED_CHANGED: "falhou ao alterar",
  DEALS_HISTORY: "Atividades de negócios",
  DEALS_NOT_CHANGED: "negócios inalterados",
  DEALS_PERFORMANCE: "Performance dos negócios",
  DEALS_REPORT: "Relatório negócios",
  DEALS_SALES_AMOUNTS: "Negócios vendas montantes",
  DEALS_STATUS: "Estados dos negócios",
  DECEMBER: "Dezembro",
  DEFAULT_LANGUAGE: "Idioma por defeito",
  DEFAULT_VIEW: "Vista geral",
  DEFENSE_OR_SPACE: "Defesa / Espaço",
  DEFINE_ADMIN_ACCESS: "Definir os acessos administrador.",
  DEFINE_CONTACT_ACCESS:
    "Todos os utilizadores têm acesso básico. As permissões abaixo são aplicadas a dados sensíveis em alguns campos dos detalhes.",
  DEFINE_LEGAL_BASIS:
    "Definir a base legal para o processamento dos dados de um contato",
  DEFINE_MODULES_ACCESS: "Definir o acesso aos módulos da conta.",
  DEFINE_SETTINGS_ACCESS: "Definir os acessos de configuração.",
  DEFINITIONS: "Definições",
  DELAY: "Atraso",
  DELETE: "Eliminar",
  DELETE_ALL: "Eliminar todos",
  DELETE_ALL_MODAL_CONFIRMATION:
    "Insira a palavra “ELIMINAR” para confirmar a ação.",
  DELETE_ALL_MODAL_CONFIRMATION_BUTTON: "Sim, eliminar",
  DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_ALL_VALID:
    "Foram selecionadas {total} faturações.",
  DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_SOME_VALID:
    "Foram selecionadas {total} faturações, apenas {valid} podem ser eliminados devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_CALLS_ALL_VALID:
    "Foram selecionadas {total} chamadas.",
  DELETE_ALL_MODAL_SUB_TITLE_CALLS_SOME_VALID:
    "Foram selecionadas {total} chamadas, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_ALL_VALID:
    "Foram selecionadas {total} contas.",
  DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_SOME_VALID:
    "Foram selecionadas {total} contas, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_ALL_VALID:
    "Foram selecionados {total} contactos.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_SOME_VALID:
    "Foram selecionados {total} contactos, apenas {valid} podem ser eliminados devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_ALL_VALID:
    "Foram selecionados {total} contratos.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_SOME_VALID:
    "Foram selecionados {total} contratos, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_DEALS_ALL_VALID:
    "Foram selecionados {total} negócios.",
  DELETE_ALL_MODAL_SUB_TITLE_DEALS_SOME_VALID:
    "Foram selecionados {total} negócios, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_ALL_VALID:
    "Foram selecionadas {total} assinaturas de email.",
  DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_SOME_VALID:
    "Foram selecionadas {total} assinaturas de email, apenas {valid} podem ser eliminados devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_ALL_VALID:
    "Foram selecionados {total} tempos.",
  DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_SOME_VALID:
    "Foram selecionados {total} tempos, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_NONE_VALID:
    "Não tem permissões para eliminar nenhum dos itens selecionados",
  DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_ALL_VALID:
    "Foram selecionados {total} projetos.",
  DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_SOME_VALID:
    "Foram selecionados {total} projetos, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_ALL_VALID:
    "Foram selecionadas {total} subscrições.",
  DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_SOME_VALID:
    "Foram selecionadas {total} subscrições, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_TASKS_ALL_VALID:
    "Foram selecionadas {total} tarefas.",
  DELETE_ALL_MODAL_SUB_TITLE_TASKS_SOME_VALID:
    "Foram selecionadas {total} tarefas, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_SUB_TITLE_TICKETS_ALL_VALID:
    "Foram selecionados {total} casos.",
  DELETE_ALL_MODAL_SUB_TITLE_TICKETS_SOME_VALID:
    "Foram selecionados {total} casos, apenas {valid} podem ser eliminadas devido a permissões.",
  DELETE_ALL_MODAL_TITLE_BILLINGS:
    "Tem a certeza que pretende eliminar as faturações abaixo?",
  DELETE_ALL_MODAL_TITLE_CALLS:
    "Tem a certeza que pretende eliminar as chamadas abaixo?",
  DELETE_ALL_MODAL_TITLE_CLIENTS:
    "Tem a certeza que pretende eliminar as contas abaixo?",
  DELETE_ALL_MODAL_TITLE_CONTACTS:
    "Tem a certeza que pretende eliminar os contactos abaixo?",
  DELETE_ALL_MODAL_TITLE_CONTRACTS:
    "Tem a certeza que pretende eliminar os contratos abaixo?",
  DELETE_ALL_MODAL_TITLE_DEALS:
    "Tem a certeza que pretende eliminar os negócios abaixo?",
  DELETE_ALL_MODAL_TITLE_EMAIL_SIGNATURES:
    "Tem a certeza que pretende eliminar as assinaturas de email abaixo?",
  DELETE_ALL_MODAL_TITLE_INTERVENTIONS:
    "Tem a certeza que pretende eliminar os tempos abaixo?",
  DELETE_ALL_MODAL_TITLE_PROJECTS:
    "Tem a certeza que pretende eliminar os projetos abaixo?",
  DELETE_ALL_MODAL_TITLE_SUBSCRIPTIONS:
    "Tem a certeza que pretende eliminar as subscrições abaixo?",
  DELETE_ALL_MODAL_TITLE_TASKS:
    "Tem a certeza que pretende eliminar as tarefas abaixo?",
  DELETE_ALL_MODAL_TITLE_TICKETS:
    "Tem a certeza que pretende eliminar os casos abaixo?",
  DELETE_API_KEY_QUESTION: "Quer eliminar esta chave?",
  DELETE_COLUMN: "Apagar coluna",
  DELETE_COMPANY_MSG: "Deseja eliminar esta empresa?",
  DELETE_COUPON: "Eliminar cupão",
  DELETE_DOMAIN_QUESTION: "Deseja eliminar este domínio?",
  DELETE_FILTER: "Eliminar filtro",
  DELETE_FILTER_WARNING: "Deseja eliminar este filtro?",
  DELETE_INVITE_CONFIRMATION: "Deseja eliminar este convite?",
  DELETE_MESSAGE: "Tem a certeza que pretende eliminar a mensagem?",
  DELETE_ROW: "Apagar linha",
  DELETE_SELECTED: "Eliminar selecionados",
  DELETE_SOCIAL_ACCOUNT: "Apagar conexão",
  DELETE_SOCIAL_ACCOUNT_QUESTION: "Pretende apagar esta conexão?",
  DELETE_SOCIAL_ACCOUNT_WARNING:
    "Ao apagar a conexão todos os dados serão apagados definitivamente",
  DELETE_STOPWATCH_CONFIRMATION: "Deseja eliminar este temporizador?",
  DELETE_SUBSCRIPTION_TYPE: "Deseja eliminar este tipo de subscrição?",
  DELETE_TEAM_QUESTION: "Deseja eliminar esta equipa?",
  DELETE_USER: "Remover utilizador",
  DELETE_USER_CONFIRMATION: "Deseja eliminar este colaborador?",
  DELETE_VIEW: "Eliminar vista",
  DELETE_VIEW_CONFIRMATION: "Deseja eliminar esta vista?",
  DELETE_WIDGET_CONFIRMATION: "Deseja eliminar este widget?",
  DELETE_WORKSPACE: "Eliminar espaço de trabalho",
  DELETE_WORKSPACE_DESC:
    "Após a eliminação deste espaço de trabalho não poderá voltar atrás, tenha a certeza desta ação.",
  DELETE_WORKSPACE_TITLE: "Eliminar espaço de trabalho",
  DELETED: "Eliminado",
  DELETED_ITEM: "Item eliminado",
  DELIVERY_TIME: "Prazo de entrega",
  DEPARTMENT: "Departamento",
  DEPARTMENT_ACTIVITY: "Atividades de departamento",
  DEPARTMENT_CASE_AVERAGE: "Departamento - tempo médio de fecho de casos",
  DEPARTMENT_EMAIL: "Email do departamento",
  DEPARTMENT_IS: "Departamento é",
  DEPARTMENT_LOAD: "Departamento - carga de trabalho",
  DEPARTMENT_NAME: "Nome do departamento",
  DEPARTMENT_OR_TEAM: "Departamento ou equipa",
  DEPARTMENT_PHONE: "Número de telefone do departamento",
  DEPARTMENT_SAVE_SUCCESS: "Departamento gravada com sucesso",
  DEPARTMENT_TASKS_AVERAGE: "Departamento - tempo médio de fecho de tarefas",
  DEPARTMENT_TEAM: "Departamento / Equipa",
  DEPARTMENT_TIME_RECORDS: "Registos de tempo departamento",
  DEPARTMENTS: "Departamentos",
  DESCRIPTION: "Descrição",
  DESCRIPTION_LENGTH:
    "A descrição deve ter no mínimo 30 caracteres (apenas {charLength} introduzidos)",
  DESCRIPTION_PERSON_RESPONSIBLE: "Descrição interna",
  DESCRIPTION_PERSON_RESPONSIBLE_ABRV: "Descrição interna",
  DESCRIPTIVE_DEALS: "Performance de negócios descritivo",
  DESIGN: "Design",
  DESIGNATION: "Designação",
  DESKTOP: "Desktop",
  DETAILED_KNOWLEDGE_OF_EACH_PLAN:
    "Saiba detalhadamente todas as funcionalidades de cada plano",
  DETAILS: "Detalhes",
  DIFFERENT_FROM: "É diferente de",
  DIRECT: "Diretas",
  DIRECTION_DEPARTMENT: "Direção",
  DISCOUNT: "Desconto",
  DISCOUNT_CODE_VALID_FOREVER: "Código promocional valido para sempre",
  DISCOUNT_CODE_VALID_ONLY_ONCE: "Código promocional valido para apenas um uso",
  DISCOUNT_CODE_VALID_UNTIL: "Código promocional valido até",
  DISCOUNT_OFF: "Desconto de",
  DISPLAY_COLUMNS: "Escolha as colunas a exibir",
  DISTRICT: "Distrito",
  DKIM_RECORD: "Registo DKIM",
  DO_NOT_IMPORT: "Não importar",
  DO_NOTHING: "Não fazer nada",
  DOC_TEMPLATES: "Modelos",
  DOCUMENTATION: "Documentação",
  DOCUMENTS: "Documentos",
  DOES_NOT_CONTAIN: "Não contém",
  DOMAIN: "Domínio",
  DOMAIN_ERROR_FORMAT: "O domínio inserido não tem um formato válido.",
  DOMAIN_KEY_DKIM_DESCRIPTION:
    "Precisa adicionar um novo registo <b>TXT</b> com o nome <clip>{Key}</clip> com o seguinte conteúdo.",
  DOMAIN_KEY_SPF_DESCRIPTION:
    "Precisa adicionar um registo <b>TXT</b> no apex/root de seu domínio <b>({subDomain})</b> com o seguinte conteúdo. Se já enviou correio de outro serviço, pode apenas necessitar de adicionar <clip>{Key}</clip> ao seu registo existente.",
  DOMAIN_MX_DESCRIPTION:
    "Para receber e-mails para este domínio, adicione os registos <b>MX</b> no apex/root de seu domínio <clip>{subDomain}</clip> {brk} Nós informamos se estão configurados. Ambos os registos devem ter prioridade <b>10.</b>",
  DOMAIN_NAME: "Nome do domínio",
  DOMAIN_NAME_ERROR_FORMAT:
    "O nome curto só pode conter letras, números e hyphen (-)",
  DOMAIN_NAME_ERROR_LENGTH: "O nome curto tem que ter no máximo 30 caracteres.",
  DOMAIN_RETURN_PATH_DESCRIPTION:
    "Deve adicionar um registo <b>CNAME</b> em <clip>{Key}</clip> para apontar para o nome de host abaixo.",
  DOMAINS: "Domínios e endereços",
  DOMAINS_URL: "Domínios",
  DOWNGRADE_PLAN: "Downgrade",
  DOWNLOAD: "Transferir",
  DRAFT: "Rascunho",
  DRAG_OR_CLICK_FILE: "Arraste o ficheiro ou clique para procurar",
  DROP_THE_FILE: "Largue o ficheiro para fazer o upload",
  DUPLICATE: "Duplicar",
  DUPLICATE_ALL: "Duplicar todos",
  DUPLICATE_MESSAGE_FOR_CLIENTS:
    "Já existem {count} clientes com este {type}. <a>Clique aqui para ver</a>",
  DUPLICATES: "Duplicados",
  DURATION: "Duração",
  E_LEARNING: "E-learning",
  EDIT: "Editar",
  EDIT_ACCOUNT: "Editar conta",
  EDIT_API_KEY: "Editar chave de API {id}",
  EDIT_CALLS: "Editar chamadas",
  EDIT_CANCELATION_REASON: "Editar razão de cancelamento",
  EDIT_CARDS: "Editar cartões",
  EDIT_CHECKLIST_TEMPLATE: "Editar template de checklist",
  EDIT_CLIENTS: "Editar contas",
  EDIT_COLOR_FILTER: "Editar cor",
  EDIT_COLUMNS: "Editar colunas",
  EDIT_COMPANY: "Editar empresa",
  EDIT_CONTACTS: "Editar contatos",
  EDIT_CONTRACTS: "Editar contratos",
  EDIT_DEAL: "Editou o negócio",
  EDIT_DEALS: "Editar negócios",
  EDIT_EMAIL_DOMAIN: "Editar domínio de email",
  EDIT_EMAIL_SIGNATURE: "Editar assinatura de email",
  EDIT_FILTER: "Editar filtro",
  EDIT_KANBAN: "Editar kanban",
  EDIT_LOSS_REASON: "Editar rasão de perda",
  EDIT_MODULES: "Editar módulos",
  EDIT_ORDER: "Editar ordem",
  EDIT_PROJECTS: "Editar projetos",
  EDIT_RECEPTION: "Editar receção",
  EDIT_STATUS: "Editar estados",
  EDIT_SUBSCRIPTIONS: "Editar subscrição",
  EDIT_TASKS: "Editar tarefas",
  EDIT_TICKETS: "Editar casos",
  EDIT_WIDGET: "Editar widget",
  EDIT_WORKFLOW: "Editar workflow",
  EDITED: "Editado",
  EDITED_ACCOUNT: "Editou a conta",
  EDITED_CALL: "Editou a chamada",
  EDITED_CASE: "Editou o caso",
  EDITED_CLASSIFICATION: "Editou a classificação",
  EDITED_CONTACT: "Editou o contato",
  EDITED_CONTRACT: "Editou o contrato",
  EDITED_CONVERSATION: "Editou a conversa",
  EDITED_DEAL: "Editou o negócio",
  EDITED_PROJECT: "Editou o projeto",
  EDITED_SUBSCRIPTION: "Editou a subscrição",
  EDITED_TASK: "Editou a tarefa",
  EDITED_TIME: "Editou o tempo",
  EDITION: "Edição",
  EDITOR: "Editor",
  EDUCATION_MANAGEMENT: "Gerenciamento de educação",
  ELECTRICAL_OR_ELECTRONIC_MANUFACTURING: "Manufatura elétrica / Eletrônica",
  ELIMINATE_CONTRACT_TYPE: "Deseja eliminar este tipo de contrato?",
  ELIMINATE_DEPARTMENT_CONFIRMATION: "Deseja eliminar este departamento?",
  ELIMINATE_INEFICIENT_TYPE:
    "Deseja eliminar esta razão de tempo não imputável?",
  ELIMINATE_INTERVENTION_TYPE: "Deseja eliminar este tipo de tempo?",
  ELIMINATE_PROJECT_CATEGORY: "Deseja eliminar esta categoria de projeto?",
  EMAIL: "Correio eletrónico",
  EMAIL_ATTACHMENTS: "Anexos de email",
  EMAIL_CORRECT: "Por favor, insira um email correto",
  EMAIL_CREATION: "Criação de email",
  EMAIL_DOMAIN: "Domínio de email",
  EMAIL_DOMAIN_ADD_TO_DNS:
    "Adicione os registos abaixo ao seu DNS para <b>{Domain}</b>",
  EMAIL_DOMAIN_VERIFY: "Verificar se registos estão corretos",
  EMAIL_DOMAIN_WILL_SEND_DESCRIPTION:
    "Os emails serão enviados a partir de: <b>{Domain}</b>",
  EMAIL_FEEDBACK_CONTENT:
    "Convidamos você a compartilhar seus comentários sobre sua solicitação (<b>{caseId} : {caseName}</b>)",
  EMAIL_FEEDBACK_START: "Estimado",
  EMAIL_FROM: "De",
  EMAIL_INFO_DESC: "O seu email profissional",
  EMAIL_PASSWORD_INSTRUCTIONS:
    "Enviaremos instruções por e-mail para redefinir sua password.",
  EMAIL_REPORT: "Relatório por email",
  EMAIL_SIGNATURE: "Assinatura email",
  EMAIL_SIGNATURE_DELETE_TITLE: "Eliminação de assinatura de email",
  EMAIL_SIGNATURES: "Assinaturas email",
  EMAIL_SIGNATURES_DELETE_DELAY_DESCRIPTION:
    "A preparar para eliminar assinaturas de email",
  EMAIL_SIGNATURES_DELETE_DELAY_TITLE: "Eliminação de assinatura de email",
  EMAIL_SIGNATURES_DELETE_DESCRIPTION: "Excluindo assinatura de email",
  EMAIL_SIGNATURES_DELETE_TITLE: "Eliminação de assinatura de email",
  EMAIL_TO: "Para",
  EMAILS: "Emails",
  EMPLOYE_NO_FILTER:
    "Não é possivel utilizar este módulo com o filtro selecionado",
  EMPLOYEE: "Colaborador não é responsável por um departamento",
  EMPLOYEE_NO_DEPARTMNET: "Colaborador não é responsável por um departamento",
  EMPLOYEES: "Colaboradores",
  EMPTY_ACCOUNTS: "Não há registos de contas ativas.",
  EMPTY_ACTIVITY_LIST: "Não existem registos de atividades associados.",
  EMPTY_API_KEYS: "Lista de chaves API vazia",
  EMPTY_AUTOMATION_LIST: "Não existem registos de automatismos associadas.",
  EMPTY_BILLING_LIST: "Listagem de faturação vazia.",
  EMPTY_CALL_LIST: "Não existem registos de chamadas associadas.",
  EMPTY_CALLS: "Não tem chamadas",
  EMPTY_CASE: "Listagem de casos vazia.",
  EMPTY_CASE_TECORDS: "Não existem registos de casos associados.",
  EMPTY_CASES: "Não tem casos",
  EMPTY_COMPANY: "Listagem de empresas vazia.",
  EMPTY_CONTACTS: "Não há registos de contatos associados.",
  EMPTY_CONTRACTS: "Não tem contratos",
  EMPTY_CONTRACTS_LIST: "Listagem de contratos vazia.",
  EMPTY_DEALS: "Não foram encontrados negócios.",
  EMPTY_DEALS_LIST: "Lista de negócios vazia",
  EMPTY_DEPARTMENT: "Listagem de departamentos vazia.",
  EMPTY_DOMAINS: "Listagem de domínios de email vazia.",
  EMPTY_EMAIL_RECEPTION: "Listagem de emails vazia.",
  EMPTY_EMAIL_SIGNATURES: "Listagem de assinaturas email vazia",
  EMPTY_ENTRY_RECEPTION: "Sem conversas abertas a mostrar",
  EMPTY_ENTRY_RECEPTION_SUBTEXT: "Não existem entradas de receções",
  EMPTY_EXPORT: "Listagem de exportações vazia.",
  EMPTY_FAVORITE_LIST: "Lista de favoritos encontra-se vazia",
  EMPTY_FILTERS: "Não foram encontrados filtros.",
  EMPTY_HISTORY: "Não existem registos de atividades.",
  EMPTY_IMPORTS: "Listagem de importações Vazia.",
  EMPTY_INBOX_RECEPTION: "Não existem conversas.",
  EMPTY_INBOX_RECEPTION_QUERY: "Não encontrámos resultados.",
  EMPTY_INBOX_RECEPTION_QUERY_SUBTEXT:
    "Experimente utilizar uma palavra-chave diferente.",
  EMPTY_INEFICIENT_TYPES:
    "Não existem registos de tipos de tempo não imputável.",
  EMPTY_INTERVENTIONS_LIST: "Listagem de tempos vazia.",
  EMPTY_LOGS: "Lista de logs vazia",
  EMPTY_LOST_DEALS: "Não tem negócios perdidos",
  EMPTY_LOST_REASON_LIST: "Listagem de motivos de perda vazia.",
  EMPTY_NOTIFICATIONS: "Não tem notificações",
  EMPTY_PIPELINES_LIST: "Listagem de pipelines vazia.",
  EMPTY_PROJECT_LIST: "Listagem de projetos vazia.",
  EMPTY_PROJECT_TYPES: "Listagem de tipos de projeto vazia.",
  EMPTY_PROJECTS: "Não tem projetos",
  EMPTY_RECORDS: "Não existem registos de atividades.",
  EMPTY_SALES: "Não tem vendas",
  EMPTY_SOCIAL_ACCOUNT: "Listagem de contas de redes sociais vazia.",
  EMPTY_SOCIAL_POST_LIST: "Listagem de publicações vazia.",
  EMPTY_SUBSCRIPTIONS: "Não tem subscrições associados.",
  EMPTY_SUBSCRIPTIONS_RECORDS:
    "Não existem registos de subscrições associados.",
  EMPTY_SUBSCRIPTIONS_TYPE_RECORDS:
    "Não existem registos de tipos de subscrições associados.",
  EMPTY_TASK_LIST: "Listagem de tarefas vazia.",
  EMPTY_TASKS: "Não foram encontradas tarefas.",
  EMPTY_TEAMS: "Listagem de equipas vazia.",
  EMPTY_TICKETS_LIST: "Listagem de casos vazia.",
  EMPTY_TIME: "Não tem registos de tempo associados.",
  EMPTY_TIMER: "Não tem nenhum temporizador",
  EMPTY_USERS: "Listagem de utilizadores vazia.",
  EMPTY_WIDGETS: "Listagem de widgets vazia.",
  ENABLE: "Ativar",
  ENABLE_CUSTOM_NODE_COLOR: "Ativar cor personalizada",
  ENABLE_MULTIPLE: "Habilitar seleção múltipla",
  ENABLE_RAW_BODY: "Enable raw body",
  ENABLE_RAW_HEADERS: "Enable raw headers",
  ENABLE_RAW_QUERY_STRINGS: "Enable raw query strings",
  ENABLE_SCHEDULE: "Ativar agendamento",
  END: "Fim",
  END_ALL_SESSIONS: "Terminar todas as sessões",
  END_DATE: "Data final",
  END_SESSION: "Terminar sessão",
  ENDS_WITH: "Acaba com",
  ENROLL_CONFIRMATION: "Tem certeza de que deseja fazer esta ação?",
  ENROLL_DESCRIPTION_CALL:
    "Um total de {count} chamadas serão inscritas neste workflow.",
  ENROLL_DESCRIPTION_CLIENT:
    "Um total de {count} clientes serão inscritos neste workflow.",
  ENROLL_DESCRIPTION_CONTACT:
    "Um total de {count} contatos serão inscritos neste workflow.",
  ENROLL_DESCRIPTION_CONTRACT:
    "Um total de {count} contratos será inscrito neste workflow.",
  ENROLL_DESCRIPTION_DEAL:
    "Um total de {count} negócios será inscrito neste workflow.",
  ENROLL_DESCRIPTION_PROJECT:
    "Um total de {count} projetos serão inscritos neste workflow.",
  ENROLL_DESCRIPTION_SUBSCRIPTION:
    "Um total de {count} assinaturas serão inscritas neste workflow.",
  ENROLL_DESCRIPTION_TASK:
    "Um total de {count} tarefas será inscrito neste workflow.",
  ENROLL_DESCRIPTION_TICKET:
    "Um total de {count} tickets serão inscritos neste workflow.",
  ENROLL_DESCRIPTION_TIME:
    "Um total de {count} tempos será inscrito neste workflow.",
  ENTER: "Entrar",
  ENTER_DEPARTMENT_EMAIL_PLACEHOLDER:
    "Insira o email do departamento (opcional)",
  ENTER_VALID_DISCOUNT_CODE: "Insira um código de desconto válido",
  ENTERTAINMENT_OR_MOVIE_PRODUCTION: "Entretenimento / Produção de filmes",
  ENTITY_ICONS: "Ícones",
  ENTRY: "Entrada",
  ENVIRONMENTAL_SERVICES: "Serviços ambientais",
  EQUAL: "Igual",
  EQUALS: "É igual a",
  ERROR: "Erro",
  ERROR_FIELDS: "Erro - Existem campos obrigatórios por preencher",
  ERROR_HAPPEND: "Ocorreu um erro",
  ERROR_LOADING_EMAIL_PREVIEW:
    "Não é possível pré-visualizar anexos deste email. Por favor, transfira o email para visualizar os anexos",
  ERROR_OCCURRED: "Ocorreu um erro",
  ERROR_OCCURRED_CONTACT_ADMIN:
    "Se o erro persistir, contacte a administração.",
  ERROR_OCCURRED_SORRY:
    "Pedimos desculpa, mas ocorreu um erro. Por favor, tente novamente mais tarde.",
  ERROR_OCCURRED_TRY_ADVANCED: "Verifique também os filtros avançados.",
  ERROR_OCURRED: "Ocorreu um erro",
  ERRORS_FOUND: "Erros encontrados",
  ESTIMATED: "Estimado",
  ESTIMATED_HOUR: "Tempo estimado",
  ESTIMATED_HOURS: "Tempo estimado",
  ESTIMATED_HOURS_ABRV: "Tempo estimado",
  ESTIMATED_HOURS_TASKS: "Tempo estimado tarefas",
  ESTIMATED_TIME: "Tempo estimado",
  EVALUATION: "Avaliação",
  EVENTS_SERVICES: "Serviços de eventos",
  EVERY_DAY: "Todos os dias",
  EVERY_HOUR: "A cada hora",
  EVERY_MINUTE: "A cada minuto",
  EVERY_MONTH: "Todos os meses",
  EVERY_WEEK: "Todas as semanas",
  EVERY_X: "A cada x",
  EVIDENCE: "Comprovativos",
  EVOLUTION_ACCOUNTS: "Evolução casos",
  EVOLUTION_AVERAGE: "Evolução do tempo médio de fecho de casos",
  EVOLUTION_DEALS: "Evolução de negócios",
  EVOLUTION_DEALS_GLOBAL: "Evolução global de negócios",
  EXAMPLES: "Exemplos",
  EXCEEDED_HOURS: "Tempo excedido",
  EXCLUDED_USER: "Excluir utilizadores",
  EXCLUSIVITY: "Exclusividade",
  EXECUTION_CONTRACT: "Execução de um contrato",
  EXECUTIVE_OFFICE: "Escritório executivo",
  EXISTING_EMAIL_CONVERSATIONS:
    "Existem conversas de email abertas associadas a este item.{br} Clique <a>aqui</a> para ver",
  EXIT: "Sair",
  EXIT_ALL_SESSIONS_DESC: "Onde tens sessão iniciada.",
  EXIT_ALL_SESSIONS_TITLE: "Início de sessão",
  EXPAND_ALL: "Expandir todos",
  EXPIRATION_DATE: "Data de expiração",
  EXPIRE_DATE: "Prazo de validade",
  EXPIRED: "Expirado",
  EXPIRED_CONTRACTS: "Contratos expirados",
  EXPORT: "Exportar",
  EXPORT_DATE: "Data a exportar",
  EXPORT_EXCEL: "Exportar tudo para Excel",
  EXPORT_FAILLED: "Falhado",
  EXPORT_NOT_CLASSIFIED: "Não classificado",
  EXPORT_PAGE: "Exportar página para Excel",
  EXPORT_PDF: "Exportar PDF",
  EXPORT_QUANTITY_TYPE: "Exportação",
  EXPORT_QUANTITY_TYPE_COMPLETE: "Completa",
  EXPORT_QUANTITY_TYPE_PARCIAL: "Parcial",
  EXPORT_SUCCESS: "Sucesso",
  EXPORT_TO_EXCEL: "Exportar para Excel",
  EXPORT_TYPE: "Tipo de exportação",
  EXPORT_WORKFLOW: "Exportar workflow",
  EXPORTATIONS: "Exportações",
  EXPORTS: "Exportações",
  EXPORTS_EXCEL: "Exportar excel",
  EXTENSION: "Extensão",
  EXTRA_GIGA_COST: "(Preço por GB(s) extra € {amount}/mês/GB)",
  EXTRA_USER_COST: "(Utilizador extra € {amount}/mês)",
  FACEBOOK_URL: "Facebook url",
  FACILITIES_SERVICES: "Serviços de instalações",
  FARMING: "Agricultura",
  FAVORITES: "Favoritos",
  FEBRUARY: "Fevereiro",
  FEEDBACK: "Feedback",
  FEEDBACK_ALREDY_SUBMITED: "Este feedback já tinha sido submetido.",
  FEEDBACK_FORM: "Formulário de feedback",
  FEEDBACK_SUBMITED_SUCCESS: "Feedback submetido com sucesso ",
  FEEDBACK_TITLE: "Título do feedback",
  FEMALE: "Mulher",
  FIELD: "Âmbito",
  FIELD_2: "Campo",
  FIELD_IS_REQUIRED: "Este campo é obrigatório",
  FIELD_IS_REQUIRED_DESCRIPTION: "Por favor, preencha os dados",
  FILE: "Ficheiro",
  FILE_UPLOAD: "Upload do ficheiro",
  FILES: "Ficheiros",
  FILES_ONLY_TYPE: "Só é possível enviar ficheiros do tipo",
  FILES_TOO_BIG: "Só é possível enviar ficheiros com o tamanho máximo de 40MB!",
  FILTER: "Filtro",
  FILTER_ADDFILTER: "Adicionar filtro",
  FILTER_AND: "e",
  FILTER_BACK: "Anterior",
  FILTER_BY: "Filtrar por:",
  FILTER_CONTAINS: "contém",
  FILTER_DATE_GT: "é posterior a",
  FILTER_DATE_LAST: "últimos x dias",
  FILTER_DATE_LT: "é anterior a",
  FILTER_DATE_NEXT: "próximos x dias",
  FILTER_DOESNOTCONTAIN: "não contém",
  FILTER_ENDSWITH: "acaba com",
  FILTER_EQ: "é igual a",
  FILTER_ERROR_OCCURRED_SORRY:
    "Pedimos desculpa, mas existe um ou vários filtros com um formato inválido. Por favor, remova os filtros e tente novamente.",
  FILTER_FILTER: "Filtro",
  FILTER_GROUP: "Agrupar",
  FILTER_GROUPBY: "Agrupar por:",
  FILTER_GT: "é maior que",
  FILTER_GTE: "é maior ou igual a",
  FILTER_ISEMPTY: "está vazio",
  FILTER_ISNOEMPTY: "não está vazio",
  FILTER_LAST_X_DAYS: "últimos {days} dias",
  FILTER_LAST_X_DAYS_PREVIEW: "Últimos {days} dias de {field}",
  FILTER_LT: "é menor que",
  FILTER_LTE: "é menor ou igual a",
  FILTER_NAME: "Nome do filtro",
  FILTER_NAME_NOT_VALID: "Nome não é válido/já existe",
  FILTER_NEQ: "é diferente de",
  FILTER_NEXT_X_DAYS: "próximos {days} dias",
  FILTER_NEXT_X_DAYS_PREVIEW: "próximos {days} dias de {field}",
  FILTER_STARTSWITH: "começa com",
  FILTERS: "Filtros",
  FINAL_DATA: "Dados finais",
  FINANCIAL_DEPARTMENT: "Financeiro",
  FINANCIAL_SERVICES: "Serviços financeiros",
  FINE_ART: "Belas artes",
  FIRST: "Primeiro",
  FISCAL_COUNTRY: "País fiscal",
  FISHERY: "Pesca",
  FOLLOW: "Seguir",
  FOLLOW_UP: "Acompanhamento",
  FOLLOWERS: "Seguidores",
  FONT_FAMILY: "Tipo de letra",
  FONT_SIZE: "Tamanho de letra",
  FOOD_OR_BEVERAGES: "Alimentos / bebidas",
  FOOD_PRODUCTION: "Produção de alimentos",
  FOOTER: "Rodapé",
  FOR_AN_EXTRA_USER_PLAN:
    "Para um plano com mais de 100 utilizadores por favor entre em contacto",
  FOR_AN_EXTRA_USER_PLAN_DESCRIPTION:
    "Não hesite em perguntar, estamos inteiramente à sua disposição",
  FOR_HIMSELF: "a si mesmo",
  FOR_USER: "ao utilizador",
  FOREVER: "Sem limite",
  FORGET_PASSWORD: "Esqueci-me da password",
  FORM: "Formulário",
  FORM_END_DATA_BIGGER_THAN_START_DATA:
    "Data final não pode ser menor que data inicial",
  FORM_OR_BLOCK: "É necessário existir pelo menos um bloco ou formulário",
  FORM_TITLE: "Título do formulário",
  FREE_TRIAL: "Avaliação gratuita",
  FREE_TRIAL_DAYS_LEFT: "dias de avaliação gratuita restantes",
  FREE_TRIAL_ENDED: "A sua avaliação gratuita terminou.",
  FREEZED_COLUMNS: "Colunas freeze",
  FRIDAY: "Sexta-feira",
  FROM: "Das",
  FROM_PLAN: "De plano",
  FULL_ACCESS: "Acesso total",
  FULL_NAME: "Nome completo",
  FULLSCREEN: "Ecrã completo",
  FUNCTIONALITIES: "Propriedades",
  FUNDRAISING: "Angariação de fundos",
  FURNITURE: "Mobília",
  GAMBLING_OR_CASINOS: "Jogos de azar / cassinos",
  GDPR: "Alterar GDPR",
  GDPR_LEGALBASIS: "Base legal para o processamento dos dados de um contato",
  GDPR_OPTION1: "Interesse legítimo - possível / potencial",
  GDPR_OPTION2: "Interesse legítimo - cliente existente",
  GDPR_OPTION3: "Interesse legítimo - outro",
  GDPR_OPTION4: "Execução de um contrato",
  GDPR_OPTION5: "Consentimento dado pelo contacto",
  GDPR_OPTION6: "Não aplicável",
  GENDER: "Género",
  GENERAL_INFORMATION: "Informação geral",
  GENERATE_CODE: "Gerar snippet",
  GENERATED_API_KEY: "Chave de API gerada",
  GEOLOCATION_BROWSER_ERROR:
    "Geolocalização não se encontra disponível neste browser",
  GEOLOCATION_REFUSED: "Utilizador recusou pedido para geolocalização",
  GEOLOCATION_SUCCESS: "Pedido de Geolocalização efetuado com sucesso",
  GEOLOCATION_TIMEOUT:
    "O pedido para obter a localização do utilizador esgotou o tempo",
  GEOLOCATION_UNAVAILABLE: "Serviço de Localização encontra-se indisponível",
  GERAL: "Geral",
  GET_CALL: "Obter chamada",
  GET_CLIENT: "Obter conta",
  GET_CONTACT: "Obter contato",
  GET_CONTRACT: "Obter contrato",
  GET_DEAL: "Obter negócio",
  GET_ITEMS: "Obter itens",
  GET_PROJECT: "Obter projeto",
  GET_SUBSCRIPTION: "Obter subscrição",
  GET_TASK: "Obter tarefa",
  GET_TICKET: "Obter caso",
  GLASS_OR_CERAMICS_OR_CONCRETE: "Vidro / Cerâmica / Concreto",
  GLOBAL_SEARCH_DEF_TO_CLOSE: "para fechar",
  GLOBAL_SEARCH_DEF_TO_NAVIGATE: "para navegar",
  GLOBAL_SEARCH_DEF_TO_SEARCH: "para selecionar",
  GLOBE_PICTURE_TOOLTIP: "Website",
  GOOD: "Bom",
  GOVERNMENT_ADMINISTRATION: "Administração governamental",
  GOVERNMENT_RELATIONS: "Relações governamentais",
  GRAPHIC_DESIGN_OR_WEB_DESIGN: "Design gráfico / Web design",
  GREATER: "Maior",
  GREATER_EQUAL: "Maior ou igual",
  GROUP: "Grupo",
  GROUP_BY: "Agrupar por",
  HEADER: "Cabeçalho",
  HEADER_WELCOME_BAR_IGNORE: "Ignorar para sempre",
  HEADER_WELCOME_BAR_TEXT: "Saiba mais sobre como começar a usar o",
  HEADERS: "Cabeçalhos",
  HEADING_1: "H1",
  HEADING_2: "H2",
  HEALTH_OR_FITNESS: "Saúde",
  HELLO: "Olá",
  HELP: "Ajuda",
  HELP_MESSAGES: "Tooltips de ajuda",
  HIDE_NOTES: "Esconder notas",
  HIGHER_EDUCATION_OR_ACADAMIA: "Ensino Superior / Academia",
  HIGHLIGHT: "Destaque",
  HISTORY: "Atividade",
  HISTORY_DEALS: "Atividade de negócios",
  HISTORY_ERROR: "Houve um erro ao carregar as atividades",
  HISTORY_PIPELINE_STAGES: "Histórico de estados da pipeline",
  HISTORY_STARTS: "A sua atividade começa aqui",
  HOLIDAY: "Feriado",
  HORIZONTAL: "Horizontal",
  HOSPITAL_OR_HEALTH_CARE: "Hospital / Saúde",
  HOSPITALITY: "Hospitalidade",
  HOUR: "Hora",
  HOURS_MULTIPLE: "hora(s)",
  HOVER_ME_TO_SHOW: "Coloque aqui o cursor para mostrar",
  HOW_TO_RECEIVE_NOTIFICATIONS: "Como receber notificações",
  HOW_WAS_THE_EXPERIENCE: "Como avaliaria a sua experiência?",
  HTTP_REQUEST: "Pedido Http",
  HUMAN_RESOURCES_DEPARTMENT: "Recursos humanos",
  HUMAN_RESOURCES_OR_HR: "Recursos humanos / RH",
  ICON: "Icon",
  ID: "ID",
  IGNORE_DEPARTMENTS_DESC:
    "Ignorar passo (poderá mais tarde criar e adicionar departamentos)",
  IGNORE_ORGANIZATION_DESC:
    "Ignorar passo (poderá mais tarde criar e adicionar empresas)",
  IGNORE_TEAMS_DESC:
    "Ignorar passo (poderá mais tarde criar e adicionar equipas)",
  IMAGE: "Imagem",
  IMPORT: "Importar",
  IMPORT_ASSOCIATION_DESCRIPTION:
    "Se pretender associar os Contactos aos Clientes, é necessário identificar as colunas que os ligam.",
  IMPORT_ASSOCIATION_TITLE: "Associação entre {importType1} e {importType2}",
  IMPORT_CHECKBOX_PROPERTIES:
    "As colunas sem propriedades não serão importadas",
  IMPORT_CHOOSE_IMPORT:
    "Para prosseguir, é necessário selecionar o que pretende importar.",
  IMPORT_DO_NOT_OVERWRITE: "Não sobrepor aos dados já existentes",
  IMPORT_DUPLICATES_DESCRIPTION:
    "Quando selecionado, o Airdesk permitirá que existam vários registos com o mesmo valor, caso contrário, a linha não será importada. Esta opção não terá efeito na sobreposição de dados.",
  IMPORT_EXAMPLES_DESCRIPTION: "Exemplos de valores detetados no seu ficheiro.",
  IMPORT_FILE_WARNING:
    "Antes de fazer upload dos ficheiros, verifique se está pronto para ser importado. Poderão ser substituídos ou duplicados registos dependente da sua escolha.",
  IMPORT_FILES: "Importar ficheiros",
  IMPORT_FILES_PAGE_REQUIRED:
    "Para prosseguir, é necessário o carregamento do ficheiro e a configuração das folhas.",
  IMPORT_FILES_REQUIRED:
    "Para prosseguir, é necessário o carregamento dos ficheiros",
  IMPORT_INCOMPLETE: "Importação incompleta",
  IMPORT_MAP_COLUMNS:
    "Mapeie colunas no seu ficheiro de modo a estar em conformidade com as propriedades",
  IMPORT_MATCHES: "Coincide",
  IMPORT_MATCHES_DESCRIPTION:
    "Valida se a coluna já está bem mapeada. O Airdesk tentará automaticamente fazer o mapeamento das propriedades consoante o nome das colunas do seu ficheiro.",
  IMPORT_NAME: "Nome da importação",
  IMPORT_NAME_DESCRIPTION:
    "Nome da coluna do seu ficheiro. Caso não esteja a aparecer a sua coluna, poderá haver alguma inconsistência no ficheiro ou nenhum valor da mesma está preenchido.",
  IMPORT_NAME_PLACEHOLDER: "Insera um nome para a importação",
  IMPORT_NR: "Nº Importação",
  IMPORT_OF: "Importação de",
  IMPORT_ONE_FILE: "Importar de um ficheiro",
  IMPORT_OR_EXPORT: "Importar / Exportar",
  IMPORT_OVERWRITE_EMPTY_VALUE: "Sobrepor caso o valor seja vazio",
  IMPORT_OVERWRITE_SELECTED_DATA: "Sobrepor aos dados já existentes",
  IMPORT_PROCESS_MESSAGE:
    "A importação está a ser processada, será notificado quando a mesma terminar.",
  IMPORT_PROPERTY_DESCRIPTION:
    "Estas são as propriedades do Airdesk, deverá mapear a sua coluna do ficheiro com os campos que pretende guardar os dados. Alguns campos são obrigatórios para importar novos registos.",
  IMPORT_REFERENCE_DESCRIPTION:
    "A referência é necessária caso pretenda atualizar dados de registos já existentes. Quando selecionado, o Airdesk irá usar este campo como valor de comparação para sobrepor os restantes dados mapeados (consoante as regras de sobreposição). Caso não seja encontrado nenhum registo com o valor, será criado um novo registo.",
  IMPORT_TWO_FILES: "Importar de dois ficheiros",
  IMPORT_TYPE: "Tipo de importação",
  IMPORT_WARNING_ALL_PROPERTIES:
    "Para prosseguir, é necessário que todas as propriedades estejam configuradas.",
  IMPORT_WARNING_CLIENTS_CONTACTS:
    "Para avançar, é necessário que complete ou limpe a relação entre clientes e contactos.",
  IMPORT_WARNING_REQUIRED_PROPERTIES:
    "Para prosseguir, é necessário mapear as propriedades que são obrigatórias.",
  IMPORT_WORKFLOW: "Importar workflow",
  IMPORT_WORKFLOW_DESCRIPTION:
    "Cole o texto na área de texto. Se o texto de importação estiver num formato inválido, é possível que a tela de edição deixe de funcionar.",
  IMPORTED_RECORDS: "Registos importados",
  IMPORTS: "Importações",
  IMPUTED_HOURS: "Tempo imputado",
  IN: "em",
  IN_APPROVAL: "Em aprovação",
  INACTIVE: "Inativo",
  INACTIVE_CONTRACTS: "Contratos inativos",
  INACTIVE_NOTIFICATIONS: "Notificações não ativas",
  INACTIVE_PROJECTS: "Projetos Inativos",
  INBOX: "Inbox",
  INBOX_EMAIL: "Email de inbox",
  INDIVIDUAL_OR_FAMILY_SERVICES: "Serviços individuais / Familiares",
  INDUSTRIAL_AUTOMATION: "Automação industrial",
  INDUSTRY: "Indústria",
  INEFFICIENT: "Não imputável",
  INEFFICIENT_HOURS: "Tempo não imputável",
  INEFFICIENT_HOURS_ABV: "Tempo não imputável",
  INEFFICIENT_TIME: "Tempo não imputável (minutos)",
  INEFFICIENT_TIME_REASONS: "Razões de tempo não imputável",
  INEFFICIENT_TIME_TYPE: "Tipos de tempo não imputável",
  INFORMATION_SERVICES: "Serviços de informação",
  INFORMATION_TECHNOLOGY_OR_IT: "Tecnologia da informação / TI",
  INPUTED_HOURS: "Tempo imputado",
  INPUTS: "Nº de Inputs",
  INSERT_COLUMN_AFTER: "Inserir coluna depois",
  INSERT_COLUMN_BEFORE: "Inserir coluna antes",
  INSERT_ONLY_VALID_SOCIAL_MEDIA_LINK:
    "Insira o link de uma rede social valida",
  INSERT_ROW_AFTER: "Inserir linha depois",
  INSERT_ROW_BEFORE: "Inserir linha antes",
  INSERT_TABLE: "Insira a tabela",
  INSTAGRAM_URL: "Instagram url",
  INSURANCE: "Seguro",
  INTEGRATIONS: "Integrações",
  INTERNAL_NOTE: "Nota interna",
  INTERNATIONAL_AFFAIRS: "Assuntos internacionais",
  INTERNATIONAL_TRADE_OR_DEVELOPMENT:
    "Comércio / Desenvolvimento internacional",
  INTERNET: "Internet",
  INTERNET_FAIL_MESSAGE:
    "A tentar restabelecer a ligação. Por favor, verifique a ligação à internet.",
  INTERVAL: "Intervalo",
  INTERVAL_BEGIN_DATE: "Data do Início do Intervalo",
  INTERVAL_CUSTOM: "Intervalo customizado",
  INTERVAL_EVEN_OLDER: "Mais antigas",
  INTERVAL_LAST_FIFTEEN_DAYS: "Últimos 15 dias",
  INTERVAL_LAST_MONTH: "Último mês",
  INTERVAL_LAST_SEMESTER: "Último semestre",
  INTERVAL_LAST_TRIMESTER: "Último trimestre",
  INTERVAL_LAST_WEEK: "Última semana",
  INTERVAL_THIS_MONTH: "Este mês",
  INTERVAL_THIS_SEMESTER: "Este semestre",
  INTERVAL_THIS_TRIMESTER: "Este trimestre",
  INTERVAL_THIS_WEEK: "Esta semana",
  INTERVAL_TODAY: "Hoje",
  INTERVAL_YESTERDAY: "Ontem",
  INTERVENTION_TYPE: "Tipo de intervenção",
  INTERVENTION_TYPES: "Tipos de intervenção",
  INTERVENTIONS: "Tempos",
  INTERVENTIONS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar tempos",
  INTERVENTIONS_DELETE_DELAY_TITLE: "Eliminação de tempos",
  INTERVENTIONS_DELETE_DESCRIPTION: "A eliminar tempos",
  INTERVENTIONS_DELETE_TITLE: "Eliminação de tempos",
  INTERVENTIONS_TYPES_LIST_EMPTY: "Listagem de tipos de tempos vazia.",
  INTERVENTIONS_TYPES_LIST_EMPTY_DESCRIPTION:
    "Não existem registos de tipos tempos associados.",
  INVALID_COMPANY_WARNING: "E necessário no mínimo uma empresa.",
  INVALID_EMAIL: "Por favor, insira um email válido",
  INVALID_FIELD: "Campo inválido",
  INVALID_FIELD_DESCRIPTION: "Por favor, insira os dados corretos",
  INVALID_INVITATION: "Convite inválido",
  INVALID_NIF: "Este NIF não é valido.",
  INVALID_NUMBER_WARNING: "Por favor, insira um numero válido",
  INVALID_PASSWORD: "Por favor, insira uma password válida",
  INVALID_SCHEDULE_DAYS:
    "É necessário que todos os horários tenham os dias colocados.",
  INVESTMENT_BANKING_OR_VENTURE: "Banco de investimento / Risco",
  INVESTMENT_MANAGEMENT_OR_HEDGE_FUND_OR_PRIVATE_EQUITY:
    "Gestão de Investimentos / Hedge fund / Private equity",
  INVITE: "Convite",
  INVITE_ALREADY_ACCEPTED: "O convite já foi aceite.",
  INVITE_EXPIRED: "Convite expirado.",
  INVITE_EXPIRED_DESCRIPTION:
    "O link deste convite já não se encontra disponível. Contacte a pessoa que o convidou para solicitar um novo.",
  INVITE_INVALID: "Convite inválido.",
  INVITE_INVALID_ACCOUNT:
    "Este convite não pertence à conta que está com o login efetuado. Por favor, faça login com a respetiva conta.",
  INVITE_LOGIN_REQUIRED: "Login necessário.",
  INVITE_LOGIN_REQUIRED_DESCRIPTION:
    "É necessário fazer login para aceitar este convite.",
  INVITE_NOT_FOUND_DESCRIPTION:
    "Pedimos desculpa. Este convite não foi encontrado. Certifique-se de que o link do convite que recebeu está correto.",
  INVITE_UNKNOWN_DESCRIPTION:
    "Ocorreu um erro ao carregar o seu convite. Pedimos desculpa pelo inconveniente.",
  INVITES: "Convites",
  INVOICE: "Fatura",
  INVOICES: "Faturas",
  IS_EMPTY: "Está vazio",
  IS_SUSPENDED: "está cancelada.",
  ISNT_EMPTY: "Não está vazio",
  IT_DEPARTMENT: "IT",
  ITALIC: "Itálico",
  JANUARY: "Janeiro",
  JUDICIARY: "Judiciário",
  JULY: "Julho",
  JUNE: "Junho",
  KEYBOARD_TO_NAVIGATE: "Use o teclado para navegar",
  LANGUAGES: "Idiomas",
  LAST: "Último",
  LAST_ACTIVITY: "Última atividade",
  LAST_CASES: "Últimos casos",
  LAST_CHANGE: "Última alteração",
  LAST_MONTH: "Último mês",
  LAST_PASSWORD_CHANGE: "Última alteração a",
  LAST_RECORDS: "Últimos registos",
  LAST_TASKS: "Últimas tarefas",
  LAST_THREE_NUMBERS_OF_CARD: "Últimos três números na parte de trás do cartão",
  LAST_TIME_REGISTRATION: "Últimos registos de tempo",
  LAST_UPDATE: "Última alteração",
  LATEST_REGISTRATIONS: "Últimos registos",
  LATITUDE: "Latitude",
  LAW_ENFORCEMENT: "Aplicação da lei",
  LAW_PRACTICE_OR_LAW_FIRMS: "Advocacia / Escritórios de advocacia",
  LEGA_BASIS: "Base legal para o processamento dos dados de um contato",
  LEGAL: "Legal",
  LEGAL_SERVICES: "Serviços legais",
  LEGISLATIVE_OFFICE: "Gabinete legislativo",
  LEISURE_OR_TRAVEL: "Viagem de lazer",
  LESSER: "Menor",
  LESSER_EQUAL: "Menor ou igual",
  LIBRARY: "Biblioteca",
  LINE_BREAK: "Quebra de linha",
  LINK: "Hiperligação",
  LINKEDIN_URL: "Linkedin url",
  LOAD_MORE: "Carregar mais",
  LOCAL: "Local",
  LOCATION: "Localização",
  LOCATION_ADDRESS: "Localização e morada",
  LOG: "Log",
  LOG_OUT: "Sair",
  LOGIN_BACK: "Voltar para login",
  LOGIN_INVALID_CREDENTIALS: "O seu email ou password estão incorretos",
  LOGIN_NO_ACCOUNT: "Não tem uma conta?",
  LOGIN_REGISTER: "Inscreva-se",
  LOGIN_SET_VALID_EMAIL: "Por favor, insira um email válido",
  LOGIN_SET_VALID_PASSWORD: "Por favor, insira uma password válida",
  LOGIN_UNKNOWN_ERROR:
    "Tivemos um problema ao fazer log in. Por favor, tente novamente passado algum tempo.",
  LOGISTICS: "Logística",
  LOGISTICS_DEPARTMENT: "Logística",
  LOGISTICS_OR_PROCUREMENT: "Logística / Aquisições",
  LOGO: "Logotipo",
  LOGO_FOR_REPORTS: "Logotipo para relatórios",
  LOGO_LINK: "Link do logotipo",
  LOGO_UPLOAD: "Carregar logotipo",
  LOGOTYPE_DESCRIPTION_INPUT: "Arraste o ficheiro ou procure",
  LOGS: "Logs",
  LONGITUDE: "Longitude",
  LOSES: "Perdidos",
  LOSS_DESCRIPTION: "Descrição de perda",
  LOST: "Perdido",
  LOST_DEALS: "Negócios perdidos",
  LOST_DEALS_AMOUNTS: "Negócios perdidos montantes",
  LOST_REASON: "Razão de perda",
  LOST_REASONS: "Razões de perda",
  LOST_REASONS_SUCCESS: "Razão de perda gravada com sucesso",
  LUXURY_GOODS_OR_JEWELRY: "Bens / joias de luxo",
  MACHINERY: "Maquinaria",
  MAIN_CONTACTS: "Contactos principais",
  MAINTENANCE: "Manutenção",
  MAINTENANCE_ONGOING_DESC:
    "O sistema encontra-se de momento em manutenção, pelo que, poderão surgir erros durante o processo.",
  MAINTENANCE_WARNING_DESC:
    "Está prevista uma manutenção do sistema dentro de {fromNow} ({date}), com duração de {minutes} minutos. Durante a atualização, o sistema estará indisponível.",
  MALE: "Homem",
  MANAGEMENT_CONSULTING: "Consultoria de gestão",
  MANAGER: "Responsável",
  MANAGER_IS: "Responsável é",
  MANAGERS: "Responsáveis",
  MANUAL: "Manual",
  MAPPING: "Mapeamento",
  MARCH: "Março",
  MARITIME: "Marítimo",
  MARK_ALL_AS_READ: "Marcar tudo como lido",
  MARK_AS_READ: "Marcar selecionadas como lidas",
  MARK_AS_UNREAD: "Marcar selecionadas como não lidas",
  MARK_CONVERSARION_AS_CLOSED: "Fechar conversas",
  MARK_CONVERSARION_AS_OPEN: "Abrir conversas",
  MARK_SINGLE_AS_READ: "Marcar como lido",
  MARK_SINGLE_AS_UNREAD: "Marcar como não lido",
  MARKET_RESEARCH: "Pesquisa de mercado",
  MARKETING: "Marketing",
  MARKETING_OR_ADVERTISING_OR_SALES: "Marketing / Publicidade / Vendas",
  MAX_BODY_LENGTH: "Max body length",
  MAX_CONTENT_LENGTH: "Max content length",
  MAX_NUMBER_OF_REDEEMS: "Número total de resgates permitidos",
  MAX_REDIRECTS: "Max redirects",
  MAXIMIZE: "Maximizar",
  MAXIMIZE_DESCRIPTION: "Maximizar descrição",
  MAXIMUM_PERIOD_HOURS: "Máximo de horas por período",
  MAXIMUM_PERIOD_HOURS_ABRV: "Máx. h/período",
  MAY: "Maio",
  ME: "Eu",
  MECHANICAL_OR_INDUSTRIAL_ENGINEERING: "Engenharia mecânica ou industrial",
  MEDIA_PRODUCTION: "Produção de mídia",
  MEDICAL_EQUIPMENT: "Equipamento médico",
  MEDICAL_PRACTICE: "Prática médica",
  MENTAL_HEALTH_CARE: "Cuidados de saúde mental",
  MENU: "Menu",
  MENU_LAYOUT: "Layout do menu",
  MERGE_CELLS: "Unir células ",
  MERGE_CONTACTS: "Agregar contactos",
  MERGE_CONTACTS_ALTERED_CALLS_DESCRIPTION:
    "chamadas associados e movidos para o contato.",
  MERGE_CONTACTS_ALTERED_CASES_DESCRIPTION:
    "tickets associados e movidos para o contato.",
  MERGE_CONTACTS_ALTERED_CONTRACTS_DESCRIPTION:
    "contratos associados e movidos para o contato.",
  MERGE_CONTACTS_ALTERED_DEALS_DESCRIPTION:
    "negócios associados e movidos para o contato.",
  MERGE_CONTACTS_ALTERED_PROJECTS_DESCRIPTION:
    "projetos associados e movidos para o contato.",
  MERGE_CONTACTS_ALTERED_SUBSCRIPTIONS_DESCRIPTION:
    "subscrições associados e movidos para o contato.",
  MERGE_CONTACTS_ALTERED_TIMES_DESCRIPTION:
    "tempos associados e movidos para o contato.",
  MESSAGE: "Mensagem",
  MESSAGE_HELP_MESSAGE: "Tooltip da mensagem",
  MESSAGES: "Mensagens",
  METHOD: "Metodo",
  MILITARY_INDUSTRY: "Indústria militar",
  MIN_AGO: "mínutos atrás",
  MINIATURES: "Miniaturas",
  MINIMIZE: "Minimizar",
  MINIMIZE_DESCRIPTION: "Minimizar descrição",
  MINIMUM_BILLING_INTERVAL: "Intervalo mínimo de faturação (min)",
  MINIMUM_BILLING_INTERVAL_ABV: "Int. min. fat.",
  MINIMUM_INTERVAL: "Intervalo mínimo de faturação (min)",
  MINING_OR_METALS: "Mineração / Metais",
  MINUTE: "Minuto",
  MOBILE_NUMBER: "Nº telemóvel",
  MOBILE_NUMBER_PREFIX: "Extensão de telemóvel",
  MODAL: "Modal",
  MODE: "Modo",
  MODEL: "Modelo",
  MODEL_BLANK: "Modelo em branco",
  MODIFICATION: "Alteração",
  MODULE_ERROR: "Houve um erro ao carregar o módulo",
  MODULES: "Módulos",
  MODULES_ACCESS: "Acesso aos módulos",
  MODULES_NAME: "Nome dos módulos",
  MONDAY: "Segunda-feira",
  MONTH: "Mês",
  MONTH_AGO: "Há um mês",
  MONTHLY: "Mensalmente",
  MONTHLY_PAYMENT: "Pagamento mensal",
  MONTHS: "Meses",
  MONTHS_AGO: "Há meses",
  MORE: "Mais",
  MORE_OPTIONS: "Mais opções",
  MOTION_PICTURES_OR_FILM: "Filmes",
  MOVE: "Mover",
  MOVE_CLASSIFICATION: "Mover classificação",
  MULTIPLE_TIMERS: "Múltiplos temporizadores",
  MUSEUMS_OR_INSTITUTIONS: "Museus / Instituições",
  MUSIC: "Música",
  MX_RECORD: "Registo MX",
  MY_DEPARTMENTS: "Meus departamentos",
  N_INPUTS: "Nº de inputs",
  NAME: "Nome",
  NAME_FILTER: "Nome do filtro",
  NAME_MODULES: "Nome dos módulos",
  NAME_OF_PIPELINE_STATUS: "Estados de pipeline",
  NANOTECHNOLOGY: "Nanotecnologia",
  NEW_CONVERSATION: "Nova conversa",
  NEW_EMAIL_CONVERSATION: "Nova conversa de email",
  NEW_FOLDER: "Nova pasta",
  NEW_FOLDER_TEXT: "Determine a cor, ícone e nome da nova pasta",
  NEW_PASSWORD: "Nova password",
  NEWSPAPERS_OR_JOURNALISM: "Jornais / Jornalismo",
  NEXT: "Próximo",
  NEXT_PERIOD: "Próxima periocidade",
  NEXT_RENEWAL: "Próxima renovação",
  NIF: "NIF",
  NIF_VALIDATION_ERROR: "Ocorreu uma falha na procura de dados deste NIF",
  NIF_VALIDATION_NOT_FOUND: "Não foi possível encontrar os dados deste NIF",
  NIF_VALIDATION_NOT_VALID: "Os dados deste NIF não se encontram validos",
  NO: "Não",
  NO_ACCOUNT: "Não existem registos de contas",
  NO_ACCOUNTS: "Não há contas associadas",
  NO_ACTIVITY_LIST: "Listagem de atividade vazia.",
  NO_API_KEYS: "Não existem registos de chaves API",
  NO_ATTACHMENTS: "Não há anexos",
  NO_AUTOMATION_LIST: "Listagem de automatizações vazia.",
  NO_BIILING_LIST: "Não existem registos de faturação associadas.",
  NO_BIRTHDAY_TODAY: "Ninguém faz anos neste período.",
  NO_CALLS_LIST: "Listagem de chamadas vazia.",
  NO_CLASSIFICATION_LIST: "Não existem registos de classificações associadas.",
  NO_COMPANY: "Não existem registos de empresas associadas.",
  NO_CONTACTS: "Você não possui contatos associados.",
  NO_CONTRACTS: "Não existem registos de contratos associados.",
  NO_CONTRACTS_LIST: "Não existem registos de contratos associados.",
  NO_DEPARTMENT: "Não existem registos de departamentos associados.",
  NO_DOMAINS: "Não existem registos de domínios de email associadas.",
  NO_EMAIL_RECEPTION: "Não existem registos de email associados.",
  NO_EMAIL_SIGNATURE: "Não existem registos de assinaturas email associados",
  NO_FILES: "Não existem ficheiros",
  NO_FILTERED_FILTERS: "Não há filtros para a sua pesquisa",
  NO_IMPORTS: "Não existem registos de Importações associados.",
  NO_INTERVENTION_LIST: "Não existem registos de tempos associados.",
  NO_LOGS: "Não há registos de logs associados",
  NO_LOST_REASON_LIST: "Não existem registos de motivos de perda associados.",
  NO_MATCHES_FOUND: "Nenhuma correspondência encontrada.",
  NO_MODULES: "Parece que não tem nenhum módulo configurado de momento.",
  NO_MODULES_EXPANDED: "Por favor, contacte a administração",
  NO_PERMISSION: "De momento não tem permissões para aceder a esta vista.",
  NO_PERMISSION_DISCLAIMER_DESCRIPTION:
    "Por favor, contacte um responsável para aceder",
  NO_PERMISSION_DISCLAIMER_TITLE:
    "Não tem permissão para aceder a este conteúdo ",
  NO_PERMISSION_EXPANDED: "Se este erro persistir contacte a administração.",
  NO_PIPELINES_LIST: "Não existem registos de pipelines associadas.",
  NO_PROJECT_LIST: "Não existem registos de projetos associados.",
  NO_PROJECT_TYPES: "Não existem registos de tipos de projeto associados.",
  NO_QUEUE_LIST: "Nenhum registo de fila encontrado",
  NO_RECORDS: "Não tem registos",
  NO_SEARCH_RESULTS: "Não existe nenhum resultado",
  NO_SOCIAL_ACCOUNT:
    "Não existem registos de contas de redes sociais associadas.",
  NO_SOCIAL_POST_LIST: "Não existem registos de publicações associados.",
  NO_SUBSCRIPTIONS: "Você não tem assinaturas associadas.",
  NO_SUBSCRIPTIONS_TYPE_RECORDS: "Listagem de tipos de subscrições vazia.",
  NO_TASK_LIST: "Não existem registos de tarefas associados.",
  NO_TASKS: "Não tem tarefas",
  NO_TEAMS: "Não existem registos de equipas associados.",
  NO_TICKETS_LIST: "Não existem registos de casos associados.",
  NO_USERS: "Não tem utilizadores",
  NO_WIDGETS: "Não existem registos de widgets associados.",
  NON_PROFIT_OR_VOLUNTEERING: "Sem fins lucrativos / voluntário",
  NONE: "Nenhum",
  NOT_APPLICABLE: "Não aplicável",
  NOT_CLASSIFIED: "não classificado(s)",
  NOT_CONTAINS: "Não contém",
  NOT_EQUAL: "Não igual",
  NOT_READ: "Não lido",
  NOT_VALIDATED: "Não validado",
  NOTE: "Nota",
  NOTE_ATTACHMENTS: "Anexos notas",
  NOTES: "Notas",
  NOTIFICATION_APEARANCE_DESCRIPTON:
    "Mostrar contagem e círculo laranja quando tem notificações não lidas no sino Airdesk.",
  NOTIFICATION_APEARANCE_TITLE: "Aparência",
  NOTIFICATION_BELL_DESCRIPTON:
    "As notificações aparecem num círculo laranja no ícone sino na barra de navegação. Pode clicar no sino a qualquer momento para ver suas notificações mais recentes.",
  NOTIFICATION_BELL_TITLE: "Sino",
  NOTIFICATION_BROWSER_DESCRIPTON:
    "As notificações do browser aparecem no monitor quando não está ativo no Airdesk, mas o site é aberto na guia ou janela do navegador.",
  NOTIFICATION_BROWSER_TITLE: "Browser",
  NOTIFICATION_EMAIL_DESCRIPTON:
    "As notificações de email serão envidas para a sua caixa de entrada.",
  NOTIFICATION_EMAIL_TITLE: "Email",
  NOTIFICATION_MOBILE_DESCRIPTON:
    "As notificações push e no aplicativo serão enviadas para o seu dispositivo móvel (iOS ou Android).",
  NOTIFICATION_MOBILE_TITLE: "Mobile",
  NOTIFICATION_POPUP_DESCRIPTON:
    "As notificações pop-up aparecem no monitor quando está ativo no Airdesk.",
  NOTIFICATION_POPUP_TITLE: "Pop-up",
  NOTIFICATION_SOUND_DESCRIPTON:
    "Tocar som quando recebe novas notificações pop-pup.",
  NOTIFICATION_SOUND_TITLE: "Som",
  NOTIFICATION_TOPIC: "Tópico de notificações",
  NOTIFICATION_TOPIC_ACCOUNTS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que uma conta é apagada",
  NOTIFICATION_TOPIC_ACCOUNTS_DELETED_TITLE: "Quando uma conta é apagada",
  NOTIFICATION_TOPIC_ACCOUNTS_MANAGER_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que muda o gestor da conta",
  NOTIFICATION_TOPIC_ACCOUNTS_MANAGER_CHANGE_TITLE:
    "Quando muda o gestor da conta",
  NOTIFICATION_TOPIC_ACCOUNTS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que uma conta é modificada",
  NOTIFICATION_TOPIC_ACCOUNTS_MODIFIED_TITLE: "Quando uma conta é modificada",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_CONTACT_DESCRIPTION:
    "Receba uma notificação cada vez que é criado um novo contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_CONTACT_TITLE:
    "Quando é criado um novo contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criada uma nova conta",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um novo seguidor à conta",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_FOLLOWER_TITLE:
    "Quando é adicionado um novo seguidor à conta",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que existem novas mensagem",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_MESSAGE_TITLE:
    "Quando existem novas mensagem",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_TITLE: "Quando é criada uma nova conta",
  NOTIFICATION_TOPIC_ACCOUNTS_ORIGINATED_DESCRIPTION:
    "Receba uma notificação cada vez que é originada uma conta",
  NOTIFICATION_TOPIC_ACCOUNTS_ORIGINATED_TITLE: "Quando é originada uma conta",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_CONTACT_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_CONTACT_TITLE:
    "Quando é removido um contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um seguidor da conta",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_FOLLOWER_TITLE:
    "Quando é removido um seguidor da conta",
  NOTIFICATION_TOPIC_ACCOUNTS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado o estado da conta",
  NOTIFICATION_TOPIC_ACCOUNTS_STATUS_CHANGE_TITLE:
    "Quando é alterado o estado da conta",
  NOTIFICATION_TOPIC_CALLS_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterada a quem é atribuída a chamada",
  NOTIFICATION_TOPIC_CALLS_ASSIGN_TO_CHANGE_TITLE:
    "Quando é alterada a quem é atribuída a chamada",
  NOTIFICATION_TOPIC_CALLS_ASSIGNED_TO_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é atribuída uma alteração a um utilizador",
  NOTIFICATION_TOPIC_CALLS_ASSIGNED_TO_CHANGE_TITLE:
    "Quando é atribuída uma alteração a um utilizador",
  NOTIFICATION_TOPIC_CALLS_BEFORE_END_DESCRIPTION:
    "Receba uma notificação cada vez que só faltarem três dias para a data de término da chamada",
  NOTIFICATION_TOPIC_CALLS_BEFORE_END_TITLE:
    "Quando só faltarem três dias para a data de término da chamada",
  NOTIFICATION_TOPIC_CALLS_CONTACT_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado o contacto a quem é atribuída a chamada",
  NOTIFICATION_TOPIC_CALLS_CONTACT_CHANGE_TITLE:
    "Quando é alterado o contacto a quem é atribuída a chamada",
  NOTIFICATION_TOPIC_CALLS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que uma chamada é removida",
  NOTIFICATION_TOPIC_CALLS_DELETED_TITLE: "Quando uma chamada é removida",
  NOTIFICATION_TOPIC_CALLS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que recebe uma nova mensagem",
  NOTIFICATION_TOPIC_CALLS_MODIFIED_TITLE: "Quando é modificada uma chamada",
  NOTIFICATION_TOPIC_CALLS_NEW_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado um departamento",
  NOTIFICATION_TOPIC_CALLS_NEW_DEPARTMENT_TITLE:
    "Quando é alterado um departamento",
  NOTIFICATION_TOPIC_CALLS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criada uma chamada",
  NOTIFICATION_TOPIC_CALLS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um seguidor",
  NOTIFICATION_TOPIC_CALLS_NEW_FOLLOWER_TITLE:
    "Quando é adicionado um seguidor",
  NOTIFICATION_TOPIC_CALLS_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que recebe uma nova mensagem",
  NOTIFICATION_TOPIC_CALLS_NEW_MESSAGE_TITLE: "Quando recebe uma nova mensagem",
  NOTIFICATION_TOPIC_CALLS_NEW_TITLE: "Quando é criada uma chamada",
  NOTIFICATION_TOPIC_CALLS_ORIGINATED_DESCRIPTION:
    "Receba uma notificação cada vez que é originada uma chamada",
  NOTIFICATION_TOPIC_CALLS_ORIGINATED_TITLE: "Quando é originada uma chamada",
  NOTIFICATION_TOPIC_CALLS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado uma equipa",
  NOTIFICATION_TOPIC_CALLS_REMOVE_DEPARTMENT_TITLE:
    "Quando é alterado uma equipa",
  NOTIFICATION_TOPIC_CALLS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um seguidor",
  NOTIFICATION_TOPIC_CALLS_REMOVE_FOLLOWER_TITLE:
    "Quando é removido um seguidor",
  NOTIFICATION_TOPIC_CALLS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que o estado de uma chamada é alterado",
  NOTIFICATION_TOPIC_CALLS_STATUS_CHANGE_TITLE:
    "Quando o estado de uma chamada é alterado",
  NOTIFICATION_TOPIC_CASES_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é atribuída uma alteração a um utilizador",
  NOTIFICATION_TOPIC_CASES_ASSIGN_TO_CHANGE_TITLE:
    "Quando é atribuída uma alteração a um utilizador",
  NOTIFICATION_TOPIC_CASES_ASSIGN_USER_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_CASES_ASSIGN_USER_CHANGE_TITLE:
    "Quando a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_CASES_DEPARTMENT_TEAM_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado um departamento/equipa",
  NOTIFICATION_TOPIC_CASES_DEPARTMENT_TEAM_CHANGE_TITLE:
    "Quando é alterado um departamento/equipa",
  NOTIFICATION_TOPIC_CASES_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que é modificado um caso",
  NOTIFICATION_TOPIC_CASES_MODIFIED_TITLE: "Quando é modificado um caso",
  NOTIFICATION_TOPIC_CASES_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criado um caso",
  NOTIFICATION_TOPIC_CASES_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é adicionado",
  NOTIFICATION_TOPIC_CASES_NEW_FOLLOWER_TITLE:
    "Quando um seguidor é adicionado",
  NOTIFICATION_TOPIC_CASES_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que recebe uma nova mensagem",
  NOTIFICATION_TOPIC_CASES_NEW_MESSAGE_TITLE: "Quando recebe uma nova mensagem",
  NOTIFICATION_TOPIC_CASES_NEW_TITLE: "Quando é criado um caso",
  NOTIFICATION_TOPIC_CASES_ORIGINATED_DESCRIPTION:
    "Receba uma notificação cada vez que é originado um caso",
  NOTIFICATION_TOPIC_CASES_ORIGINATED_TITLE: "Quando é originado um caso",
  NOTIFICATION_TOPIC_CASES_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é removido",
  NOTIFICATION_TOPIC_CASES_REMOVE_FOLLOWER_TITLE:
    "Quando um seguidor é removido",
  NOTIFICATION_TOPIC_CASES_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado um estado de um caso",
  NOTIFICATION_TOPIC_CASES_STATUS_CHANGE_TITLE:
    "Quando é alterado um estado de um caso",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma classificação",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_DELETED_TITLE:
    "Quando é removida uma classificação",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que é modificada uma classificação",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_MODIFIED_TITLE:
    "Quando é modificada uma classificação",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criada uma nova classificação",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_NEW_TITLE:
    "Quando é criada uma nova classificação",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado o estado uma classificação",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_STATUS_CHANGE_TITLE:
    "Quando é alterado o estado uma classificação",
  NOTIFICATION_TOPIC_CONTACTS_ADD_ACCOUNT_DESCRIPTION:
    "Receba uma notificação cada vez que o contacto é adicionado a uma conta",
  NOTIFICATION_TOPIC_CONTACTS_ADD_ACCOUNT_TITLE:
    "Quando o contacto é adicionado a uma conta",
  NOTIFICATION_TOPIC_CONTACTS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que um contacto é apagado",
  NOTIFICATION_TOPIC_CONTACTS_DELETED_TITLE: "Quando um contacto é apagado",
  NOTIFICATION_TOPIC_CONTACTS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que um contacto é modificado",
  NOTIFICATION_TOPIC_CONTACTS_MODIFIED_TITLE: "Quando um contacto é modificado",
  NOTIFICATION_TOPIC_CONTACTS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criado um novo contacto",
  NOTIFICATION_TOPIC_CONTACTS_NEW_TITLE: "Quando é criado um novo contacto",
  NOTIFICATION_TOPIC_CONTACTS_REMOVE_ACCOUNT_DESCRIPTION:
    "Receba uma notificação cada vez que o contacto é removido de uma conta",
  NOTIFICATION_TOPIC_CONTACTS_REMOVE_ACCOUNT_TITLE:
    "Quando o contacto é removido de uma conta",
  NOTIFICATION_TOPIC_CONTACTS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que muda o estado de um contacto",
  NOTIFICATION_TOPIC_CONTACTS_STATUS_CHANGE_TITLE:
    "Quando muda o estado de um contacto",
  NOTIFICATION_TOPIC_CONTRACTS_ASSIGNED_TO_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_CONTRACTS_ASSIGNED_TO_CHANGE_TITLE:
    "Quando a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_CONTRACTS_BEFORE_CLOSE_DESCRIPTION:
    "Receba uma notificação cada vez que só faltarem três dias para a data de término de um contrato",
  NOTIFICATION_TOPIC_CONTRACTS_BEFORE_CLOSE_TITLE:
    "Quando só faltarem três dias para a data de término de um contrato",
  NOTIFICATION_TOPIC_CONTRACTS_COMPANY_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a empresa for alterada",
  NOTIFICATION_TOPIC_CONTRACTS_COMPANY_CHANGE_TITLE:
    "Quando a empresa é alterada",
  NOTIFICATION_TOPIC_CONTRACTS_CONTACTS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que um contrato é alterado",
  NOTIFICATION_TOPIC_CONTRACTS_CONTACTS_CHANGE_TITLE:
    "Quando um contrato é alterado",
  NOTIFICATION_TOPIC_CONTRACTS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que um contrato é apagado",
  NOTIFICATION_TOPIC_CONTRACTS_DELETED_TITLE: "Quando um contrato é apagado",
  NOTIFICATION_TOPIC_CONTRACTS_END_DESCRIPTION:
    "Receba uma notificação cada vez que um contrato é terminado",
  NOTIFICATION_TOPIC_CONTRACTS_END_TITLE: "Quando um contrato é terminado",
  NOTIFICATION_TOPIC_CONTRACTS_EXCEEDED_TIME_DESCRIPTION:
    "Receba uma notificação cada vez que um contrato exceda o limite de tempo",
  NOTIFICATION_TOPIC_CONTRACTS_EXCEEDED_TIME_TITLE:
    "Quando um contrato excede o limite de tempo",
  NOTIFICATION_TOPIC_CONTRACTS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Receba uma notificação cada vez que faltar x tempo para o fim do contrato",
  NOTIFICATION_TOPIC_CONTRACTS_LEFT_TO_EXCEEDED_TITLE:
    "Quando faltar x tempo para o fim do contrato",
  NOTIFICATION_TOPIC_CONTRACTS_MANAGER_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_CONTRACTS_MANAGER_CHANGE_TITLE:
    "Quando a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_CONTRACTS_MAXED_HOURS_DESCRIPTION:
    "Receba uma notificação cada vez que um contrato excede o limite de tempo",
  NOTIFICATION_TOPIC_CONTRACTS_MAXED_HOURS_TITLE:
    "Quando um contrato excede o limite de tempo",
  NOTIFICATION_TOPIC_CONTRACTS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que um contrato é modificado",
  NOTIFICATION_TOPIC_CONTRACTS_MODIFIED_TITLE:
    "Quando um contrato é modificado",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que um departamento é adicionado",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DEPARTMENT_TITLE:
    "Quando um departamento é adicionado",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criado um novo contrato",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é adicionado",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_FOLLOWER_TITLE:
    "Quando um seguidor é adicionado",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que uma nova equipa é adicionada",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TEAM_TITLE:
    "Quando uma nova equipa é adicionada",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TITLE: "Quando é criado um novo contrato",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_USER_DESCRIPTION:
    "Receba uma notificação cada vez que um novo utilizador é adicionado",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_USER_TITLE:
    "Quando um novo utilizador é adicionado",
  NOTIFICATION_TOPIC_CONTRACTS_ORIGINATED_DESCRIPTION:
    "Receba uma notificação cada vez que é originado um contrato",
  NOTIFICATION_TOPIC_CONTRACTS_ORIGINATED_TITLE:
    "Quando é originado um contrato",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que um departamento é removido",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_DEPARTMENT_TITLE:
    "Quando um departamento é removido",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é removido",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_FOLLOWER_TITLE:
    "Quando um seguidor é removido",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que uma nova equipa é removida",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_TEAM_TITLE:
    "Quando uma nova equipa é removida",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_USER_DESCRIPTION:
    "Receba uma notificação cada vez que um novo utilizador é removido",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_USER_TITLE:
    "Quando um novo utilizador é removido",
  NOTIFICATION_TOPIC_CONTRACTS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que o estado de um contrato é alterado",
  NOTIFICATION_TOPIC_CONTRACTS_STATUS_CHANGE_TITLE:
    "Quando o estado de um contrato é alterado",
  NOTIFICATION_TOPIC_DEALS_BEFORE_CLOSE_DESCRIPTION:
    "Receba uma notificação cada vez que receber uma nova mensagem",
  NOTIFICATION_TOPIC_DEALS_BEFORE_CLOSE_TITLE:
    "Quando receber uma nova mensagem",
  NOTIFICATION_TOPIC_DEALS_CHANGE_CONTACT_DESCRIPTION:
    "Receba uma notificação cada vez que o contacto é alterado",
  NOTIFICATION_TOPIC_DEALS_CHANGE_CONTACT_TITLE: "Quando o contacto é alterado",
  NOTIFICATION_TOPIC_DEALS_CHANGE_RESPONSIBLE_DESCRIPTION:
    "Receba uma notificação cada vez que a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_DEALS_CHANGE_RESPONSIBLE_TITLE:
    "Quando a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_DEALS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que um negócio é apagado",
  NOTIFICATION_TOPIC_DEALS_DELETED_TITLE: "Quando um negócio é apagado",
  NOTIFICATION_TOPIC_DEALS_END_DESCRIPTION:
    "Receba uma notificação cada vez que um negócio terminar",
  NOTIFICATION_TOPIC_DEALS_END_TITLE: "Quando um negócio terminar",
  NOTIFICATION_TOPIC_DEALS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que um negócio é modificado",
  NOTIFICATION_TOPIC_DEALS_MODIFIED_TITLE: "Quando um negócio é modificado",
  NOTIFICATION_TOPIC_DEALS_NEW_ATTR_DESCRIPTION:
    "Receba uma notificação cada vez que é alterada a atribuição do negócio",
  NOTIFICATION_TOPIC_DEALS_NEW_ATTR_TITLE:
    "Quando a pessoa atríbuida ao negócio é alterada",
  NOTIFICATION_TOPIC_DEALS_NEW_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que um departamento é adicionado",
  NOTIFICATION_TOPIC_DEALS_NEW_DEPARTMENT_TITLE:
    "Quando um departamento é adicionado",
  NOTIFICATION_TOPIC_DEALS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criado um novo negócio",
  NOTIFICATION_TOPIC_DEALS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é adicionado",
  NOTIFICATION_TOPIC_DEALS_NEW_FOLLOWER_TITLE:
    "Quando um seguidor é adicionado",
  NOTIFICATION_TOPIC_DEALS_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que existem novas mensagem",
  NOTIFICATION_TOPIC_DEALS_NEW_MESSAGE_TITLE: "Quando existem novas mensagem",
  NOTIFICATION_TOPIC_DEALS_NEW_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que uma equipa é adicionada",
  NOTIFICATION_TOPIC_DEALS_NEW_TEAM_TITLE: "Quando uma equipa é adicionada",
  NOTIFICATION_TOPIC_DEALS_NEW_TITLE: "Quando é criado um novo negócio",
  NOTIFICATION_TOPIC_DEALS_NEW_USER_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é adicionado",
  NOTIFICATION_TOPIC_DEALS_NEW_USER_TITLE: "Quando um utilizador é adicionado",
  NOTIFICATION_TOPIC_DEALS_ORIGINATED_DESCRIPTION:
    "Receba uma notificação cada vez que é originado um negócio",
  NOTIFICATION_TOPIC_DEALS_ORIGINATED_TITLE: "Quando é originado um negócio",
  NOTIFICATION_TOPIC_DEALS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que um departamento é removido",
  NOTIFICATION_TOPIC_DEALS_REMOVE_DEPARTMENT_TITLE:
    "Quando um departamento é removido",
  NOTIFICATION_TOPIC_DEALS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é removido",
  NOTIFICATION_TOPIC_DEALS_REMOVE_FOLLOWER_TITLE:
    "Quando um seguidor é removido",
  NOTIFICATION_TOPIC_DEALS_REMOVE_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que uma equipa é removida",
  NOTIFICATION_TOPIC_DEALS_REMOVE_TEAM_TITLE: "Quando uma equipa é removida",
  NOTIFICATION_TOPIC_DEALS_REMOVE_USER_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é removido",
  NOTIFICATION_TOPIC_DEALS_REMOVE_USER_TITLE: "Quando um utilizador é removido",
  NOTIFICATION_TOPIC_DEALS_START_DESCRIPTION:
    "Receba uma notificação cada vez que um negócio iniciar",
  NOTIFICATION_TOPIC_DEALS_START_TITLE: "Quando um negócio iniciar",
  NOTIFICATION_TOPIC_DEALS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que o estado de um negócio é alterado",
  NOTIFICATION_TOPIC_DEALS_STATUS_CHANGE_TITLE:
    "Quando o estado de um negócio é alterado",
  NOTIFICATION_TOPIC_INBOX_CLOSED_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que é fechado um email",
  NOTIFICATION_TOPIC_INBOX_CLOSED_MESSAGE_TITLE: "Quando é fechado um email",
  NOTIFICATION_TOPIC_INBOX_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um email",
  NOTIFICATION_TOPIC_INBOX_DELETED_TITLE: "Quando é removido um email",
  NOTIFICATION_TOPIC_INBOX_DELETED_TWO_DESCRIPTION:
    "Receba uma notificação cada vez que é apagado um email",
  NOTIFICATION_TOPIC_INBOX_DELETED_TWO_TITLE: "Quando é apagado um email",
  NOTIFICATION_TOPIC_INBOX_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que recebe um novo email",
  NOTIFICATION_TOPIC_INBOX_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que é um enviado um email associado a uma conversa de email.",
  NOTIFICATION_TOPIC_INBOX_NEW_MESSAGE_TITLE:
    "Quando é enviado um email do Airdesk associado a uma conversa de email",
  NOTIFICATION_TOPIC_INBOX_NEW_TITLE:
    "Quando é criada uma nova conversa de email",
  NOTIFICATION_TOPIC_INBOX_OPEN_DESCRIPTION:
    "Receba uma notificação cada vez que é criada uma conversa de email",
  NOTIFICATION_TOPIC_INBOX_OPEN_TITLE: "Quando é aberto um email",
  NOTIFICATION_TOPIC_INBOX_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado o estado da conversa de email",
  NOTIFICATION_TOPIC_INBOX_STATUS_CHANGE_TITLE:
    "Quando é alterado o estado da conversa de email",
  NOTIFICATION_TOPIC_INTERVENTIONS_ASSOCIATE_TO_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_INTERVENTIONS_ASSOCIATE_TO_CHANGE_TITLE:
    "Quando a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_INTERVENTIONS_CONTACTS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alteado um contacto",
  NOTIFICATION_TOPIC_INTERVENTIONS_CONTACTS_CHANGE_TITLE:
    "Quando é alterado um contacto",
  NOTIFICATION_TOPIC_INTERVENTIONS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um tempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_DELETED_TITLE: "Quando é removido um tempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que é modificado um tempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_MODIFIED_TITLE:
    "Quando é modificado um tempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criado um tempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é adicionado",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_FOLLOWER_TITLE:
    "Quando um seguidor é adicionado",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que recebe uma nova mensagem",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_MESSAGE_TITLE:
    "Quando recebe uma nova mensagem",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_TITLE: "Quando é criado um tempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é removido",
  NOTIFICATION_TOPIC_INTERVENTIONS_REMOVE_FOLLOWER_TITLE:
    "Quando um seguidor é removido",
  NOTIFICATION_TOPIC_OWNER_ADD_COMPANY_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionada uma empresa",
  NOTIFICATION_TOPIC_OWNER_ADD_COMPANY_TITLE: "Quando é adicionada uma empresa",
  NOTIFICATION_TOPIC_OWNER_ADD_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um departamento",
  NOTIFICATION_TOPIC_OWNER_ADD_DEPARTMENT_TITLE:
    "Quando é adicionado um departamento",
  NOTIFICATION_TOPIC_OWNER_ADD_SOCIAL_MEDIA_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionada uma rede social",
  NOTIFICATION_TOPIC_OWNER_ADD_SOCIAL_MEDIA_TITLE:
    "Quando é adicionada uma rede social",
  NOTIFICATION_TOPIC_OWNER_ADD_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionada uma empresa",
  NOTIFICATION_TOPIC_OWNER_ADD_TEAM_TITLE: "Quando é adicionada uma equipa",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CASES_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador cancela um caso",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CASES_TITLE:
    "Quando um utilizador cancela um caso",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CONTRACT_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador cancela um contrato",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CONTRACT_TITLE:
    "Quando um utilizador cancela um contrato",
  NOTIFICATION_TOPIC_OWNER_CANCELED_DEAL_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador cancela um negócio",
  NOTIFICATION_TOPIC_OWNER_CANCELED_DEAL_TITLE:
    "Quando um utilizador cancela um negócio",
  NOTIFICATION_TOPIC_OWNER_CANCELED_TASK_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador cancela uma tarefa",
  NOTIFICATION_TOPIC_OWNER_CANCELED_TASK_TITLE:
    "Quando um utilizador cancela uma tarefa",
  NOTIFICATION_TOPIC_OWNER_DAY_FOR_PAYMENT_DESCRIPTION:
    "Receba uma notificação cada vez que só faltarem três dias para um pagamento",
  NOTIFICATION_TOPIC_OWNER_DAY_FOR_PAYMENT_TITLE:
    "Quando só faltarem três dias para um pagamento",
  NOTIFICATION_TOPIC_OWNER_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que uma conta é apagada",
  NOTIFICATION_TOPIC_OWNER_DELETED_TITLE: "Quando uma conta é apagada",
  NOTIFICATION_TOPIC_OWNER_DISABLED_ACCOUNT_DESCRIPTION:
    "Receba uma notificação cada vez que uma conta é desativada",
  NOTIFICATION_TOPIC_OWNER_DISABLED_ACCOUNT_TITLE:
    "Quando uma conta é desativada",
  NOTIFICATION_TOPIC_OWNER_EXPIRED_INVITATION_DESCRIPTION:
    "Receba uma notificação cada vez que é expirado um convite",
  NOTIFICATION_TOPIC_OWNER_EXPIRED_INVITATION_TITLE:
    "Quando é expirado um convite",
  NOTIFICATION_TOPIC_OWNER_INVITATION_ACCEPED_DESCRIPTION:
    "Receba uma notificação cada vez que é aceite um convite",
  NOTIFICATION_TOPIC_OWNER_INVITATION_ACCEPED_TITLE:
    "Quando é aceite um convite",
  NOTIFICATION_TOPIC_OWNER_INVITATION_SEND_DESCRIPTION:
    "Receba uma notificação cada vez que é enviado um convite",
  NOTIFICATION_TOPIC_OWNER_INVITATION_SEND_TITLE: "Quando é enviado um convite",
  NOTIFICATION_TOPIC_OWNER_LOWER_DEAL_WIN_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador tem lucros de negócios baixos",
  NOTIFICATION_TOPIC_OWNER_LOWER_DEAL_WIN_TITLE:
    "Quando um utilizador tem lucros de negócios baixos",
  NOTIFICATION_TOPIC_OWNER_MADE_EXPORT_DESCRIPTION:
    "Receba uma notificação cada vez que uma exportação é feita pelo utilizador",
  NOTIFICATION_TOPIC_OWNER_MADE_EXPORT_TITLE:
    "Quando uma exportação é feita pelo utilizador",
  NOTIFICATION_TOPIC_OWNER_MISSING_DAILY_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador tem 8h por dia em falta",
  NOTIFICATION_TOPIC_OWNER_MISSING_DAILY_TITLE:
    "Quando um utilizador tem 8h por dia em falta",
  NOTIFICATION_TOPIC_OWNER_OPEN_CASES_ONE_MONTH_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador tem casos abertos há mais de 1 mês",
  NOTIFICATION_TOPIC_OWNER_OPEN_CASES_ONE_MONTH_TITLE:
    "Quando um utilizador tem casos abertos há mais de 1 mês",
  NOTIFICATION_TOPIC_OWNER_OPEN_DEAL_ONE_MONTH_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador tem um negócio aberto há mais de 1 mês",
  NOTIFICATION_TOPIC_OWNER_OPEN_DEAL_ONE_MONTH_TITLE:
    "Quando um utilizador tem um negócio aberto há mais de 1 mês",
  NOTIFICATION_TOPIC_OWNER_OPEN_TASK_ONE_MONTH_DESCRIPTION:
    "Receba uma notificação cada vez que tem uma tarefa aberta há mais de 1 mês",
  NOTIFICATION_TOPIC_OWNER_OPEN_TASK_ONE_MONTH_TITLE:
    "Quando um utilizador tem uma tarefa aberta há mais de 1 mês",
  NOTIFICATION_TOPIC_OWNER_PAYMENT_SUCCESS_DESCRIPTION:
    "Receba uma notificação cada vez que um pagamento da subscrição é bem-sucedido",
  NOTIFICATION_TOPIC_OWNER_PAYMENT_SUCCESS_TITLE:
    "Quando um pagamento da subscrição é bem-sucedido",
  NOTIFICATION_TOPIC_OWNER_PLAN_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que um plano é alterado",
  NOTIFICATION_TOPIC_OWNER_PLAN_CHANGE_TITLE: "Quando um plano é alterado",
  NOTIFICATION_TOPIC_OWNER_REMOVE_COMPANY_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma empresa",
  NOTIFICATION_TOPIC_OWNER_REMOVE_COMPANY_TITLE:
    "Quando é removida uma empresa",
  NOTIFICATION_TOPIC_OWNER_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um departamento",
  NOTIFICATION_TOPIC_OWNER_REMOVE_DEPARTMENT_TITLE:
    "Quando é removido um departamento",
  NOTIFICATION_TOPIC_OWNER_REMOVE_SOCIAL_MEDIA_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma rede social",
  NOTIFICATION_TOPIC_OWNER_REMOVE_SOCIAL_MEDIA_TITLE:
    "Quando é removida uma rede social",
  NOTIFICATION_TOPIC_OWNER_REMOVE_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma empresa",
  NOTIFICATION_TOPIC_OWNER_REMOVE_TEAM_TITLE: "Quando é removida uma equipa",
  NOTIFICATION_TOPIC_OWNER_TO_MUTCH_DEAL_LOST_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador perdeu diversos negócios",
  NOTIFICATION_TOPIC_OWNER_TO_MUTCH_DEAL_LOST_TITLE:
    "Quando um utilizador perdeu diversos negócios",
  NOTIFICATION_TOPIC_OWNER_TRANSFER_DESCRIPTION:
    "Receba uma notificação cada vez que é transferida a responsabilidade",
  NOTIFICATION_TOPIC_OWNER_TRANSFER_TITLE:
    "Quando é transferida a responsabilidade",
  NOTIFICATION_TOPIC_OWNER_WORKLOAD_TASK_CASES_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador está sobrecarregado com tarefas e casos",
  NOTIFICATION_TOPIC_OWNER_WORKLOAD_TASK_CASES_TITLE:
    "Quando um utilizador está sobrecarregado com tarefas e casos",
  NOTIFICATION_TOPIC_PROJECTS_BEFORE_END_DESCRIPTION:
    "Receba uma notificação cada vez que só faltarem três dias para a data de término do projeto",
  NOTIFICATION_TOPIC_PROJECTS_BEFORE_END_TITLE:
    "Quando só faltarem três dias para a data de término do projeto",
  NOTIFICATION_TOPIC_PROJECTS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um projeto",
  NOTIFICATION_TOPIC_PROJECTS_DELETED_TITLE: "Quando é removido um projeto",
  NOTIFICATION_TOPIC_PROJECTS_END_DESCRIPTION:
    "Receba uma notificação cada vez que um projeto terminar",
  NOTIFICATION_TOPIC_PROJECTS_END_TITLE: "Quando um projeto terminar",
  NOTIFICATION_TOPIC_PROJECTS_ENDED_DESCRIPTION:
    "Receba uma notificação cada vez que um projeto terminar",
  NOTIFICATION_TOPIC_PROJECTS_ENDED_TITLE: "Quando um projeto terminar",
  NOTIFICATION_TOPIC_PROJECTS_EXCEEDED_TIME_DESCRIPTION:
    "Receba uma notificação cada vez que o projeto excede o limite de tempo",
  NOTIFICATION_TOPIC_PROJECTS_EXCEEDED_TIME_TITLE:
    "Quando o projeto excede o limite de tempo",
  NOTIFICATION_TOPIC_PROJECTS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Receba uma notificação cada vez que faltar x tempo para o fim do contrato",
  NOTIFICATION_TOPIC_PROJECTS_LEFT_TO_EXCEEDED_TITLE:
    "Quando faltar x tempo para o fim do contrato",
  NOTIFICATION_TOPIC_PROJECTS_MANAGER_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_PROJECTS_MANAGER_CHANGE_TITLE:
    "Quando a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_PROJECTS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que é modificado um projeto",
  NOTIFICATION_TOPIC_PROJECTS_MODIFIED_TITLE: "Quando é modificado um projeto",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que um departamento é adicionado",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DEPARTMENT_TITLE:
    "Quando um departamento é adicionado",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criado um projeto",
  NOTIFICATION_TOPIC_PROJECTS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é adicionado",
  NOTIFICATION_TOPIC_PROJECTS_NEW_FOLLOWER_TITLE:
    "Quando um seguidor é adicionado",
  NOTIFICATION_TOPIC_PROJECTS_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que recebe uma nova mensagem",
  NOTIFICATION_TOPIC_PROJECTS_NEW_MESSAGE_TITLE:
    "Quando recebe uma nova mensagem",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que uma equipa é adicionada",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TEAM_TITLE: "Quando uma equipa é adicionada",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TITLE: "Quando é criado um projeto",
  NOTIFICATION_TOPIC_PROJECTS_NEW_USER_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é adicionado",
  NOTIFICATION_TOPIC_PROJECTS_NEW_USER_TITLE:
    "Quando um utilizador é adicionado",
  NOTIFICATION_TOPIC_PROJECTS_ORIGINATED_DESCRIPTION:
    "Receba uma notificação cada vez que é originado um projeto",
  NOTIFICATION_TOPIC_PROJECTS_ORIGINATED_TITLE: "Quando é originado um projeto",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que um departamento é removido",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_DEPARTMENT_TITLE:
    "Quando um departamento é removido",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é removido",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_FOLLOWER_TITLE:
    "Quando um seguidor é removido",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que uma equipa é removida",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_TEAM_TITLE: "Quando uma equipa é removida",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_USER_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é removido",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_USER_TITLE:
    "Quando um utilizador é removido",
  NOTIFICATION_TOPIC_PROJECTS_START_DESCRIPTION:
    "Receba uma notificação cada vez que um projeto iniciar",
  NOTIFICATION_TOPIC_PROJECTS_START_TITLE: "Quando um projeto iniciar",
  NOTIFICATION_TOPIC_PROJECTS_STARTED_DESCRIPTION:
    "Receba uma notificação cada vez que um projeto iniciar",
  NOTIFICATION_TOPIC_PROJECTS_STARTED_TITLE: "Quando um projeto iniciar",
  NOTIFICATION_TOPIC_PROJECTS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é modificado o estado de um projeto",
  NOTIFICATION_TOPIC_PROJECTS_STATUS_CHANGE_TITLE:
    "Quando é alterado o estado de um projeto",
  NOTIFICATION_TOPIC_SOCIALMEDIA_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_DELETED_TITLE:
    "Quando é removida uma publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que é modificada uma publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_MODIFIED_TITLE:
    "Quando é modificada uma publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é agendada uma nova publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_NEW_TITLE:
    "Quando é agendada uma nova publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_PUBLISHED_DESCRIPTION:
    "Receba uma notificação cada vez que é publicada uma publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_PUBLISHED_TITLE:
    "Quando é publicada uma publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é modificado o estado de uma publicação",
  NOTIFICATION_TOPIC_SOCIALMEDIA_STATUS_CHANGE_TITLE:
    "Quando é modificado o estado de uma publicação",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_BEFORE_END_DESCRIPTION:
    "Receba uma notificação cada vez que só faltarem três dias para a data de término da subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_BEFORE_END_TITLE:
    "Quando só faltarem três dias para a data de término da subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é apagada uma subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_DELETED_TITLE:
    "Quando é apagada uma subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_END_DESCRIPTION:
    "Receba uma notificação cada vez que uma subscrição é terminada",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_END_TITLE:
    "Quando uma subscrição é terminada",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MANAGER_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MANAGER_CHANGE_TITLE:
    "Quando a pessoa responsável é alterada",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que uma subscrição é modificada",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MODIFIED_TITLE:
    "Quando uma subscrição é modificada",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DEPARTMENT_TITLE:
    "Quando é adicionado um departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criada uma nova subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_FOLLOWER_TITLE:
    "Quando é adicionado um seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que recebe uma nova mensagem",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_MESSAGE_TITLE:
    "Quando recebe uma nova mensagem",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionada uma equipa",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TEAM_TITLE:
    "Quando é adicionada uma equipa",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TITLE:
    "Quando é criada uma nova subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_USER_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um novo utilizador",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_USER_TITLE:
    "Quando é adicionado um novo utilizador",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_DEPARTMENT_TITLE:
    "Quando é removido um departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_FOLLOWER_TITLE:
    "Quando é removido um seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma equipa",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_TEAM_TITLE:
    "Quando é removida uma equipa",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_USER_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um utilizador",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_USER_TITLE:
    "Quando é removido um utilizador",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_RENEWAL_DESCRIPTION:
    "Receba uma notificação cada vez que é renovada uma subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_RENEWAL_TITLE:
    "Quando é renovada uma subscrição",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que o estado de uma subscrição é alterado",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_STATUS_CHANGE_TITLE:
    "Quando o estado de uma subscrição é alterado",
  NOTIFICATION_TOPIC_TASKS_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_TASKS_ASSIGN_TO_CHANGE_TITLE:
    "Quando a alteração é atribuída a um utilizador",
  NOTIFICATION_TOPIC_TASKS_ASSOCIATE_TO_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que a alteração é associada a um utilizador",
  NOTIFICATION_TOPIC_TASKS_ASSOCIATE_TO_CHANGE_TITLE:
    "Quando a alteração é associada a um utilizador",
  NOTIFICATION_TOPIC_TASKS_BEFORE_END_DESCRIPTION:
    "Receba uma notificação cada vez que só faltarem três dias para a data de término da tarefa",
  NOTIFICATION_TOPIC_TASKS_BEFORE_END_TITLE:
    "Quando só faltarem três dias para a data de término da tarefa",
  NOTIFICATION_TOPIC_TASKS_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma tarefa",
  NOTIFICATION_TOPIC_TASKS_DELETED_TITLE: "Quando é removida uma tarefa",
  NOTIFICATION_TOPIC_TASKS_ENDED_DESCRIPTION:
    "Receba uma notificação cada vez que uma tarefa terminar",
  NOTIFICATION_TOPIC_TASKS_ENDED_TITLE: "Quando uma tarefa terminar",
  NOTIFICATION_TOPIC_TASKS_EXCEEDED_TIME_DESCRIPTION:
    "Receba uma notificação cada vez que a tarefa excede o limite de horas",
  NOTIFICATION_TOPIC_TASKS_EXCEEDED_TIME_TITLE:
    "Quando a tarefa excede o limite de horas",
  NOTIFICATION_TOPIC_TASKS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Receba uma notificação cada vez que faltar x tempo para o fim da tarefa",
  NOTIFICATION_TOPIC_TASKS_LEFT_TO_EXCEEDED_TITLE:
    "Quando faltar x tempo para o fim da tarefa",
  NOTIFICATION_TOPIC_TASKS_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que é modificada uma tarefa",
  NOTIFICATION_TOPIC_TASKS_MODIFIED_TITLE: "Quando é modificada uma tarefa",
  NOTIFICATION_TOPIC_TASKS_NEW_DESCRIPTION:
    "Receba uma notificação cada vez que é criada uma tarefa",
  NOTIFICATION_TOPIC_TASKS_NEW_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um seguidor",
  NOTIFICATION_TOPIC_TASKS_NEW_FOLLOWER_TITLE:
    "Quando é adicionado um seguidor",
  NOTIFICATION_TOPIC_TASKS_NEW_MESSAGE_DESCRIPTION:
    "Receba uma notificação cada vez que recebe uma nova mensagem",
  NOTIFICATION_TOPIC_TASKS_NEW_MESSAGE_TITLE: "Quando recebe uma nova mensagem",
  NOTIFICATION_TOPIC_TASKS_NEW_TITLE: "Quando é criada uma tarefa",
  NOTIFICATION_TOPIC_TASKS_ORIGINATED_DESCRIPTION:
    "Receba uma notificação cada vez que é originada uma tarefa",
  NOTIFICATION_TOPIC_TASKS_ORIGINATED_TITLE: "Quando é originada uma tarefa",
  NOTIFICATION_TOPIC_TASKS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um seguidor",
  NOTIFICATION_TOPIC_TASKS_REMOVE_FOLLOWER_TITLE:
    "Quando é removido um seguidor",
  NOTIFICATION_TOPIC_TASKS_STARTED_DESCRIPTION:
    "Receba uma notificação cada vez que uma tarefa iniciar",
  NOTIFICATION_TOPIC_TASKS_STARTED_TITLE: "Quando uma tarefa iniciar",
  NOTIFICATION_TOPIC_TASKS_STATUS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterado um estado de uma tarefa",
  NOTIFICATION_TOPIC_TASKS_STATUS_CHANGE_TITLE:
    "Quando é alterado um estado de uma tarefa",
  NOTIFICATION_TOPIC_USERS_ADD_AS_MANAGER_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é adicionado como responsável",
  NOTIFICATION_TOPIC_USERS_ADD_AS_MANAGER_TITLE:
    "Quando um utilizador é adicionado como responsável",
  NOTIFICATION_TOPIC_USERS_ADD_COMPANY_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é adicionado à empresa",
  NOTIFICATION_TOPIC_USERS_ADD_COMPANY_TITLE:
    "Quando um utilizador é adicionado à empresa",
  NOTIFICATION_TOPIC_USERS_ADD_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionado um departamento",
  NOTIFICATION_TOPIC_USERS_ADD_DEPARTMENT_TITLE:
    "Quando é adicionado um departamento",
  NOTIFICATION_TOPIC_USERS_ADD_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que é adicionada uma equipa",
  NOTIFICATION_TOPIC_USERS_ADD_TEAM_TITLE: "Quando é adicionada uma equipa",
  NOTIFICATION_TOPIC_USERS_BIRTHDAY_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador faz anos",
  NOTIFICATION_TOPIC_USERS_BIRTHDAY_TITLE: "Quando o utilizador faz anos",
  NOTIFICATION_TOPIC_USERS_PASS_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterada a palavra-passe",
  NOTIFICATION_TOPIC_USERS_PASS_CHANGE_TITLE:
    "Quando é alterada a palavra-passe",
  NOTIFICATION_TOPIC_USERS_REMOVE_AS_MANAGER_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é removido como responsável",
  NOTIFICATION_TOPIC_USERS_REMOVE_AS_MANAGER_TITLE:
    "Quando um utilizador é removido como responsável",
  NOTIFICATION_TOPIC_USERS_REMOVE_COMPANY_DESCRIPTION:
    "Receba uma notificação cada vez que um utilizador é removido da empresa",
  NOTIFICATION_TOPIC_USERS_REMOVE_COMPANY_TITLE:
    "Quando um utilizador é removido da empresa",
  NOTIFICATION_TOPIC_USERS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um departamento",
  NOTIFICATION_TOPIC_USERS_REMOVE_DEPARTMENT_TITLE:
    "Quando é removido um departamento",
  NOTIFICATION_TOPIC_USERS_REMOVE_TEAM_DESCRIPTION:
    "Receba uma notificação cada vez que é removida uma equipa",
  NOTIFICATION_TOPIC_USERS_REMOVE_TEAM_TITLE: "Quando é removida uma equipa",
  NOTIFICATION_TOPIC_USERS_ROLE_CHANGE_DESCRIPTION:
    "Receba uma notificação cada vez que é alterada a função do utilizador",
  NOTIFICATION_TOPIC_USERS_ROLE_CHANGE_TITLE:
    "Quando é alterada a função do utilizador",
  NOTIFICATIONS: "Notificações",
  NOTIFICATIONS_CONF: "Deseja ativar notificações neste dispositivo?",
  NOTIFICATIONS_READ: "Lidas",
  NOTIFICATIONS_UNREAD: "Não lidas",
  NOVEMBER: "Novembro",
  NR_BILLING: "Nº faturação",
  NR_ITEMS: "Numero de itens",
  NR_SALE_OPPORTUNITY: "Nº oportunidade venda",
  NUMBERED_LIST: "Lista numerada",
  OCTOBER: "Outubro",
  OF: "de",
  OIL_OR_ENERGY_OR_SOLAR_OR_GREENTECH: "Petróleo / Energia / Solar / Greentech",
  OLD_PASSWORD: "Password atual",
  ON_SITE: "On site",
  ONCE: "Apenas uma vez",
  ONGOING_PROJECTS: "Projetos a decorrer",
  ONLINE_PUBLISHING: "Publicação online",
  ONLY_1_LINK_PER_SOCIAL_NETWORK: "Apenas 1 link por rede social",
  ONLY_MAX_FIVE_EMAILS:
    "Máximo de 5 convides nesta fase da inscrição, caso queira adicionar mais utilizadores consulte:",
  ONLY_MAX_FIVE_EMAILS_PART_TWO: "configurações -> utilizadores -> convites",
  ONLY_ME: "Eu",
  OPEN: "Aberto",
  OPEN_ACCOUNTS: "Casos abertos",
  OPEN_CASES: "Casos abertos",
  OPEN_CONTRACTS: "Contratos abertos",
  OPEN_CONTRACTS_PROGRESS: "Evolução contratos abertos",
  OPEN_DEAL: "Negócios abertos",
  OPEN_DEALS: "Negócios abertos",
  OPEN_DEALS_AMOUNTS: "Negócios abertos montantes",
  OPEN_IN_APP: "Abrir na aplicação",
  OPEN_PROJECT_ATRIBUTED: "Equipa - projetos abertos atribuídos",
  OPEN_PROJECTS: "Projetos abertos",
  OPEN_TASKS: "Tarefas em aberto",
  OPEN_TASKS_PROGRESS: "Tempo tarefas abertas em resolução",
  OPENIN_SCHEDULE: "Horário de agendamento",
  OPENING_HOURS: "Horário de atendimento",
  OPERATION: "Operador",
  OPERATIONS_DEPARTMENT: "Operação",
  OPERATOR: "Operador",
  OPTIONAL: "Opcional",
  OPTIONAL_INBOX_EMAIL: "Email customizado (opcional)",
  OR: "ou",
  ORDER: "Ordenação",
  ORDER_BY: "Ordenar por",
  ORDERING: "Ordenação",
  ORGANIZATION_NAME: "Nome da empresa",
  ORGANIZATION_NAME_INFO_DESC: "O nome da sua empresa",
  ORGANIZATION_REQUEST_GENERAL_ERROR:
    "Houve um erro ao processar o seu pedido, por favor tente de novo mais tarde.",
  ORGANIZATION_REQUEST_NO_DATA:
    "Para prosseguir é necessário adicionar uma empresa.",
  ORGANIZATION_REQUEST_NO_DATA_NO_PERMISSION:
    "É necessário estar numa empresa para prosseguir. Por favor, contacte o administrador.",
  ORGANIZATION_REQUEST_NO_PERMISSION:
    "São necessárias permissões para efetuar este pedido",
  ORGANIZATION_SAVE_SUCCESS: "Organização gravada com sucesso",
  ORGANIZATIONS_SHORT_NAME: "Nome curto da empresa",
  ORIGIN: "Origem",
  ORIGIN_AND_STATUS: "Origem e estado",
  ORIGIN_IS: "Origem é",
  ORIGINATED: "Originou",
  OTHER_INDUSTRY: "Outra indústria",
  OUT_OF_CONTRACT_HOURS_ABRV: "T. fora do contrato",
  OUTSIDE_CONTRACT_HOURS: "Tempo fora do contrato",
  OUTSOURCING_OR_OFFSHORING: "Terceirização / Offshoring",
  OVER: "Até",
  OVERALL_TASK_PERFORMANCE: "Performance global de tarefas",
  OVERALL_TICKET_PERFORMANCE: "Performance global de casos",
  OVERLAP_RULE: "Regra de sobreposição",
  OWNER: "Proprietário",
  PACKAGE_OR_FREIGHT_DELIVERY: "Entrega de pacote / frete",
  PACKAGING_OR_CONTAINERS: "Embalagem / recipientes",
  PAGE: "Folha",
  PAPER_OR_FOREST_PRODUCTS: "Papel / Produtos florestais",
  PARAMETERS: "Parâmetros",
  PASSWORD: "Password",
  PASSWORD_CHANGE: "Alteração da password",
  PASSWORD_CHANGED_SUCCESS: "Password alterada com sucesso",
  PASSWORD_STRENGH_MODERATE: "Password é moderada",
  PASSWORD_STRENGH_STRONG: "Password é forte",
  PASSWORD_STRENGH_VERY_STRONG: "Password é muito forte",
  PASSWORD_STRENGH_WEAK:
    "Sua senha não é forte o suficiente. Sua senha deve ter pelo menos 8 caracteres, uma maiúscula e uma minúscula.",
  PASSWORDS_DONT_MATCH: "As passwords não são iguais",
  PAUSES: "Pausas",
  PAY_AND_UPGRADE: "Pagar e efetuar upgrade",
  PAY_BY_CREDIT_CARD: "Pagar com cartão de crédito",
  PAYMENT_DETAILS: "Detalhes de pagamento",
  PAYMENT_METHOD: "Método de pagamento",
  PAYMENT_METHODS: "Métodos de pagamento",
  PAYMENT_PENDING_DESCRIPTION:
    "Por favor, efetue o pagamento novamente ou cancele a subscrição.",
  PAYMENT_PENDING_TITLE: "O seu pagamento não foi efetuado",
  PAYMENT_SUMARY: "Resumo do pedido",
  PAYMENT_TOTAL: "Total a pagar",
  PAYMENTS_BILLED_MONTHLY_MESSAGE_FIRST_HALF:
    "O valor apresentado é uma simulação com os dados atuais do seu espaço e só será cobrado a partir de",
  PAYMENTS_BILLED_MONTHLY_MESSAGE_SECOND_HALF:
    "O valor poderá ser diferente caso o número de utilizadores e/ou armazenamento seja alterado.",
  PENDING: "Pendente",
  PER_PAGE: "Por página",
  PERCENTAGE: "Percentagem",
  PERCENTAGE_HOURS: "Percentagem de tempo consumido ",
  PERCENTAGE_PERFORMANCE: "Percentagem de performance",
  PERFORMANCE: "Performance",
  PERFORMANCE_ACCOUNT: "Performance de casos",
  PERFORMING_ARTS: "Artes performáticas",
  PERIOD_TOTAL: "Total no período",
  PERSONAL: "Pessoal",
  PERSONAL_DATA: "Dados pessoais",
  PERSONALIZE_BLOCKS: "Personalizar blocos",
  PERSONALIZED: "Personalizado",
  PHARMACEUTICALS: "Farmacêutica",
  PHILANTHROPY: "Filantropia",
  PHONE_CODES: "Alterar indicativos telefone",
  PHONE_NUMBER: "Nº telefone",
  PHONE_NUMBER_PREFIX: "Extensão de telefone",
  PHOTOGRAPHY: "Fotografia",
  PICK_THE_MOST_IMPORTANT_TOPICS:
    "Escolha os tópicos mais importantes e como receber notificações.",
  PIN: "Pin",
  PINNED: "Pinned",
  PIPE_STATUS: "Pipe. Estado",
  PIPELINE: "Pipeline",
  PIPELINE_DELETE_MSG: "Deseja eliminar este pipeline?",
  PIPELINE_ERROR: "Erro de pipeline",
  PIPELINE_ERROR_DESC:
    "A pipeline selecionada pode não existir ou estar mal configurada",
  PIPELINE_STATUS: "Estado Pipeline",
  PLAN: "Plano",
  PLAN_ADMINISTRATION: "Administração",
  PLAN_ADVANCED: "Filtros avançados",
  PLAN_ADVANCED_PERMISSION: "Permissões avançadas",
  PLAN_ANNUALLY: "Anualmente",
  PLAN_BASIC: "Pro",
  PLAN_BULK: "Exportação em massa de PDF’s",
  PLAN_BUSINESS: "Empresas",
  PLAN_BUSINESS_CONTRACTS_SUBSCRIPTIONS: "Negócios, contratos e subscrições",
  PLAN_CALLS: "Chamadas e casos",
  PLAN_COMMENTS: "Comentários",
  PLAN_COMPANY: "Enterprise",
  PLAN_CONTACTS: "Contactos e clientes",
  PLAN_DASHBOARDS: "Dashboards",
  PLAN_DEDICATED: "Gestor dedicado",
  PLAN_DEPARTMENTS: "Departamentos e equipas",
  PLAN_EARLY: "Acesso antecipado a novas funcionalidades",
  PLAN_EXPORT: "Exportação",
  PLAN_EXPORT_CONTROL: "Controlo de exportações",
  PLAN_EXTRA_USER: "Utilizador extra",
  PLAN_FORMATION: "Formação (horas)",
  PLAN_FUNCTIONALITIES: "Funcionalidades",
  PLAN_GEOLOCATION: "Geo-localização e geo-referenciação",
  PLAN_GEOLOCATION_SM: "Geo-localização",
  PLAN_MANAGEMENT: "Painel de gestão",
  PLAN_MANDATORY: "Obrigatório",
  PLAN_MOST_POPULAR: "Mais popular",
  PLAN_NOTIFICATIONS: "Notificações por email",
  PLAN_OF_SPACE: "de espaço",
  PLAN_ONBOARDING: "Onboarding / Preço instalação",
  PLAN_PRICE: "Preço",
  PLAN_PRICE_GIGA: "Preço por GB(s) extra",
  PLAN_PRICING: "Preço e faturação à medida",
  PLAN_PRIORITY: "Suporte prioritário",
  PLAN_PRO: "Business",
  PLAN_PROJECTS: "Projetos e tarefas",
  PLAN_REGISTRATION: "Registo de Tempo",
  PLAN_RENOVATION_DATE: "Data de renovação da assinatura",
  PLAN_RENOVATION_PAYMENT_AMOUNT: "Quanto será cobrado",
  PLAN_REPORTING: "Reporting",
  PLAN_RULE_APPLICATION: "Regra de aplicação (planos)",
  PLAN_SPACE: "Limite de espaço (GB)",
  PLAN_STARTER: "Start",
  PLAN_SUPPORT: "Suporte",
  PLAN_TERM: "Termo",
  PLAN_UPLOAD: "Upload de ficheiros",
  PLAN_USE: "Utilização",
  PLAN_USERS_LIMIT: "Limite de utilizadores",
  PLAN_VERSION: "Histórico de versões",
  PLAN_WEB: "Web e mobile apps",
  PLANS: "Planos",
  PLASTICS: "Plásticos",
  PLEASE_CONTACT_YOUR_ADMIN: "Por favor, contacte o administrador",
  PLEASE_RECONECT: "Reconecte-se por favor",
  POLITICAL_ORGANIZATION: "Organização política",
  POST_PAYMENT: "Pós-pagamento",
  POSTAL_CODE: "Codigo postal",
  POTENTIALLY_DANGEROUS: "Potencialmente perigoso",
  PREVIEW: "Pré-visualizar",
  PREVIEW_TEST_RESULTS: "Resultados do teste",
  PREVIOUS: "Anterior",
  PREVIOUS_BILLING_PLAN: "O seu plano anterior era",
  PRIMARY_DOMAIN: "Domínio principal",
  PRIMARY_OR_SECONDARY_EDUCATION: "Educação primária / secundária",
  PRINTING: "Impressão",
  PRIORITY: "Prioridade",
  PROCESSING: "Em processamento",
  PRODUCT: "Produto",
  PROFESSIONAL_TRAINING: "Treinamento profissional",
  PROFILE: "Perfil",
  PROGRAM_DEVELOPMENT: "Desenvolvimento do programa",
  PROGRAMMING: "Programação",
  PROGRESS_CLOSED_TASKS: "Evolução da média de tarefas fechados",
  PROGRESS_OPEN_PROJECTS: "Evolução projetos abertos",
  PROJECT: "Projeto",
  PROJECT_ASSIGNED: "atribuiu-lhe o projeto",
  PROJECT_CREATION: "Criação de projeto",
  PROJECT_DELETED: "apagou o projeto",
  PROJECT_DESCRIPTION:
    "Tenha uma visão global do desenvolvimento dos projetos da sua empresa.",
  PROJECT_IS: "Projeto é",
  PROJECT_NR: "Nº de projeto",
  PROJECT_OVERIMPOSED_FOUND: "Existe sobreposição de datas de projetos.",
  PROJECT_TYPE: "Tipo de projeto",
  PROJECT_TYPE_EQUALS: "Tipo de projeto é igual a",
  PROJECT_TYPES: "Tipos de projeto",
  PROJECT_UPDATE: "atualizou o projeto",
  PROJECTS: "Projetos",
  PROJECTS_ACTIVITY: "Projetos - atividade",
  PROJECTS_ALMOST_EXCEEDED: "Projetos - tempo quase excedido",
  PROJECTS_BUDGET_RISK: "Projetos - budget Risk",
  PROJECTS_CHANGED: "projetos alteradas",
  PROJECTS_CLOSED: "Projetos - fechados",
  PROJECTS_DEADLINE_RISK: "Projetos - deadline Risk",
  PROJECTS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar projetos",
  PROJECTS_DELETE_DELAY_TITLE: "Eliminação de projetos",
  PROJECTS_DELETE_DESCRIPTION: "A eliminar projetos",
  PROJECTS_DELETE_TITLE: "Eliminação de projetos",
  PROJECTS_EXCEEDED_HOURS: "Projetos - tempo excedido",
  PROJECTS_FAILED_CHANGED: "falhou ao alterar",
  PROJECTS_INACTIVE: "Projetos - inativos",
  PROJECTS_NEAR_DEADLINE: "Projetos - quase fora do prazo de entrega",
  PROJECTS_NOT_CHANGED: "projetos inalterados",
  PROJECTS_OPEN_EVOLUTION: "Evolução de abertura de projetos",
  PROJECTS_REPORT: "Relatório projetos",
  PROJECTS_STATUS: "Estados projetos",
  PROJECTS_WORKLOAD: "Projetos - workload",
  PROMOTIONAL_CODE: "Código promocional",
  PROPERTY: "Propriedade",
  PROPERTY_SETTER: "Property setter",
  PROTOCOL: "Protocolo",
  PUBLIC: "Público",
  PUBLIC_RELATIONS_OR_PR: "Relações públicas / RP",
  PUBLIC_SAFETY: "Segurança pública",
  PUBLICATION_DATE: "Data da publicação",
  PUBLICATION_NR: "Nº publicação",
  PUBLICATION_REMOVE_MSG: "Deseja remover esta publicação?",
  PUBLICATIONS: "Publicações",
  PUBLICATIONS_SCHEDULE: "Horário de publicações",
  PUBLISH_FROM: "Publicar de",
  PUBLISH_NOW: "Publicar agora",
  PUBLISHED: "Publicado",
  PUBLISHING: "Publicando",
  PUBLISHING_DATE: "Data de publicação",
  PUBLISHING_FAIL: "Falha publicação",
  PUBLISHING_INDUSTRY: "Indústria editorial",
  PUBLISHING_PERMISSION: "Permissão de publicação",
  PURCHASES_DEPARTMENT: "Compras",
  QUALITY: "Qualidade",
  QUANTITY: "Quantidade",
  QUARTER: "Quarto",
  QUERY_PARAMETERS: "Query parameters",
  QUEUE_LIST_EMPTY: "Lista de filas vazias",
  QUICK_ACTIONS: "Ações rápidas",
  QUOTE_BLOCK: "Bloco de citação",
  RAILROAD_MANUFACTURE: "Fabricação de ferrovias",
  RANCHING: "Ranching",
  RATING: "Rating",
  READ: "Lido",
  REAL_ESTATE_OR_MORTGAGE: "Imóveis / hipotecas",
  REALTIME_DISCONNECTED: "Foi desconectado das funcionalidades em tempo real.",
  REASON_INEFFICIENCY: "Motivo de tempo não imputável",
  REASON_TEXT: "Motivo: {reason}",
  REASONS_LOSS: "Motivos de perda",
  RECEIVE_CALLS: "Chamadas recebidas",
  RECEPTION: "Inbox",
  RECEPTION_ASSIGNED_TO: "Assignado a",
  RECEPTION_SIGNATURE: "Primeira enviada",
  RECONNECT: "Reconectar",
  RECONNECTING: "A reconectar...",
  RECORD: "Registo",
  RECREATIONAL_FACILITIES_OR_SERVICES: "Instalações / serviços recreativos",
  RECURRENCE_CONFIGURATION: "Configuração de recorrência",
  RECURRENCY_DAYS: "dia(s)",
  RECURRENCY_EVERY: "Cada:",
  RECURRENCY_LIMIT_EXCEEDED:
    "Número máximo de tarefas por recorrência excedido, a criação será ajustada para as 100 primeiras tarefas nos intervalos de datas pretendidos.",
  RECURRENCY_MONTH_WARNING:
    "Há meses que têm menos de {day} dias, nesses casos a tarefa será criada no último dia do mês.",
  RECURRENCY_MONTHS: "mês(s)",
  RECURRENCY_ON: "Em:",
  RECURRENCY_ORIGIN_DATE_WARNING:
    "Confirme as datas das tarefas que vão ser criadas.",
  RECURRENCY_WEEKS: "semana(s)",
  RECURRENCY_WILL_CREATE: "Serão criadas <b>{count}</b> tarefas recorrentes:",
  RECURRENCY_YEARS: "ano(s)",
  RECURRENT_TASKS_CREATION: "A criar tarefas recorrentes",
  RECURRENT_TASKS_CREATION_DELAY_DESCRIPTION:
    "A preparar para criar tarefas recorrentes",
  RECURRENT_TASKS_CREATION_DELAY_TITLE: "Tarefas recorrentes",
  RECURRENT_TASKS_CREATION_DESCRIPTION: "A criar tarefas recorrentes",
  RECURRENT_TASKS_CREATION_SUCCESS: "Tarefas recorrentes criadas com sucesso",
  RECURRENT_TASKS_CREATION_TITLE: "Tarefas recorrentes",
  REDEEM_RULES: "Regras de resgate",
  REDEEMS: "Resgates",
  REDO: "Refazer",
  REFERENCE: "Referência",
  REFRESH: "Refresh",
  REGION_STATE_DISCTRICT: "Região / Estado / Distrito",
  REGISTER_DESCRIPTION:
    "Para criar uma nova conta preencha e os dados necessários",
  REGISTER_EMAIL_EXISTS: "Este email já está em uso",
  REGISTER_ERROR_ALREADY_ACCEPTED: "Convite já foi aceite",
  REGISTER_ERROR_EMAIL_EXISTS: "Este email já existe",
  REGISTER_ERROR_EMAIL_MISMATCH: "Email não coincide com email do convite",
  REGISTER_ERROR_INVITE_NOT_FOUND: "Convite não foi encontrado",
  REGISTER_ERROR_MEMBERSHIP_ERROR: "Membership error",
  REGISTER_ERROR_NOT_VALID:
    "Os dados fornecidos não são válidos. Certifique-se de fornecer um nome, senha e email válidos",
  REGISTER_ERROR_RESERVED_NAME: "Nome é reservado",
  REGISTER_INVALID_EMAIL: "Por favor insira um email válido",
  REGISTER_INVALID_EMAIL_OR_DUPLICATED:
    "Por favor, insira um email válido e não duplicado.",
  REGISTER_INVALID_NAME: "Por favor, insira um nome válido",
  REGISTER_INVALID_PASSWORD: "Por favor, insira uma password válida",
  REGISTER_UNKNOWN_ERROR: "Não conseguimos finalizar o seu registo",
  REGISTER_WELCOME: "Bem-vindo, crie uma nova conta",
  REGISTRATION: "Registo",
  REJECTED: "Não aprovado",
  RELATED_ATTACHMENTS: "Anexos relacionados",
  RELIGIOUS_INSTITUTIONS: "Instituições religiosas",
  REMOTE: "Remoto",
  REMOVAL: "Remoção",
  REMOVE: "Remover",
  REMOVE_ALL: "Remover tudo",
  REMOVE_ASSOCIATED_CARD_DESCRIPTION:
    "Ao remover o cartão associado, a subscrição não será renovada automaticamente e será cancelada ao fim de três dias após o próximo período de faturação.",
  REMOVE_ASSOCIATED_CARD_TITLE: "Remover cartão associado",
  REMOVE_FORMAT: "Remover formato",
  REMOVE_LINK: "Remover link",
  REMOVE_TABLE: "Apagar tabela",
  RENAME: "Alterar nome",
  RENEWABLES_OR_ENVIRONMENT: "Renováveis ​​/ Meio Ambiente",
  RENEWAL: "Renovação",
  REORDER_VIEWS: "Ordenar vistas",
  REPEAT_ON_FAIL: "Reaparecer em caso de falha",
  REPEAT_ON_FAIL_COUNT: "Número mximo de repetiçõesá",
  REPEATING: "Múltiplas vezes",
  REPEATS: "Repete-se",
  REPLACE_CARD: "Substituir cartão",
  REPLY: "Responder",
  REPORT: "Relatório",
  REPORT_ACCOUNT: "Relatório para cliente",
  REPORT_CASES: "Relatório casos",
  REPORT_CLIENT: "Relatório para cliente",
  REPORTING: "Relatórios",
  REPORTS: "Relatórios",
  REPORTS_TIMES: "Relatório tempos",
  REPUBLISH: "Republicar",
  REQUEST_BODY: "Body do pedido",
  REQUEST_HELP: "Pedir suporte",
  REQUEST_TYPE: "Tipo de pedido",
  RESEARCH_INDUSTRY: "Indústria de pesquisa",
  RESET_EMAIL_ERROR: "Tivemos um erro ao tentar fazer reset da sua password.",
  RESET_EMAIL_ERROR_TRY_AGAIN: "Por favor, tente novamente dentro de momentos.",
  RESET_EMAIL_INSERT_NEW_PASSWORD: "Por favor, insira a sua nova senha.",
  RESET_EMAIL_INVALID_URL: "Este endereço já não é válido.",
  RESET_EMAIL_SENT_DESCRIPTION:
    "Siga as instruções no email para finalizar o reset da sua password",
  RESET_EMAIL_SENT_TITLE: "Enviámos um email para <b>{email}</b>",
  RESET_PASSWORD: "Redefina a sua password",
  RESOLVED: "Resolvidos",
  RESOURCE: "Recurso",
  RESPONSABILITY: "Responsável",
  RESPONSE_BODY: "Body de resposta",
  RESPONSE_CODE: "Código de resposta",
  RESPONSE_FORMAT: "Formato da resposta",
  RESPONSIBLE_DEAL: "Responsável de negócio",
  RESPONSIBLE_DEAL_2: "Responsável negócios",
  RESTAURANTS: "Restaurantes",
  RESTORE_ALL: "Restaurar tudo",
  RESTORE_CARDS: "Restaurar cartões",
  RESTORE_COLUMNS: "Restaurar colunas",
  RESTORE_KANBAN: "Restaurar kanban",
  RESTORE_MODULES: "Restaurar módulos",
  RESTORE_PIPELINE_STATUS: "Restaurar estados de pipeline",
  RESTORE_STATES: "Restaurar estados",
  RETAIL_INDUSTRY: "Indústria de varejo",
  RETRY_PAYMENT: "Efetuar novamente",
  RETURN: "Voltar",
  RETURN_PATH_RECORD: "Caminho de retorno",
  ROADMAP: "Roadmap",
  ROLE: "Cargo",
  ROUNDING_MARGIN: "Margem de arredondamento (min)",
  ROUNDING_MARGIN_ABV: "Margem arredondamento",
  SALES: "Vendas",
  SALES_DEALS: "Negócios vendas",
  SALESPERSON: "Comercial",
  SATURDAY: "Sábado",
  SAVE: "Guardar",
  SAVE_SUCCESS: "Gravada com sucesso",
  SAVE_VIEW: "Guardar vista",
  SCHEDULE: "Horário",
  SCHEDULE_TASKS: "Tarefas agendadas",
  SCHEDULED: "Agendado",
  SCOPE: "Âmbito",
  SCORE: "Pontuação",
  SCREEN_RECORD_TOOLTIP: "Gravador de ecrã",
  SCRIPT: "Scripts",
  SEARCH: "Pesquisar",
  SEARCH_ASSOCIATION: "Pesquisar associação",
  SEARCH_CASES_CALLS: "Procure casos, chamadas, etc ...",
  SEARCH_CLIENT_NAME: "Pesquisar nome de conta, numero de conta, etc ..",
  SEARCH_FUNCTIONALITIES: "Procurar propriedade",
  SEARCH_GIFS: "Pesquisar GIFs",
  SEARCH_INBOX_PLACEHOLDER: "Procurar em Inbox",
  SEARCH_MODULES: "Procurar módulos",
  SEARCH_PIPELINE_STATUS: "Procurar estados de pipeline",
  SEARCHING: "A pesquisar...",
  SECOND: "Segundo",
  SECURITY: "Segurança",
  SECURITY_OR_INVESTIGATIONS: "Segurança / Investigações",
  SEE_AS: "Ver como",
  SEE_CHANGES: "Ver alterações",
  SEE_ORIGINAL: "Ver original",
  SELECT_A_PROPERTY: "Escolha uma propriedade",
  SELECT_ALL: "Selecionar todos",
  SELECT_LANGUAGE: "Selecione um Idioma",
  SELECT_LEGAL_BASIS: "Selecionar base",
  SELECT_NEW_PLAN_AFTER_TRIAL: "Selecione um plano para sua nova subscrição",
  SELECT_ONE_PIPELINE: "Por favor, selecione uma pipeline",
  SELECT_PAYMENT_METHOD: "Selecione o seu método de pagamento",
  SELECT_PRIMARY: "Selecionar primário",
  SELECT_PRIMARY_CONTACT: "Selecione o contato primário",
  SELECT_PRIMARY_CONTACT_DESCRIPTION:
    "Por favor, defina o contato principal a ser utilizado. Esta ação é irreversível, todos os registos serão associados ao contato principal e os demais serão apagados permanentemente.",
  SELECT_PROPERTY: "Selecionar propriedade",
  SELECT_SCHEDULE: "Selecionar um horário",
  SELECT_SOCIAL_NETWORK: "Selecione uma rede social para conectar",
  SELECT_STATUS: "Selecionar estado",
  SELECT_TEST_TARGET: "Faça um teste",
  SELECT_WHAT_IMPORT: "Selecione o que quer importar",
  SELECTED_COLUMNS: "Colunas selecionadas",
  SELECTED_LANGUAGE: "Idioma selecionado",
  SELECTED_MODULES: "Modules selecionados",
  SELECTED_ORIGIN: "Origem selecionada:",
  SEMICONDUCTORS: "Semicondutores",
  SEND: "Enviar",
  SEND_EMAIL: "Enviar email",
  SEND_INVITES: "Enviar convites",
  SEND_INVITES_DESC:
    "Seu espaço de trabalho do Airdesk está pronto para ser usado. Conhece alguns amigos ou colegas de trabalho que gostariam de explorar o Airdesk com você?",
  SEND_INVITES_FORM_LABEL: "Endereço de correio eletrónico",
  SEND_MAIL: "Enviar email",
  SEND_NOTIFICATION: "Notificar contacto",
  SENDING_DATE: "Data a enviar",
  SENT_BY: "Enviado por",
  SENT_DATE: "Data de envio",
  SENT_EMAIL: "Enviou o email",
  SENT_STATUS: "Status da mensagem",
  SEPERATOR: "Separador",
  SEPTEMBER: "Setembro",
  SERVICE: "Serviço a criar",
  SERVICES: "Serviços",
  SESSION_ENDED: "Sessão terminada",
  SESSIONS_CLOSED_SUCCESS: "Sessões terminadas com sucesso",
  SET_NEW_PASSWORD: "Definir nova password",
  SETTINGS: "Configurações",
  SETTINGS_ACCESS: "Acesso a configurações",
  SETUP: "Configurar",
  SHARE: "Publicar",
  SHARE_BUTTON_ALT: "Partilhar",
  SHARE_ERROR_DESCRIPTION: "Houve um erro durante a partilha",
  SHARE_ERROR_TITLE: "Erro de partilha",
  SHARE_SUCCESS_DESCRIPTION: "Ação efetuada com sucesso",
  SHARE_SUCCESS_DESCRIPTION_ALT:
    "Ação efetuada foi finalizada. {total} concluídas, {errors} falharam.",
  SHARING_API_KEY: "A chave da API {id} está a ser partilhada",
  SHARING_API_KEY_SUCCESS_DESCRIPTION:
    "Ação efetuada foi finalizada. {total} concluídas, {errors} falharam.",
  SHARING_API_KEY_SUCCESS_TITLE: "A chave da API {id} foi partilhada",
  SHARING_AUTOMATION_DESCRIPTION: "O workflow  {id} está a ser partilhado",
  SHARING_AUTOMATION_SUCCESS_TITLE: "O workflow  {id} foi partilhado",
  SHARING_AUTOMATION_TITLE: "O workflow  {id} está a ser partilhado",
  SHARING_BILLING_SUCCESS_TITLE: "Classificação {id} foi partilhada",
  SHARING_BILLING_TITLE: "Classificação {id} está a ser partilhada",
  SHARING_CALL_SUCCESS_TITLE: "A chamada {id} foi partilhada",
  SHARING_CALL_TITLE: "A chamada {id} está a ser partilhada",
  SHARING_CLIENT_SUCCESS_TITLE: "O cliente {id} foi partilhado",
  SHARING_CLIENT_TITLE: "O cliente {id} está a ser partilhado",
  SHARING_COMPANY_SUCCESS_TITLE: "Empresa {id} foi partilhada",
  SHARING_COMPANY_TITLE: "Empresa {id} está a ser partilhada",
  SHARING_CONTACT_SUCCESS_TITLE: "O contacto {id} foi partilhado",
  SHARING_CONTACT_TITLE: "O contacto {id} está a ser partilhado",
  SHARING_CONTRACT_SUCCESS_TITLE: "O contrato {id} foi partilhado",
  SHARING_CONTRACT_TITLE: "O contrato {id} está a ser partilhado",
  SHARING_CONVERSATION: "A conversa está a ser partilhada",
  SHARING_CONVERSATION_SUCCESS_TITLE: "A conversa foi partilhada",
  SHARING_DEAL_SUCCESS_TITLE: "O negócio {id} foi partilhado",
  SHARING_DEAL_TITLE: "O negócio {id} está a ser partilhado",
  SHARING_DEPARTMENT_SUCCESS_TITLE: "O departamento {id} foi partilhado",
  SHARING_DEPARTMENT_TITLE: "O departamento {id} está a ser partilhado",
  SHARING_DESCRIPTION: "A ação efetuada está a ser processada",
  SHARING_EMAIL_SIGNATURE_SUCCESS_TITLE:
    "Assinatura de email {id} foi partilhada",
  SHARING_EMAIL_SIGNATURE_TITLE:
    "Assinatura de email {id} está a ser partilhada",
  SHARING_PROJECT_SUCCESS_TITLE: "O projeto {id} foi partilhado",
  SHARING_PROJECT_TITLE: "O projeto {id} está a ser partilhado",
  SHARING_SOCIAL_POST_SUCCESS_TITLE: "Publicação {id} foi partilhada",
  SHARING_SOCIAL_POST_TITLE: "Publicação {id} está a ser partilhada",
  SHARING_SUBSCRIPTION_SUCCESS_TITLE: "A subscrição {id} foi partilhada",
  SHARING_SUBSCRIPTION_TITLE: "A subscrição {id} está a ser partilhada",
  SHARING_TASK_SUCCESS_TITLE: "A tarefa {id} foi partilhada",
  SHARING_TASK_TITLE: "A tarefa {id} está a ser partilhada",
  SHARING_TEAM_SUCCESS_TITLE: "Equipa {id} foi partilhado",
  SHARING_TEAM_TITLE: "Equipa {id} está a ser partilhado",
  SHARING_TICKET_SUCCESS_TITLE: "O caso {id} foi partilhado",
  SHARING_TICKET_TITLE: "O caso {id} está a ser partilhado",
  SHARING_TIMECHARGE_SUCCESS_TITLE: "O registo de tempo {id} foi partilhado",
  SHARING_TIMECHARGE_TITLE: "O registo de tempo {id} está a ser partilhado",
  SHIPBUILDING: "Construção naval",
  SHORT_NAME: "Nome curto",
  SHORT_NAME_DES: "nomecurto",
  SHORT_NAME_INFORMATION:
    "O nome curto é necessário para a criação de emails de receção. Exemplo de email:",
  SHOW_ALL: "Mostrar todos",
  SHOW_LESS: "Ver menos",
  SHOW_MORE: "Ver mais",
  SHOW_NOTES: "Ver notas",
  SIDEBAR: "Sidebar",
  SIGN_IN: "Entrar",
  SIGNATURE: "Assinatura",
  SIGNATURE_BANNER: "Banner",
  SIGNATURE_LOGO: "Logotipo",
  SIMULATION: "Simulação",
  SMALL_SCREEN_SUBTITLE:
    "Por favor, aumente a janela do Bowser ou para dispositivos móveis descarregue/abra a aplicação para android.",
  SMALL_SCREEN_TITLE: "O seu ecrã é muito pequeno para trabalhar com o Airdesk",
  SNIPPET: "Snippet",
  SNIPPET_DESCRIPTION_AFTER:
    "Tag da página inicial para conformidade e recolha de detalhes.",
  SNIPPET_DESCRIPTION_BEFORE: "Copie e cole o snippet antes da",
  SNIPPET_TITLE: "Código do snippet",
  SOCIAL_ACCOUNTS_REQUEST_NO_DATA:
    "Para prosseguir é necessário adicionar uma conta de rede social.",
  SOCIAL_ACCOUNTS_REQUEST_NO_DATA_NO_PERMISSION:
    "É necessário ter uma conta de rede social para prosseguir. Por favor, contacte o administrador.",
  SOCIAL_AUTHENTICATION: "Social - autenticação",
  SOCIAL_MEDIA: "Redes sociais",
  SOCIAL_MEDIA_ACCOUNTS: "Contas rede social",
  SOCIALMEDIA: "Redes sociais",
  SORT_YOUR_VIEWS: "Ordene as suas vistas criadas",
  SOUND_AND_APEARANCE: "Som e aparência",
  SPACE: "Espaço",
  SPACE_ENTERED: "entrou no espaço",
  SPACE_OPEN: "Abrir",
  SPACE_SETUP_DATA: "Dados espaço de trabalho",
  SPACE_SETUP_DESCRIPTION:
    "Usaremos os dados para configurar o seu espaço de trabalho, poderá sempre alterar mais tarde",
  SPACE_SETUP_NAME_FIELD: "Nome do espaço de trabalho",
  SPACE_SETUP_NAME_FIELD_INFO_DESC:
    "Nome do seu espaço de trabalho no Airdesk (ex: nome da empresa / empresa grupo)",
  SPACE_SETUP_TITLE: "Configure o seu novo espaço de trabalho",
  SPACE_SUBSCRIPTION_WARNING:
    "Se continuar a subscrição atual irá ser cancelada.",
  SPACE_UPDATE: "Atualização espaço",
  SPACE_UPDATE_DOMAIN_CONFLICT: "O nome curto selecionado já existe",
  SPACE_UPDATE_MSG: "Configuração de espaço foi alterada com sucesso",
  SPEECH_TO_TEXT: "Ditar",
  SPEECH_TO_TEXT_LANGUAGE: "Linguagem",
  SPENT_HOURS: "Tempo consumido ",
  SPENT_HOURS_ABRV: "T. consumido ",
  SPF_RECORD: "Registo SPF",
  SPLIT: "Separar",
  SPLIT_CELLS: "Separar células",
  SPLIT_IN_BATCHES: "Dividir em lotes",
  SPORTING_GOODS: "Artigos desportivos",
  SPORTS: "Desportos",
  STAFFING_OR_RECRUITING: "Equipe / Recrutamento",
  START: "Início",
  START_CONVERSATION: "Enviar email",
  START_DATE: "Data Inicial",
  START_DATE_NO_BIGGER_THEN_END:
    "Data inicial não pode ser maior que data final",
  STARTS_WITH: "Começa com",
  STATUS: "Estado",
  STATUS_CHANGED_SUCCESS: "Estado alterado com sucesso.",
  STATUS_CONTRACTS: "Tag da página inicial para conformidade",
  STATUS_EQUALS: "Estado é igual a",
  STATUS_PROJECTS: "Estados projetos",
  STEP: "Passo",
  STICKY: "Colar",
  STORAGE: "Armazenamento",
  STRIKETHROUGH: "Rasurado",
  SUBJECT: "Assunto",
  SUBJECT_HELP_MESSAGE: "Tooltip do assunto",
  SUBMIT: "Confirmar",
  SUBMIT_AND_SEND: "Enviar convite",
  SUBMIT_FEEDBACK: "Submeter feedback ",
  SUBMIT_NOT_SEND: "Guardar sem convidar",
  SUBSCRIBE: "Subscrever",
  SUBSCRIPTION: "Subscrição",
  SUBSCRIPTION_CANCELED: "Subscrição cancelada",
  SUBSCRIPTION_CREATION: "Criação de subscrição",
  SUBSCRIPTION_DELETED: "apagou a subscrição ",
  SUBSCRIPTION_EXPIRED: "Trial expirou",
  SUBSCRIPTION_NOT_RENOVATED: "Subscrição s/pagamento",
  SUBSCRIPTION_NR: "Nº subscrição",
  SUBSCRIPTION_OF_WORKSPACE: "A subscrição do espaço de trabalho",
  SUBSCRIPTION_OF_WORKSPACE_TRIAL_ENDED: "A sua avaliação gratuita acabou.",
  SUBSCRIPTION_TYPE: "Tipo de subscrição",
  SUBSCRIPTION_TYPES: "Tipos de subscrições",
  SUBSCRIPTION_WAS_CANCELED_DESCRIPTION:
    "Selecione um plano para a sua nova subscrição.",
  SUBSCRIPTION_WAS_CANCELED_TITLE: "A sua subscrição foi cancelada",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION:
    "Devido a algumas falhas de pagamento, a sua subscrição foi cancelada. Selecione um plano para a sua nova subscrição.",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION_PART_ONE:
    "Por favor, confira se tem saldo suficiente ou atualize o método de pagamento, dentro de",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION_PART_TWO:
    "dias a sua subscrição irá ser cancelada.",
  SUBSCRIPTION_WAS_NOT_RENOVATED_TITLE: "A sua subscrição não foi renovada",
  SUBSCRIPTIONS: "Subscrições",
  SUBSCRIPTIONS_CHANGED: "subscrições alteradas",
  SUBSCRIPTIONS_DELETE_DELAY_DESCRIPTION:
    "A preparar para eliminar subscrições",
  SUBSCRIPTIONS_DELETE_DELAY_TITLE: "Eliminação de subscrições",
  SUBSCRIPTIONS_DELETE_DESCRIPTION: "A eliminar subscrições",
  SUBSCRIPTIONS_DELETE_TITLE: "Eliminação de subscrições",
  SUBSCRIPTIONS_FAILED_CHANGED: "falhou ao alterar",
  SUBSCRIPTIONS_NOT_CHANGED: "subscrições inalteradas",
  SUBSCRITION_CANCELED_SUCCESS: "Subscrição cancelada com sucesso",
  SUBSCRITION_CANCELED_SUCCESS_DESCRIPT:
    "A subscrição não será renovada no final do período de cobrança",
  SUBTOTAL: "Subtotal",
  SUCCESS: "Sucesso",
  SUCCESS_DELETED: "Eliminado com sucesso",
  SUCCESS_MESSAGES: "Mensagem de sucesso",
  SUCCESS_PAYMENT: "Pagamento efetuado com sucesso",
  SUCCESS_PLAN_CHANGE: "Alteração de plano com sucesso",
  SUCCESS_SAVED: "Gravado com sucesso",
  SUCCESS_SUBMIT_MESSAGE: "Mensagem de confirmação de sucesso",
  SUCCESSFULLY_CONCLUDED: "Alterado com sucesso",
  SUCCESSFULLY_RECORDED: "Gravado com sucesso",
  SUMMARY: "Resumo",
  SUNDAY: "Domingo",
  SUPERMARKETS: "Supermercados",
  SUPPORT: "Suporte",
  SUPPORT_REQUEST: "Pedido suporte",
  SYSTEM: "Sistema",
  SYTEM_AIRDESK_EMAIL: "Airdesk alias email",
  TABLE_CALLS: "Tabela de chamadas",
  TABLE_CONTRACTS: "Tabela de contratos",
  TABLE_DEALS: "Tabela de negócios ",
  TABLE_PROJECTS: "Tabela de projetos",
  TABLE_SUBSCRIPTIONS: "Tabela de assinaturas",
  TABLE_TASKS: "Tabela de tarefas",
  TABLE_TICKETS: "Tabela de casos",
  TABLE_TIMES: "Tabela de tempos",
  TASK: "Tarefa",
  TASK_ASSIGNED: "atribuiu-lhe a tarefa",
  TASK_ASSIGNED_TO: "atribuiu a tarefa",
  TASK_CLOSING_TIME: "Equipa - tempo médio de fecho de Tarefas",
  TASK_CREATION: "Criação de tarefa",
  TASK_DELETED: "apagou a tarefa",
  TASK_HISTORY: "Atividade de tarefas",
  TASK_IS: "Tarefa é",
  TASK_LIST: "Lista de tarefas",
  TASK_NR: "Nº tarefa",
  TASK_OVERIMPOSED_FOUND: "Existe sobreposição de datas de tarefas ou tempos.",
  TASK_REMOVED: "retirou-lhe a tarefa",
  TASK_UPDATE: "atualizou a tarefa",
  TASKS: "Tarefas",
  TASKS_CHANGED: "tarefas alteradas",
  TASKS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar tarefas",
  TASKS_DELETE_DELAY_TITLE: "Eliminação de tarefas",
  TASKS_DELETE_DESCRIPTION: "A eliminar tarefas",
  TASKS_DELETE_TITLE: "Eliminação de tarefas",
  TASKS_DESCRIPTION:
    "Acompanhe a contagem de tarefas da sua empresa, o tempo de resposta e muito mais.",
  TASKS_FAILED_CHANGED: "falhou ao alterar",
  TASKS_MULTIPLE: "Tarefas(s)",
  TASKS_NOT_CHANGED: "tarefas inalteradas",
  TASKS_PERFORMANCE: "Performance de tarefas",
  TASKS_PROGRESS: "Evolução tarefas",
  TASKS_REPORT: "Relatório tarefas",
  TASKS_STATUS: "Tarefas por estado",
  TAX_INCLUDED: "(inclui impostos)",
  TEAM: "Equipa",
  TEAM_ACTIVITY: "Atividade de equipa",
  TEAM_CASE_AVERAGE: "Equipa - tempo médio de fecho de casos",
  TEAM_DEPARTMENT: "Departamento da equipa",
  TEAM_IS: "Equipa é",
  TEAM_LOAD: "Equipa - carga de trabalho",
  TEAM_MEMBER: "Membro de equipa",
  TEAM_NAME: "Nome da equipa",
  TEAM_SAVE_SUCCESS: "Equipa gravada com sucesso",
  TEAM_TASK_AVERAGE: "Equipa - tempo médio de fecho de tarefas",
  TEAM_WORKLOAD: "Equipa - carga de trabalho",
  TEAMS: "Equipas",
  TEAMS_CONTRIBUTORS: "Equipas / Colaboradores",
  TECHNICIANS: "Técnica",
  TELECOMMUNICATIONS: "Telecomunicações",
  TEMPLATE: "Template",
  TEMPLATES: "Templates",
  TERMS_CONDITIONS: "Termos e condições",
  TEST: "Teste",
  TEST_EMAIL_SEND_SUCCESS: "Email enviado com sucesso",
  TEST_EMAIL_SIGNATURE: "Testar assinatura",
  TEST_EMAIL_SIGNATURE_DESCRIPTION:
    "Será enviado um email para o seu correio pessoal.",
  TEXT: "Texto",
  TEXT_FIELD_IS_REQUIRED: "Por favor, escreva uma mensagem",
  TEXT_LINK: "Texto link",
  TEXTILES: "Têxteis",
  THE_CALL_IS_CREATED: "Chamada é criada.",
  THE_CASE_STATUS: "O estado do caso",
  THE_CLASSIFICATION_IS_CREATED: "Classificação é criada.",
  THE_CLIENT_IS_CREATED: "Conta é criada.",
  THE_CLIENT_STATUS: "O estado da conta",
  THE_CONTACT_IS_CREATED: "Contato é criado.",
  THE_CONTACT_STATUS: "O estado do contato",
  THE_CONTRACT_IS_CREATED: "Contrato é criado.",
  THE_DEAL_IS_CREATED: "Negócio é criado.",
  THE_PROJECT_IS_CREATED: "Projeto é criado.",
  THE_PROJECT_STATUS: "O estado do projeto",
  THE_SUBSCRIPTION_IS_CREATED: "Subscrição criada.",
  THE_TASK_IS_CREATED: "Tarefa é criada.",
  THE_TASK_STATUS: "O estado da tarefa",
  THE_TEAM: "A equipa",
  THE_TICKET_IS_CREATED: "Ticket é criado.",
  THE_TIME_IS_CREATED: "Tempo é criado.",
  THE_TIME_RECORD_STATUS: "O estado do registo de tempo",
  THE_USER: "O utilizador",
  THEME: "Tema",
  THINK_TANKS: "Think tanks",
  THIRD: "Terceiro",
  THUMBNAIL: "Avatar",
  THURSDAY: "Quinta-feira",
  TICKET_CLOSE: "Equipa - tempo médio de fecho de casos",
  TICKET_CREATION: "Criação de caso",
  TICKET_ERROR_NO_CONTACT:
    "Não existe contacto de email para envio da notificação",
  TICKET_FORM: "Formulário de caso",
  TICKET_HISTORY: "Atividade de casos",
  TICKET_PERFORMANCE: "Performance casos",
  TICKET_PROGRESS: "Tempo casos abertos em resolução",
  TICKETS_CHANGED: "casos alterados",
  TICKETS_DELETE_DELAY_DESCRIPTION: "A preparar para eliminar casos",
  TICKETS_DELETE_DELAY_TITLE: "Eliminação de casos",
  TICKETS_DELETE_DESCRIPTION: "A eliminar casos",
  TICKETS_DELETE_TITLE: "Eliminação de casos",
  TICKETS_DESCRIPTION:
    "Acompanhe a contagem de casos da sua empresa, o tempo de resposta e muito mais.",
  TICKETS_FAILED_CHANGED: "falhou ao alterar",
  TICKETS_MULTIPLE: "Caso(s)",
  TICKETS_NOT_CHANGED: "casos inalterados",
  TIME: "Tempo",
  TIME_CHARGES_MULTIPLE: "Registo(s) de tempo",
  TIME_CONTROL: "Cronômetro",
  TIME_CONTROL_PERIODICITY: "Periodicidade do controlo de horas",
  TIME_EQUIVALENT: "Este módulo é equivalente ao registo de tempo",
  TIME_IS: "Tempo é",
  TIME_MONITORING: "Controlo de tempo",
  TIME_NR: "Nº registo de tempo",
  TIME_RECORD: "Registo tempo",
  TIME_RECORDS: "Registos de tempo",
  TIME_REGISTER: "Registos de tempo",
  TIME_REGISTRATION: "Registo de tempo",
  TIME_REPORT: "Relatório tempos",
  TIME_SUMMARY: "Resumo dos últimos 9 dias do registo de tempo.",
  TIME_TYPE: "Tipo de tempo",
  TIME_TYPE_EQUALS: 'Tipo de tempo é igual a "',
  TIMEOUT: "Timeout",
  TIMEOUT_BETWEEN_FAIL: "Tempo limite entre falhas",
  TIMES: "Tempos",
  TIMESHEET: "Timesheet",
  TIMESHEET_DESCRIPTION:
    "Consulte os tempos lançados pelos seus colaboradores.",
  TIMETABLES_PREDEFINED: "Estão predefinidos horários para o dia escolhido",
  TIMEZONE: "Fuso horário",
  TITLE: "Título",
  TO: "Às",
  TO_BILL: "Por faturar",
  TO_PLAN: "Para plano",
  TO_PUBLISH: "A publicar",
  TOBACCO: "Tabaco",
  TODAY: "Hoje",
  TODO_ITEMS: "To do's",
  TODO_NR_ITEMS: "Número de itens {items}",
  TODOS_NUMBER: "Número de passos",
  TOMOROW: "Amanhã",
  TOP_ACCOUNTS: "Top contas",
  TOTAL: "Total",
  TOTAL_HOURS: "Horas totais",
  TOTAL_PAUSES: "Total de pausas (minutos)",
  TOTAL_PAUSES_HOURS_ABRV: "Total de pausas",
  TOTAL_RECORDS: "Total de registos",
  TOTAL_VALUE: "Valor total",
  TOTALS: "Totais",
  TRANSACTION: "Transação",
  TRANSFER: "Transferir",
  TRANSFER_OWNERSHIP: "Transferir propriedade",
  TRANSFER_OWNERSHIP_DESCRIPTION:
    "Tem a certeza que pretende transferir a propriedade do Espaço de trabalho <b>{spaceName}</b> para {userName}?",
  TRANSFER_OWNERSHIP_SUBSCRIPTION_WARNING:
    "Para transferir propriedade é necessário cancelar a sua subscrição",
  TRANSFER_PROPERTY: "Transferir propriedade",
  TRANSFER_PROPERTY_TITLE: "Transferir propriedade do espaço de trabalho",
  TRANSFER_PROPERTY_TITLE_DESCRIPTION:
    "A transferência deste espaço de trabalho para outro utilizador fará você perder os privilégios de proprietário.",
  TRANSLATION_OR_LOCALIZATION: "Tradução / localização",
  TRANSPORTATION: "Transporte",
  TRAVEL: "Deslocação",
  TRAVEL_TIME: "Tempo de deslocação (minutos)",
  TRAVEL_TIME_HOURS_ABRV: "Tempo de deslocação",
  TRAVELS: "Deslocações",
  TREEVIEW_NO_CHILDREN: "Não foram originados elementos",
  TRIAL_ENDED: "Avaliação terminada",
  TRIGGER_CONDITION: "Condição de trigger",
  TRY_AGAIN: "Tentar novamente",
  TRY_AGAIN_IN_SOME_TIME: "Por favor, tente novamente passado algum tempo.",
  TUESDAY: "Terça-feira",
  TWITTER_URL: "Twitter url",
  TYPE: "Tipo",
  TYPE_CONTRACT: "Tipo de contrato",
  TYPE_CONTRACT_EQUALS: "Tipo de contrato é igual a",
  TYPE_SUBSCRIPTION: "Tipo de subscrição",
  TYPE_SUBSCRIPTION_EQUALS: "Tipo de subscrição é igual a",
  TYPE_TO_SEARCH: "Escreva para pesquisar",
  TYPES: "Tipos",
  UNASSIGNED: "Não atribuídos",
  UNCLASSIFIED: "Por classificar",
  UNDERLINE: "Sublinhado",
  UNDO: "Desfazer",
  UNFOLLOW: "Não seguir",
  UNIMPORTED_RECORDS: "Registos não importados",
  UNIQUE_USAGE_PER_SPACE: "Utilização única por espaço",
  UNIT: "Unidade",
  UNLIMITED: "Ilimitado",
  UNMODIFIED_RECORDS: "Registos não alterados",
  UNPIN: "Remover pin",
  UNRESOLVED: "Não resolvidos",
  UNSPLASH_TOOLTIP: "Stockphoto Unsplash",
  UNSTICKY: "Descolar",
  UPDATE: "Atualizar",
  UPDATE_BILLING_DATA: "Atualizar dados de faturação",
  UPDATE_BILLING_INFO_SUCCESS: "Dados de faturação atualizados com sucesso",
  UPDATE_CALL: "O utilizador atualizou a chamada",
  UPDATE_CASE: "O utilizador atualizou o caso",
  UPDATE_DEAL: "O utilizador atualizou o negócio",
  UPDATE_DNS_DATA: "Atualizar dados de DNS",
  UPDATE_NOTIFICATION_MSG: "Configurações alteradas com successo",
  UPDATE_PASSWORD: "Atualizar password",
  UPDATE_PAYMENT_METHOD_WARNING_PART_ONE: "Atualize o método de pagamento,",
  UPDATE_PAYMENT_METHOD_WARNING_PART_TWO: "dias para cancelamento.",
  UPDATE_PAYMENT_SUCCESS: "Método de pagamento atualizado com sucesso",
  UPDATE_TASK: "O utilizador atualizou a Tarefa",
  UPDATE_USER_PERMISSION_SUCCESS: "Permissões atualizadas",
  UPDATED_RECORDS: "Registos atualizados",
  UPDATING_USER_PERMISSION: "As permissões estão a ser atualizadas",
  UPGRADE_PLAN: "Upgrade",
  UPGRADE_TO_BASIC: "para Pro",
  UPGRADE_TO_COMPANY: "para Enterprise",
  UPGRADE_TO_PRO: "para Business",
  UPGRADE_TO_STARTER: "para Start",
  UPLOAD: "Upload",
  UPLOAD_IMAGE: "Carregar foto",
  UPLOAD_LOGO: "Carregar logotipo",
  UPLOAD_PHOTO: "Carregar foto",
  URL: "Url",
  URL_BASE: "URL base",
  URL_LINK: "Url do link",
  USE_DEFAULT: "Usar default",
  USE_KEYBOARD: "Use o teclado para navegar",
  USED: "consumido ",
  USED_COUNT: "Nº de utilizações",
  USER: "Utilizador",
  USER_AVATAR: "Avatar",
  USER_COMPANY: "Empresa",
  USER_DEPARTMENTS: "Departamentos",
  USER_EMAIL: "Email",
  USER_EMPLOYEE_NUMBER: "Número de empregado",
  USER_EXNTENSION: "Extensão do telefone",
  USER_FULL_NAME: "Nome completo",
  USER_NAME: "Nome de utilizador",
  USER_PERMISSION_ERROR_DESCRIPTION: "Houve um erro ao atualizar as permissões",
  USER_PERMISSION_ERROR_TITLE: "Erro de permissões",
  USER_PHONE: "Número de Telefone",
  USER_ROLE: "Função",
  USER_SIGNATURE: "Assinatura de utilizador",
  USER_TEAMS: "Times",
  USERS: "Utilizadores",
  USERS_NUMBER: "Nº utilizadores",
  UTILITIES: "Serviços de utilidade pública",
  VALIDATE_NIF: "Validar NIF",
  VALIDATED: "Validado",
  VALIDATION_COMPLETE: "Validação concluída",
  VALIDITY: "Validade",
  VALUE: "Valor",
  VALUE_HOUR: "Valor por hora",
  VALUES: "Valores",
  VARIABLE_SELCTION: "Seletor de variável",
  VENTURE_CAPITAL_OR_VC: "Capital de risco / VC",
  VERSION: "Versão",
  VERTICAL: "Vertical",
  VERY_BAD: "Muito mau",
  VERY_GOOD: "Muito bom",
  VETERINARY: "Veterinário",
  VIDEOS: "Vídeos",
  VIEW: "Vista",
  VIEW_ALL: "Ver tudo",
  VIEW_LOGS: "Ver logs",
  VIEWS: "Vistas",
  VISUALIZE: "Ver",
  WAITING_PAYMENT: "Aguardando pagamento",
  WAREHOUSING: "Armazenagem",
  WARRANTY: "Garantia",
  WAS_CHANGED_TO: "foi alterado para",
  WAS_UPDATED: "for atualizado",
  WE_APPRICIATE_YOUR_FEEDBACK:
    "Obrigado por entrar em contato com a {CompanyName} recentemente! Estamos sempre nos esforçando para melhorar e adoraríamos receber seus comentários sobre a experiência.",
  WE_APPRICIATE_YOUR_PATIANCE: "Agradecemos desde já a sua paciência e apoio,",
  WEBHOOK: "Webhook",
  WEBSITE: "Website",
  WEBSITE_NAME_ERROR:
    "O website não tem um formato válido (https://domain.com)",
  WEBSITE_SNIPPET: "Código para site",
  WEDNESDAY: "Quarta-feira",
  WEED_LOVE_FEEDBACK: "Adoraríamos receber seus comentários",
  WEEK: "Semana",
  WEEKDAY: "Dia da semana",
  WEEKLY: "Semanalmente",
  WELCOME_ALL_READY: "Tudo pronto para ir para o dashboard!",
  WELCOME_CLIENTS_CONTACTS_SUBTITLE: "Descubra como configurar os contactos",
  WELCOME_DEALS_CONTRACTS_SUBTITLE:
    "Descubra como configurar o módulo comercial",
  WELCOME_FIRST_TITLE: "Armazene e utilize os seus dados comerciais",
  WELCOME_SECOND_TITLE:
    "Atualize e tenha mais controle sobre o setor comercial",
  WELCOME_SERVICES_SUBTITLE: "Descubra como configurar os serviços",
  WELCOME_THIRD_TITLE: "Comece por explorar a nossa oferta de serviços",
  WELCOME_USERNAME: "Bem vindo de volta",
  WELCOME_VIEW_LINK: "Página de boas vindas",
  WHAT_DATES_TO_EXCLUDE: "Quais as datas a excluir?",
  WHAT_TO_BE_NOTIFIED: "Notificações",
  WHERE: "Onde",
  WHOLESALE: "Atacado",
  WHOS_NEXT: "Quem é o próximo?!",
  WIDGET: "Widget",
  WIDGET_ALLOWED_DOMAINS_MSG: "O Widget apenas será permitido nestes domínios",
  WIDGET_BUTTON_CONFIGURATE: "Configurar botão",
  WIDGET_DOMAINS: "O Widget apenas será permitido nestes domínios",
  WIDGET_FEEDBACK_TICKET_CONFIGURATE:
    "Configurar feedback - atribuição de casos",
  WIDGET_FORM_CONFIG: "Configurar assuntos - atribuição de casos",
  WIDGET_FORM_CONFIG_ERROR:
    "É necessário adicionar a atribuição de pelo menos um caso",
  WIDGET_TAB_ERROR:
    "Existem campos que falta preencher nos separadores assinalados. Verifique se preencheu todos os campos assinalados em todas as linguagens adicionadas.",
  WIDGETS: "Widgets",
  WINE_OR_SPIRITS: "Vinho / destilados",
  WIRELESS: "Sem fio",
  WITH_THE_SUBJECT: "com o assunto",
  WON: "Ganho",
  WORK_HOURS: "Horário de atendimento",
  WORKFLOW: "Workflow",
  WORKFLOW_CURRENTLY_RUNNING: "A automação está em execução no momento",
  WORKING_IN: "A trabalhar em",
  WORKSPACE: "Selecione um espaço de trabalho",
  WORKSPACE_NAME: "Nome do espaço",
  WORKSPACE_SUBDOMAIN: "Domínio do espaço de trabalho",
  WORKSPACE_SUBDOMAIN_DESC:
    "Pode personalizar o subdomínio do seu espaço de trabalho. Pode utilizar o nome da sua empresa, por exemplo. Uma vez escolhido não pode ser alterado.",
  WORKSPACE_SUBSCRIPTION: "Subscrição do workspace",
  WORKSPACE_TEXT: "Espaço de trabalho",
  WRITE_A_NEW_MESSAGE: "Escreva uma nova mensagem",
  WRITE_MESSAGE_OR_DROP: "Escreva uma nova mensagem e/ou arraste ficheiros",
  WRITE_NOTE_OR_DROP: "Escreva uma nova nota e/ou arraste ficheiros",
  WRITE_YOUR_EMAIL: "Escreva um novo email",
  WRITING_OR_EDITING: "Escrita / Edição",
  YEAR: "Ano",
  YEARLY: "Anualmente",
  YES: "Sim",
  YESTERDAY: "Ontem",
  YOUR_COMPANY_DATA: "Dados da sua empresa",
  YOUR_FEEDBACK: "Deixe-nos um comentário ou testemunho",
  YOUTUBE_URL: "Youtube url",
  ZIP_CODE: "Código postal",
  SUBSCRIPTION_UPDATED: "atualizou a subscrição",
  DEAL_CREATED: "criou o negócio ",
  SUBSCRIPTION_CREATED: "criou a subscrição",
  ACCOUNT_CREATED: "criou a conta",
  CONTRACT_CREATED: "criou o contrato",
  CASE_CREATED: "criou o caso",
  TASK_CREATED: "criou a tarefa",
  CALL_CREATED: "criou a chamada",
  PROJECT_CREATED: "criou o projeto",
  NOTIFICATION_TOPIC_CASES_DELETED_TITLE: "Quando é removido um caso",
  NOTIFICATION_TOPIC_CASES_DELETED_DESCRIPTION:
    "Receba uma notificação cada vez que é removido um caso",
  TIMES_REPORTS: "Relatório de tempo",
  RENOVATE: "Renovar",
  ADD_TEMPLATE: "Guardar como template",
  USE_TEMPLATE: "Utilizar template",
  NO_TEMPLATES: "Nenhum modelo disponível",
  SAVE_FORM: "Salvar formulário como modelo",
  CHOOSE_A_TEMPLATE: "Escolha um modelo",
  TEMPLATE_APPLIED: "Modelo aplicado",
  DATA: "Dados",
  FINANCIAL_DATA: "Dados financeiros",
  RENAME_VIEW: "Mudar nome da vista",
  WARN_UNSAVED:
    "Ainda não gravou as suas alterações. Tem certeza que deseja sair?",
  EXPORTS_REPORTS_DESCRIPTION:
    "Consulte todas as exportações geradas por todos os colaboradores.",
  GIVEN_HOURS: "Tempo consumido ",
  SESSION_CLOSED_SUCCESS: "Sessão terminada com sucesso",
  THE_DEAL_STATUS: "O estado do negócio ",
  THE_CONTRACT_STATUS: "O estado do contrato ",
  THE_CALL_STATUS: "O estado da chamada",
  THE_SUBSCRIPTION_STATUS: "O estado da subscrição",
  TIMECHARGE_DELETED: "apagou o registo de tempo",
  TIMECHARGE_CREATED: "criou o registo de tempo",
  TIMECHARGE_UPDATED: "atualizou o registo de tempo",
  BOOLEAN: "Verdadeiro ou falso",
  STRING: "Texto",
  NUMBER: "Número ",
  CONFIRM_UNSUB_TITLE:
    "Tem a certeza que pretende desativar as notificações deste item?",
  CONFIRM_UNSUB_DESCRIPTION:
    "Caso clique em 'Confirmar', irá perder o acesso às conversas e ficheiros associados, mas continuará a ter acesso aos detalhes.",
  UPDATE_USER_ERROR_COMPANY_MANAGER:
    "O utilizador é responsável por pelo menos uma empresa removida. Altere o responsável da empresa antes de o remover da empresa.",
  UPDATE_USER_ERROR_TEAM_MANAGER:
    "O utilizador é responsável por pelo menos uma equipa removida. Altere o responsável da equipa antes de o remover da equipa.",
  UPDATE_USER_ERROR_DEPARTMENT_MANAGER:
    "O utilizador é responsável por pelo menos um departamento removido. Altere o responsável do departamento antes de o remover do departamento.",
  TEST_JSON: "Testar JSON",
  EMAIL_CONVERSATION_CREATED:
    "Foi criada uma nova conversa de email com o assunto ",
  EMAIL_CONVERSATION_UPDATE: "atualizou a conversa de email com o assunto ",
  WARNING: "Aviso",
  ITEM_DELETED_WARNING: "Este item foi eliminado.",
  SIDEBAR_CONFIRM_EXIT:
    "As alterações feitas serão perdidas. Tem a certeza que quer sair?",
  VIEW_GRID: "Vista tabela",
  VIEW_CALENDAR: "Vista calendário",
  VIEW_TIMELINE: "Vista timeline",
  VIEW_BOARD: "Vista kanban",
  VIEW_CARDS: "Vista cartões",
  SHARE_WITH: "Partilhar com",
  SEND_NOTIFICATION_TO: "Enviar notificações a",
  CLIENT_NOT_FOUND_OR_INVALID: "A conta é inválida ou não foi encontrada",
  CLIENT_NOT_IN_SPACE: "Conta não encontrada",
  CLIENT_IS_DISABLED: "Conta desativada",
  PROJECT_NOT_FOUND_OR_INVALID: "O projeto é inválido ou não foi encontrado",
  PROJECT_NOT_IN_SPACE: "Projeto não encontrado",
  PROJECT_MUST_BE_OPEN: "O projeto deve estar aberto",
  TICKET_NOT_FOUND_OR_INVALID: "O caso é inválido ou não foi encontrado",
  TICKET_NOT_IN_SPACE: "Caso não encontrado",
  TICKET_MUST_BE_OPEN: "Caso deve estar aberto",
  CALL_NOT_FOUND_OR_INVALID: "A chamada é inválida ou não foi encontrada",
  CALL_NOT_IN_SPACE: "Chamada não encontrada",
  CALL_MUST_BE_OPEN: "A chamada deve estar aberta",
  TASK_NOT_FOUND_OR_INVALID: "A tarefa é inválida ou não foi encontrada",
  TASK_NOT_IN_SPACE: "Tarefa não encontrada",
  TASK_MUST_BE_OPEN: "A tarefa deve estar aberta",
  CONTRACT_NOT_FOUND_OR_INVALID: "O contrato é inválido ou não foi encontrado",
  CONTRACT_NOT_IN_SPACE: "Contrato não encontrado",
  CONTRACT_MUST_BE_OPEN: "O contrato deve estar aberto",
  DEAL_NOT_FOUND_OR_INVALID: "O negócio é inválido ou não foi encontrado",
  DEAL_NOT_IN_SPACE: "Negócio não encontrado",
  DEAL_MUST_BE_OPEN_WON: "O negócio deve estar aberto",
  ORIGIN_IS_REQUIRED: "A origem é obrigatória",
  CLIENT_INVALID: "A conta é inválida",
  DEAL_INVALID: "O negócio é inválido",
  PROJECT_INVALID: "O projeto é inválido",
  TICKET_INVALID: "O caso é inválido",
  CALL_INVALID: "A chamada é inválida",
  TASK_INVALID: "A tarefa é inválida",
  CONTRACT_INVALID: "O contrato é inválido",
  WORKSPACE_LABEL: "Workspace",
  ADD_ANOTHER_SCHEDULE: "Adicionar outro agendamento ",
  WORKSPACES: "Espaços de trabalho",
  VIEW_ALL_ITEMS: "Ver todos",
  ERROR_GETTING_GLOBAL_PERMISSION: "Erro ao obter permissões base",
  DISK_LIMIT_INVALID: "Tamanho da partição invalido",
  ERROR_DELETED_ACCOUNT_UNIQUE_PERMISSION:
    "Erro ao eliminar permissões de conta",
  ERROR_UPDATING_ACCOUNT_UNIQUE_PERMISSION:
    "Erro ao atualizar permissões de conta",
  ERROR_GETTING_GLOBAL_CLIENTS: "Erro ao buscar as contas do espaço",
  ERROR_DELETED_ALL_CLIENTS_IN_SPACE: "Erro ao eliminar as contas do espaço",
  ERROR_UPDATING_ALL_CLIENTS_IN_SPACE: "Erro ao atualizar as contas do espaço",
  ERROR_GETTING_GLOBAL_DEALS: "Erro ao buscar os negócios do espaço",
  ERROR_DELETED_ALL_DEALS_IN_SPACE: "Erro ao eliminar os negocios do espaço",
  ERROR_UPDATING_ALL_DEALS_IN_SPACE: "Erro ao atualizar os negócios do espaço",
  INFO_NEW_VERTICAL_BAR_ALERT_FROM_HORIZONTAL:
    "Pssst, experimente aqui o novo menu vertical e não vai querer outra coisa! 🤫",
  INFO_NEW_VERTICAL_BAR_ALERT_FROM_VERTICAL:
    "Notou algo diferente? Excelente! Melhorámos o menu lateral 🥳",
  DOCUMENT_TYPES: "Tipos de documento",
  EMPTY_DOCUMENT_TYPES: "Não existem registos de tipos de documentos.",
  DOCUMENT_TYPE: "Tipo de documento",
  ADD_DOCUMENT_TYPE: "Adicionar tipo de documento ",
  REVIEW: "Em revisão ",
  FAILURE: "Falhado",
  PUBLIC_DOCUMENT_TITLE: "Documento público",
  PUBLIC_DOCUMENT_DESCRIPTION:
    "Todos, neste espaço de trabalho, podem visualizar este documento",
  SOCIAL_POST: "Publicação ",
  FONT_STYLE: "Estilo de fonte",
  PAGE_MARGIN: "Margens da página",
  SMALL: "Pequena",
  NORMAL: "Normal",
  LARGE: "Grande",
  TOPICS: "Tópicos",
  DATA_TYPE_TITLE: "Tipo de dados",
  DATA_TYPE_DESCRIPTION: "Escolha um dos seguintes",
  DATA_PARAMETER_TITLE: "Parâmetros",
  DATA_PARAMETER_DESCRIPTION: "Escolha quais parâmetros você deseja importar",
  CHECK_ALL: "Tudo",
  DATA_FILTER_TITLE: "Filtros & ordenação ",
  DATA_FILTER_DESCRIPTION: "Escolha os filtros que deseja aplicar",
  IMPORT_DATA: "Importar dados",
  STATUS_REASON: "Motivo do estado",
  STATUS_REASON_DESCRIPTION: "Descrição do motivo do estado",
  ADD_FILTERS: "Adicionar filtros",
  EVERY: "Cada",
  INDIVIDUAL: "Individual",
  LOST_CANCELLED_REASON: "Motivo de perda/cancelamento",
  LOST_CANCELLED_REASON_DESCRIPTION: "Descrição de perda/cancelamento",
  CANCELLED_REASON: "Motivo do cancelamento",
  CANCELLED_REASON_DESCRIPTION: "Descrição do cancelamento",
  SPENT_TIME: "Tempo consumido ",
  ADD_DOCUMENT: "Adicionar documento",
  TIME_SPENT_BEFORE_CONTRACT: "Tempo consumido antes do início do contrato",
  TIME_SPENT_AFTER_CONTRACT: "Tempo consumido após o fim do contrato",
  TIME_SPENT_OUTSIDE_CONTRACT: "Tempo consumido fora do contrato",
  ADD_FILTERS_AND_SORTING: "Adicionar filtros e classificação",
  VIEW_APPLIED_OPTIONS: "Ver opções aplicadas",
  ITEMS: "Itens",
  RENOVATED: "Renovado",
  PAGE_BACKGROUND: "Fundo",
  EMAIL_CONVERSATION_NEW_EMAIL:
    "A conversa de email com o assunto {EmailSub} recebeu um novo email.",
  CREATE_DOCUMENT: "Criar Documento",
  MISSING_RECORDS: "Registos de tempo em falta",
  DOCUMENT: "Documento",
  LAST_UPDATE_DATE: "Data da última atualização",
  LAST_UPDATE_BY: "Última atualização por",
  CREATE_DOCUMENT_TEMPLATE: "Criar modelo de documento",
  ADD_DOC_TEMPLATE: "Adicionar modelo de documento",
  API_TYPE_INVALID: "O tipo de item é inválido",
  API_NAME_REQUIRED: "O nome é obrigatório",
  API_ASSOCIATE_TO_INVALID: "A associação é inválida",
  API_ORGANIZATION_INVALID: "A organização é inválida",
  API_PIPELINE_INVALID: "O oleoduto é inválido",
  API_PIPELINESTATUS_INVALID: "O estado das condutas é inválido",
  API_VARIABLE_INVALID: "A variável é inválida",
  API_ASSIGNED_TO_INVALID: "Atribuído a é inválido",
  API_INVALID_REQUEST_OR_REQUEST_LOCKED:
    "O pedido é inválido ou outro utilizador está atualmente a editar",
  API_NOT_FOUND_OR_NO_PERMISSIONS: "O item não existe ou não tem permissão",
  API_NOT_FOUND_OR_NO_PERMISSIONS_DELETE:
    "O item não existe ou não tem permissão para apagar",
  API_FAILED_GETTING_TEMPLATE: "Não é possível encontrar o modelo dado",
  VARIABLES: "Variáveis",
  EMPTY: "Vazio",
  VARIABLES_DESCRIPTION: "Descrição da variável",
  DOCUMENT_SAVE_SUCCESS: "Documento guardado com sucesso",
  TEMPLATE_SAVE_SUCCESS: "Modelo de documento guardado com sucesso",
  EMAIL_NO_SUBJECT: "Sem Assunto",
  API_EMAIL_SUBJECT_INVALID: "Assunto é obrigatório",
  CUSTOM_TAGS: "Variáveis personalizadas",
  SUBSCRIPTION_IS: "A subscrição é",
  LAST_CHANGE_DATE: "Data da última alteração",
  DOCUMENT_TYPE_EQUALS: "O tipo de documento é igual",
  DOC_TEMPLATE: "Modelo de documento",
  DOC_TEMPLATE_NR: "Modelo de documento Nº",
  NO_DOC_TEMPLATES_LIST:
    "Não existem registos de modelos de documentos associados.",
  EMPTY_DOC_TEMPLATES_LIST: "Não existem modelos de documentos",
  BI_NUMBER: "Número do cartão cidadão",
  BI_LOCALE: "Local de Emissão",
  BI_DATE: "Data de Emissão",
  BI_EXPIRATION: "Data de Expiração",
  NISS: "NISS",
  EMPTY_CATEGORIES_LIST: "Não existem categorias de utilizadores",
  NO_CATEGORIES_LIST:
    "Não há registos de categorias de utilizadores associados.",
  EMPTY_INVITES_LIST: "Não existem convites de utilizadores",
  NO_INVITES_LIST: "Não há registos de convites de utilizadores associados.",
  CATEGORIES: "Categorias",
  DEFAULT_HOUR_COST: "Custo padrão por hora",
  ADD_CATEGORY: "Criar Categoria",
  CODEERP: "Código ERP",
  API_NIF_INVALID: "NIF inválido",
  API_NISS_INVALID: "NISS inválido",
  API_COUNTRY_INVALID: "País inválido",
  PRINT: "Imprimir",
  API_CATEGORY_INVALID: "Categoria Inválida",
  API_UPDATE_USER_ERROR_COMPANY_MANAGER:
    "Retirar primeiro o utilizador dos gestores da empresa",
  API_UPDATE_USER_ERROR_DEPARTMENT_MANAGER:
    "Remover primeiro o utilizador dos gestores do departamento",
  API_UPDATE_USER_ERROR_TEAM_MANAGER:
    "Remover primeiro o utilizador dos gestores de equipa",
  API_ORGANIZATION_DEPARMENT_TEAM_INVALID: "Empresa/Departamento/Team Inválido",
  API_EXPIRATIONDATE_INVALID: "Dados de expiração do documento são inválidos",
  API_ISSUEDATE_INVALID: "Dados de emissão do documento são inválidos",
  API_SOCIALSECURITY_TOLARGE: "NISS Inválido - Demasiado grande",
  API_NIF_TOLARGE: "NIF Inválido - Demasiado grande",
  API_BILOCALE_TOLARGE: "BI Localização da edição - Demasiado grande",
  API_BINUMBER_TOLARGE: "Número do cartão BI - Demasiado grande",
  API_CODE_TOLARGE: "Código ERP - Demasiado grande",
  API_INVALID_REQUEST: "Pedido inválido",
  API_EMAIL_REQUIRED: "O e-mail é necessário",
  API_NAME_MAX_50: "Nome de utilizador demasiado grande",
  API_EMAIL_INVALID: "Email Inválido",
  API_USER_ALREADY_IN_SPACE: "O utilizador já existe no espaço",
  API_INVALID_INVITE_HAS_BLOCKED_USER: "O utilizador está bloqueado",
  API_DEPARMENT_INVALID: "Departamento Inválido",
  API_TEAM_INVALID: "Equipa Inválida",
  API_SPACE_INVALID_OR_DELETED: "Espaço Inválido ou Apagado",
  API_INVITATION_FULL: "A lista de convites está cheia",
  API_ERROR_SEND_EMAIL: "Ocorreu um erro durante o envio do e-mail",
  API_INVITATION_NOT_FOUND: "Convite não encontrado ou apagado",
  EMPLOYEE_PERSONAL_INFORMATION: "Informação Pessoal do Empregado",
  SOCIAL_SECURITY: "Número da Segurança Social",
  API_STANDARDCOST_TOLARGE: "Custo padrão demasiado grande",
  API_ORGANIZATION_REQUIRED: "Empresa é obrigatório",
  PRIVATE_DATA: "Dados Privados",
  API_INVITATION_INVALID: "Convite inválido",
  JOINED_THE_SPACE: "Juntou-se com sucesso ao espaço ",
  CITIZEN_CARD: "Cartão de cidadão",
  PASSPORT: "Passaporte",
  DOCUMENT_STYLE: "Estilos",
  BIRTHDAY_FROM: "De",
  BIRTHDAY_TO: "a",
  EDITOR_IS_CLOSING_WARNING:
    "Não detetamos alterações neste documento nos últimos 10 minutos. O documento será fechado.",
  EDITOR_REFUSE_CLOSE: "Continuar a editar",
  DOCUMENT_VERSIONS: "Histórico de versões",
  CONFIRM_RESTORE_VERSION_QUESTION:
    "O seu documento atual será revertido para {Date}",
  RESTORE: "Restaurar",
  RESTORE_THIS_VERSION: "Restaurar esta versão",
  ORIGIN_DATA: "Dados da origem",
  API_POSTALWEBHOOK_NOT_FOUND:
    "Não foi encontrado o registo do Gancho do Tesouro Postal",
  API_INVALID_POSTAL_MODEL: "Modelo postal inválido",
  API_POSTALWEBHOOK_CAN_NOT_RETRY: "Não pode ser recuperado",
  IS_EDITING: "{Name} está a editar",
  API_INVALID_REQUEST_YOUR_SESSION_EXPIRED: "A sua sessão de edição expirou",
  COPY_URL: "Copiar URL",
  API_NOT_FOUND_OR_NO_PERMISSION: "Não encontrado ou requer autorização",
  DOCUMENT_IS_BEING_EDITED: "Este documento já se encontra em edição",
  SELECT_DOC_TEMPLATE: "Selecionar modelo de documento",
  NUMBER_TEMPLATES: "{num} templates",
  BLANK_DOCUMENT: "Documento em branco",
  CREATED_SUCCEFULLY: "Criado com sucesso",
  NO_DOCUMENTS_LIST: "Lista de documentos vazia",
  EMPTY_DOCUMENTS_LIST: "Não existem registos de documentos associados",
  KNOWLEDGE_BASE: "Base de conhecimento",
  BILLABLE: "Contabilizável",
  ACTIVE_CONTRACTS: "Contratos Ativos",
  CONCLUDED_CONTRACTS: "Contratos Concluídos",
  ACTIVE_CONTRACTS_PROGRESS: "Evolução Contratos ativos",
  TEMPLATE_CONFIRM_MESSAGE:
    "As alterações que efetuou serão substituídas. Tem a certeza de que pretende aplicar este modelo?",
  THIS_MONTH: "Este mês",
  ALLOW_FUTURE: "Permitir datas futuras",
  TASK_TYPE: "Tipo de tarefa",
  TASK_TYPES: "Tipos de tarefas",
  ADD_TASK_TYPE: "Adicionar tipo de tarefa",
  EDIT_TASK_TYPE: "Editar tipo de tarefa",
  TASK_TYPES_LIST_EMPTY: "Listagem de tipos de tarefas vazias.",
  TASK_TYPES_LIST_EMPTY_DESCRIPTION:
    "Não existem registos de tipos de tarefas associadas.",
  ELIMINATE_DOCUMENT_TYPE: "Quer apagar este tipo de documento?",
  API_FAILED_LIST: "Ocorreu um erro ao obter a lista pedida",
  API_REPEATED_RECORDS: "Erro - Registos repetidos",
  API_INVALID_TYPE: "Erro - Tipo invalido",
  API_FAILED_GETTING_DETAILS: "Ocorreu um erro na obtenção dos detalhes",
  API_INVALID_COMPANY: "Erro - Empresa invalida",
  INVALID_DEPARTMENT: "Erro - Departamento Inválido",
  API_INVALID_TEAM: "Erro - Equipa Inválida",
  API_DOCUMENT_TYPE_NOT_FOUND: "Não foi encontrado esse tipo de documento",
  API_STATUS_INVALID: "Erro - Estado inválido",
  API_COLLABORATOR_INVALID: "Erro - Colaborador inválido",
  API_FOLLOWERS_INVALID: "Erro - Seguidor inválido",
  API_TEMPLATE_TYPE_INVALID: "Erro - Tipo de template inválido",
  API_NAME_TOLARGE: "Nome demasiado grande",
  API_DESCRIPTION_TOLARGE: "Descrição demasiado grande",
  API_CLIENT_INVALID: "Erro - Cliente inválido",
  API_DEAL_INVALID: "Erro - Negócio inválido",
  API_CONTRACT_INVALID: "Erro - Contrato inválido",
  API_SUBSCRIPTION_INVALID: "Erro - Subscrição inválida",
  API_TICKET_INVALID: "Erro - Caso inválido",
  API_PROJECT_INVALID: "Erro - Projecto inválido",
  API_TASK_INVALID: "Erro - Tarefa inválida",
  API_TIMECHARGE_INVALID: "Erro - Intervenção inválida",
  API_INVALID_ASSOCIATION: "Serviço associado inválido",
  API_FAIL_UPLOAD_FILES:
    "Ocorreu um erro ao fazer o upload de um ou mais ficheiros",
  API_DOCUMENT_TYPE_REQUIRED: "Falta tipo de documento",
  TASK_TYPE_EQUALS: "Tipo de tarefa é igual a",
  API_CAN_NOT_REMOVE_STATUS:
    "Não é possível remover um estado de pipeline que já se encontra em uso",
  API_CAN_NOT_CHANGE_STATUS:
    "Não é possível alterar o estado de um estado de pipeline que já se encontra em uso",
  API_INVALID_PIPELINE_STATUS: "Erro - Mudança de Estado inválida",
  API_PIPELINE_STATUS_INVALID: "Erro - Estado Pipeline Inválido",
  PIPELINE_STATUS_CHANGE_DATE: "Data em que o pipeline estado mudou",
  STATUS_CHANGE_DATE: "Data em que o estado mudou",
  LAST_PIPELINE_STATUS_ACCOUNT: "Quem fez a última mudança de pipeline estado",
  LAST_PIPELINE_STATUS_DATE: "Data da última mudança de pipeline estado",
  LAST_STATUS_ACCOUNT: "Quem fez a última mudança de estado",
  LAST_STATUS_DATE: "Data da última mudança de estado",
  FILTER_NOT_NEXT_X_DAYS_PREVIEW: "Não nos próximos {days} dias de {field}",
  FILTER_NOT_LAST_X_DAYS_PREVIEW: "Não nos últimos {days} dias de {field}",
  FILTER_NOT_NEXT_DAYS: "não nos próximos dias",
  FILTER_NOT_LAST_DAYS: "não nos últimos dias",
  FILTER_LAST_DAYS: "nos últimos dias",
  FILTER_NEXT_DAYS: "nos próximos dias",
  API_TIMECHARGE_TYPE_INVALID: "Erro - Tipo de tempo inválido",
  DENIED_WARNING_NOTIFICATIONS:
    "Detetámos que o seu browser está a bloquear notificações.",
  WARNING_SEND_MAIL_MESSAGE:
    "Este relatório só será enviado se o contrato tiver um contacto ativo e com email associado.",
  API_INVALID_EXPORT_TYPE: "Tem de selecionar um tipo de exportação.",
  API_CONTRACT_NOT_FOUND: "Contrato não encontrado",
  API_CONTRACT_HISTORY_ERROR:
    "Ocorreu um erro na obtenção das informações para o relatório",
  API_CONTRACT_CLIENT_ERROR:
    "Não foi possível encontrar o cliente relacionado com este contrato",
  API_ASSOCIATED_COMPANY: "Não foi possível encontrar uma empresa associada",
  API_ERROR_READ_PDF_CONTENT: "Erro ao anexar o PDF ao email",
  API_ERROR_SENT_EMAIL: "Ocorreu um erro ao enviar o email.",
  API_CONTACT_ASSOCIATED_WITH_EMAIL: "Não existe contacto associado com email",
  API_CONTACT_ASSOCIATED_INVALID: "Contacto associado inválido",
  API_PIPELINE_TYPE_INVALID: "Tipo de pipeline é invalido",
  API_EXIST_INVALID_TICKETS: "Existe um ou mais casos inválidos",
  API_INVALID_REQUEST_MAX_25: "Não é possível exportar mais de 25 elementos",
  API_INVALID_DATA_REPORT: "Erro na obtenção dos dados para o relatório",
  API_CREATE_PDF_REPORT: "Ocorreu um erro ao criar o anexo para o email",
  COST_CENTER: "Centro de Custo",
  EMPTY_COST_CENTER_LIST: "Não existem registos de centros de custo.",
  NO_COST_CENTER_LIST: "Nenhum centro de custo disponível",
  ADD_COST_CENTER: "Adicionar centro de custo",
  COST_CENTERS: "Centros de custo",
  PRODUCTIVITY_REPORT: "Relatório de Produtividade",
  PRODUCTIVITY: "Produtividade",
  PRODUCTIVITY_DESCRIPTION:
    "Consulte os totais de tempos classificados pelos seus colaboradores agrupados por centros de custo.",
  PROD_AVERAGE: "Média",
  API_INVALID_ACCOUNT: "Existe um ou mais utilizadores inválidos",
  API_COST_CENTER_REQUIRED: "Centro de custo obrigatório",
  API_NOT_FOUND_OR_NO_PERMISSIONS_EXPORT:
    "Não foi encontrado o conteúdo pretendido ou não tem permissão para exportar",
  PRODUCTION_TOTAL_TIME: "Total Tempos",
  PRODUCTION_AVERAGE_TIMES: "Média Tempos",
  PRODUCTION_TOTAL_TRAVEL: "Total Deslocação",
  PRODUCTION_AVERAGE_TRAVEL: "Média Deslocação",
  PRODUCTION_AVERAGE_FINAL: "Média final",
  PRODUCTION_TOTAL_TIME_ABV: "Total T.",
  PRODUCTION_AVERAGE_TIMES_ABV: "Média T.",
  PRODUCTION_TOTAL_TRAVEL_ABV: "Total D.",
  PRODUCTION_AVERAGE_TRAVEL_ABV: "Média D.",
  PRODUCTION_AVERAGE_FINAL_ABV: "Média F.",
  TRAVEL_TIMES: "Registos de Deslocações",
  TRAVEL_TIMES_DESCRIPTION:
    "Consulte todos os registos de tempos que contêm deslocações.",
  DESLOCATION_CONTRACT: "Inclui deslocações",
  NEW_ITEMS_ADDDED_OR_UPDATED: "Foram adicionados/editados novos itens",
  DESCRIPTION_TOO_BIG:
    "Conteúdo do campo descrição demasiado grande. Por favor, reduza o tamanho do texto/imagem. Se tiver imagens coloque como anexo.",
  INTERNAL_DESCRIPTION_TOO_BIG:
    "Conteúdo do campo descrição interna demasiado grande. Por favor, reduza o tamanho do texto/imagem. Se tiver imagens coloque como anexo.",
  TICKET_LIMIT: "Limite de casos",
  TICKETS_ASSIGNED: "Casos associados",
  SUPPORT_EMAIL: "Email de Suporte",
  CONTINUE_TO_WEBSITE: "Continuar para a página",
  ANY_SITUATION: "Qualquer situação",
  INBOX_EMAIL_SENT_STATUS_DELAYED: "A entrega de email foi atrasada.",
  API_INVALID_TAGS: "Existe uma ou mais marcador inválidas",
  API_REPEATED_TAG: "Já existe um marcador com esse nome",
  API_EMAIL_SUPPORT_EMAIL_INVALID:
    "Não é possível enviar email para o email de suporte",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_TO:
    "O email de suporte não pode ser usado no campo para",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_CC:
    "O e-mail de suporte não é permitido no campo CC",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_BCC:
    "O e-mail de suporte não é permitido no campo BCC",
  CASE_TAGS: "Marcadores de Casos",
  API_FAILED_CONNECTION: "Falha ao conectar ao serviço",
  API_FAILED_CONNECTION_TO_EXPORT_PDF:
    "Não foi possível conectar ao serviço de exportação de PDF. Tente novamente mais tarde.",
  TAGS: "Marcadores",
  TAG: "Marcador",
  CONVERSATION_REPLIED: "respondeu na conversa de email com o assunto ",
  ADD_CASE_TAG: "Adicionar marcador de caso",
  EDIT_CASE_TAG: "Editar marcador de caso",
  CASE_TAG: "Marcador de Caso",
  EMAIL_CONVERSATION: "Conversa de Email",
  CASE_CONVERSATIONS: "Conversas do Caso",
  CONVERSATION_NOTE_CREATED:
    "adicionou uma nota na conversa de email com o assunto ",
  NOTIFICATION_TOPIC_INBOX_NEW_INTERNAL_NOTE_TITLE:
    "Quando é adicionada uma nova nota interna á conversa de email",
  NOTIFICATION_TOPIC_INBOX_NEW_INTERNAL_NOTE_DESCRIPTION:
    "Receba uma notificação cada vez que é associada uma nova nota interna á conversa",
  THE_EMAIL_CONVERSATION_STATUS: "O estado da conversa de email",
  CASE_TAGS_LIST_EMPTY: "Listagem de marcadores de caso vazias.",
  CASE_TAGS_LIST_NO: "Não existem registos de marcadores de casos associadas.",
  API_FAILED_GENERATING_PDF:
    "Ocorreu um erro a gerar o PDF. Por favor tente mais tarde.",
  EMAIL_CONVERSATION_EXPORT: "Exportar em pdf conversa de email",
  GET_EXPORT_FILE_BY_ID: "Obter ficheiro de exportação por Id",
  COMPLEMENTED_TIME: "Tempo Complementar",
  BUDGET_TIME: "Tempo Orçamentado",
  NOTE_CREATED_SUCCEFULLY: "Nota criada com sucesso",
  API_ERROR_DUPLICATE_INBOUND_EMAIL:
    "Já existe um email de inbox com este alias para esta empresa",
  PERSONAL_PRODUCTIVITY_DESCRIPTION:
    "Consulte os seus totais de tempos classificados.",
  EMAIL_SIZE_TOO_BIG:
    "O tamanho do seu e-mail é demasiado grande. Por favor, reduza o tamanho do texto/imagens/anexos",
  EXTERNAL_USER: "Utilizador Externo",
  API_TAG_INVALID_DISABLED_INBOUND_EMAIL:
    "Não pode desativar um marcador que está associado a um email de inbox",
  PLAN_PUBLIC_API: "API Pública",
  API_DATE_INVALID_INTERVAL: "As datas estão no intervalo errado",
  API_INVALID_MODEL: "Modelo inválido",
  API_ERROR_NO_DATA: "Falha ao processar dados",
  API_FAILED_GENERATING_EXCEL: "Falha ao gerar o excel",
  HTTP_STATUS_REQUEST: "Pedido estado Http ",
  NOTIFICATION_TOPIC_INBOX_CONVERSATION_PARTICIPATION_TITLE:
    "Quando é enviado ou recebido um email associado a uma conversa de email que tenha participado anteriormente.",
  NOTIFICATION_TOPIC_INBOX_CONVERSATION_PARTICIPATION_DESCRIPTION:
    "Recebe uma notificação cada vez que é associado um email ou nota interna numa conversa de email que tenha enviado ou um email ou uma nota interna",
  EMAIL_CONVERSATION_EMAIL_PERSONAL_RESPONSE: "Receber notificação de resposta",
  NOTIFICATION_EMAIL_CONVERSATION_PARTICIPATION:
    "A conversa de email, em que <b>participou</b>, com o assunto <b>{subject}</b> foi atualizada.",
  NOTIFICATION_TOPIC_INBOX_NEW_EMAIL_FROM_OUT_TITLE:
    "Quando é recebido um email associado a uma conversa de email",
  NOTIFICATION_TOPIC_INBOX_NEW_EMAIL_FROM_OUT_DESCRIPTION:
    "Receba uma notificação cada vez que é recebido um email associado a uma conversa de email.",
  NOTIFICATION_TOPIC_INBOX_MODIFIED_TITLE:
    "Quando é modificada uma conversa de email",
  NOTIFICATION_TOPIC_INBOX_MODIFIED_DESCRIPTION:
    "Receba uma notificação cada vez que é alterada uma conversa de email",
  NOTIFICATION_EMAIL_CONVERSATION_PARTICIPATION_FROM_OUTSIDE:
    "A conversa de emaill, em que <b>participou</b>, com o assunto <b>{subject}</b> recebeu um novo email.",
  CONVERSATION_REPLIED_PARTICIPATION:
    "respondeu na conversa de emaill, em que <b>participou</b>, com o assunto <b>{subject}</b>",
  API_ERROR_EMAIL_REFUSED:
    "Email enviado com sucesso, mas o servidor de destino recusou o mesmo.",
  API_CLIENT_DELETED: "Cliente eliminado",
  API_ATTACHMENTS_TOLARGE: "O tamanho do anexo excede o limite permitido",
  DEAL_TAGS_LIST_EMPTY: "Listagem de marcadores de negócios vazios.",
  DEAL_TAGS_LIST_NO:
    "Não existem registos de marcadores de negócios associados.",
  DEAL_TAG: "Marcador de Negocio",
  ADD_DEAL_TAG: "Adicionar marcador de negócio",
  EDIT_DEAL_TAG: "Editar marcador de negócio",
  ACCOUNT_TAGS_LIST_EMPTY: "Listagem de marcadores de conta vazio.",
  ACCOUNT_TAGS_LIST_NO:
    "Não existem registos de marcadores de conta associados.",
  ACCOUNT_TAG: "Marcador de conta",
  ADD_ACCOUNT_TAG: "Adicionar marcador de conta",
  EDIT_ACCOUNT_TAG: "Editar marcador de conta",
  DEAL_TAGS: "Marcadores de Negocio",
  ACCOUNT_TAGS: "Marcadores de conta",
  INVALID_TIME_VALUE_FOR_RENOVATION:
    "Valor inválido para o valor temporal da renovação do contrato",
  INVALID_TIME_VALUE_FOR_HOUR_CONTROL:
    "Valor inválido para o valor temporal da renovação do intervalo de horas",
  API_CLIENT_REQUIRED: "Conta obrigatória",
  CONTRACT_TAG: "Marcador de contrato",
  ADD_CONTRACT_TAG: "Adicionar marcador de contrato",
  EDIT_CONTRACT_TAG: "Editar marcador de contrato",
  CONTRACT_TAGS: "Marcadores de contrato",
  CONTRACT_TAGS_LIST_EMPTY: "Listagem de marcadores de contrato vazio.",
  CONTRACT_TAGS_LIST_NO:
    "Não existem registos de marcadores de contrato associados.",
  SUBSCRIPTION_TAGS: "Marcadores de subscrição",
  SUBSCRIPTION_TAG: "Marcador de subscrição",
  ADD_SUBSCRIPTION_TAGS: "Adicionar marcador de subscrição",
  EDIT_SUBSCRIPTION_TAGS: "Editar marcador de subscrição",
  SUBSCRIPTION_TAGS_LIST_EMPTY: "Listagem de marcadores de subscrição vazio.",
  SUBSCRIPTION_TAGS_LIST_NO:
    "Não existem registos de marcadores de subscrição associados.",
  CALL_TAGS: "Marcadores de chamadas",
  CALL_TAG: "Marcador de chamada",
  ADD_CALL_TAG: "Adicionar marcador de chamada",
  EDIT_CALL_TAG: "Editar marcador de chamada",
  CALL_TAGS_LIST_EMPTY: "Listagem de marcadores de chamadas vazio.",
  CALL_TAGS_LIST_NO:
    "Não existem registos de marcadores de chamada associados.",
  PROJECT_TAGS: "Marcadores de projeto",
  PROJECT_TAG: "Marcador de projeto",
  ADD_PROJECT_TAG: "Adicionar marcador de projeto",
  EDIT_PROJECT_TAG: "Editar marcador de projeto",
  PROJECT_TAGS_LIST_EMPTY: "Listagem de marcadores de projeto vazio.",
  PROJECT_TAGS_LIST_NO:
    "Não existem registos de marcadores de projeto associados.",
  TASK_TAGS: "Marcadores de tarefas",
  TASK_TAG: "Marcador de tarefa",
  ADD_TASK_TAG: "Adicionar marcador de tarefa",
  EDIT_TASK_TAG: "Editar marcador de tarefa",
  TASK_TAGS_LIST_EMPTY: "Listagem de marcadores de tarefas vazio.",
  TASK_TAGS_LIST_NO:
    "Não existem registos de marcadores de tarefas associados.",
  API_TICKET_MANAGER_INVALID:
    "O utilizador 'Atribuído a' não pertence ao 'Departamento/Equipa' indicado.",
  CANCELLED_CASES: "Casos Cancelados",
  CREATE_CONVERSATION: "Criar Conversa de Email",
  SEND_NOTIFICATION_WARNING:
    "Pretende notificar o Cliente sobre o estado deste caso?",
  SEND_DISABLED_EMAIL_MESSAGE: "Email de airbot inativo",
  API_TICKET_EMAIL_REPORT_SENT_SUCCESS:
    "Relatório de estado enviado por email com successo",
  API_TICKET_EMAIL_REPORT_SENT_ERROR:
    "Ocorreu um erro a enviar o relatório de estado por email",
  API_TICKET_EMAIL_REPORT_STARTED:
    "O pedido foi iniciado com sucesso. Será notificado quando este terminar.",
  API_COMPANY_NOT_FOUND: "Empresa não encontrada.",
  API_DEPARTMENT_NOT_FOUND: "Departamento não encontrado",
  HAS_SUPPORT_EMAIL: "Tem email de suporte",
  DOES_NOT_HAVE_SUPPORT_EMAIL: "Não tem email de suporte",
  API_STATUS_NAME_REQUIRED: "Nome do estado é obrigatório.",
  API_STATUS_REQUIRED: "É obrigatório inserir um estado.",
  API_PIPELINE_NAME_REQUIRED: "É obrigatório inserir o nome do Pipeline.",
  API_FEEDBACK_STATUS_INVALID: "Estado inválido para activar o feedback.",
  API_EMAIL_TO_LARGE: "Conteúdo do email ultrapassou o limite",
  API_STATUS_DETAIL_INVALID: "Motivo de cancelamento invalido",
  API_STATUS_DETAIL_DESCRIPTION_REQUIRED:
    "Descrição de motivo de cancelamento em falta",
  CHANGE_TAGS: "Alterar Marcadores",
  REMOVE_TAGS: "Remover marcadores",
  CLIENTS_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  DEALS_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  CONTRACTS_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  SUBSCRIPTIONS_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  CALL_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  TICKETS_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  PROJECTS_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  TASKS_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  TIMES_NO_PERMISSION:
    "não foram alterados devido a não ter a permissão necessária",
  API_PIPELINE_DISABLED: "Pipeline desativado",
  REPORT_EMAIL: "Relatório Email",
  CONTRACT_SIGNED: "Contrato Assinado",
  SIGNED: "Assinado",
  IS_LOCKED: "Está Bloqueado",
  FAILED_PASSWORD_ATTEMPTS: "Tentativas falhadas de palavra-passe",
  UNLOCK: "Desbloquear",
  NOTIFICATION_SUCCESS: "Notificação enviada com successo",
  ASK_NOTIFICATION_CASE: "Deseja notificar o contacto deste caso?",
  API_PERIOD_NO_CONTENT:
    "Não existe informação de marcadores para o período selecionado",
  SEND_CONTRACT_RENOVATION_WARNING:
    "Pretende notificar o Cliente por email da renovação deste contrato?",
  SEND_CONTRACT_RENOVATION_BUTTON: "Enviar Aviso Renovação",
  API_CONTRACT_RENOVATION_ALERT_EMAIL_SENT_ERROR:
    "Ocorreu um erro a enviar o email da renovação deste contrato",
  API_CONTRACT_NEED_EMAIL_CONTACT:
    "O contrato tem que ter um contacto com email válido.",
  API_CONTRACT_RENOVATION_ALERT_EMAIL_SENT_SUCCESS:
    "Aviso de renovação de contrato enviado com sucesso",
  EMAIL_SENT: "Email enviado",
  API_CONTRACT_NEED_OPV: "O contrato tem que ter uma OPV",
  BILLING_CLIENT: "Cliente Faturação",
  FINAL_CLIENT: "Cliente Final",
  FINAL_CLIENT_INVALID: "Cliente Final Invalido",
  BILLING_CLIENT_INVALID: "Cliente Faturação Invalido",
  CLIENT_CODE_ERP_INVALID: "Codigo ERP do cliente invalido",
  DEAL_SETTINGS_NOT_FOUND: "Configurações do Negócio não foram encontradas",
  CONTRACT_SETTINGS_NOT_FOUND:
    "Configurações do contrato não foram encontradas",
  SUBSCRIPTION_SETTINGS_NOT_FOUND:
    "Configurações da subscrição não foram encontradas",
  CLIENT_ERPCODE_INVALID: "O código de cliente ERP já existe",
  CONTRACT_ONLY_ONE_ORIGIN: "Os contratos só podem ter 1 origem",
  INVALID_CREATION_STATUS: "O estado selecionado é inválido para criação",
  DOCUMENT_NUMBER: "Numero do Documento",
  API_DOCUMENTTYPE_REQUIRED: "O tipo de documento é obrigatório",
  VIEW_SAVING_ERROR: "Ocorreu um erro ao gravar a vista",
  API_INVALID_INTERVENTIONTYPE: "Tipo de tempo é inválido"
};
