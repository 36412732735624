// import { PhoneNumber } from "../Components/Input/PhoneInput";
import { getFormmatedModules } from "../Helpers/ModulesHelper";
import {
  EntitySchema,
  fk,
  many,
  defineEntityCircularReference
} from "../Helpers/SchemaHelper";
import { schema } from "normalizr";

const mergeInterventionEntity = (oldEntity, newEntity) => {
  let resolvedOldEntity = { ...oldEntity };

  delete resolvedOldEntity.CloseDate;
  delete resolvedOldEntity.Project;
  delete resolvedOldEntity.Client;
  delete resolvedOldEntity.Ticket;
  delete resolvedOldEntity.Call;
  delete resolvedOldEntity.Task;
  delete resolvedOldEntity.Contract;
  delete resolvedOldEntity.Deal;

  return { ...resolvedOldEntity, ...newEntity };
};

const handleOriginUpdate = (oldEntity, newEntity) => {
  if (oldEntity.InboundEmail && !newEntity.InboundEmail) {
    delete oldEntity.InboundEmail;
  }
  if (oldEntity.InboundEmails && !newEntity.InboundEmails) {
    delete oldEntity.InboundEmails;
  }
  // if (oldEntity.Client && !newEntity.Client) {
  //   delete oldEntity.Client;
  // }
  // if (oldEntity.Contract && !newEntity.Contract) {
  //   delete oldEntity.Contract;
  // }
  // if (oldEntity.Ticket && !newEntity.Ticket) {
  //   delete oldEntity.Ticket;
  // }
  // if (oldEntity.IndirectClient && !newEntity.IndirectClient) {
  //   delete oldEntity.IndirectClient;
  // }

  const { Project, Client, Ticket, Call, Task, Contract, Deal } = newEntity;

  if (Project || Client || Ticket || Call || Task || Contract || Deal) {
    return mergeInterventionEntity(oldEntity, newEntity);
  }

  return {
    ...oldEntity,
    ...newEntity
  };
};

const handleShareWithUpdates = (oldEntity, newEntity) => {
  if (newEntity.FromSignalR) {
    return {
      ...oldEntity,
      ...newEntity
    };
  }

  delete oldEntity.Organizations;
  delete oldEntity.Departments;
  delete oldEntity.Teams;

  return {
    ...oldEntity,
    ...newEntity
  };
};

export const industrySchema = new EntitySchema("Industries");
export const UserDocumentType = new EntitySchema("UserDocumentType");

export const countrySchema = new EntitySchema("Countries");

export const currencySchema = new EntitySchema("Currencies");

export const spaceSchema = new EntitySchema("Spaces", {
  relationships: {
    AvailableCurrencies: many(currencySchema),
    Currency: fk(currencySchema),
    SpaceCurrencies: many(currencySchema),
    Country: fk(countrySchema)
  }
});

export const accountSummarySpaceSchema = new EntitySchema("AccountSpaces", {
  processStrategy: (value, parent, key) => {
    //  if(!value.Modules){
    //    value.Modules = getFormmatedModules(value.Modules);
    //  }

    if (value.Account) {
      const { Account, ...rest } = value;
      value.Account.AccountSpace = rest;
    }

    return value;
  },
  relationships: {
    Space: fk(spaceSchema),
    AccountDocumentType: fk(UserDocumentType)
  }
});

export const spaceUserCategoriesSchema = new EntitySchema(
  "SpaceUserCategories",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/Settings/Users/Category`
  }
);

export const spaceCostCentersSchema = new EntitySchema("SpaceCostCenters", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Settings/Users/CostCenter`
});

export const accountSchema = new EntitySchema("Accounts", {
  getQueryEndpoint: (spaceId, value) =>
    `spaces/${spaceId}/query/accounts?query=${value}`,
  getEndpoint: (spaceId) => `spaces/${spaceId}/users`,
  processStrategy: (value, parent, key) => {
    let { AccountSpace, ...newValue } = value;
    if (!AccountSpace) return value;
    return { ...newValue, AccountSpaces: [AccountSpace] };
  },
  handleEntityUpdate: (oldEntity, newEntity) => {
    let value = {
      ...oldEntity,
      ...newEntity
    };

    if (
      typeof oldEntity.Category === "number" &&
      typeof newEntity.Category === "undefined"
    ) {
      delete value.Category;
    }
    // if (
    //   typeof oldEntity.CostCenter === "number" &&
    //   typeof newEntity.CostCenter === "undefined"
    // ) {
    //   delete value.CostCenter;
    // }

    if (oldEntity.AccountSpaces) {
      if (newEntity.AccountSpaces) {
        const filtered = oldEntity.AccountSpaces.filter(
          (a) => !newEntity.AccountSpaces.includes(a)
        );

        value.AccountSpaces = [...filtered, ...newEntity.AccountSpaces];
      }
    }

    return value;
  },
  relationships: {
    AccountSpaces: many(accountSummarySpaceSchema),
    Category: fk(spaceUserCategoriesSchema),
    CostCenter: fk(spaceCostCentersSchema),
    Country: fk(countrySchema),
    AccountDocumentType: fk(UserDocumentType),
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ]
  }
});

defineEntityCircularReference(spaceCostCentersSchema, {
  Accounts: many(accountSchema)
});

defineEntityCircularReference(accountSchema, {
  Account: fk(accountSchema)
});

export const UserControlSchema = new EntitySchema("UserControl", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/UserControl/Users`,
  relationships: {
    Account: fk(accountSchema),
    AccountSpace: fk(accountSummarySpaceSchema),
    AccountSpaces: many(accountSummarySpaceSchema)
  }
});

export const inviteSchema = new EntitySchema("Invites", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/settings/invites`,
  relationships: {
    InviterAccount: fk(accountSchema),
    Country: fk(countrySchema),
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ]
  }
});

defineEntityCircularReference(accountSummarySpaceSchema, {
  Account: fk(accountSchema)
});

export const spaceNoteSchema = new EntitySchema("SpaceNotes", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/notes`,
  relationships: {
    Account: fk(accountSchema),
    ReadUsers: many(accountSchema)
  },
  processStrategy: (value, parent, key) => {
    let { ReadUsers, LastMessageRead } = value;
    const newValue = { ...value };
    if (!ReadUsers) newValue.ReadUsers = undefined;

    if (!LastMessageRead) newValue.LastMessageRead = false;

    return { ...newValue };
  }
});
defineEntityCircularReference(spaceNoteSchema, {
  ParentNote: fk(spaceNoteSchema)
});

export const spaceFlatContactSchema = new EntitySchema("SpaceContacts");

export const spaceClientTypeSchema = new EntitySchema("SpaceClientType", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/clienttypes`
});

export const spaceBreadcrumbsSchema = new EntitySchema("SpaceBreadcrumbs");

const OriginChild = new schema.Union({}, "Type");
OriginChild.addEntity = (type, entity) => {
  OriginChild.schema[type] = entity;
};

export const spacePipelineStatusSchema = new EntitySchema(
  "SpacePipelineStatus",
  {
    // getEndpoint: spaceId => `spaces/${spaceId}/Pipelines`,
    // handleEntityUpdate: handleOriginUpdate
  }
);

export const spacePipelineSchema = new EntitySchema("SpacePipelines", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Pipelines`,
  relationships: {
    PipelineStatus: many(spacePipelineStatusSchema)
  }
  // handleEntityUpdate: handleOriginUpdate
});

export const spaceErrorLogSchema = new EntitySchema("SpaceErrorLog", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Logs`,
  relationships: {
    Account: fk(accountSchema)
  }
  // handleEntityUpdate: handleOriginUpdate
});

export const emailWebhooksLogSchema = new EntitySchema("EmailWebhooksLog", {
  getEndpoint: (spaceId) => `CommonTools/PostalWebhook`,
  relationships: {}
  // handleEntityUpdate: handleOriginUpdate
});
export const emailOutboundWebhooksLogSchema = new EntitySchema(
  "OutboundEmailWebhooksLog",
  {
    getEndpoint: (spaceId) => `CommonTools/PostalSendEmail`,
    relationships: {}
    // handleEntityUpdate: handleOriginUpdate
  }
);
export const reprocessingLogSchema = new EntitySchema("ReprocessingLog", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/QueueSettings/ReprocessingQueue`,
  relationships: {}
  // handleEntityUpdate: handleOriginUpdate
});

export const spaceCuponSchema = new EntitySchema("SpacesCupons", {
  getEndpoint: (spaceId) => `Payment/Coupons`,
  relationships: {
    TargetSpaces: many(spaceSchema)
  }
});

export const spacesListSchema = new EntitySchema("SpacesList", {
  getEndpoint: (spaceId) => `spaces/List`,
  relationships: {
    Accounts: many(accountSchema),
    Owner: fk(accountSchema)
  }
  // handleEntityUpdate: handleOriginUpdate
});

export const spaceAddressSchema = new EntitySchema("SpaceAddress", {
  relationships: {
    Country: fk(countrySchema)
  }
});

export const spaceClientSchema = new EntitySchema("SpaceClients", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/clients`,
  getSchemaName: () => "clients",
  relationships: {
    Contacts: many(spaceFlatContactSchema),
    Industries: many(industrySchema),
    Manager: fk(accountSchema),
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ],
    ClientTypes: many(spaceClientTypeSchema),
    FiscalCountry: fk(countrySchema),
    Creator: fk(accountSchema),
    Comments: many(spaceNoteSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  handleEntityUpdate: (oldEntity, newEntity) => {
    let value = {
      ...oldEntity,
      ...newEntity
    };

    if (value.FromSignalR) return value;

    if (oldEntity.Image) {
      if (newEntity.Image === "") {
        delete value.Image;
      }
    }

    if (oldEntity.PhoneNumber) {
      if (newEntity.PhoneNumber === "") {
        delete value.PhoneNumber;
      }
    }
    if (oldEntity.Domain) {
      if (!newEntity.Domain) {
        delete value.Domain;
      }
    }
    if (oldEntity.Email) {
      if (!newEntity.Email) {
        delete value.Email;
      }
    }

    return value;
  }
});
OriginChild.addEntity("client", fk(spaceClientSchema));

export const spaceContactSchema = new EntitySchema("SpaceContacts", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/contacts`,
  getSchemaName: () => "contacts",
  relationships: {
    Clients: many(spaceClientSchema),
    Creator: fk(accountSchema),
    AccountsInRoom: many(accountSchema)
    // Organizations: many(spaceFlatOrganizationSchema)
  },
  processStrategy: (value, parent, key) => {
    // value.PhoneNumber = new PhoneNumber(value.PhoneNumber);

    return value;
  },
  handleEntityUpdate: (oldEntity, newEntity) => {
    let value = {
      ...oldEntity,
      ...newEntity
    };

    if (value.FromSignalR) return value;

    if (oldEntity.PhoneNumber) {
      if (newEntity.PhoneNumber === "") {
        delete value.PhoneNumber;
      }
    }
    if (oldEntity.MobileNumber) {
      if (!newEntity.MobileNumber) {
        delete value.MobileNumber;
      }
    }
    if (oldEntity.Email) {
      if (!newEntity.Email) {
        delete value.Email;
      }
    }
    return value;
  }
});

export const ContactMediumTypes = {
  Email: 1,
  PhoneNumber: 2
};

export const spaceExportsSchema = new EntitySchema("SpaceExports", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/settings/export`,
  relationships: {
    Account: fk(accountSchema)
  }
});

export const spaceAccountExportsSchema = new EntitySchema(
  "SpaceAccountExports",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/Export/User`,
    relationships: {
      Account: fk(accountSchema)
    }
  }
);

export const spaceImportsSchema = new EntitySchema("SpaceImports", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/imports`,
  relationships: {
    Account: fk(accountSchema)
  }
});

const handleOrgUpdate = (oldEntity, newEntity) => {
  const { HasDomain } = newEntity;
  // ;

  const resolvedE = {
    ...oldEntity,
    ...newEntity
  };

  if (HasDomain === false) {
    delete resolvedE.Domain;
    delete resolvedE.HasDomain;
  }

  return resolvedE;
};

export const spaceOrganizationSchema = new EntitySchema("SpaceOrganizations", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/organizations`,
  relationships: {
    Managers: many(accountSchema),
    Industry: many(industrySchema),
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ],
    Collaborators: many(accountSchema)
  },
  processStrategy: (value, parent, key) => {
    let { ContactMediums, ...newValue } = value;
    if (!ContactMediums) return newValue;

    let phone = ContactMediums.find(
      (v) => v.ContactMediumTypeId === ContactMediumTypes.PhoneNumber
    );
    if (phone) newValue.PhoneNumber = phone.Contact;

    // if (phone) newValue.PhoneNumber = new PhoneNumber(phone.Contact);

    let email = ContactMediums.find(
      (v) => v.ContactMediumTypeId === ContactMediumTypes.Email
    );
    if (email) newValue.Email = email.Contact;

    return newValue;
  },
  handleEntityUpdate: handleOrgUpdate
});

export const spaceTaskTypeSchema = new EntitySchema("SpaceProjectTaskType", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/projectTaskType`,
  relationships: {
    Organizations: many(spaceOrganizationSchema)
  }
});

export const spaceSocialAccountSchema = new EntitySchema(
  "spaceSocialAccounts",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/social`,
    relationships: {
      Creator: fk(accountSchema),
      Organizations: many(spaceOrganizationSchema)
    }
  }
);

export const spaceDepartmentSchema = new EntitySchema("SpaceDepartments", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/departments`,
  relationships: {
    ParentOrganization: fk(spaceOrganizationSchema),
    Managers: many(accountSchema),
    Collaborators: many(accountSchema)
  },
  processStrategy: (value, parent, key) => {
    let { ContactMediums, ...newValue } = value;
    if (!ContactMediums) return newValue;

    let phone = ContactMediums.find(
      (v) => v.ContactMediumTypeId === ContactMediumTypes.PhoneNumber
    );
    if (phone) newValue.PhoneNumber = phone.Contact;
    // if (phone) newValue.PhoneNumber = new PhoneNumber(phone.Contact);

    let email = ContactMediums.find(
      (v) => v.ContactMediumTypeId === ContactMediumTypes.Email
    );
    if (email) newValue.Email = email.Contact;

    return newValue;
  }
});

export const SpaceDocTemplateFoldersSchema = new EntitySchema(
  "SpaceDocTemplateFolders",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/Folders/1`,
    relationships: {
      Organizations: many(spaceOrganizationSchema)
    }
  }
);
export const SpaceDocFoldersSchema = new EntitySchema("SpaceDocFolders", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Folders/0`,
  relationships: {
    Organizations: many(spaceOrganizationSchema)
  }
});

export const spaceContractTypeSchema = new EntitySchema("SpaceContractTypes", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/contractTypes`,
  relationships: {
    Organizations: many(spaceOrganizationSchema)
  }
});

export const spaceEmailDomainsSchema = new EntitySchema("spaceEmailDomains", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/EmailDomain`,
  relationships: {
    Organizations: many(spaceOrganizationSchema)
  }
});

export const spaceApiTokensSchema = new EntitySchema("spaceApiTokens", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/ApiKey`,
  relationships: {
    Account: fk(accountSchema),
    Organization: fk(spaceOrganizationSchema),
    Creator: fk(accountSchema)
  }
});

export const spaceApiLogsSchema = new EntitySchema("spaceApiLogs", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/ApiLog`,
  relationships: {
    Impresonate: fk(accountSchema)
  }
});
export const spaceFeedbackQueueSchema = new EntitySchema(
  "spaceFeedbackQueueSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/QueueSettings/Feedback`
  }
);
export const spaceNotificationsQueueSchema = new EntitySchema(
  "spaceNotificationsQueueSchema",
  {
    getEndpoint: (spaceId) =>
      `spaces/${spaceId}/QueueSettings/NotificationQueue`
  }
);
export const spaceAutomationTriggerQueueSchema = new EntitySchema(
  "spaceAutomationTriggerQueueSchema",
  {
    getEndpoint: (spaceId) =>
      `spaces/${spaceId}/QueueSettings/AutomationTrigger`
  }
);

export const spaceAllUsersPermissionsQueueSchema = new EntitySchema(
  "spaceAllUsersPermissionsQueueSchema",
  {
    getEndpoint: (spaceId) =>
      `spaces/${spaceId}/QueueSettings/AllUsersPermissionQueue`
  }
);
export const spaceAutomationEnrollQueueSchema = new EntitySchema(
  "spaceAutomationEnrollQueueSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/QueueSettings/AutomationEnroll`
  }
);

export const spaceAutomationEmailConversationExportQueueSchema =
  new EntitySchema("spaceAutomationEmailConversationExportQueueSchema", {
    getEndpoint: (spaceId) =>
      `spaces/${spaceId}/QueueSettings/AutomationEmailConversationGenerationQueue`
  });

export const spaceWidgetEmailQueueSchema = new EntitySchema(
  "spaceWidgetEmailQueueSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/QueueSettings/WidgetEmailQueue`
  }
);
export const spaceWidgetTicketImageQueueSchema = new EntitySchema(
  "spaceWidgetTicketImageQueueSchema",
  {
    getEndpoint: (spaceId) =>
      `spaces/${spaceId}/QueueSettings/WidgetTicketImageQueue`
  }
);
export const spaceEnrollManagerQueueSchema = new EntitySchema(
  "spaceEnrollManagerQueueSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/QueueSettings/Enroll`
  }
);
export const spacePermissionQueueSchema = new EntitySchema(
  "spacePermissionQueueSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/QueueSettings/Permission`
  }
);
export const spaceBreadcrumbsQueueSchema = new EntitySchema(
  "spaceBreadcrumbsQueueSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/QueueSettings/BreadcrumbCache`,
    relationships: {
      Accounts: many(accountSchema)
    }
  }
);

defineEntityCircularReference(spaceOrganizationSchema, {
  Domain: fk(spaceEmailDomainsSchema)
});

export const spaceSubscriptionTypeSchema = new EntitySchema(
  "SpaceSubscriptionTypes",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/subscriptionTypes`,
    relationships: {
      Organizations: many(spaceOrganizationSchema)
    }
  }
);

export const spaceWidgetLanguagesSchema = new EntitySchema(
  "SpaceWidgetLanguages",
  {}
);

export const spaceIneficientTypesSchema = new EntitySchema(
  "SpaceIneficientTypes",
  {
    getEndpoint: (spaceId) =>
      `spaces/${spaceId}/TimeDetail/settingsInterventions/1`,
    relationships: {
      Organizations: many(spaceOrganizationSchema)
    }
  }
);

export const spaceTravelTimeAmmountSchema = new EntitySchema(
  `SpaceTravelTimeAmmount`,
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/TravelSettings`
  }
);

export const AlterEgoControlOverviewSchema = new EntitySchema("SpaceAlterEgo", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/UserControl/AlterEgo`,
  relationships: {
    Account: fk(accountSchema),
    TargetAccount: fk(accountSchema)
  }
});

export const spaceTeamSchema = new EntitySchema("SpaceTeams", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/teams`,
  relationships: {
    Managers: many(accountSchema),
    Collaborators: many(accountSchema),
    ParentDepartment: fk(spaceDepartmentSchema),
    ParentOrganization: fk(spaceOrganizationSchema)
  },
  processStrategy: (value, parent, key) => {
    let { ContactMediums, ...newValue } = value;
    if (!ContactMediums) return newValue;

    let phone = ContactMediums.find(
      (v) => v.ContactMediumTypeId === ContactMediumTypes.PhoneNumber
    );
    if (phone) newValue.PhoneNumber = phone.Contact;
    // if (phone) newValue.PhoneNumber = new PhoneNumber(phone.Contact);

    let email = ContactMediums.find(
      (v) => v.ContactMediumTypeId === ContactMediumTypes.Email
    );
    if (email) newValue.Email = email.Contact;

    return newValue;
  }
});

export const spaceCancelationReasonSchema = new EntitySchema(
  "SpaceCancellationReasons",
  {
    // getEndpoint: spaceId => `spaces/${spaceId}/CancellationReasons`,
    relationships: {
      Teams: many(spaceTeamSchema),
      Departments: many(spaceDepartmentSchema),
      Organizations: many(spaceOrganizationSchema)
    }
  }
);

export const spaceChecklistTemplatesSchema = new EntitySchema(
  "SpaceChecklistTemplates",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/Settings/Checklist`,
    relationships: {
      Companies: many(spaceOrganizationSchema)
    }
  }
);

export const spaceInviteSchema = new EntitySchema("SpaceInvites", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/invites`,
  relationships: {
    InviterAccount: fk(accountSchema),
    Organizations: many(spaceOrganizationSchema),
    CostCenter: fk(spaceCostCentersSchema),
    Departments: many(spaceDepartmentSchema),
    Teams: many(spaceTeamSchema)
  }
});

export const spaceSocialPostsSchema = new EntitySchema("SpaceSocialPosts", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/social/post`,
  relationships: {
    Creator: fk(accountSchema),
    Social: fk(spaceSocialAccountSchema),
    AccountsInRoom: many(accountSchema)
  }
});

export const spaceDealsSchema = new EntitySchema("SpaceDeals", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/deals`,
  getSchemaName: () => "deals",
  relationships: {
    Client: fk(spaceClientSchema),
    IndirectClient: fk(spaceClientSchema),
    PipelineAccount: fk(accountSchema),
    StatusAccount: fk(accountSchema),
    // Pipeline: fk(pipeline),
    Contact: fk(spaceContactSchema),
    Creator: fk(accountSchema),
    Departments: many(spaceDepartmentSchema),
    Collaborators: {
      Accounts: many(accountSchema),
      Teams: many(spaceTeamSchema)
      // Departments: many(spaceDepartmentSchema)
    },
    DealManager: fk(accountSchema),
    Assigned: fk(accountSchema),
    Company: fk(spaceOrganizationSchema),
    // CancelationReason: fk(spaceCancelationReasonSchema),
    StatusDetail: fk(spaceCancelationReasonSchema),
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    Comments: many(spaceNoteSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  handleEntityUpdate: handleOriginUpdate
});

export const spaceContractSchema = new EntitySchema("SpaceContracts", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/contracts`,
  getSchemaName: () => "contracts",
  relationships: {
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    PipelineAccount: fk(accountSchema),
    StatusAccount: fk(accountSchema),
    Client: fk(spaceClientSchema),
    IndirectClient: fk(spaceClientSchema),
    Deal: fk(spaceDealsSchema),
    Departments: many(spaceDepartmentSchema),
    Collaborators: {
      Accounts: many(accountSchema),
      Teams: many(spaceTeamSchema)
    },
    StatusDetail: fk(spaceCancelationReasonSchema),
    Creator: fk(accountSchema),
    Manager: fk(accountSchema),
    Contact: fk(spaceContactSchema),
    ContractType: fk(spaceContractTypeSchema),
    Company: fk(spaceOrganizationSchema),
    Comments: many(spaceNoteSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  processStrategy: (value, parent, key) => {
    if (value.FromDate) value.FromDate = new Date(value.FromDate);
    if (value.ToDate) value.ToDate = new Date(value.ToDate);

    return value;
  },
  handleEntityUpdate: handleOriginUpdate
});
OriginChild.addEntity("contract", fk(spaceContractSchema));

export const spaceSubscriptionSchema = new EntitySchema("SpaceSubscriptions", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Subscriptions`,
  getSchemaName: () => "subscriptions",
  relationships: {
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    Client: fk(spaceClientSchema),
    PipelineAccount: fk(accountSchema),
    StatusAccount: fk(accountSchema),
    IndirectClient: fk(spaceClientSchema),
    Deal: fk(spaceDealsSchema),
    Departments: many(spaceDepartmentSchema),
    Collaborators: {
      Accounts: many(accountSchema),
      Teams: many(spaceTeamSchema)
    },
    Creator: fk(accountSchema),
    Manager: fk(accountSchema),
    Contact: fk(spaceContactSchema),
    StatusDetail: fk(spaceCancelationReasonSchema),
    SubscriptionType: fk(spaceSubscriptionTypeSchema),
    Company: fk(spaceOrganizationSchema),
    Comments: many(spaceNoteSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  processStrategy: (value, parent, key) => {
    if (value.FromDate) value.FromDate = new Date(value.FromDate);
    if (value.ToDate) value.ToDate = new Date(value.ToDate);

    return value;
  },
  handleEntityUpdate: handleOriginUpdate
});
OriginChild.addEntity("subscription", fk(spaceSubscriptionSchema));

export const spaceLostReasonSchema = new EntitySchema("SpaceLostReasonSchema", {
  // getEndpoint: spaceId => `spaces/${spaceId}/CancellationReasons`,
  relationships: {
    Teams: many(spaceTeamSchema),
    Departments: many(spaceDepartmentSchema),
    Organizations: many(spaceOrganizationSchema)
  }
});

export const spaceProjectCategorySchema = new EntitySchema(
  "SpaceProjectCategories",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/projecttypes`,
    relationships: {
      Organizations: many(spaceOrganizationSchema)
    }
  }
);

export const spaceDocumentTypesSchema = new EntitySchema("SpaceDocumentTypes", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Documents/Types`,
  relationships: {
    Organizations: many(spaceOrganizationSchema),
    Departments: many(spaceDepartmentSchema),
    Teams: many(spaceTeamSchema)
  },
  handleEntityUpdate: handleShareWithUpdates
});

export const spaceProjectsSchema = new EntitySchema("SpaceProjects", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/projects`,
  getSchemaName: () => "projects",
  relationships: {
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    Manager: fk(accountSchema),
    Creator: fk(accountSchema),
    PipelineAccount: fk(accountSchema),
    StatusAccount: fk(accountSchema),
    Departments: many(spaceDepartmentSchema),
    Collaborators: {
      Accounts: many(accountSchema),
      Teams: many(spaceTeamSchema)
    },
    Category: fk(spaceProjectCategorySchema),
    Contact: fk(spaceContactSchema),
    Contract: fk(spaceContractSchema),
    Client: fk(spaceClientSchema),
    IndirectClient: fk(spaceClientSchema),
    StatusDetail: fk(spaceCancelationReasonSchema),
    Company: fk(spaceOrganizationSchema),
    Comments: many(spaceNoteSchema),
    ProjectType: fk(spaceProjectCategorySchema),
    Deal: fk(spaceDealsSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  handleEntityUpdate: handleOriginUpdate
});
OriginChild.addEntity("project", fk(spaceProjectsSchema));

export const spaceCallsSchema = new EntitySchema("SpaceCalls", {
  relationships: {
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    Client: fk(spaceClientSchema),
    IndirectClient: fk(spaceClientSchema),
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ],
    Project: fk(spaceProjectsSchema),
    Contact: fk(spaceContactSchema),
    Contract: fk(spaceContractSchema),
    PipelineAccount: fk(accountSchema),
    StatusAccount: fk(accountSchema),
    Department: fk(spaceDepartmentSchema),
    Team: fk(spaceTeamSchema),
    ManagerAccount: fk(accountSchema),
    Creator: fk(accountSchema),
    StatusDetail: fk(spaceCancelationReasonSchema),
    Comments: many(spaceNoteSchema),
    Company: fk(spaceOrganizationSchema),
    Deal: fk(spaceDealsSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  getEndpoint: (spaceId) => `spaces/${spaceId}/calls`,
  getSchemaName: () => "calls",
  processStrategy: (value, parent, key) => {
    let { ReceivedDate, ...newValue } = value;
    if (!ReceivedDate) return value;
    return { ...newValue, ReceivedDate: new Date(ReceivedDate) };
  },
  handleEntityUpdate: handleOriginUpdate
});
OriginChild.addEntity("call", fk(spaceCallsSchema));

export const spaceTicketsSchema = new EntitySchema("SpaceTickets", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/tickets`,
  getSchemaName: () => "tickets",
  relationships: {
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ],
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    Client: fk(spaceClientSchema),
    IndirectClient: fk(spaceClientSchema),
    Call: fk(spaceCallsSchema),
    Project: fk(spaceProjectsSchema),
    Contact: fk(spaceContactSchema),
    Team: fk(spaceTeamSchema),
    Department: fk(spaceDepartmentSchema),
    ManagerAccount: fk(accountSchema),
    Manager: fk(accountSchema),
    Creator: fk(accountSchema),
    PipelineAccount: fk(accountSchema),
    StatusAccount: fk(accountSchema),
    Contract: fk(spaceContractSchema),
    StatusDetail: fk(spaceCancelationReasonSchema),
    Comments: many(spaceNoteSchema),
    Company: fk(spaceOrganizationSchema),
    Deal: fk(spaceDealsSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  handleEntityUpdate: handleOriginUpdate,
  processStrategy: (value, parent, key) => {
    let { ReceivedDate, ...newValue } = value;
    if (!ReceivedDate) return value;
    return { ...newValue, ReceivedDate: new Date(ReceivedDate) };
  }
});

defineEntityCircularReference(spaceTicketsSchema, {
  Ticket: fk(spaceTicketsSchema)
});

defineEntityCircularReference(spaceNoteSchema, {
  Comments: [
    {
      Note: fk(spaceNoteSchema)
    }
  ]
});

OriginChild.addEntity("ticket", fk(spaceTicketsSchema));

defineEntityCircularReference(spaceCallsSchema, {
  Ticket: fk(spaceTicketsSchema)
});

export const spaceInterventionTypeSchema = new EntitySchema(
  "SpaceInterventionTypes",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/interventionTypes`,
    relationships: {
      Account: fk(accountSchema),
      Teams: many(spaceTeamSchema),
      Departments: many(spaceDepartmentSchema),
      Organizations: many(spaceOrganizationSchema)
    }
  }
);

defineEntityCircularReference(accountSchema, {
	InterventionTypes: many(spaceInterventionTypeSchema),
});

export const spaceTasksSchema = new EntitySchema("SpaceTasks", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/tasks`,
  getSchemaName: () => "tasks",
  relationships: {
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ],
    TaskType: fk(spaceTaskTypeSchema),
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    Project: fk(spaceProjectsSchema),
    Manager: fk(accountSchema),
    Creator: fk(accountSchema),
    PipelineAccount: fk(accountSchema),
    StatusAccount: fk(accountSchema),
    Contact: fk(spaceContactSchema),
    Company: fk(spaceOrganizationSchema),
    Ticket: fk(spaceTicketsSchema),
    Call: fk(spaceCallsSchema),
    IndirectClient: fk(spaceClientSchema),
    Client: fk(spaceClientSchema),
    Contract: fk(spaceContractSchema),
    Breadcrumbs: many(spaceBreadcrumbsSchema),
    StatusDetail: fk(spaceCancelationReasonSchema),
    Comments: many(spaceNoteSchema),
    Deal: fk(spaceDealsSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    LastNote: fk(spaceNoteSchema)
  },
  handleEntityUpdate: handleOriginUpdate
});
OriginChild.addEntity("task", fk(spaceTasksSchema));
defineEntityCircularReference(spaceCallsSchema, {
  Task: fk(spaceTasksSchema)
});
defineEntityCircularReference(spaceTasksSchema, {
  Task: fk(spaceTasksSchema)
});

export const spaceTaskTargetSchema = new EntitySchema("SpaceTaskTarget", {
  idAttribute: "Type",
  relationships: {
    Project: many(spaceProjectsSchema),
    Client: many(spaceClientSchema),
    Ticket: many(spaceTicketsSchema),
    Call: many(spaceCallsSchema),
    Task: many(spaceTasksSchema)
  },
  processStrategy: (value, parent, key) => {
    switch (value.Type) {
      case "Project":
        return {
          ...value,
          Project: value.Data
        };
      case "Client":
        return {
          ...value,
          Client: value.Data
        };
      case "Ticket":
        return {
          ...value,
          Ticket: value.Data
        };
      case "Call":
        return {
          ...value,
          Call: value.Data
        };
      case "Task":
        return {
          ...value,
          Task: value.Data
        };

      default:
        return value;
    }
  }
});

export const spaceTicketTargetSchema = new EntitySchema("SpaceTicketTarget", {
  idAttribute: "Type",
  relationships: {
    Project: many(spaceProjectsSchema),
    Client: many(spaceClientSchema),
    Call: many(spaceCallsSchema)
  },
  processStrategy: (value, parent, key) => {
    switch (value.Type) {
      case "Project":
        return {
          ...value,
          Project: value.Data
        };
      case "Client":
        return {
          ...value,
          Client: value.Data
        };
      case "Call":
        return {
          ...value,
          Call: value.Data
        };

      default:
        return value;
    }
  }
});

export const spaceInterventionTargetSchema = new EntitySchema(
  "SpaceInterventionTarget",
  {
    idAttribute: "Type",
    relationships: {
      Project: many(spaceProjectsSchema),
      Client: many(spaceClientSchema),
      Ticket: many(spaceTicketsSchema),
      Call: many(spaceCallsSchema),
      Task: many(spaceTasksSchema),
      Contract: many(spaceContractSchema),
      Comments: many(spaceNoteSchema)
    },
    processStrategy: (value, parent, key) => {
      switch (value.Type) {
        case "Project":
          return {
            ...value,
            Project: value.Data
          };
        case "Client":
          return {
            ...value,
            Client: value.Data
          };
        case "Ticket":
          return {
            ...value,
            Ticket: value.Data
          };
        case "Call":
          return {
            ...value,
            Call: value.Data
          };
        case "Task":
          return {
            ...value,
            Task: value.Data
          };
        case "Contract":
          return {
            ...value,
            Contract: value.Data
          };

        default:
          return value;
      }
    }
  }
);

export const spaceClientCallSchema = new EntitySchema("SpaceClientCall", {
  idAttribute: "Type",
  relationships: {
    Client: many(spaceClientSchema),
    Call: many(spaceCallsSchema)
  },
  processStrategy: (value, parent, key) => {
    switch (value.Type) {
      case "Client":
        return {
          ...value,
          Client: value.Data
        };
      case "Call":
        return {
          ...value,
          Call: value.Data
        };

      default:
        return value;
    }
  }
});

export const spaceTeamCollabSchema = new EntitySchema("SpaceTeamCollab", {
  idAttribute: "Type",
  relationships: {
    Account: many(accountSchema),
    Team: many(spaceTeamSchema)
  },
  processStrategy: (value, parent, key) => {
    switch (value.Type) {
      case "Account":
        return {
          ...value,
          Account: value.Data
        };
      case "Team":
        return {
          ...value,
          Team: value.Data
        };

      default:
        return value;
    }
  }
});

export const spaceDepartmentTeamSchema = new EntitySchema(
  "SpaceDepartmentTeam",
  {
    idAttribute: "Type",
    relationships: {
      Department: many(spaceDepartmentSchema),
      Team: many(spaceTeamSchema)
    },
    processStrategy: (value, parent, key) => {
      switch (value.Type) {
        case "Department":
          return {
            ...value,
            Department: value.Data
          };
        case "Team":
          return {
            ...value,
            Team: value.Data
          };

        default:
          return value;
      }
    }
  }
);

defineEntityCircularReference(accountSchema, {
  Organizations: many(spaceOrganizationSchema),
  Departments: many(spaceDepartmentSchema),
  Teams: many(spaceTeamSchema)
});

const BillingInterventionRelationships = {
  Project: fk(spaceProjectsSchema),
  Ticket: fk(spaceTicketsSchema),
  Call: fk(spaceCallsSchema),
  Task: fk(spaceTasksSchema),
  Contract: fk(spaceContractSchema),
  Client: fk(spaceClientSchema),
  Deal: fk(spaceDealsSchema),

  IndirectClient: fk(spaceClientSchema),
  Contact: fk(spaceContactSchema),
  Account: fk(accountSchema),
  Type: fk(spaceInterventionTypeSchema),
  Organization: fk(spaceOrganizationSchema),
  Contacts: many(spaceFlatContactSchema),
  Creator: fk(accountSchema),
  IndirectProject: fk(spaceProjectsSchema),
  IndirectContract: fk(spaceContractSchema),
  IndirectDeal: fk(spaceDealsSchema)
};

export const spaceBillingSchema = new EntitySchema("SpaceBilling", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/billing`,
  relationships: {
    ...BillingInterventionRelationships,
    AccountsInRoom: many(accountSchema),
    Creator: fk(accountSchema)
  }
  // handleEntityUpdate: (oldEntity, newEntity) => {
  //   let TimeCharges;

  //   if (newEntity.TimeCharges) {
  //     if (oldEntity.TimeCharges)
  //       TimeCharges = [...oldEntity.TimeCharges, ...newEntity.TimeCharges];
  //     else TimeCharges = newEntity.TimeCharges;
  //   } else TimeCharges = oldEntity.TimeCharges;

  //   return {
  //     ...oldEntity,
  //     ...newEntity,
  //     TimeCharges
  //   };
  // }
});

export const spaceClassificationSchema = new EntitySchema(
  "SpaceClassifications",
  {
    getSchemaName: () => "classifications",
    relationships: {
      Client: fk(spaceClientSchema),
      IndirectClient: fk(spaceClientSchema)
    }
  }
);

export const spaceInterventionsSchema = new EntitySchema("SpaceInterventions", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/interventions`,
  getSchemaName: () => "interventions",
  relationships: {
    ...BillingInterventionRelationships,
    Billing: fk(spaceBillingSchema),
    Comments: many(spaceNoteSchema),
    Addresses: [
      {
        Country: fk(countrySchema)
      }
    ],
    InterventionType: fk(spaceInterventionTypeSchema),
    TimeDetail: fk(spaceIneficientTypesSchema),
    Followers: many(accountSchema),
    AccountsInRoom: many(accountSchema),
    CostCenter: fk(spaceCostCentersSchema),
    LastNote: fk(spaceNoteSchema)
  },
  handleEntityUpdate: handleOriginUpdate
});
defineEntityCircularReference(spaceBillingSchema, {
  TimeCharges: many(spaceInterventionsSchema)
});

OriginChild.addEntity("timecharge", fk(spaceInterventionsSchema));
export const accountSummarySchema = {
  AccountSpaces: [accountSummarySpaceSchema]
};

export const spaceEmailSchema = new EntitySchema("spaceEmailSchema", {
  relationships: {
    FromContact: fk(spaceContactSchema),
    ToContacts: many(spaceContactSchema),
    BccContacts: many(spaceContactSchema),
    CcContacts: many(spaceContactSchema),
    Account: fk(accountSchema)
  }
});

export const spaceEmailConversationSchema = new EntitySchema(
  "spaceEmailConversation",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/emailConversation`,
    relationships: {
      LastEmail: fk(spaceEmailSchema),
      Accounts: many(accountSchema),
      FirstContacts: many(spaceContactSchema),
      Manager: fk(accountSchema),
      Contacts: many(spaceContactSchema),
      Tickets: many(spaceTicketsSchema),
      Client: fk(spaceClientSchema),
      Organization: fk(spaceOrganizationSchema),
      Tasks: many(spaceTasksSchema),
      Deals: many(spaceDealsSchema),
      Followers: many(accountSchema),
      AccountsInRoom: many(accountSchema)
    }
  }
);

export const spaceHistoryObject = {
  Account: fk(accountSchema),
  Subscription: fk(spaceSubscriptionSchema),
  Call: fk(spaceCallsSchema),
  Ticket: fk(spaceTicketsSchema),
  Project: fk(spaceProjectsSchema),
  Task: fk(spaceTasksSchema),
  TimeCharge: fk(spaceInterventionsSchema),
  Client: fk(spaceClientSchema),
  Contact: fk(spaceContactSchema),
  Contract: fk(spaceContractSchema),
  Billing: fk(spaceBillingSchema),
  Note: fk(spaceNoteSchema),
  Deal: fk(spaceDealsSchema),
  EmailConversation: fk(spaceEmailConversationSchema)
};

export const getspaceUserSchema = (space) => {
  const partialAccountSpaceSchema = new EntitySchema("AccountSpaces", {
    relationships: {
      Space: fk(spaceSchema)
    },
    processStrategy: (value, parent, key) => {
      value.Modules = getFormmatedModules(value.Modules);
      value.Space = space;
      // return { ...value, Space: space };
      return value;
    }
  });

  const spaceUserSchema = new EntitySchema("Accounts", {
    relationships: {
      AccountSpaces: many(partialAccountSpaceSchema),
      Category: fk(spaceUserCategoriesSchema),
      Country: fk(countrySchema),
      AccountDocumentType: fk(UserDocumentType)
    },
    processStrategy: (value, parent, key) => {
      const { AccountSpace, ...newValue } = value;

      return { ...newValue, AccountSpaces: [AccountSpace] };
    }
  });
  return spaceUserSchema;
};

export const spaceNotificationSchema = new EntitySchema("SpaceNotifications", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/notifications/all/0`
  // relationships: {
  // 	Project: fk(spaceProjectsSchema),
  // 	Call: fk(spaceCallsSchema),
  // 	Ticket: fk(spaceTicketsSchema),
  // 	Task: fk(spaceTasksSchema),
  // 	Contract: fk(spaceContractSchema),
  // 	Client: fk(spaceClientSchema),
  // 	Account: fk(accountSchema),
  // 	Intervention: fk(spaceInterventionsSchema),
  // 	FromAccount: fk(accountSchema),
  // 	Contact: fk(spaceContactSchema),
  // 	Subscription: fk(spaceSubscriptionSchema),
  // 	ToAccount: fk(accountSchema),
  // 	Deal: fk(spaceDealsSchema),
  // },
  // processStrategy: (value, parent, key) => {
  // 	value.Date = new moment(value.Date);
  // 	return value;
  // },
});
export const spacePopupNotificationSchema = new EntitySchema(
  "SpacePopupNotifications",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/notifications/all/2`
    // relationships: {
    // 	Project: fk(spaceProjectsSchema),
    // 	Call: fk(spaceCallsSchema),
    // 	Ticket: fk(spaceTicketsSchema),
    // 	Task: fk(spaceTasksSchema),
    // 	Contract: fk(spaceContractSchema),
    // 	Client: fk(spaceClientSchema),
    // 	Account: fk(accountSchema),
    // 	Intervention: fk(spaceInterventionsSchema),
    // 	FromAccount: fk(accountSchema),
    // 	Contact: fk(spaceContactSchema),
    // 	Subscription: fk(spaceSubscriptionSchema),
    // 	ToAccount: fk(accountSchema),
    // 	Deal: fk(spaceDealsSchema),
    // },
    // processStrategy: (value, parent, key) => {
    // 	value.Date = new moment(value.Date);
    // 	return value;
    // },
  }
);
export const spaceBellNotificationSchema = new EntitySchema(
  "SpaceBellNotifications",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/notifications/all/1`
    // relationships: {
    // 	Project: fk(spaceProjectsSchema),
    // 	Call: fk(spaceCallsSchema),
    // 	Ticket: fk(spaceTicketsSchema),
    // 	Task: fk(spaceTasksSchema),
    // 	Contract: fk(spaceContractSchema),
    // 	Client: fk(spaceClientSchema),
    // 	Account: fk(accountSchema),
    // 	Intervention: fk(spaceInterventionsSchema),
    // 	FromAccount: fk(accountSchema),
    // 	Contact: fk(spaceContactSchema),
    // 	Subscription: fk(spaceSubscriptionSchema),
    // 	ToAccount: fk(accountSchema),
    // 	Deal: fk(spaceDealsSchema),
    // },
    // processStrategy: (value, parent, key) => {
    // 	value.Date = new moment(value.Date);
    // 	return value;
    // },
  }
);

export const spaceMissingTimeChargeSchema = new EntitySchema(
  "SpaceMissingTimeCharge",
  {
    idAttribute: "Date",
    getEndpoint: (spaceId) => `spaces/${spaceId}/Interventions/Report`
    // processStrategy: (value, parent, key) => {
    //   value.Date = new moment(value.Date);
    //   return value;
    // }
  }
);

export const spaceUserTimeChargeReportSchema = { Account: fk(accountSchema) };

export const spaceTimeChargeReportSchema = new EntitySchema(
  "SpaceTimeChargeReportSchema",
  {
    idAttribute: "DepartmentId",
    getEndpoint: (spaceId) =>
      `Spaces/${spaceId}/Interventions/ReportByDepartment`,
    relationships: {
      Department: fk(spaceDepartmentSchema),
      Reports: [spaceUserTimeChargeReportSchema]
      // Call: fk(spaceCallsSchema),
      // Ticket: fk(spaceTicketsSchema),
      // Task: fk(spaceTasksSchema),
      // Contract: fk(spaceContractSchema),
      // Client: fk(spaceClientSchema),
      // Account: fk(accountSchema),
      // Intervention: fk(spaceInterventionsSchema),
      // FromAccount: fk(accountSchema),
      // ToAccount: fk(accountSchema)
    },
    processStrategy: (value, parent, key) => {
      value.DepartmentId = value.Department.Id;
      return value;
    }
  }
);

export const spaceAdvancedFilterSchema = new EntitySchema(
  "SpaceAdvancedFilters",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/CustomFilters`,
    relationships: {
      Account: fk(accountSchema),
      Accounts: many(accountSchema)
    }
  }
);

export const spaceColorFilterSchema = new EntitySchema("SpaceColorFilters", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/ColorFilter`,
  relationships: {
    Account: fk(accountSchema),
    Accounts: many(accountSchema)
  }
});

defineEntityCircularReference(spaceClientSchema, {
  Contracts: many(spaceContractSchema),
  Projects: many(spaceProjectsSchema)
});

// export const spaceDealsSchema = new EntitySchema("SpaceDeals", {
// 	getEndpoint: spaceId => `spaces/${spaceId}/deals`,
// 	relationships: {
// 		Client: fk(spaceClientSchema),
// 		// Pipeline: fk(pipeline),
// 		Contact: fk(spaceContactSchema),
// 		Creator: fk(accountSchema),
// 		Departments: many(spaceDepartmentSchema),
// 		Collaborators: {
// 			Accounts: many(accountSchema),
// 			Teams: many(spaceTeamSchema)
// 		},
// 		DealManager: fk(accountSchema),
// 		Assigned: fk(accountSchema),
// 		StatusDetail: fk(spaceCancelationReasonSchema),
// 		Company: fk(spaceOrganizationSchema),
// 		Pipeline: fk(spacePipelineSchema),
// 		PipelineStatus: fk(spacePipelineStatusSchema),
// 		Comments: many(spaceNoteSchema)

// 	},
// 	handleEntityUpdate: handleOriginUpdate
// });

export const AccountDetailsResponse = {
  account: fk(accountSchema),
  Tickets: many(spaceTicketsSchema),
  Tasks: many(spaceTasksSchema),
  Organizations: many(spaceOrganizationSchema),
  Teams: many(spaceTeamSchema),
  Departments: many(spaceDepartmentSchema)
};

export const WidgetCategoryOrganizationSpace = new EntitySchema(
  "SpaceWidgetCategoryOrganizationSpace",
  {
    idAttribute: "data"
  }
);

export const WidgetCategorySubject = new EntitySchema(
  "SpaceWidgetCategorySubject",
  {
    relationships: {
      Pipeline: fk(spacePipelineSchema),
      PipelineStatus: fk(spacePipelineStatusSchema),

      OrganizationSpace: fk(WidgetCategoryOrganizationSpace)
    }
  }
);

// export const WidgetCategory = new EntitySchema("SpaceWidgetCategory", {
// 	relationships: {
// 		Children: many(WidgetCategorySubject)
// 	}
// })

const WidgetCategories = [
  {
    Children: [
      {
        Pipeline: fk(spacePipelineSchema),
        PipelineStatus: fk(spacePipelineStatusSchema),
        Follows: many(accountSchema),
        ClientTypeIds: many(spaceClientTypeSchema)
      }
    ]
  }
];
const WidgetFeedback = {
  Pipeline: fk(spacePipelineSchema),
  PipelineStatus: fk(spacePipelineStatusSchema),
  Follows: many(accountSchema),
  AccountId: fk(accountSchema)
};

export const spaceAutomationSchema = new EntitySchema("SpaceAutomation", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/automation`,
  relationships: {
    Creator: fk(accountSchema),
    AssignedTo: fk(accountSchema),
    Company: fk(spaceOrganizationSchema),
    Departments: many(spaceDepartmentSchema),
    Teams: many(spaceTeamSchema)
    // Languages: many(spaceWidgetLanguagesSchema),
    // Pipeline: fk(spacePipelineSchema),
    // PipelineStatus: fk(spacePipelineStatusSchema),
    // WidgetCategories: WidgetCategories,
    // FeedBack: WidgetFeedback,
    // WidgetCategories: many(WidgetCategory)
  }
});
export const spaceDocumentsSchema = new EntitySchema("SpaceDocuments", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/documents`,
  getSchemaName: () => "documents",
  relationships: {
    Creator: fk(accountSchema),
    BlockedBy: fk(accountSchema),
    ChangedBy: fk(accountSchema),
    AssignedTo: fk(accountSchema),
    Company: fk(spaceOrganizationSchema),
    Time: fk(spaceInterventionsSchema),
    Client: fk(spaceClientSchema),
    Call: fk(spaceCallsSchema),
    Ticket: fk(spaceTicketsSchema),
    Task: fk(spaceTasksSchema),
    Contract: fk(spaceContractSchema),
    Project: fk(spaceProjectsSchema),
    Deal: fk(spaceDealsSchema),
    Subscription: fk(spaceSubscriptionSchema),
    Followers: many(accountSchema),
    Collaborators: {
      Companies: many(spaceOrganizationSchema),
      Accounts: many(accountSchema),
      Departments: many(spaceDepartmentSchema),
      Teams: many(spaceTeamSchema)
    },
    DocumentType: fk(spaceDocumentTypesSchema),
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema)
  },
  handleEntityUpdate: (oldEntity, newEntity) => {
    let value = {
      ...oldEntity,
      ...newEntity
    };

    if (oldEntity.BlockedBy) {
      if (!newEntity.BlockedBy) {
        value.BlockedBy = undefined;
      }
    }

    return value;
  }
});
export const spaceDocTemplatesSchema = new EntitySchema(
  "spaceDocTemplatesSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/documents/template`,
    getSchemaName: () => "documents/template",
    relationships: {
      Creator: fk(accountSchema),
      ChangedBy: fk(accountSchema),
      BlockedBy: fk(accountSchema),
      AssignedTo: fk(accountSchema),
      Company: fk(spaceOrganizationSchema),
      Time: fk(spaceInterventionsSchema),
      Client: fk(spaceClientSchema),
      Call: fk(spaceCallsSchema),
      Ticket: fk(spaceTicketsSchema),
      Task: fk(spaceTasksSchema),
      Contract: fk(spaceContractSchema),
      Project: fk(spaceProjectsSchema),
      DocumentType: fk(spaceDocumentTypesSchema),
      Deal: fk(spaceDealsSchema),
      Subscription: fk(spaceSubscriptionSchema),
      Followers: many(accountSchema),
      Collaborators: {
        Companies: many(spaceOrganizationSchema),
        Accounts: many(accountSchema),
        Departments: many(spaceDepartmentSchema),
        Teams: many(spaceTeamSchema)
      },
      Pipeline: fk(spacePipelineSchema),
      PipelineStatus: fk(spacePipelineStatusSchema)
    },
    handleEntityUpdate: (oldEntity, newEntity) => {
      let value = {
        ...oldEntity,
        ...newEntity
      };

      if (oldEntity.BlockedBy) {
        if (!newEntity.BlockedBy) {
          value.BlockedBy = undefined;
        }
      }

      return value;
    }
  }
);

export const spaceAutomationActivitySchema = new EntitySchema(
  "SpaceAutomationActivities",
  {
    // getEndpoint: (spaceId) => `spaces/${spaceId}/automationLogs`,
    relationships: {
      Creator: fk(accountSchema),
      AssignedTo: fk(accountSchema)
      // Company: fk(spaceOrganizationSchema),
      // Languages: many(spaceWidgetLanguagesSchema),
      // Pipeline: fk(spacePipelineSchema),
      // PipelineStatus: fk(spacePipelineStatusSchema),
      // WidgetCategories: WidgetCategories,
      // FeedBack: WidgetFeedback,
      // WidgetCategories: many(WidgetCategory)
    }
  }
);

export const spaceWidgetSchema = new EntitySchema("SpaceWidgets", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Settings/Widgets`,
  relationships: {
    Creator: fk(accountSchema),
    Company: fk(spaceOrganizationSchema),
    Languages: many(spaceWidgetLanguagesSchema),
    Pipeline: fk(spacePipelineSchema),
    PipelineStatus: fk(spacePipelineStatusSchema),
    WidgetCategories: WidgetCategories,
    FeedBack: WidgetFeedback,
    ClientTypes: many(spaceClientTypeSchema)
    // WidgetCategories: many(WidgetCategory)
  }
});

export const spacePrioritySchema = new EntitySchema("SpaceDeals", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/priorities`,
  relationships: {
    Call: fk(spaceCallsSchema),
    Ticket: fk(spaceTicketsSchema),
    Task: fk(spaceTasksSchema),
    Contract: fk(spaceContractSchema),
    Project: fk(spaceProjectsSchema),
    Deal: fk(spaceDealsSchema)
  }
  // handleEntityUpdate: handleOriginUpdate
});

export const spaceEmailReceptionSchema = new EntitySchema(
  "SpaceEmailReception",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/InboundEmails`,
    relationships: {
      Company: fk(spaceOrganizationSchema),
      Departments: many(spaceDepartmentSchema),
      Teams: many(spaceTeamSchema),
      Client: fk(spaceClientSchema),
      IndirectClient: fk(spaceClientSchema),
      Manager: fk(accountSchema),
      Creator: fk(accountSchema),
      StatusChangeBy: fk(accountSchema),
      Call: fk(spaceCallsSchema),
      Ticket: fk(spaceTicketsSchema),
      Task: fk(spaceTasksSchema),
      Contract: fk(spaceContractSchema),
      Project: fk(spaceProjectsSchema),
      Deal: fk(spaceDealsSchema),
      Pipeline: fk(spacePipelineSchema),
      PipelineStatus: fk(spacePipelineStatusSchema),
      Followers: many(accountSchema),
      Assigned: fk(accountSchema),
      Domain: fk(spaceEmailDomainsSchema),
      DealManager: fk(accountSchema)
    },
    handleEntityUpdate: (oldEntity, newEntity) => {
      if (oldEntity.InboundEmail && !newEntity.InboundEmail) {
        delete oldEntity.InboundEmail;
      }
      if (oldEntity.InboundEmails && !newEntity.InboundEmails) {
        delete oldEntity.InboundEmails;
      }
      if (oldEntity.Client && !newEntity.Client) {
        delete oldEntity.Client;
      }

      if (oldEntity.Contract && !newEntity.Contract) {
        delete oldEntity.Contract;
      }
      if (oldEntity.Ticket && !newEntity.Ticket) {
        delete oldEntity.Ticket;
      }
      if (oldEntity.IndirectClient && !newEntity.IndirectClient) {
        delete oldEntity.IndirectClient;
      }

      const { Project, Client, Ticket, Call, Task, Contract, Deal } = newEntity;

      if (Project || Client || Ticket || Call || Task || Contract || Deal) {
        return mergeInterventionEntity(oldEntity, newEntity);
      }

      return {
        ...oldEntity,
        ...newEntity
      };
    }
  }
);

defineEntityCircularReference(spaceContractSchema, {
  InboundEmail: fk(spaceEmailReceptionSchema),
  InboundEmails: many(spaceEmailReceptionSchema)
});

export const spaceEmailSignatureSchema = new EntitySchema(
  "SpaceEmailSignature",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/EmailSignatures`,
    relationships: {
      Companies: many(spaceOrganizationSchema),
      Departments: many(spaceDepartmentSchema),
      Teams: many(spaceTeamSchema),
      Creator: fk(accountSchema),
      Users: many(accountSchema),
      IgnoredUsers: many(accountSchema)
    },
    handleEntityUpdate: (oldEntity, newEntity) => {
      let value = {
        ...oldEntity,
        ...newEntity
      };

      if (oldEntity.BannerImage) {
        if (!newEntity.BannerImage) {
          value.BannerImage = undefined;
        }
      }
      if (oldEntity.LogoImage) {
        if (!newEntity.LogoImage) {
          value.LogoImage = undefined;
        }
      }

      if (oldEntity.LogoLink) {
        if (!newEntity.LogoLink) {
          value.LogoLink = undefined;
        }
      }
      if (oldEntity.BannerLink) {
        if (!newEntity.BannerLink) {
          value.BannerLink = undefined;
        }
      }

      return value;
    }
  }
);

export const spaceSignatureAggregationSchema = new EntitySchema(
  "spaceSignatureAggregationSchema",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/EmailSignatures/Choice`,
    relationships: {
      Company: fk(spaceOrganizationSchema),
      Department: fk(spaceDepartmentSchema),
      Team: fk(spaceTeamSchema),
      User: fk(accountSchema)
    }
  }
);

spaceDealsSchema.updateRelations({
  Project: fk(spaceProjectsSchema),
  Ticket: fk(spaceTicketsSchema),
  Call: fk(spaceCallsSchema),
  Contract: fk(spaceContractSchema),
  Deal: fk(spaceDealsSchema)
});

export const spaceTravelReportsSchema = new EntitySchema("SpaceTravelReport", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Classification/Travels`,
  relationships: {
    Creator: fk(accountSchema),
    Client: fk(spaceClientSchema),
    Contract: fk(spaceContractSchema),
    Project: fk(spaceProjectsSchema),
    Deal: fk(spaceDealsSchema)
  }
});

export const spaceDealsTagsSchema = new EntitySchema("SpaceDealsType", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Deals`,
  getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Deal/Tags`,
  relationships: {
    Pipelines: many(spacePipelineSchema),
    Pipeline: fk(spacePipelineSchema)
  }
});

defineEntityCircularReference(spaceDealsSchema, {
  Tags: many(spaceDealsTagsSchema)
});

export const spaceCallsTagsSchema = new EntitySchema("SpaceCallsType", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Calls`,
  getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Call/Tags`,
  relationships: {
    Pipelines: many(spacePipelineSchema),
    Pipeline: fk(spacePipelineSchema)
  }
});

defineEntityCircularReference(spaceCallsSchema, {
  Tags: many(spaceCallsTagsSchema)
});

export const spaceProjectTagsSchema = new EntitySchema("spaceProjectTags", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Projects`,
  getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Project/Tags`,
  relationships: {
    Pipelines: many(spacePipelineSchema),
    Pipeline: fk(spacePipelineSchema)
  }
});

defineEntityCircularReference(spaceProjectsSchema, {
  Tags: many(spaceProjectTagsSchema)
});

export const spaceTaskTagsSchema = new EntitySchema("spaceTaskTags", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Tasks`,
  getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Task/Tags`,
  relationships: {
    Pipelines: many(spacePipelineSchema),
    Pipeline: fk(spacePipelineSchema)
  }
});

defineEntityCircularReference(spaceTasksSchema, {
  Tags: many(spaceTaskTagsSchema)
});

export const spaceContractTagsSchema = new EntitySchema("SpaceContractTags", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Contracts`,
  getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Contract/Tags`,
  relationships: {
    Pipelines: many(spacePipelineSchema),
    Pipeline: fk(spacePipelineSchema)
  }
});

defineEntityCircularReference(spaceContractSchema, {
  Tags: many(spaceContractTagsSchema)
});

export const spaceSubscriptionTagsSchema = new EntitySchema(
  "SpaceSubscriptionTags",
  {
    getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Subscriptions`,
    getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Subscription/Tags`,
    relationships: {
      Pipelines: many(spacePipelineSchema),
      Pipeline: fk(spacePipelineSchema)
    }
  }
);

defineEntityCircularReference(spaceSubscriptionSchema, {
  Tags: many(spaceSubscriptionTagsSchema)
});

export const spaceClientTagsSchema = new EntitySchema("SpaceClientTags", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Clients`,
  getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Client/Tags`
});

defineEntityCircularReference(spaceClientSchema, {
  Tags: many(spaceClientTagsSchema)
});

export const spaceTicketTagsSchema = new EntitySchema("SpaceTicketType", {
  getEndpoint: (spaceId) => `spaces/${spaceId}/Tags/Tickets`,
  getForcedEndpoint: (spaceId) => `spaces/${spaceId}/Query/Ticket/Tags`,
  relationships: {
    Pipelines: many(spacePipelineSchema),
    Pipeline: fk(spacePipelineSchema)
  }
});

defineEntityCircularReference(spaceTicketsSchema, {
  Tags: many(spaceTicketTagsSchema)
});

defineEntityCircularReference(spaceEmailReceptionSchema, {
  DealTags: many(spaceDealsTagsSchema),
  TicketTags: many(spaceTicketTagsSchema)
});
