import React, {
  useMemo,
  useContext,
  useState,
  useLayoutEffect,
  useCallback,
  useRef
} from "react";
import { HeightContext, VirtualGridColumnProps } from "./VirtualGridHelper";
import classnames from "classnames";
import Checkbox from "../Checkbox/Checkbox";
import { useEntitySummarySidebar } from "../EntitySummaries/EntitySummariesHelper";
import { HoverStateContext } from "../../Helpers/MiscHelper";
import ContextMenu, { GridActionContext } from "../ContextMenu/ContextMenu";
import { useStickyInfo } from "./VirtualGridResizer";
import { VirtualGridColumnChooserContext } from "./VirtualGridColumnChooser";
import { FilterColumnColorProvider } from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColors";

interface VirtualGridBodyProps {
  style: any;
  data: any[];
  RowComponent: any;
  getKey?: any;
  indexes?: {
    startIndex: number;
    endIndex: number;
  };
  columns: VirtualGridColumnProps[];
  selectedItemsDict: any;
  onSelectedItemsChange?: any;
}

const CellTd = React.memo(function CellTd(props: any) {
  const { ColumnCell, value } = props;

  return <ColumnCell Data={value} />;
});

const Cell = React.memo(function Cell(props: any) {
  const { ColumnCell, value, height, field, left, sticky, lastSticky } = props;

  return (
    <td
      style={{ left: isNaN(left) ? undefined : left }}
      className={classnames({ pinned: sticky, lastPin: lastSticky })}
    >
      {ColumnCell ? (
        <div style={{ height }}>
          {/* <Cell Data={value} /> */}
          <CellTd ColumnCell={ColumnCell} value={value} />
        </div>
      ) : field ? (
        <div style={{ height }}> {value[field]}</div>
      ) : null}
    </td>
  );
});

const SelectableCell = React.memo(function SelectableCell(props: any) {
  const { isSelected, onChange, sticky, left, lastSticky } = props;
  return (
    <td
      style={{ left }}
      className={classnames({ pinned: sticky, lastPin: lastSticky })}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div>
        <Checkbox onChange={onChange} checked={isSelected} />
      </div>
    </td>
  );
});

const VirtualGridRowContent = React.memo(function VirtualGridRowContent({
  columns,
  toggleSelectedItem,
  rowKey,
  isSelected,
  height,
  value,
  widthDict,
  totalSticky
}: any) {
  let accumulatedLeft = 0;
  let accumulatedSticky = 0;
  const toggleColumnChooser = useContext(VirtualGridColumnChooserContext);
  return (
    <>
      {columns.map((c: any, i: number) => {
        const { field, sticky } = c;
        const width = widthDict[i];
        const left = sticky ? accumulatedLeft : undefined;
        if (sticky) {
          accumulatedSticky++;
        }

        if (sticky) {
          accumulatedLeft += width;
        }

        return c.selectable ? (
          <SelectableCell
            lastSticky={sticky && accumulatedSticky === totalSticky}
            sticky={sticky}
            left={left}
            onChange={() => {
              toggleSelectedItem(rowKey);
            }}
            isSelected={isSelected || false}
            key={i}
          />
        ) : (
          <Cell
            lastSticky={sticky && accumulatedSticky === totalSticky}
            sticky={sticky}
            left={left}
            field={field}
            key={i}
            ColumnCell={c.Cell}
            height={height}
            value={value}
          />
        );
      })}
      {toggleColumnChooser && <td />}
      <td />
    </>
  );
});

const isBoolean = (val: any) => "boolean" === typeof val;

export const VirtualGridRow = React.memo(function Row({
  columns,
  value,
  odd,
  isSelected,
  toggleSelectedItem,
  rowKey,
  className,
  formType,
  widthDict,
  totalSticky
}: any) {
  const ContextChildren = useContext<any>(GridActionContext);

  const height = useContext(HeightContext);
  const RowRef: any = useRef();
  const [shouldRender, setShouldRender] = useState(false);

  const openForm = useEntitySummarySidebar();

  useLayoutEffect(() => {
    let frame = requestAnimationFrame(() => {
      setShouldRender(true);
      // frame = requestAnimationFrame(() => {
      // });
    });

    return () => cancelAnimationFrame(frame);
  }, []);

  const [isHovering, setIsHovering] = useState(false);

  if (!shouldRender) return <tr style={{ height, overflow: "hidden" }} />;

  const handleRowClick = (e: any) => {
    if (e.detail === 2) {
      if (formType)
        openForm({
          type: formType,
          props: {
            id: value.Id
          }
        });
    }
    // else if (e.detail === 3) {
    //   const buttons =
    //     RowRef.current.getElementsByClassName("EntityLinkClassID");
    //   buttons.length > 0 && buttons[0].click();
    // }
    toggleSelectedItem(rowKey, true);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  if (!value) return null;

  const { HasUpdates, IsDeleted, IsRead } = value;

  const resolvedIsDeleted = isBoolean(IsDeleted)
    ? IsDeleted
    : IsDeleted === "true"
    ? true
    : false;

  return (
    <HoverStateContext.Provider value={isHovering}>
      <tr
        ref={RowRef}
        onClick={handleRowClick}
        onContextMenu={(event) => {
          event.preventDefault();
          handleRowClick(event);
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        // onDoubleClick={() => {
        //   if (formType)
        //     openForm({
        //       type: formType,
        //       props: {
        //         id: value.Id
        //       }
        //     });
        //   // if (RowRef.current) {
        //   //   const buttons = RowRef.current.getElementsByClassName(
        //   //     "FormButtonClassID"
        //   //   );
        //   //   buttons.length > 0 && buttons[0].click();
        //   // }
        // }}
        className={classnames(className, {
          odd,
          deleted_row: resolvedIsDeleted,
          selected: isSelected,
          updated: !resolvedIsDeleted && HasUpdates,
          unread: IsRead === false
        })}
        style={{ height, overflow: "hidden" }}
      >
        <VirtualGridRowContent
          totalSticky={totalSticky}
          widthDict={widthDict}
          columns={columns}
          toggleSelectedItem={toggleSelectedItem}
          rowKey={rowKey}
          isSelected={isSelected}
          height={height}
          value={value}
        />
      </tr>
      {ContextChildren && (
        <ContextMenu enableOnClick={false} targetRef={RowRef}>
          <ContextChildren entity={value} addSendNotificationModal={true} />
        </ContextMenu>
      )}
    </HoverStateContext.Provider>
  );
});

const buildArray = (
  items: any[],
  indexes: any,
  columns: any,
  getKey: any,
  RowComponent: any,
  selectedItemsDict: any,
  toggleSelectedItem: any,
  widthDict: any,
  totalSticky: number
) => {
  const { startIndex, endIndex } = indexes;
  const arr = [];
  const Row = RowComponent || VirtualGridRow;

  for (let i = startIndex; i <= endIndex; i++) {
    const v = items[i];
    if (v === undefined) continue;
    const isSelected = selectedItemsDict[v];
    const key = getKey ? getKey(v) : v;
    const element = (
      <Row
        totalSticky={totalSticky}
        widthDict={widthDict}
        rowKey={key}
        toggleSelectedItem={toggleSelectedItem}
        odd={Boolean(i % 2)}
        key={key}
        isSelected={isSelected}
        columns={columns}
        value={v}
      />
    );
    arr.push(element);
  }
  return arr;
};

export const VirtualGridBody = React.memo(
  ({
    data,
    RowComponent,
    columns,
    getKey,
    indexes,
    style,
    selectedItemsDict,
    onSelectedItemsChange
  }: VirtualGridBodyProps) => {
    const toggleSelectedItem = useCallback(
      (key: any, removePrevious: any) => {
        if (!onSelectedItemsChange) return;
        onSelectedItemsChange((selectedItems: any) => {
          const index = selectedItems ? selectedItems.indexOf(key) : -1;

          const newSelectedItems = [...selectedItems];

          if (index === -1) {
            newSelectedItems.push(key);
          } else {
            newSelectedItems.splice(index, 1);
          }

          if (removePrevious) {
            return [key];
          }

          return newSelectedItems;
        });
      },
      [onSelectedItemsChange]
    );

    const { widthDict, totalSticky } = useStickyInfo();

    const children = useMemo(() => {
      if (!indexes) return null;

      return buildArray(
        data,
        indexes,
        columns,
        getKey,
        RowComponent,
        selectedItemsDict,
        toggleSelectedItem,
        widthDict,
        totalSticky
      );
    }, [
      indexes,
      data,
      columns,
      getKey,
      RowComponent,
      selectedItemsDict,
      toggleSelectedItem,
      widthDict,
      totalSticky
    ]);

    if (!children || !style) return null;
    const { paddingTop, paddingBottom } = style;
    return (
      <FilterColumnColorProvider>
        <tbody>
          <tr style={{ height: paddingTop }} />
          {children}
          <tr style={{ height: paddingBottom }} />
        </tbody>
      </FilterColumnColorProvider>
    );
  }
);
