import React, { useMemo, useCallback } from "react";
import "../Messages.css";
import { getFileExtension, FileIcon } from "../../../Files/Files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
const MessageFile = ({ file, index, setFiles }) => {
  const { fullName, extension } = useMemo(() => {
    let fullName, extension;

    if (file instanceof File) {
      fullName = file.name;
      extension = getFileExtension(file);
    } else {
      fullName = file.Name + file.Extension;
      extension = file.Extension;
    }

    return { fullName, extension };
  }, [file]);

  // const extension = useMemo(() => getFileExtension(file), [file]);

  const removeFile = useCallback(() => {
    setFiles((files) => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      return newFiles;
    });
  }, [index, setFiles]);

  return (
    <div className="ar-message-file w-50">
      <div className="border p-2 rounded d-flex align-items-center">
        <FileIcon extension={extension} size="lg" className="mr-2" />
        <div className="text-black text-truncate flex-1">{fullName}</div>
        <div className="cursor-pointer ml-2" onClick={removeFile}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

const MessageSenderEditFiles = ({ files, setFiles }) => {
  if (!files || files.length === 0) return null;

  return (
    <div className="mt-2 flex-1 of-y-auto of-x-hidden">
      <div className="d-flex align-items-center flex-wrap ar-message-files">
        {files.map((file, index) => {
          return (
            <MessageFile
              file={file}
              key={index}
              index={index}
              setFiles={setFiles}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MessageSenderEditFiles;
