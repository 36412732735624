export const es = {
  "14_DAYS_TRIAL": " días de prueba gratis",
  "180DAYS": "180 días",
  "24H": "24H",
  ACCEPT_INVITATION: "La invitación ya ha sido aceptada",
  ACCEPT_TERMS_AND_CONDITIONS: "Acepto los <a> términos y condiciones </a>",
  ACCESS: "Acceso",
  ACCESSES: "Accesos",
  ACCOUNT: "Cuenta",
  ACCOUNT_ASSIGNED: "te asignó la cuenta",
  ACCOUNT_DELETED: "eliminó el cliente",
  ACCOUNT_IS: "La cuenta es",
  ACCOUNT_NR: "Número de cuenta",
  ACCOUNT_PROFILE: "Cuenta y perfil",
  ACCOUNT_STATUS: "Estado de la cuenta",
  ACCOUNT_TYPE: "Tipo de cuenta",
  ACCOUNT_UPDATE: "actualizó la cuenta",
  ACCOUNT_UPDATED: "actualizó el cuenta",
  ACCOUNTING: "Contabilidad",
  ACCOUNTS: "Cuentas",
  ACTIONS: "Acciones",
  ACTIVATE_TRIGGER_ON_CREATION_OR_EDITION: "Activar disparador en",
  ACTIVE: "Activo",
  ACTIVE_NOTIFICATIONS: "Notificaciones activas",
  ACTIVE_NOW: "Activo ahora",
  ACTIVE_WORKSPACE: "Espacio de trabajo",
  ACTIVITY: "Actividad",
  ADD: "Añadir",
  ADD_ACCOUNT: "Añadir cuenta",
  ADD_ADDRESS: "Añadir dirección",
  ADD_ANOTHER_PUBLICATION: "Agregar otra publicación",
  ADD_API_KEY: "Agregar clave API",
  ADD_BLOCK: "Agregar nuevo bloque",
  ADD_BOOLEAN: "Agregar verdadero o falso",
  ADD_CALL: "Agregar llamada",
  ADD_CANCELATION_REASON: "Agregar motivo de cancelación",
  ADD_CASE: "Agregar caso",
  ADD_CHECKLIST_TEMPLATE: "Agregar plantilla de lista de verificación",
  ADD_CLIENT_TYPE: "Añadir tipo de cliente",
  ADD_CLIENTS_WORKSPACE: "Agrega clientes a tu espacio de trabajo",
  ADD_COMPANY: "Agregar empresa",
  ADD_CONDITION: "Agregar condición",
  ADD_CONTACT: "Agregar contacto",
  ADD_CONTACTS: "Añadir contactos",
  ADD_CONTACTS_WORKSPACE: "Asocia los contactos de tus clientes",
  ADD_CONTRACT: "Agregar contrato",
  ADD_CONTRACTS: "Agregar contratos",
  ADD_CONTRACTS_WORKSPACE: "Inserta y actualiza tus contratos",
  ADD_COUPON: "Agregar cupón",
  ADD_CRON_TIME: "Agregar cron",
  ADD_DATE: "Agregar fecha",
  ADD_DEAL: "Agregar negocio",
  ADD_DEALS_WORKSPACE:
    "Crear una empresa te ayuda a tomar las mejores decisiones",
  ADD_DEPARTMENT: "Agregar departamento",
  ADD_EMAIL_DOMAIN: "Agregar dominio de correo electrónico",
  ADD_EMAIL_SIGNATURE: "Agregar firma de correo electrónico",
  ADD_FAVORITE: "Agregar favorito",
  ADD_FILTER: "Añadir filtro",
  ADD_FOLDER: "Agregar carpeta",
  ADD_INEFICIENT_TYPE: "Añadir ratio de tiempo no imputable",
  ADD_ITEM: "Añadir artículo",
  ADD_LOSS_REASON: "Agregar motivo de pérdida",
  ADD_LOST_REASON: "Agregar motivo de pérdida",
  ADD_NEW_ACCOUNT: "Agregar cuenta nueva",
  ADD_NEW_BILLING_CONTACT: "Actualizar los contactos de facturación",
  ADD_NEW_BLOCK: "Agregar nuevo bloque",
  ADD_NEW_CONTACT: "Añadir nuevo contacto",
  ADD_NEW_COUPON: "Agregar nuevo cupón",
  ADD_NEW_DETAIL_VIEW_APP_BAR: "Agregar vista 360",
  ADD_NEW_DETAILS_APP_BAR_DESC:
    "Seleccione el elemento para agregar a favoritos",
  ADD_NEW_DETAILS_APP_BAR_TITLE: "Agregar vista 360 a favorites",
  ADD_NEW_EMAIL_INPUT: "Agregar otra invitación",
  ADD_NEW_PERIOD: "Agregar nuevo período",
  ADD_NEW_VIEW_APP_BAR: "Agregar nueva vista",
  ADD_NEW_VIEW_APP_BAR_DESC:
    "Seleccione el tipo de vista para agregar a favoritos",
  ADD_NEW_VIEW_APP_BAR_TITLE: "Agregar vista a favoritos",
  ADD_NODES: "Agregar nodos",
  ADD_NOTE: "Añadir la nota",
  ADD_NUMBER: "Agregar número",
  ADD_PIPELINE: "Agregar canalización",
  ADD_PROJECT: "Agregar proyecto",
  ADD_PUBLICATION: "Agregar publicación",
  ADD_RECEPTION: "Agregar inbox",
  ADD_SERVICES_WORKSPACE:
    "Toda una gama de funcionalidades al servicio de su empresa",
  ADD_SOCIAL_POST: "Agregar publicación",
  ADD_SOCIAL_SCHEDULE: "Agregar programa de publicación",
  ADD_SPACE: "Agregar espacio",
  ADD_STATUS: "Agregar estado",
  ADD_STRING: "Agregar texto",
  ADD_SUBJECT: "Añadir tema",
  ADD_SUBSCRIPTION: "Agregar suscripción",
  ADD_TASK: "Agregar tarea",
  ADD_TEAM: "Agregar equipo",
  ADD_TIME: "Agregar tiempo",
  ADD_TIME_TYPE: "Agregar tipo de hora",
  ADD_TIMER: "Agregar temporizador",
  ADD_TIMERS: "Agregar temporizadores",
  ADD_TYPE_OF_CONTRACT: "Agregar tipo de contrato",
  ADD_TYPE_OF_PROJECTS: "Agregar tipo de proyecto",
  ADD_TYPE_OF_SUBSCRIPTION: "Agregar tipo de suscripción",
  ADD_USER: "Agregar usuario",
  ADD_VIEW: "Agregar vista",
  ADD_WIDGET: "Agregar widget",
  ADD_WORKFLOW: "Agregar flujo de trabajo",
  ADDRESS: "Dirección",
  ADDRESS_1: "Dirección 1",
  ADDRESS_2: "Dirección 2",
  ADITION: "Adición",
  ADMIN: "Administración",
  ADMIN_ACCESS: "Acceso de administrador",
  ADMINISTRATION_DEPARTMENT: "Administración",
  ADVANCE_TO_AIRDESK: "Junto a Airdesk",
  ADVANCED_FILTER: "Filtro avanzado",
  ADVANCED_OPTIONS: "Opciones avanzadas",
  ADVANCED_SETTINGS: "Ajustes avanzados",
  AGROUP: "Grupo",
  AIRDESK_UPDATE_DESCRIPTION: "Actualice la página",
  AIRDESK_UPDATE_TITLE: "¡Hay una nueva actualización disponible!",
  AIRLINES_OR_AVIATION: "Aerolíneas / Aviación",
  ALIGN_CENTER: "Alinear al centro",
  ALIGN_JUSTIFY: "Justificar",
  ALIGN_LEFT: "Alinear a la izquierda",
  ALIGN_RIGHT: "Alinear a la derecha",
  ALL: "Todas",
  ALL_CONVERSATIONS: "Todos los mensajes",
  ALLOWED_DOMAINS: "Dominios permitidos",
  ALMOST_EXPIRED_CONTRACTS: "Contratos casi caducados",
  ALMOST_EXPIRED_PROJECTS: "Proyetos casi caducados",
  ALTERNATIVE_DISPUTE_RESOLUTION: "Resolución de conflicto alternativa",
  ALTERNATIVE_MEDICINE: "Medicina alternativa",
  AMOUNT: "Cantidad",
  ANALYSIS: "Análisis",
  ANALYSIS_DEALS: "Análisis de negocios",
  AND: "y",
  ANIMATION: "Animación",
  ANNUALY: "Anual",
  ANSWER_SIGNATURE: "Respuesta",
  API_ALREADY_FOLLOWING: "Ya estás siguiendo este elemento",
  API_ALREADY_NOT_FOLLOWING: "No estás siguiendo este elemento",
  API_CANNOT_CLOSE_DEPENDENCIES_OPEN:
    "El elemento no puede ser cerrado/cancelado porque tiene dependencias en estado abierto",
  API_CANNOT_REMOVE_HAS_CONVERSATIONS:
    "El elemento no se puede eliminar porque tiene correos electrónicos asociados",
  API_CANNOT_REMOVE_HAS_CONVERSATIONS_MULTIPLE:
    "Los elementos de {Ids} no se pueden eliminar porque tienen correos electrónicos asociados",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES:
    "El elemento no se puede eliminar porque tiene dependencias asociadas.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES_DATA:
    "El elemento no se puede eliminar porque tiene las seguintes dependencias asociadas: {Depends}.",
  API_CANNOT_REMOVE_HAS_DEPENDENCIES_MULTIPLE:
    "Los elementos {Ids} no se pueden eliminar porque tienen dependencias asociadas",
  API_CANNOT_REMOVE_HAS_TIMECHARGES:
    "El elemento no se puede eliminar porque tiene horas asociadas.",
  API_CANNOT_REMOVE_HAS_TIMECHARGES_MULTIPLE:
    "Los elementos {Ids} no se pueden eliminar porque tienen horas asociadas",
  API_CANNOT_REMOVE_IS_CLASSIFIED:
    "El elemento no se puede eliminar porque ya está en una clasificación",
  API_CANNOT_REMOVE_IS_CLASSIFIED_MULTIPLE:
    "No se pueden eliminar {Ids} elementos porque ya están clasificados",
  API_DATA_IS_EQUAL: "Los datos son los mismos.",
  API_EXPORT_COMPLETED_SUCCESSFULLY:
    "La exportación se completó correctamente. Ahora puede ver su archivo en la vista de exportación personal.",
  API_EXPORT_STARTED: "La exportación se inició correctamente.",
  API_FILTER_ALREADY_EXISTS: "El nombre del filtro ya existe.",
  API_IMPORT_CLIENT_END: "El trabajo de importación de cuentas ha finalizado",
  API_IMPORT_CONTACT_END:
    "El trabajo de importación de contactos ha finalizado.",
  API_KEY: "Clave de API",
  API_KEY_ID: "Claves API Id",
  API_KEYS: "API",
  API_MARKED_AS_READ: "Ya está marcado como leído",
  API_MARKED_AS_UNREAD: "Ya está marcado como no leído",
  API_NO_SOCIAL_COMPANY_PAGE_ASSOCIATED:
    "No hay cuentas comerciales asociadas a su cuenta",
  API_NOT_FOUND: "Elemento no encontrado",
  API_NOT_FOUND_OR_NO_PERMISSIONS_EDIT:
    "Elemento no encontrado o no tiene suficientes permisos para editarlo",
  API_NOT_FOUND_OR_NO_PERMISSIONS_REMOVE:
    "Elemento no encontrado o no tienes los permisos necesarios para eliminarlo",
  API_NOT_FOUND_OR_NO_PERMISSIONS_VIEW:
    "Elemento no encontrado o no tiene suficientes permisos para verlo",
  API_REFERENCE: "Refêrencia à API",
  APPAREL_OR_FASHION: "Ropa / Moda",
  APPLY: "Aplicar",
  APPLY_ALL: "Aplicar todo",
  APPLY_TO: "Aplicar para",
  APRIL: "Abril",
  APROVED: "Aprobado",
  APROVING: "Aprobar",
  ARCHITECTURE_OR_PLANNING: "Arquitectura / Planificación",
  ARTS_OR_CRAFTS: "Arte y Artesanía",
  ASSIGN_TO: "Asignar a",
  ASSIGNED_THE_DEAL: "asignou o negocio",
  ASSIGNED_TO: "Asignado a",
  ASSOCIATE_CASE: "Asociar caso a",
  ASSOCIATE_CONTRACT_TO: "Asociar contrato a",
  ASSOCIATE_DEAL_TO: "Acuerdo asociado a",
  ASSOCIATE_PROJECT_TO: "Proyecto asociado a",
  ASSOCIATE_SUBSCRIPTION: "Suscripción de asociado a",
  ASSOCIATE_TASK_TO: "Asociar tarea a",
  ASSOCIATE_TIME: "Asociar registro de tiempo a",
  ASSOCIATE_TIME_RECORD: "Asociar registro de tiempo a",
  ASSOCIATE_TO: "Asociar a",
  ASSOCIATED: "Asociado",
  ASSOCIATED_ACCOUNTS: "Cuentas asociadas",
  ASSOCIATED_CONTRACTS: "Contratos asociados",
  ASSOCIATED_PROJECTS: "Proyectos asociados",
  ASSOCIATED_TO: "Asociado a",
  ASSOCIATIONS: "Asociaciones",
  ASSOCIATIONS_CONTACT_DATA: "Asociación de datos de contacto",
  ATTACHMENTS: "Archivos adjuntos",
  ATTRIBUTED_TO: "Atribuido a",
  ATTRIBUTES: "Atributos",
  AUGUST: "Agosto",
  AUTO_REFRESH: "Actualización automática",
  AUTOMATION: "Automatización",
  AUTOMOTIVE: "Automotor",
  AVERAGE: "Neutral,",
  AVERAGE_CLOSE_CASE: "Tiempo promedio para cerrar el caso",
  AVERAGE_CLOSE_TASKS: "Tiempo medio para cerrar tareas",
  AVERAGE_CLOSE_TIME: "Tiempo medio para cerrar",
  AVERAGE_STATUS: "Tiempo medio por estado",
  AVIATION_OR_AEROSPACE: "Aviación / Aeroespacial",
  BACK: "Atrás",
  BACKGROUND: "Antecedentes",
  BACKGROUND_JOB_CANCELED_TITLE: "El proceso fue cancelado",
  BAD: "Malo,",
  BANKING_OR_MORTGAGE: "Banca / Hipoteca",
  BANNER: "Bandera",
  BANNER_LINK: "Enlace de banner",
  BASIC: "Básico",
  BASIS_CUSTOMER: "Interés legítimo: cliente existente",
  BASIS_OTHER: "Interés legítimo - otro",
  BASIS_POSSIBLE: "Interés legítimo: posible / potencial",
  BEGINS_WITH: "Empieza con",
  BILLED: "Facturado",
  BILLED_NR: "Nº de facturación",
  BILLING: "Facturación",
  BILLING_CONTACTS_ADD_SUCCESS:
    "Contactos de facturación agregados correctamente",
  BILLING_CONTACTS_UPDATED_SUCCESS:
    "Contactos de facturación actualizados correctamente",
  BILLING_DETAILS: "Detalles de facturación",
  BILLING_EMAIL: "Contacto de correo electrónico de facturación",
  BILLING_EMAILS: "Correo electrónico de contacto de facturación",
  BILLING_HISTORY: "Historial de facturación",
  BILLING_INFORMATION_OPTIONAL: "Datos de facturación",
  BILLING_NR: "Nº de facturación",
  BILLING_STATUS: "Estado de facturación",
  BILLING_STATUS_CUSTOM: "Empresa",
  BILLINGS_DELETE_DELAY_DESCRIPTION: "Preparándose para eliminar facturas",
  BILLINGS_DELETE_DELAY_TITLE: "Eliminación de cambios",
  BILLINGS_DELETE_DESCRIPTION: "Eliminando facturación",
  BILLINGS_DELETE_TITLE: "Eliminación de cambios",
  BIOTECHNOLOGY_OR_GREENTECH: "Biotecnología / Greentech",
  BIRTH_DATE: "Fecha de cumpleaños",
  BIRTHDAY: "Cumpleaños",
  BIRTHDAY_TODAY: "Descubre quién es el cumpleaños de esta semana",
  BLOCK: "Bloque",
  BLOCK_USER: "Bloquear usuario",
  BLOCKED: "Obstruido",
  BLOCKS: "Bloques",
  BODY: "Body",
  BOLD: "Negrita",
  BOTH: "Ambas",
  BRANDING: "Marca",
  BROADCAST_MEDIA: "Medios de difusión",
  BROKEN_SOCIAL_CONNECTION: "Enlace roto, vuelva a conectar la cuenta",
  BUDGET_RISK: "Riesgo presupuestario",
  BUILDING_MATERIALS: "Materiales de construcción",
  BULLET_LIST: "Lista de marcas",
  BUSINESS_SUPPLIES_OR_EQUIPMENT: "Material / equipo de negocios",
  BUTTONS: "Acciones",
  CALENDAR_GENERAL_ERROR: "Hubo un error al cargar el calendario.",
  CALENDAR_MORE_ITEMS: "{num} más",
  CALL: "Llamada",
  CALL_ASSIGNED: "te asignó la llamada",
  CALL_CREATION: "Creación de llamada",
  CALL_DELETED: "eliminó la llamada",
  CALL_IS: "La llamada es",
  CALL_NR: "Llamar Nº",
  CALL_UPDATE: "actualizó la llamada",
  CALLS: "Llamadas",
  CALLS_CHANGED: "llamada cambiados",
  CALLS_DELETE_DELAY_DESCRIPTION: "Preparándose para eliminar llamadas",
  CALLS_DELETE_DELAY_TITLE: "Eliminando llamadas",
  CALLS_DELETE_DESCRIPTION: "Eliminando llamadas",
  CALLS_DELETE_TITLE: "Eliminación de llamadas",
  CALLS_DESCRIPTION:
    "Realice un seguimiento del recuento de llamadas, el tiempo de respuesta y más de su empresa",
  CALLS_FAILED_CHANGED: "falló al cambiar",
  CALLS_MULTIPLE: "Llamadas (s)",
  CALLS_NOT_CHANGED: "llamada sin cambios",
  CANCEL: "Cancelar",
  CANCEL_BILLING_ANYTIME: "Cancelar en cualquier momento",
  CANCEL_PLAN_MODAL_DESCRIPTION:
    "Antes de irse, comuníquese con nosotros y díganos por qué se va. Podríamos ayudarlo antes de irse. Contáctenos vía",
  CANCEL_PLAN_MODAL_DESCRIPTION_WARNING:
    "Perderá el acceso a la información del espacio de trabajo, asegúrese de exportar antes de cancelar",
  CANCEL_PLAN_MODAL_TITLE: "Cancelar suscripción",
  CANCEL_PLAN_SUBSCRIPTION: "Cancelar suscripción",
  CANCEL_SUBSCRIPTION: "Cancelar suscripción",
  CANCEL_SUBSCRIPTION_AIRDESK: "Cancele su suscripción a Airdesk",
  CANCEL_SUBSCRIPTION_AIRDESK_UNDER:
    "La cancelación de su suscripción suspende el servicio y los datos se eliminan en 15 días",
  CANCELATION_DESCRIPTION: "Descripción de cancelación",
  CANCELATION_REASON: "Motivo de cancelacion",
  CANCELATION_REASONS: "Motivos de cancelación",
  CANCELATION_REASONS_SUCCESS:
    "El motivo de la cancelación se ha guardado correctamente",
  CANCELED_SUBSCRIPTION: "Suscripción cancelada",
  CANCELLED: "Cancelado",
  CANCELLED_PROJECTS: "Proyectos cancelados",
  CANT_REPLY_CLOSED_CONVERSATION:
    "Solo se pueden responder conversaciones abiertas",
  CAPITAL_MARKETS_OR_HEDGE_FUND_OR_PRIVATE_EQUITY:
    "Mercados de capital / hedge fund / private equity",
  CARD_CANCELED_SUCCESS: "Tarjeta eliminada correctamente",
  CARD_NAME: "Nombre en la tarjeta",
  CARD_NUMBER: "Número de tarjeta",
  CARD_SECURITY_CODE: "Código de seguridad",
  CASE: "Caso",
  CASE_ASSIGNED: "te asignó el caso",
  CASE_ASSIGNED_TO: "asignado el caso",
  CASE_DELETED: "eliminó el caso",
  CASE_FORM: "Formulario de caso",
  CASE_IS: "El caso es",
  CASE_NR: "Caso Nº",
  CASE_UPDATE: "actualizó el caso",
  CASE_WAS_CLOSED: "el caso <b>{caseName}</b> fue cerrado",
  CASES: "Casos",
  CASES_STATUS: "Estado del caso",
  CATEGORY: "Categoría",
  CATEGORY_IS: "La categoría es",
  CELLPHONE_CODES: "Cambiar códigos de teléfonos móviles",
  CHANGE_ACTIVE_PLAN: "Cambio de plan",
  CHANGE_ASSIGNED: "Cambio de asignado",
  CHANGE_BILLING_ANYTIME: "Cambia de planes cuando quieras",
  CHANGE_GDPR: "Cambiar GDPR",
  CHANGE_MANAGER: "Cambio de gerente",
  CHANGE_NOTE: "Cambiar nota",
  CHANGE_PASSWORD: "Cambiar",
  CHANGE_PASSWORD_BLOCKED_DESC:
    "La contraseña actual ha fallado más de 3 veces, inténtelo de nuevo después",
  CHANGE_PASSWORD_BLOCKED_TITLE: "Cambio de contraseña bloqueado",
  CHANGE_PIPELINE: "Cambiar canalización",
  CHANGE_STATUS: "Cambiar estado",
  CHANGE_WORKSPACE: "Cambiar espacio de trabajo",
  CHANGED_BY: "Cambiado por",
  CHANGES: "Cambios",
  CHANGES_SAVED_WITH_SUCCESS: "Los cambios se guardaron exitosamente",
  CHAT_CONNECTION_ERROR_DESCRIPTION:
    "Hubo un problema al intentar conectarte con el chat",
  CHAT_NO_MESSAGES: "No hay mensajes",
  CHAT_NO_NOTES: "No hay notas",
  CHAT_ONLINE: "Chat en línea",
  CHAT_RECONNECTING: "Reconectando ...",
  CHECK_LIST: "Lista de verificación",
  CHECKLIST: "Lista de verificación",
  CHECKLIST_TEMPLATE: "Plantillas de lista de verificación",
  CHEMICALS: "Productos químicos",
  CHOOSE_A_SPACE_NAME: "¿Cuál es el nombre de su empresa / grupo?",
  CHOOSE_A_SPACE_NAME_EXPLANATION:
    "Usaremos esto para identificar su espacio de trabajo de Airdesk, que siempre puede cambiar más adelante",
  CHOOSE_A_TEMPLATE_TO_USE: "Elija una plantilla inicial",
  CHOOSE_BILLING_PLAN: "Elija el plan que mejor se adapte a su empresa",
  CHOOSE_COLUMNS: "Elija las columnas para mostrar",
  CHOOSE_DEPARTMENT: "Elige el departamento",
  CHOOSE_MODULES: "Elija los módulos para mostrar",
  CHOOSE_PAGE: "Escoge una página",
  CHOOSE_PIPELINE_STATUS: "Elija el estado de la canalización para mostrar",
  CHOOSE_STATUS: "Elija el estado para mostrar",
  CHOOSE_THE_DROPDOWN_ORDER: "Elija el orden para el menú desplegable",
  CHOSEN_PLAN: "Plan elegido:",
  CITY: "Ciudad",
  CIVIC_OR_SOCIAL_ORGANIZATION: "Organización cívica / social",
  CIVIL_ENGINEERING: "Ingeniero civil",
  CLASS_NR: "Clase Nº",
  CLASSIFICATION: "Clasificación",
  CLASSIFICATION_CONFIRMATION_TEXT: "¿Quieres realizar esta acción?",
  CLASSIFICATION_DESCRIPTION:
    "Vea la clasificación de tiempos publicada por sus colaboradores",
  CLASSIFICATION_ERROR:
    "Hubo un error al procesar su solicitud. Si el error persiste, comuníquese con la administración",
  CLASSIFICATION_IDENTIFIER_ERROR:
    "El identificador ya existe. Seleccione otro",
  CLASSIFICATION_INTEGRITY_AFFECTED:
    "El sistema detectó que cambiar el origen afectará",
  CLASSIFICATION_LIST_EMPTY: "Lista de clasificación vacía",
  CLASSIFICATION_NR: "Clasificación Nº",
  CLASSIFICATION_ORIGIN: "Origen de la clasificación",
  CLASSIFICATION_ORIGIN_ERROR: "El origen es obligatorio",
  CLASSIFICATION_ORIGIN_RECOMMENDATION:
    "Dependiendo de los registros de tiempo a continuación, el sistema recomienda la siguiente fuente:",
  CLASSIFICATION_REPORT: "Informe de clasificación",
  CLASSIFICATION_SELECT_ORIGIN: "Seleccione un origen",
  CLASSIFICATION_SELECT_ORIGIN_QUESTION: "¿Quieres seleccionar?",
  CLASSIFICATION_STATUS_ERROR: "El estado debe estar clasificado",
  CLASSIFICATION_TIMECHARGE_ADDITION_SUCCESS:
    "Los cargos de tiempo se agregaron correctamente",
  CLASSIFICATION_TIMECHARGE_ERROR:
    "El sistema detectó que hay uno o más registros de cargos de tiempo que se verían afectados, ya clasificados y con otra fuente. Para proceder con esta clasificación, actualice lo siguiente:",
  CLASSIFICATION_TO_NEW_ORIGIN: "Al nuevo origen:",
  CLASSIFICATION_TOTAL_OF: "obteniendo un total de",
  CLASSIFICATION_UPDATE_SUCCESS: "Clasificación actualizada correctamente",
  CLASSIFICATION_WILL_BE_ADDED_TO: "se agregará a",
  CLASSIFICATION_WILL_CHANGE: "Cambiará:",
  CLASSIFICATIONS: "Clasificaciones",
  CLASSIFIED: "Clasificado",
  CLASSIFIED_BUT_OTHER_ENTITY: "Asociado pero clasificado con otra entidad",
  CLASSIFIED_BY: "Clasificado por",
  CLASSIFY: "Clasificar",
  CLEAN: "Limpiar",
  CLEAN_ALL: "Limpiar todos",
  CLEAN_BOARD: "",
  CLIENT_CREATION: "Creación de cliente",
  CLIENT_DATA_IMPORT_AIRDESK:
    "Importación de datos de clientes para asociarlos con Airdesk",
  CLIENT_SIGNATURE: "Firma del usuario",
  CLIENT_STATUS: "Estado de la cuenta",
  CLIENT_STATUS_IS: "El estado de la cuenta es",
  CLIENT_TYPE_EQUALS: "El tipo de cliente es igual a",
  CLIENT_TYPE_LIST_EMPTY: "Lista de tipos de cuenta vacía",
  CLIENT_TYPE_LIST_NO: "No hay registros de tipo de cuenta asociados",
  CLIENTS_CHANGED: "cuentas cambiados",
  CLIENTS_DELETE_DELAY_DESCRIPTION: "Preparándose para eliminar cuentas",
  CLIENTS_DELETE_DELAY_TITLE: "Eliminación de cuenta",
  CLIENTS_DELETE_DESCRIPTION: "Eliminando cuentas",
  CLIENTS_DELETE_TITLE: "Eliminación de cuenta",
  CLIENTS_FAILED_CHANGED: "falló al cambiar",
  CLIENTS_NOT_CHANGED: "cuentas sin cambios",
  CLOSE_ACCOUNTS: "Cuentas cerradas",
  CLOSE_DATE: "Fecha de cierre",
  CLOSED: "Cerrado",
  CLOSED_CASES: "Casos cerrados",
  CLOSED_CONTRACTS: "Contratos cerrados",
  CLOSED_PROJECTS: "Proyectos cerrados",
  CLOSED_RECEPTION: "Cerrado",
  CLOSED_TASKS: "Tareas cerradas",
  CODE: "Código",
  CODE_CONTAINER: "Caja de código",
  CODE_VIEW: "Estilo de código",
  COLABORATORS: "Colaboradores",
  COLLABORATOR: "Colaborador",
  COLLABORATOR_NR: "Nº de colaborador",
  COLLABORATORS: "Colaboradores",
  COLOR: "Color",
  COLOR_FILTER: "Color",
  COLUMN: "Columna",
  COLUMNS: "Columnas",
  COMBINE: "Combinar",
  COMERCIAL: "Ventas",
  COMMENT: "Comentario",
  COMMERCIAL: "Comercial",
  COMMERCIAL_DEPARTMENT: "Comercial",
  COMMERCIAL_REAL_ESTATE: "Bienes raíces comerciales",
  COMPANIES: "Compañías",
  COMPANIES_SUBSIDIARIES: "Empresas / Subsidiarias",
  COMPANY: "Empresa",
  COMPANY_ADDRESS1: "Dirección 1 de la empresa",
  COMPANY_ADDRESS2: "Dirección 2 de la empresa",
  COMPANY_AVATAR: "Avatar de la empresa",
  COMPANY_DOMAIN: "Dominio de la empresa",
  COMPANY_EMAIL: "Correo electrónico de la empresa",
  COMPANY_FACEBOOK_URL: "Facebook de la empresa",
  COMPANY_GROUP_NAME: "Nombre de la empresa / grupo",
  COMPANY_INDUSTRY: "Industria de la empresa",
  COMPANY_INFORMATION: "Información de la empresa",
  COMPANY_INSTAGRAM_URL: "Instagram de la empresa",
  COMPANY_IS: "La empresa es",
  COMPANY_LINKEDIN_URL: "Empresa Linkedin",
  COMPANY_LOGO: "Logo de la compañía",
  COMPANY_NAME: "Nombre de la empresa o grupo de empresas",
  COMPANY_PHONE: "Número de teléfono de la empresa",
  COMPANY_SIGNATURE_NAME: "Nombre de empresa",
  COMPANY_TWITTER_URL: "Twitter de la empresa",
  COMPANY_VAT: "IVA de la empresa",
  COMPANY_YOUTUBE_URL: "Empresa Youtube",
  COMPARE_OUR_PLANS: "Compara nuestras ofertas",
  COMPARE_THE_PRICE_OF_PLANS: "Compara los precios de nuestros planes",
  COMPLETE: "Completar",
  COMPLETED: "Terminado",
  COMPRESS_ALL: "Minimizar todo",
  COMPUTER_GAMES: "Juegos de computadora",
  COMPUTER_HARDWARE: "Hardware de la computadora",
  COMPUTER_NETWORKING: "Redes de computadoras",
  COMPUTER_OR_NETWORK_SECURITY: "Seguridad informática / de red",
  COMPUTER_SOFTWARE_OR_ENGINEERING: "Software / Ingeniería informática",
  CONCLUDE: "Si",
  CONCLUDE_CALL: "Cambiar el estado de llamada",
  CONCLUDE_CALL_ASK: "Desea cambiar el estado de llamada?",
  CONCLUDE_ENTITY: "Cambiar el estado de {entityText}",
  CONCLUDE_TASK: "Cambiar el estado de tarea",
  CONCLUDE_TASK_ASK: "Desea cambiar el estado de tarea?",
  CONCLUDE_TICKET: "Cambiar el estado de ticket",
  CONCLUDE_TICKET_ASK: "Desea cambiar el estado de ticket?",
  CONCLUDED: "Cerrado",
  CONCLUSION: "Conclusión",
  CONDITION: "Condición",
  CONFIG_TITLE: "Configuración: Airdesk",
  CONFIGURE_BUTTON: "Botón configurar",
  CONFIGURE_SUBJECTS: "Configurar sujetos: atribución de casos",
  CONFIGURE_WORK_START: "Empiece por configurar su espacio de trabajo",
  CONFIRM: "Confirmar",
  CONFIRM_CHANGES:
    "¿Quiere guardar los cambios realizados en las notificaciones?",
  CONFIRM_CONTACT_INFORMATION: "Verificación de datos",
  CONFIRM_CONTACT_INFORMATION_DESCRIPTION:
    "Los datos agregados de los contactos que se fusionaron se asociarán con este contacto",
  CONFIRM_PASSWORD: "Confirme la contraseña",
  CONNECT_WORKFLOW: "Flujo de trabajo de conexión",
  CONNECTED_BY: "Conectado por",
  CONSENT_CONTACT: "Consentimiento dado por contacto",
  CONSTRUCTION: "Construcción",
  CONSUMER_ELECTRONICS: "Electrónica de consumo",
  CONSUMER_GOODS: "Bienes de consumo",
  CONSUMER_SERVICES: "Servicio al consumidor",
  CONTACT: "Contacto",
  CONTACT_ACCESS: "Accesos de contacto",
  CONTACT_CREATED: "creó el contacto",
  CONTACT_CREATION: "Creación de contacto",
  CONTACT_DATA_IMPORT_AIRDESK:
    "Importación de datos de contacto para asociarlos con Airdesk",
  CONTACT_IS: "El contacto es",
  CONTACT_NOTIFICATIONS: "Notificaciones del contacto",
  CONTACT_NR: "Nº de contacto",
  CONTACT_UPDATED: "actualizó el contacto",
  CONTACT_US: "Contáctenos",
  CONTACTED_DELETED: "eliminó lo contato",
  CONTACTS: "Contactos",
  CONTACTS_CHANGED: "contactos cambiados",
  CONTACTS_DEFAULT_DESCRIPTION_UNDER_TABLES:
    "Todos los correos electrónicos relacionados con la facturación se enviarán a este correo electrónico",
  CONTACTS_DELETE_DELAY_DESCRIPTION: "Preparándose para eliminar contactos",
  CONTACTS_DELETE_DELAY_TITLE: "Eliminando contactos",
  CONTACTS_DELETE_DESCRIPTION: "Eliminando contactos",
  CONTACTS_DELETE_TITLE: "Eliminando contactos",
  CONTACTS_DUPLICATE_DESCRIPTION:
    "El sistema detectó {count} contacto (s) con los datos insertados arriba, le recomendamos que use un contacto existente para evitar duplicados",
  CONTACTS_FAILED_CHANGED: "falló al cambiar",
  CONTACTS_MERGE: "Combinar contactos",
  CONTACTS_MERGED_FAILURE:
    "Hubo un error al combinar los contactos. Vuelva a intentarlo",
  CONTACTS_MERGED_SUCCESS: "Los contactos se combinaron correctamente",
  CONTACTS_NOT_CHANGED: "contactos sin cambios",
  CONTACTS_NR: "Número de contactos",
  CONTAINS: "Contiene",
  CONTATCS: "Contactos",
  CONTINUE: "Seguir",
  CONTINUE_ON_FAIL: "Continuar en falla",
  CONTRACT: "Contrato",
  CONTRACT_ASSIGNED: "te asignó el contrato",
  CONTRACT_CREATION: "Creación de contrato",
  CONTRACT_DELETED: "eliminó el contrato",
  CONTRACT_END: "Fin de contrato",
  CONTRACT_IS: "El contrato es",
  CONTRACT_NR: "Contrato Nº",
  CONTRACT_STATUS: "Estado del contrato",
  CONTRACT_TYPE: "Tipo de contrato",
  CONTRACT_TYPES: "Tipos de contrato",
  CONTRACT_TYPES_EMPTY_LIST: "Lista de tipos de contrato vacía",
  CONTRACT_TYPES_EMPTY_LIST_DESCRIPTION:
    "No hay registros de tipos de contratos",
  CONTRACT_UPDATE: "actualizó el contrato",
  CONTRACTS: "Contratos",
  CONTRACTS_ACTIVITY: "Contratos - actividad",
  CONTRACTS_ALMOST_EXCEEDED: "Contratos: tiempo casi ha terminado",
  CONTRACTS_BUDGET_RISK: "Contratos: riesgo presupuestario",
  CONTRACTS_CHANGED: "contratos cambiados",
  CONTRACTS_DEADLINE_RISK: "Contratos: riesgo de fecha límite",
  CONTRACTS_DELETE_DELAY_DESCRIPTION: "Preparando para eliminar contratos",
  CONTRACTS_DELETE_DELAY_TITLE: "Eliminación de contratos",
  CONTRACTS_DELETE_DESCRIPTION: "Eliminando contratos",
  CONTRACTS_DELETE_TITLE: "Eliminación de contratos",
  CONTRACTS_DESCRIPTION:
    "Tener una visión global del desarrollo de los contratos de su empresa",
  CONTRACTS_EXCEEDED_HOURS: "Contratos: tiempo excedido",
  CONTRACTS_FAILED_CHANGED: "falló al cambiar",
  CONTRACTS_NOT_CHANGED: "contratos sin cambios",
  CONTRACTS_PROGRESS: "Progreso de los contratos",
  CONTRACTS_REPORT: "Informe de contratos",
  CONTROL_HOURS: "Control de tiempo",
  CONVERSATION: "Conversa",
  COPIED: "Copiado",
  COPY: "Copiar",
  COPY_HYPERLINK: "Copiar link",
  COPYRIGHT: "Todos los derechos reservados.",
  COPYRIGHT_FOOTER:
    "© {date} Airdesk. Todos los derechos reservados. <a> Política de privacidad </a>",
  CORRESPOND_CONTACT_INFORMATION: "Coincidir con la información",
  CORRESPOND_CONTACT_INFORMATION_DESCRIPTION:
    "Antes de continuar con esta acción, confirme los contactos que desea fusionar. Esta acción no se puede deshacer y todos los registros permanecerán asociados con el contacto final",
  CORRESPONDENCE: "Partido",
  COSMETICS: "Productos cosméticos",
  COUNTRY: "País",
  COUNTRY_TAX: "IVA",
  COUPON_ALREDY_EXISTS: "Ya existe un cupón con el mismo nombre",
  CREATE: "Crear",
  CREATE_ACCOUNT: "Crear una cuenta",
  CREATE_ADVANCED_FILTER: "Crear filtro avanzado",
  CREATE_CALL: "Crear llamada",
  CREATE_CASE: "Crear caso",
  CREATE_COLOR_FILTER: "Crear color",
  CREATE_CONTACT: "Crear contacto",
  CREATE_CONTRACT: "Crear contrato",
  CREATE_DEAL: "Crear negocio",
  CREATE_DEPARTMENTS: "Crear departamentos",
  CREATE_DEPARTMENTS_DESC: "Agregue sus departamentos a su espacio de trabajo",
  CREATE_DEPARTMENTS_DESC_INFO_DESC:
    "Añada los departamentos de su empresa a Airdesk (por ejemplo, Departamento de Ventas, Departamento de Finanzas, Departamento de Design)",
  CREATE_EMAIL: "Crear email",
  CREATE_INTERNAL_NOTE: "Crear notas internas",
  CREATE_NEW_WIDGET: "Crear nuevo widget",
  CREATE_NEW_WORKSPACE: "Crear nuevo espacio de trabajo",
  CREATE_PROJECT: "Crear proyecto",
  CREATE_RECURRENCE: "Crear recurrencia",
  CREATE_SUBSCRIPTION: "Crear suscripción",
  CREATE_TASK: "Crear tarea",
  CREATE_TEAMS: "Crear equipos",
  CREATE_TEAMS_DESC:
    "Agregue sus equipos a su espacio de trabajo y asócielos con un departamento",
  CREATE_TIME_RECORD: "Crear registro de tiempo",
  CREATE_TIME_REPORT: "Enviar Informe de tiempos",
  CREATE_USER: "Crear usuario",
  CREATE_WORKFLOW: "Crear flujo de trabajo",
  CREATE_WORKSPACE: "Crear espacio de trabajo",
  CREATED_ACCOUNT: "Creó la cuenta",
  CREATED_BY: "Creado por",
  CREATED_CALL: "Creó la llamada",
  CREATED_CASE: "Creó el caso",
  CREATED_CLASSIFICATION: "Creó la clasificación",
  CREATED_CONTACT: "Creó el contacto",
  CREATED_CONTRACT: "Creó el contrato",
  CREATED_CONVERSATION: "Creó la conversación",
  CREATED_DEAL: "Creó el trato",
  CREATED_NOTE: "Creó la nota",
  CREATED_PROJECT: "Creó el proyecto",
  CREATED_SUBSCRIPTION: "Creó la suscripción",
  CREATED_TASK: "Creó la tarea",
  CREATED_THE_NOTE: "creó la nota",
  CREATED_TIME: "Creó el registro de tiempo",
  CREATED_VIEWS: "Vistas creadas",
  CREATION: "Creación",
  CREATION_DATE: "Fecha de creación",
  CREDIT_CARD: "Tarjeta de crédito",
  CRON: "Cron",
  CUPON: "Cupón",
  CURRENCY: "Monedas",
  CURRENT_BILLING_PLAN: "Tu plan actual es",
  CURRENT_CHOSEN_PLAN: "O seu plano atual é",
  CURRENT_ITEM_WAS_DELETED: "El elemento que estabas mirando ha sido eliminado",
  CURRENT_NEW_PASSWORD_SAME:
    "La nueva contraseña es la misma que la contraseña actual",
  CURRENT_PASSWORD_INVALID: "Contraseña actual incorrecta",
  CURRENT_PLAN: "Plan actual",
  CUSTOM: "Personalizado",
  CUSTOM_DASHBOARDS: "Paneles personalizados",
  CUSTOM_DOMAIN: "Dominio personalizado",
  CUSTOM_NODE_COLOR: "Color personalizado",
  CYCLE: "Lote",
  DAILY: "Diario",
  DAIRY: "Lechería",
  DANGER_ZONE: "Zona peligrosa",
  DASHBOARD: "Tablero",
  DASHBOARDS: "Paneles de control estándar",
  DATA_TO_EXPORT: "Dados para exportar",
  DATE: "Fecha",
  DATE_CREATION: "Fecha de creación",
  DATE_LAST_CHANGE: "Fecha del último cambio",
  DAY: "Día",
  DAY_AGO: "Hace un dia",
  DAY_OF_MONTH: "Día del mes",
  DAYS: "Dias",
  DAYS_AGO: "Hace días",
  DEADLINE: "Fecha límite",
  DEAL: "Negocio",
  DEAL_ASSIGNED: "te asignó el trato",
  DEAL_ASSIGNED_TO: "Negocio asignado a",
  DEAL_CREATION: "Creación de negocio",
  DEAL_DELETED: "eliminó el negocio",
  DEAL_IS: "El trato es",
  DEAL_NR: "Número de negocio",
  DEAL_OVERIMPOSED_FOUND: "Hay superposición de negocios.",
  DEAL_PROGRESS: "Progreso de negocios",
  DEAL_STATUS: "Estado de el negocio",
  DEAL_UPDATE: "actualizó el trato",
  DEAL_UPDATED: "actualizó el trato",
  DEALS: "Negocios",
  DEALS_ANALYSIS: "Análisis de negocios",
  DEALS_CHANGED: "Negocio cambiados",
  DEALS_DELETE_DELAY_DESCRIPTION: "Preparando la eliminación de la empresa",
  DEALS_DELETE_DELAY_TITLE: "Eliminación de negocios",
  DEALS_DELETE_DESCRIPTION: "Eliminando empresa",
  DEALS_DELETE_TITLE: "Eliminación de negocios",
  DEALS_DESCRIPTION:
    "Realice un seguimiento de las oportunidades creadas por su equipo de ventas, cierre acuerdos y más",
  DEALS_DEVELOPMENT: "Desarrollo de negocios",
  DEALS_FAILED_CHANGED: "falló al cambiar",
  DEALS_HISTORY: "Historial de negocios",
  DEALS_NOT_CHANGED: "ofertas sin cambios",
  DEALS_PERFORMANCE: "Rendimiento de las negocios",
  DEALS_REPORT: "Informe de negocios",
  DEALS_SALES_AMOUNTS: "Montos de ventas de negocios",
  DEALS_STATUS: "Estado de negocios",
  DECEMBER: "Diciembre",
  DEFAULT_LANGUAGE: "Idioma predeterminado",
  DEFAULT_VIEW: "Vista general",
  DEFENSE_OR_SPACE: "Defensa / Espacio",
  DEFINE_ADMIN_ACCESS: "Definir el acceso de administrador",
  DEFINE_CONTACT_ACCESS:
    "Todos los usuarios tienen acceso básico. Los permisos a continuación se aplican a datos confidenciales en algunos campos de los detalles.",
  DEFINE_LEGAL_BASIS:
    "Definir la base legal para procesar los datos de un contacto",
  DEFINE_MODULES_ACCESS: "Definir el acceso a los módulos",
  DEFINE_SETTINGS_ACCESS: "Definir el acceso a la configuración",
  DEFINITIONS: "Configuración",
  DELAY: "Retraso",
  DELETE: "Eliminar",
  DELETE_ALL: "Eliminar todos",
  DELETE_ALL_MODAL_CONFIRMATION:
    "Ingrese la palabra 'ELIMINAR' para confirmar la acción.",
  DELETE_ALL_MODAL_CONFIRMATION_BUTTON: "Sí, eliminar",
  DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_ALL_VALID:
    "Se seleccionaron {total} facturación.",
  DELETE_ALL_MODAL_SUB_TITLE_BILLINGS_SOME_VALID:
    "Se han seleccionado {total} facturas, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_CALLS_ALL_VALID:
    "Se han seleccionado {total} llamadas",
  DELETE_ALL_MODAL_SUB_TITLE_CALLS_SOME_VALID:
    "Se han seleccionado {total} llamadas, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_ALL_VALID:
    "Se seleccionaron {total} cuentas",
  DELETE_ALL_MODAL_SUB_TITLE_CLIENTS_SOME_VALID:
    "Se seleccionaron {total} cuentas, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_ALL_VALID:
    "Se han seleccionado {total} contactos",
  DELETE_ALL_MODAL_SUB_TITLE_CONTACTS_SOME_VALID:
    "Se han seleccionado {total} contactos, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_ALL_VALID:
    "Se seleccionaron {total} contratos.",
  DELETE_ALL_MODAL_SUB_TITLE_CONTRACTS_SOME_VALID:
    "Se han seleccionado {total} contratos, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_DEALS_ALL_VALID:
    "Se seleccionaron {total} negocios",
  DELETE_ALL_MODAL_SUB_TITLE_DEALS_SOME_VALID:
    "Se seleccionaron {total} acuerdos, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_ALL_VALID:
    "Se seleccionaron {total} firmas de correo electrónico.",
  DELETE_ALL_MODAL_SUB_TITLE_EMAIL_SIGNATURE_SOME_VALID:
    "Se seleccionaron {total} firmas de correo electrónico, solo se pueden eliminar {valid} debido a los permisos.",
  DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_ALL_VALID:
    "Se seleccionaron {total} veces.",
  DELETE_ALL_MODAL_SUB_TITLE_INTERVENTIONS_SOME_VALID:
    "Se han seleccionado {total} veces, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_NONE_VALID:
    "No tienes permiso para eliminar ninguno de los elementos seleccionados",
  DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_ALL_VALID:
    "Se han seleccionado {total} proyectos",
  DELETE_ALL_MODAL_SUB_TITLE_PROJECTS_SOME_VALID:
    "Se han seleccionado {total} proyectos, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_ALL_VALID:
    "Se seleccionaron {total} suscripciones",
  DELETE_ALL_MODAL_SUB_TITLE_SUBSCRIPTIONS_SOME_VALID:
    "Se seleccionaron {total} suscripciones, solo se pueden eliminar {valid} debido a los permisos.",
  DELETE_ALL_MODAL_SUB_TITLE_TASKS_ALL_VALID:
    "Se han seleccionado {total} tareas",
  DELETE_ALL_MODAL_SUB_TITLE_TASKS_SOME_VALID:
    "Se han seleccionado {total} tareas, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_SUB_TITLE_TICKETS_ALL_VALID:
    "Se seleccionaron {total} casos",
  DELETE_ALL_MODAL_SUB_TITLE_TICKETS_SOME_VALID:
    "Se han seleccionado {total} casos, solo se pueden eliminar {valid} debido a los permisos",
  DELETE_ALL_MODAL_TITLE_BILLINGS:
    "¿Está seguro de que desea eliminar las siguientes facturas?",
  DELETE_ALL_MODAL_TITLE_CALLS:
    "¿Está seguro de que desea eliminar las siguientes llamadas?",
  DELETE_ALL_MODAL_TITLE_CLIENTS:
    "¿Está seguro de que desea eliminar las siguientes cuentas?",
  DELETE_ALL_MODAL_TITLE_CONTACTS:
    "¿Está seguro de que desea eliminar los contactos a continuación?",
  DELETE_ALL_MODAL_TITLE_CONTRACTS:
    "¿Está seguro de que desea eliminar los contratos a continuación?",
  DELETE_ALL_MODAL_TITLE_DEALS:
    "¿Estás seguro de que deseas eliminar la empresa a continuación?",
  DELETE_ALL_MODAL_TITLE_EMAIL_SIGNATURES:
    "¿Está seguro de que desea eliminar las firmas de correo electrónico a continuación?",
  DELETE_ALL_MODAL_TITLE_INTERVENTIONS:
    "¿Estás seguro de que quieres eliminar los tiempos a continuación?",
  DELETE_ALL_MODAL_TITLE_PROJECTS:
    "¿Está seguro de que desea eliminar los proyectos a continuación?",
  DELETE_ALL_MODAL_TITLE_SUBSCRIPTIONS:
    "¿Estás seguro de que deseas eliminar las suscripciones a continuación?",
  DELETE_ALL_MODAL_TITLE_TASKS:
    "¿Está seguro de que desea eliminar las siguientes tareas?",
  DELETE_ALL_MODAL_TITLE_TICKETS:
    "¿Está seguro de que desea eliminar los casos siguientes?",
  DELETE_API_KEY_QUESTION: "¿Quieres borrar esta clave?",
  DELETE_COLUMN: "Eliminar columna",
  DELETE_COMPANY_MSG: "¿Desea eliminar esta empresa?",
  DELETE_COUPON: "Eliminar cupón",
  DELETE_DOMAIN_QUESTION: "¿Quieres eliminar este dominio?",
  DELETE_FILTER: "Eliminar filtro",
  DELETE_FILTER_WARNING: "¿Quieres eliminar este filtro?",
  DELETE_INVITE_CONFIRMATION: "¿Deseas eliminar esta invitación?",
  DELETE_MESSAGE: "¿Seguro que quieres borrar este mensaje?",
  DELETE_ROW: "Eliminar linea",
  DELETE_SELECTED: "Eliminar selecionados",
  DELETE_SOCIAL_ACCOUNT: "Eliminar conexión",
  DELETE_SOCIAL_ACCOUNT_QUESTION: "¿Quieres eliminar esta conexión?",
  DELETE_SOCIAL_ACCOUNT_WARNING:
    "Cuando elimine la conexión, todos los datos se eliminarán de forma permanente",
  DELETE_STOPWATCH_CONFIRMATION: "¿Quieres eliminar este temporizador?",
  DELETE_SUBSCRIPTION_TYPE: "¿Quieres eliminar este tipo de suscripción?",
  DELETE_TEAM_QUESTION: "¿Quieres eliminar este equipo?",
  DELETE_USER: "Borrar usuario",
  DELETE_USER_CONFIRMATION: "¿Deseas eliminar a este colaborador?",
  DELETE_VIEW: "Eliminar vista",
  DELETE_VIEW_CONFIRMATION: "¿Quieres eliminar esta vista?",
  DELETE_WIDGET_CONFIRMATION: "¿Quieres eliminar este widget?",
  DELETE_WORKSPACE: "Eliminar espacio de trabajo",
  DELETE_WORKSPACE_DESC:
    "Después de eliminar este espacio de trabajo no puede volver atrás, asegúrese de esta acción",
  DELETE_WORKSPACE_TITLE: "Eliminar espacio de trabajo",
  DELETED: "Eliminado",
  DELETED_ITEM: "Elemento eliminado",
  DELIVERY_TIME: "El tiempo de entrega",
  DEPARTMENT: "Departamento",
  DEPARTMENT_ACTIVITY: "Actividad del departamento",
  DEPARTMENT_CASE_AVERAGE: "Departamento: tiempo medio de cierre de casos",
  DEPARTMENT_EMAIL: "Correo electrónico del departamento",
  DEPARTMENT_IS: "Departamento es",
  DEPARTMENT_LOAD: "Departamento - carga de trabajo",
  DEPARTMENT_NAME: "Nombre de departamento",
  DEPARTMENT_OR_TEAM: "Departamento ou equipo",
  DEPARTMENT_PHONE: "Número de teléfono del departamento",
  DEPARTMENT_SAVE_SUCCESS: "Department guardada correctamente",
  DEPARTMENT_TASKS_AVERAGE: "Departamento: tiempo medio de cierre de tareas",
  DEPARTMENT_TEAM: "Departamento / Equipo",
  DEPARTMENT_TIME_RECORDS: "Registros de tiempos del departamento",
  DEPARTMENTS: "Departamentos",
  DESCRIPTION: "Descripción",
  DESCRIPTION_LENGTH:
    "La descripción debe tener al menos 30 caracteres (solo se ingresó {charLength})",
  DESCRIPTION_PERSON_RESPONSIBLE: "Descripción interna",
  DESCRIPTION_PERSON_RESPONSIBLE_ABRV: "Descripción interna",
  DESCRIPTIVE_DEALS: "Rendimiento descriptivo de las negocios",
  DESIGN: "Diseño",
  DESIGNATION: "Designacion",
  DESKTOP: "Desktop",
  DETAILED_KNOWLEDGE_OF_EACH_PLAN:
    "Conoce en detalle todas las características de cada plan",
  DETAILS: "Detalles",
  DIFFERENT_FROM: "Es diferente de",
  DIRECT: "Mensaje directo",
  DIRECTION_DEPARTMENT: "Dirección",
  DISCOUNT: "Descuento",
  DISCOUNT_CODE_VALID_FOREVER: "Código promocional válido para siempre",
  DISCOUNT_CODE_VALID_ONLY_ONCE: "Código promocional válido para un solo uso",
  DISCOUNT_CODE_VALID_UNTIL: "Código promocional válido hasta",
  DISCOUNT_OFF: "Descuento de",
  DISPLAY_COLUMNS: "Elija las columnas para mostrar",
  DISTRICT: "Distrito",
  DKIM_RECORD: "Registro DKIM",
  DO_NOT_IMPORT: "No importar",
  DO_NOTHING: "No hacer nada",
  DOC_TEMPLATES: "Plantillas",
  DOCUMENTATION: "Documentación",
  DOCUMENTS: "Documentos",
  DOES_NOT_CONTAIN: "No contiene",
  DOMAIN: "Dominio",
  DOMAIN_ERROR_FORMAT: "El dominio que ingresó no tiene un formato válido",
  DOMAIN_KEY_DKIM_DESCRIPTION:
    "Debe agregar un nuevo registro <b> TXT </b> con el nombre <clip> {Key} </clip> con el siguiente contenido",
  DOMAIN_KEY_SPF_DESCRIPTION:
    "Debe agregar un registro <b> TXT </b> en el vértice / raíz de su dominio <b> ({subDomain}) </b> con el siguiente contenido. Si ya envía correo desde otro servicio, es posible que solo necesite agregar <clip> {Key} </clip> a su registro existente",
  DOMAIN_MX_DESCRIPTION:
    "Para recibir correos electrónicos entrantes para este dominio, debe agregar los siguientes registros <b> MX </b> en el vértice / raíz de su dominio <clip>{subDomain}</clip>. No No tienes que hacer esto y solo te diremos si están configurados o no. Ambos registros deben tener prioridad <b> 10. </b>",
  DOMAIN_NAME: "Nombre de dominio",
  DOMAIN_NAME_ERROR_FORMAT:
    "El nombre corto solo puede contener letras, números y guiones (-)",
  DOMAIN_NAME_ERROR_LENGTH:
    "El nombre corto debe tener un máximo de 30 caracteres",
  DOMAIN_RETURN_PATH_DESCRIPTION:
    "Debe agregar un registro <b> CNAME </b> en <clip> {Key} </clip> para que apunte al nombre de host a continuación",
  DOMAINS: "Dominios y URL",
  DOMAINS_URL: "Dominios",
  DOWNGRADE_PLAN: "Degradar",
  DOWNLOAD: "Descargar",
  DRAFT: "Sequía",
  DRAG_OR_CLICK_FILE: "Arrastra el archivo o haz clic para buscar",
  DROP_THE_FILE: "Suelta el archivo para iniciar la carga",
  DUPLICATE: "Duplicar",
  DUPLICATE_ALL: "Duplicar todo",
  DUPLICATE_MESSAGE_FOR_CLIENTS:
    "Ya hay {count} clientes con este {type}. <a> Haga clic aquí para ver </a>",
  DUPLICATES: "Duplicados",
  DURATION: "Duración",
  E_LEARNING: "Aprendizaje electrónico",
  EDIT: "Editar",
  EDIT_ACCOUNT: "Editar cuenta",
  EDIT_API_KEY: "Editar clave de API {id}",
  EDIT_CALLS: "Editar llamada",
  EDIT_CANCELATION_REASON: "Editar motivo de cancelación",
  EDIT_CARDS: "Editar tarjetas",
  EDIT_CHECKLIST_TEMPLATE: "Editar plantilla de lista de verificación",
  EDIT_CLIENTS: "Editar cuenta",
  EDIT_COLOR_FILTER: "Editar color",
  EDIT_COLUMNS: "Editar columnas",
  EDIT_COMPANY: "Editar empresa",
  EDIT_CONTACTS: "Editar contactos",
  EDIT_CONTRACTS: "Editar contratos",
  EDIT_DEAL: "Negocio editada",
  EDIT_DEALS: "Editar negocios",
  EDIT_EMAIL_DOMAIN: "Editar dominio de correo electrónico",
  EDIT_EMAIL_SIGNATURE: "Editar firma de correo electrónico",
  EDIT_FILTER: "Editar filtro",
  EDIT_KANBAN: "Editar kanban",
  EDIT_LOSS_REASON: "Editar motivo de pérdida",
  EDIT_MODULES: "Editar módulos",
  EDIT_ORDER: "Editar orden",
  EDIT_PROJECTS: "Editar proyectos",
  EDIT_RECEPTION: "Editar recepción",
  EDIT_STATUS: "Editar estado",
  EDIT_SUBSCRIPTIONS: "Editar suscripcións",
  EDIT_TASKS: "Editar tarefas",
  EDIT_TICKETS: "Editar casos",
  EDIT_WIDGET: "Editar widget",
  EDIT_WORKFLOW: "Editar workflow",
  EDITED: "Editado",
  EDITED_ACCOUNT: "Editó la cuenta",
  EDITED_CALL: "Editó la llamada",
  EDITED_CASE: "Caso editado",
  EDITED_CLASSIFICATION: "Editó la clasificación",
  EDITED_CONTACT: "Editó el contacto",
  EDITED_CONTRACT: "Editó el contrato",
  EDITED_CONVERSATION: "Editó la conversación",
  EDITED_DEAL: "Editó el trato",
  EDITED_PROJECT: "Proyecto editado",
  EDITED_SUBSCRIPTION: "Editó la suscripción",
  EDITED_TASK: "Editó la tarea",
  EDITED_TIME: "Editó el registro de tiempo",
  EDITION: "Edición",
  EDITOR: "Editor",
  EDUCATION_MANAGEMENT: "Administración de educación",
  ELECTRICAL_OR_ELECTRONIC_MANUFACTURING: "Fabricación eléctrica / electrónica",
  ELIMINATE_CONTRACT_TYPE: "¿Quieres eliminar esta tipo de contract?",
  ELIMINATE_DEPARTMENT_CONFIRMATION: "¿Quieres eliminar este departamento?",
  ELIMINATE_INEFICIENT_TYPE:
    "¿Quieres eliminar este motivo de tiempo no imputable?",
  ELIMINATE_INTERVENTION_TYPE: "¿Quieres eliminar este tipo de hora?",
  ELIMINATE_PROJECT_CATEGORY: "¿Quieres eliminar esta categoría de proyecto?",
  EMAIL: "Correo electrónico",
  EMAIL_ATTACHMENTS: "Adjuntos de correo electrónico",
  EMAIL_CORRECT: "Introduzca un correo electrónico válido",
  EMAIL_CREATION: "Creación de email",
  EMAIL_DOMAIN: "Dominio de correo electrónico",
  EMAIL_DOMAIN_ADD_TO_DNS:
    "Agregue los registros siguientes a su DNS para <b> {Domain} </b>",
  EMAIL_DOMAIN_VERIFY: "Validar registros",
  EMAIL_DOMAIN_WILL_SEND_DESCRIPTION:
    "Los correos electrónicos se enviarán desde: <b> {Domain} </b>",
  EMAIL_FEEDBACK_CONTENT:
    "Lo invitamos a compartir sus comentarios con respecto a su solicitud (<b>{caseId} : {caseName}</b>)",
  EMAIL_FEEDBACK_START: "Estimado",
  EMAIL_FROM: "Desde",
  EMAIL_INFO_DESC: "Su correo electrónico profesional",
  EMAIL_PASSWORD_INSTRUCTIONS:
    "Enviaremos instrucciones por correo electrónico para restablecer su contraseña",
  EMAIL_REPORT: "Informe por correo electrónico",
  EMAIL_SIGNATURE: "Firma de email",
  EMAIL_SIGNATURE_DELETE_TITLE: "Eliminando firma de correo electrónico",
  EMAIL_SIGNATURES: "Firmas de correo electrónico",
  EMAIL_SIGNATURES_DELETE_DELAY_DESCRIPTION:
    "Preparándose para eliminar las firmas de correo electrónico",
  EMAIL_SIGNATURES_DELETE_DELAY_TITLE:
    "Eliminación de la firma de correo electrónico",
  EMAIL_SIGNATURES_DELETE_DESCRIPTION:
    "Eliminación de la firma de correo electrónico",
  EMAIL_SIGNATURES_DELETE_TITLE: "Eliminación de firma de correo electrónico",
  EMAIL_TO: "A",
  EMAILS: "Emails",
  EMPLOYE_NO_FILTER:
    "No es posible utilizar este módulo con el filtro seleccionado",
  EMPLOYEE: "El empleado no es responsable de un departamento",
  EMPLOYEE_NO_DEPARTMNET: "El colaborador no es responsable de un departamento",
  EMPLOYEES: "Empleados",
  EMPTY_ACCOUNTS: "No hay registros de cuentas activas",
  EMPTY_ACTIVITY_LIST: "No hay registros de actividad asociados",
  EMPTY_API_KEYS: "Lista de clave API vacía",
  EMPTY_AUTOMATION_LIST: "No hay registros de automatizaciones asociadas",
  EMPTY_BILLING_LIST: "Lista de facturación vacía",
  EMPTY_CALL_LIST: "No hay registros de llamadas asociados",
  EMPTY_CALLS: "Sin llamadas",
  EMPTY_CASE: "Lista de casos vacía",
  EMPTY_CASE_TECORDS: "No hay registros de casos asociados",
  EMPTY_CASES: "Sin casos",
  EMPTY_COMPANY: "Lista de empresas vacía",
  EMPTY_CONTACTS: "No hay registros de contactos asociados",
  EMPTY_CONTRACTS: "Sin contratos",
  EMPTY_CONTRACTS_LIST: "Lista de contratos vacía",
  EMPTY_DEALS: "No hay registros de acuerdos asociados",
  EMPTY_DEALS_LIST: "Lista de negocios vacía",
  EMPTY_DEPARTMENT: "Lista de departamentos vacía",
  EMPTY_DOMAINS: "Lista de dominios de correo electrónico vacía",
  EMPTY_EMAIL_RECEPTION: "Lista de emails vacía",
  EMPTY_EMAIL_SIGNATURES: "Lista de suscripciones de correo electrónico vacía",
  EMPTY_ENTRY_RECEPTION: "No hay conversaciones abiertas para mostrar",
  EMPTY_ENTRY_RECEPTION_SUBTEXT: "No se ha seleccionado nada",
  EMPTY_EXPORT: "La lista de exportación está vacía",
  EMPTY_FAVORITE_LIST: "La lista de favoritos está vacía actualmente",
  EMPTY_FILTERS: "No se encontraron filtros",
  EMPTY_HISTORY: "No hay registros históricos",
  EMPTY_IMPORTS: "Listado de importaciones vacío",
  EMPTY_INBOX_RECEPTION: "No hay conversaciones",
  EMPTY_INBOX_RECEPTION_QUERY: "No encontramos ningún resultado",
  EMPTY_INBOX_RECEPTION_QUERY_SUBTEXT:
    "Intente utilizar una palabra clave diferente",
  EMPTY_INEFICIENT_TYPES: "No hay registros de tipos de tiempo no imputable",
  EMPTY_INTERVENTIONS_LIST: "Lista de tiempo vacía",
  EMPTY_LOGS: "Lista de registro vacía",
  EMPTY_LOST_DEALS: "No hay negocios perdidas",
  EMPTY_LOST_REASON_LIST: "Lista de motivos de pérdida vacía.",
  EMPTY_NOTIFICATIONS: "No tienes notificaciones",
  EMPTY_PIPELINES_LIST: "Listado de canalizaciones vacío",
  EMPTY_PROJECT_LIST: "Lista de proyectos vacía",
  EMPTY_PROJECT_TYPES: "Lista de tipos de proyectos vacía",
  EMPTY_PROJECTS: "No hay proyectos",
  EMPTY_RECORDS: "No hay registros históricos",
  EMPTY_SALES: "No hay ventas",
  EMPTY_SOCIAL_ACCOUNT: "Lista de cuentas de redes sociales vacía",
  EMPTY_SOCIAL_POST_LIST: "Lista de publicaciones vacía",
  EMPTY_SUBSCRIPTIONS: "No hay suscripciones asociadas",
  EMPTY_SUBSCRIPTIONS_RECORDS: "No hay registros de suscripción asociados",
  EMPTY_SUBSCRIPTIONS_TYPE_RECORDS:
    "No hay registros de tipo de suscripción asociados",
  EMPTY_TASK_LIST: "Lista de tareas vacía",
  EMPTY_TASKS: "No hay registros de tareas asociadas",
  EMPTY_TEAMS: "Lista de equipos vacía",
  EMPTY_TICKETS_LIST: "Lista de casos vacía",
  EMPTY_TIME: "No tienes registros de tiempo",
  EMPTY_TIMER: "Aún no tienes cronómetros",
  EMPTY_USERS: "Lista de usuarios vacía",
  EMPTY_WIDGETS: "Lista de widgets vacía",
  ENABLE: "Habilitar",
  ENABLE_CUSTOM_NODE_COLOR: "Habilitar color personalizado",
  ENABLE_MULTIPLE: "Habilitar selección múltiple",
  ENABLE_RAW_BODY: "Enable raw body",
  ENABLE_RAW_HEADERS: "Enable raw headers",
  ENABLE_RAW_QUERY_STRINGS: "Enable raw query strings",
  ENABLE_SCHEDULE: "Activar programa",
  END: "Fin",
  END_ALL_SESSIONS: "Finalizar todas las sesiones",
  END_DATE: "Fecha final",
  END_SESSION: "Finalizar sesión",
  ENDS_WITH: "Termina con",
  ENROLL_CONFIRMATION: "Limpiar la pantalla",
  ENROLL_DESCRIPTION_CALL:
    "Se inscribirá un total de {count} tiempos en este workflow.",
  ENROLL_DESCRIPTION_CLIENT:
    "Se inscribirá un total de {count} contactos en este workflow.",
  ENROLL_DESCRIPTION_CONTACT:
    "Se inscribirá un total de {count} negocios en este workflow.",
  ENROLL_DESCRIPTION_CONTRACT:
    "Se ingresará un total de {count} suscripciones en este workflow.",
  ENROLL_DESCRIPTION_DEAL:
    "Se inscribirá un total de {count} contratos en este workflow.",
  ENROLL_DESCRIPTION_PROJECT:
    "Se inscribirá un total de {count} llamadas en este workflow.",
  ENROLL_DESCRIPTION_SUBSCRIPTION:
    "Se ingresará un total de {count} tickets en este workflow.",
  ENROLL_DESCRIPTION_TASK:
    "Se inscribirá un total de {count} proyectos en este workflow.",
  ENROLL_DESCRIPTION_TICKET:
    "Se ingresará un total de {count} tareas en este workflow.",
  ENROLL_DESCRIPTION_TIME: "¿Está seguro de que desea realizar esta acción?",
  ENTER: "Entrar",
  ENTER_DEPARTMENT_EMAIL_PLACEHOLDER:
    "Ingrese el correo electrónico del departamento (opcional)",
  ENTER_VALID_DISCOUNT_CODE: "Ingrese un código de descuento válido",
  ENTERTAINMENT_OR_MOVIE_PRODUCTION:
    "Entretenimiento / Producción de películas",
  ENTITY_ICONS: "Iconos",
  ENTRY: "Entrada",
  ENVIRONMENTAL_SERVICES: "Servicios ambientales",
  EQUAL: "Igual",
  EQUALS: "Igual a",
  ERROR: "Error",
  ERROR_FIELDS: "Error: hay campos obligatorios para completar",
  ERROR_HAPPEND: "Ocurrió un error",
  ERROR_LOADING_EMAIL_PREVIEW:
    "No es posible obtener una vista previa de los archivos adjuntos en este correo electrónico. Descargue el correo electrónico para ver los archivos adjuntos",
  ERROR_OCCURRED: "Ocurrió un error",
  ERROR_OCCURRED_CONTACT_ADMIN:
    "Si el error persiste, póngase en contacto con la administración",
  ERROR_OCCURRED_SORRY:
    "Lo sentimos, pero ha ocurrido un error. Por favor, intente de nuevo más tarde",
  ERROR_OCCURRED_TRY_ADVANCED: "También revise los filtros avanzados",
  ERROR_OCURRED: "Ocurrio un error",
  ERRORS_FOUND: "Erros encontrado",
  ESTIMATED: "Estimado",
  ESTIMATED_HOUR: "Tiempo estimado",
  ESTIMATED_HOURS: "Tiempo estimado",
  ESTIMATED_HOURS_ABRV: "Tiempo estimado",
  ESTIMATED_HOURS_TASKS: "Tiempo estimado de las tareas",
  ESTIMATED_TIME: "Tiempo estimado",
  EVALUATION: "Evaluación",
  EVENTS_SERVICES: "Servicios de eventos",
  EVERY_DAY: "Todos los días",
  EVERY_HOUR: "Cada hora",
  EVERY_MINUTE: "Cada minuto",
  EVERY_MONTH: "Cada mes",
  EVERY_WEEK: "Cada semana",
  EVERY_X: "Cada x",
  EVIDENCE: "Evidencia",
  EVOLUTION_ACCOUNTS: "Evolución de casos",
  EVOLUTION_AVERAGE: "Evolución de lo tiempo medio de cerro de casos",
  EVOLUTION_DEALS: "Evolución de las negocios",
  EVOLUTION_DEALS_GLOBAL: "Evolución global de las negocios",
  EXAMPLES: "Ejemplos",
  EXCEEDED_HOURS: "Tiempo excedido",
  EXCLUDED_USER: "Excluir usuario(s)",
  EXCLUSIVITY: "Exclusividad",
  EXECUTION_CONTRACT: "Ejecución de un contrato",
  EXECUTIVE_OFFICE: "Oficina Ejecutiva",
  EXISTING_EMAIL_CONVERSATIONS:
    "Hay conversaciones de email abiertas asociadas con este elemento. {br} Haga clic <a> aquí </a> para ver",
  EXIT: "Salir",
  EXIT_ALL_SESSIONS_DESC: "Dónde iniciaste sesión.",
  EXIT_ALL_SESSIONS_TITLE: "Todas las sesiones",
  EXPAND_ALL: "Expandir todo",
  EXPIRATION_DATE: "Fecha de caducidad",
  EXPIRE_DATE: "Vencimiento",
  EXPIRED: "Caducado",
  EXPIRED_CONTRACTS: "Contratos vencidos",
  EXPORT: "Exportar",
  EXPORT_DATE: "Fecha de exportación",
  EXPORT_EXCEL: "Exportar todo para excel",
  EXPORT_FAILLED: "Ha fallado",
  EXPORT_NOT_CLASSIFIED: "No classificado",
  EXPORT_PAGE: "Exportar página para excel",
  EXPORT_PDF: "Exportar PDF",
  EXPORT_QUANTITY_TYPE: "Exportar",
  EXPORT_QUANTITY_TYPE_COMPLETE: "Completar",
  EXPORT_QUANTITY_TYPE_PARCIAL: "Parcial",
  EXPORT_SUCCESS: "Éxito",
  EXPORT_TO_EXCEL: "Exportar a Excel",
  EXPORT_TYPE: "Tipo de exportación",
  EXPORT_WORKFLOW: "Exportación workflow",
  EXPORTATIONS: "Exportaciones",
  EXPORTS: "Exportaciones",
  EXPORTS_EXCEL: "Exportar Excel",
  EXTENSION: "Extensión telefónica",
  EXTRA_GIGA_COST: "(Precio por GB extra € {amount}/mes/GB)",
  EXTRA_USER_COST: "(Usuario adicional € {amount}/mes)",
  FACEBOOK_URL: "Facebook URL",
  FACILITIES_SERVICES: "Servicios de instalaciones",
  FARMING: "Agricultura",
  FAVORITES: "Favoritos",
  FEBRUARY: "Febrero",
  FEEDBACK: "Retroalimentación",
  FEEDBACK_ALREDY_SUBMITED: "Esta feedback ya ha sido enviada.",
  FEEDBACK_FORM: "Formulario de comentarios",
  FEEDBACK_SUBMITED_SUCCESS: "Feedback enviado correctamente",
  FEEDBACK_TITLE: "Título del comentario",
  FEMALE: "Hembra",
  FIELD: "Campo",
  FIELD_2: "Campo",
  FIELD_IS_REQUIRED: "Este campo es requerido",
  FIELD_IS_REQUIRED_DESCRIPTION: "Por favor complete los detalles",
  FILE: "Archivo",
  FILE_UPLOAD: "Subir archivo",
  FILES: "Archivos",
  FILES_ONLY_TYPE: "Solo es posible enviar archivos de tipo",
  FILES_TOO_BIG:
    "¡Solo es posible enviar archivos con un tamaño máximo de 40Mb!",
  FILTER: "Filtrar",
  FILTER_ADDFILTER: "Añadir filtro",
  FILTER_AND: "y",
  FILTER_BACK: "Atrás",
  FILTER_BY: "Filtrado por:",
  FILTER_CONTAINS: "contiene",
  FILTER_DATE_GT: "es despues",
  FILTER_DATE_LAST: "últimos x días",
  FILTER_DATE_LT: "es antes",
  FILTER_DATE_NEXT: "próximos x días",
  FILTER_DOESNOTCONTAIN: "no contiene",
  FILTER_ENDSWITH: "termina con",
  FILTER_EQ: "es igual a",
  FILTER_ERROR_OCCURRED_SORRY:
    "Lo sentimos, pero hay uno o más filtros con un formato no válido. Por favor, retire los filtros y vuelva a intentarlo",
  FILTER_FILTER: "Filtrar",
  FILTER_GROUP: "Grupo",
  FILTER_GROUPBY: "Agrupar por:",
  FILTER_GT: "es mayor que",
  FILTER_GTE: "es mayor o igual a",
  FILTER_ISEMPTY: "esta vacio",
  FILTER_ISNOEMPTY: "no está vacío",
  FILTER_LAST_X_DAYS: "últimos {days} días",
  FILTER_LAST_X_DAYS_PREVIEW: "Últimos {days} días de {field}",
  FILTER_LT: "es menos que",
  FILTER_LTE: "es menor o igual que",
  FILTER_NAME: "Nombre de filtro",
  FILTER_NAME_NOT_VALID: "El nombre no es válido / ya existe",
  FILTER_NEQ: "no es igual a",
  FILTER_NEXT_X_DAYS: "próximos {days} días",
  FILTER_NEXT_X_DAYS_PREVIEW: "próximos {days} días de {field}",
  FILTER_STARTSWITH: "comienza con",
  FILTERS: "Filtros",
  FINAL_DATA: "Datos finales",
  FINANCIAL_DEPARTMENT: "Financiero",
  FINANCIAL_SERVICES: "Servicios financieros",
  FINE_ART: "Arte fino",
  FIRST: "Primero",
  FISCAL_COUNTRY: "País fiscal",
  FISHERY: "Pesquería",
  FOLLOW: "Seguir",
  FOLLOW_UP: "Seguimiento",
  FOLLOWERS: "Seguidores",
  FONT_FAMILY: "Tipo de letra",
  FONT_SIZE: "Tamanho de letra",
  FOOD_OR_BEVERAGES: "Alimentos / Bebidas",
  FOOD_PRODUCTION: "La producción de alimentos",
  FOOTER: "Pie de página",
  FOR_AN_EXTRA_USER_PLAN:
    "Para un plan con más de 100 usuarios, póngase en contacto",
  FOR_AN_EXTRA_USER_PLAN_DESCRIPTION:
    "No dudes en preguntar, estamos a tu entera disposición",
  FOR_HIMSELF: "a él mismo",
  FOR_USER: "al usuario",
  FOREVER: "Sin límite",
  FORGET_PASSWORD: "Olvidé mi contraseña",
  FORM: "Formar",
  FORM_END_DATA_BIGGER_THAN_START_DATA:
    "La fecha final no puede ser menor que la fecha de inicio",
  FORM_OR_BLOCK: "Es necesario tener al menos un bloque o Formulario",
  FORM_TITLE: "Título del formulario",
  FREE_TRIAL: "Prueba gratis",
  FREE_TRIAL_DAYS_LEFT: "días restantes de la prueba gratuita",
  FREE_TRIAL_ENDED: "Tu prueba gratuita ha finalizado",
  FREEZED_COLUMNS: "Columnas freeze",
  FRIDAY: "Viernes",
  FROM: "Desde",
  FROM_PLAN: "Desde el plan",
  FULL_ACCESS: "Acceso completo",
  FULL_NAME: "Nombre completo",
  FULLSCREEN: "Pantalla completa",
  FUNCTIONALITIES: "Funcionalidades",
  FUNDRAISING: "Recaudación de fondos",
  FURNITURE: "Mueble",
  GAMBLING_OR_CASINOS: "Juegos de apuestas / casinos",
  GDPR: "Cambiar GDPR",
  GDPR_LEGALBASIS: "Base legal para procesar los datos de un contacto",
  GDPR_OPTION1: "Interés legítimo: prospecto / cliente potencial",
  GDPR_OPTION2: "Interés legítimo: cliente existente",
  GDPR_OPTION3: "Interés legítimo - otros",
  GDPR_OPTION4: "Ejecución de un contrato",
  GDPR_OPTION5: "Consentimiento dado libremente por contacto",
  GDPR_OPTION6: "No aplica",
  GENDER: "Género",
  GENERAL_INFORMATION: "Información general",
  GENERATE_CODE: "Generar fragmento",
  GENERATED_API_KEY: "clave de API generada",
  GEOLOCATION_BROWSER_ERROR:
    "La geolocalización no está disponible en este navegador",
  GEOLOCATION_REFUSED:
    "El usuario rechazó la solicitud de ubicación geográfica",
  GEOLOCATION_SUCCESS: "Solicitud de geolocalización realizada correctamente",
  GEOLOCATION_TIMEOUT:
    "Se agotó el tiempo de espera de la solicitud para obtener la ubicación del usuario",
  GEOLOCATION_UNAVAILABLE: "El servicio de ubicación no está disponible",
  GERAL: "General",
  GET_CALL: "Recibir llamada",
  GET_CLIENT: "Obtener cliente",
  GET_CONTACT: "Obtener contacto",
  GET_CONTRACT: "Obtener contrato",
  GET_DEAL: "Obtener negocio",
  GET_ITEMS: "Obtener elementos",
  GET_PROJECT: "Obtener proyecto",
  GET_SUBSCRIPTION: "Obtener suscripción",
  GET_TASK: "Obtener tarea",
  GET_TICKET: "Obtener caso",
  GLASS_OR_CERAMICS_OR_CONCRETE: "Vidrio / Cerámica / Concreto",
  GLOBAL_SEARCH_DEF_TO_CLOSE: "para cerrar",
  GLOBAL_SEARCH_DEF_TO_NAVIGATE: "para navegar",
  GLOBAL_SEARCH_DEF_TO_SEARCH: "para seleccionar",
  GLOBE_PICTURE_TOOLTIP: "Sitio web",
  GOOD: "Bueno,",
  GOVERNMENT_ADMINISTRATION: "Administración gubernamental",
  GOVERNMENT_RELATIONS: "Relaciones gubernamentales",
  GRAPHIC_DESIGN_OR_WEB_DESIGN: "Diseño gráfico / Diseño web",
  GREATER: "Mayor o igual",
  GREATER_EQUAL: "Menor",
  GROUP: "Grupo",
  GROUP_BY: "Agrupar por",
  HEADER: "Encabezamiento",
  HEADER_WELCOME_BAR_IGNORE: "Ignorar para siempre",
  HEADER_WELCOME_BAR_TEXT: "Obtenga más información sobre cómo comenzar con",
  HEADERS: "Encabezados",
  HEADING_1: "H1",
  HEADING_2: "H2",
  HEALTH_OR_FITNESS: "Salud y estado fisico",
  HELLO: "Hola",
  HELP: "Ayuda",
  HELP_MESSAGES: "Mensajes de ayuda",
  HIDE_NOTES: "Ocultar notas",
  HIGHER_EDUCATION_OR_ACADAMIA: "Educación superior / Acadamia",
  HIGHLIGHT: "Realce",
  HISTORY: "Historia",
  HISTORY_DEALS: "Historial de acuerdos",
  HISTORY_ERROR: "Hubo un error al cargar el historial",
  HISTORY_PIPELINE_STAGES: "Historia de las etapas del pipeline",
  HISTORY_STARTS: "Tu historia comienza aquí",
  HOLIDAY: "Vacaciones",
  HORIZONTAL: "Horizontal",
  HOSPITAL_OR_HEALTH_CARE: "Hospital / Atención sanitaria",
  HOSPITALITY: "Hospitalidad",
  HOUR: "Hora",
  HOURS_MULTIPLE: "Hora (s)",
  HOVER_ME_TO_SHOW: "Coloca el cursor aquí para mostrar",
  HOW_TO_RECEIVE_NOTIFICATIONS: "Cómo recibir notificaciones",
  HOW_WAS_THE_EXPERIENCE: "¿Cómo calificaría su experiencia?",
  HTTP_REQUEST: "Solicitud Http",
  HUMAN_RESOURCES_DEPARTMENT: "Recursos humanos",
  HUMAN_RESOURCES_OR_HR: "Recursos humanos / RRHH",
  ICON: "Icone",
  ID: "Carné de identidad",
  IGNORE_DEPARTMENTS_DESC:
    "Omitir el paso (luego puede crear y agregar departamentos)",
  IGNORE_ORGANIZATION_DESC:
    "Omitir paso (puede crear y agregar empresas más tarde)",
  IGNORE_TEAMS_DESC: "Omitir paso (puede crear y agregar equipos más tarde)",
  IMAGE: "Imagen",
  IMPORT: "Importar",
  IMPORT_ASSOCIATION_DESCRIPTION:
    "Si desea asociar Contactos con Clientes, es necesario identificar las columnas que los conectan",
  IMPORT_ASSOCIATION_TITLE: "Asociación entre {importType1} y {importType2}",
  IMPORT_CHECKBOX_PROPERTIES: "Las columnas sin propiedades no se importarán",
  IMPORT_CHOOSE_IMPORT:
    "Para continuar, debe seleccionar lo que desea importar",
  IMPORT_DO_NOT_OVERWRITE: "No sobrescriba los datos existentes",
  IMPORT_DUPLICATES_DESCRIPTION:
    "Cuando se selecciona, Airdesk permitirá que existan varios registros con el mismo valor; de lo contrario, la línea no se importará. Esta opción no tendrá ningún efecto sobre la superposición de datos",
  IMPORT_EXAMPLES_DESCRIPTION: "Ejemplos de valores detectados en su archivo",
  IMPORT_FILE_WARNING:
    "Antes de cargar los archivos, verifique que esté listo para ser importado. Los registros se pueden reemplazar o duplicar según su elección",
  IMPORT_FILES: "Importar archivos",
  IMPORT_FILES_PAGE_REQUIRED:
    "Para continuar, es necesario cargar el archivo y configurar las hojas",
  IMPORT_FILES_REQUIRED: "Para continuar, es necesario subir los archivos",
  IMPORT_INCOMPLETE: "Importación incompleta",
  IMPORT_MAP_COLUMNS:
    "Asigne columnas en su archivo para que se ajusten a las propiedades",
  IMPORT_MATCHES: "Partidos",
  IMPORT_MATCHES_DESCRIPTION:
    "Valida que la columna ya está bien mapeada. Airdesk intentará automáticamente mapear las propiedades de acuerdo con el nombre de las columnas en su archivo",
  IMPORT_NAME: "Nombre de importación",
  IMPORT_NAME_DESCRIPTION:
    "Nombre de columna de su archivo. Si su columna no se muestra, puede haber alguna inconsistencia en el archivo o no se ha completado ningún valor",
  IMPORT_NAME_PLACEHOLDER: "Ingrese un nombre para la importación",
  IMPORT_NR: "Importar Nº",
  IMPORT_OF: "Importación de",
  IMPORT_ONE_FILE: "Importar de un archivo",
  IMPORT_OR_EXPORT: "Importación y exportación",
  IMPORT_OVERWRITE_EMPTY_VALUE: "Sobrescribir si el valor está vacío",
  IMPORT_OVERWRITE_SELECTED_DATA: "Sobrescribir datos existentes",
  IMPORT_PROCESS_MESSAGE:
    "La importación se está procesando, se le notificará cuando finalice",
  IMPORT_PROPERTY_DESCRIPTION:
    "Estas son las propiedades de Airdesk, debe asignar su columna en el archivo con los campos en los que desea guardar los datos. Algunos campos son obligatorios para importar nuevos registros",
  IMPORT_REFERENCE_DESCRIPTION:
    "La referencia es necesaria si desea actualizar los datos de los registros existentes. Cuando se selecciona, Airdesk utilizará este campo como un valor de comparación para superponer el resto de los datos mapeados (según las reglas superpuestas). Si no se encuentra ningún registro con el valor, se creará un nuevo registro",
  IMPORT_TWO_FILES: "Importar desde dos archivos",
  IMPORT_TYPE: "Tipo de importación",
  IMPORT_WARNING_ALL_PROPERTIES:
    "Para continuar, es necesario que todas las propiedades estén configuradas",
  IMPORT_WARNING_CLIENTS_CONTACTS:
    "Para continuar, es necesario completar o borrar la relación entre clientes y contactos",
  IMPORT_WARNING_REQUIRED_PROPERTIES:
    "Para continuar, es necesario mapear las propiedades que son obligatorias",
  IMPORT_WORKFLOW: "Importación workflow",
  IMPORT_WORKFLOW_DESCRIPTION:
    "Pegue el texto en el área de texto. Si el texto de importación tiene un formato no válido, es posible que la pantalla de edición no funcione.",
  IMPORTED_RECORDS: "Registros importados",
  IMPORTS: "Importaciones",
  IMPUTED_HOURS: "Tiempo imputado",
  IN: "en",
  IN_APPROVAL: "En aprobacion",
  INACTIVE: "Inactivo",
  INACTIVE_CONTRACTS: "Contratos inactivos",
  INACTIVE_NOTIFICATIONS: "Notificaciones inactivas",
  INACTIVE_PROJECTS: "Proyectos inactivos",
  INBOX: "Bandeja de entrada",
  INBOX_EMAIL: "Correo electrónico de la bandeja de entrada",
  INDIVIDUAL_OR_FAMILY_SERVICES: "Servicios individuales / familiares",
  INDUSTRIAL_AUTOMATION: "Automatización industrial",
  INDUSTRY: "Industria",
  INEFFICIENT: "No imputable",
  INEFFICIENT_HOURS: "Tiempo no imputable",
  INEFFICIENT_HOURS_ABV: "Tiempo no imputable",
  INEFFICIENT_TIME: "Tiempo no imputable (minutos)",
  INEFFICIENT_TIME_REASONS: "Razones de tiempo no imputables",
  INEFFICIENT_TIME_TYPE: "Tipos de tiempo no imputable",
  INFORMATION_SERVICES: "Servicios de información",
  INFORMATION_TECHNOLOGY_OR_IT: "Tecnología de la información / TI",
  INPUTED_HOURS: "Tiempo imputado",
  INPUTS: "No. de entradas",
  INSERT_COLUMN_AFTER: "Insertar columna después",
  INSERT_COLUMN_BEFORE: "Insertar columna antes",
  INSERT_ONLY_VALID_SOCIAL_MEDIA_LINK: "Inserta un enlace de red social válido",
  INSERT_ROW_AFTER: "Insertar línea después",
  INSERT_ROW_BEFORE: "Insertar línea antes",
  INSERT_TABLE: "Insertar la mesa",
  INSTAGRAM_URL: "URL de Instagram",
  INSURANCE: "Seguro",
  INTEGRATIONS: "Integraciones",
  INTERNAL_NOTE: "Nota interna",
  INTERNATIONAL_AFFAIRS: "Asuntos internacionales",
  INTERNATIONAL_TRADE_OR_DEVELOPMENT: "Comercio internacional / Desarrollo",
  INTERNET: "Internet",
  INTERNET_FAIL_MESSAGE:
    "Intentando restablecer la conexión. Por favor, compruebe la conexión a Internet.",
  INTERVAL: "Rango de fechas",
  INTERVAL_BEGIN_DATE: "Fecha de inicio de inversión",
  INTERVAL_CUSTOM: "Rango personalizado",
  INTERVAL_EVEN_OLDER: "Aun mayor",
  INTERVAL_LAST_FIFTEEN_DAYS: "Últimos 15 días",
  INTERVAL_LAST_MONTH: "El mes pasado",
  INTERVAL_LAST_SEMESTER: "Último semestre",
  INTERVAL_LAST_TRIMESTER: "Último cuarto",
  INTERVAL_LAST_WEEK: "La semana pasada",
  INTERVAL_THIS_MONTH: "Este mes",
  INTERVAL_THIS_SEMESTER: "Este semestre",
  INTERVAL_THIS_TRIMESTER: "Este cuarto",
  INTERVAL_THIS_WEEK: "Esta semana",
  INTERVAL_TODAY: "Hoy",
  INTERVAL_YESTERDAY: "Ayer",
  INTERVENTION_TYPE: "Tipo de intervención",
  INTERVENTION_TYPES: "Tipos de intervención",
  INTERVENTIONS: "Tiempos",
  INTERVENTIONS_DELETE_DELAY_DESCRIPTION: "Preparándose para eliminar horas",
  INTERVENTIONS_DELETE_DELAY_TITLE: "Eliminación de horas",
  INTERVENTIONS_DELETE_DESCRIPTION: "Eliminando horas",
  INTERVENTIONS_DELETE_TITLE: "Eliminación de horas",
  INTERVENTIONS_TYPES_LIST_EMPTY: "Lista de tipos de tiempo vacía",
  INTERVENTIONS_TYPES_LIST_EMPTY_DESCRIPTION:
    "No hay registros de tiempo asociados",
  INVALID_COMPANY_WARNING: "Se requiere al menos una empresa.",
  INVALID_EMAIL: "Introduzca un correo electrónico válido",
  INVALID_FIELD: "Campo no válido",
  INVALID_FIELD_DESCRIPTION: "Introduzca los datos correctos",
  INVALID_INVITATION: "Invitación no válida",
  INVALID_NIF: "Este número fiscal no es válido.",
  INVALID_NUMBER_WARNING: "Por favor ingrese un número valido",
  INVALID_PASSWORD: "Introduzca una contraseña válida",
  INVALID_SCHEDULE_DAYS: "Todas las horas deben tener días definidos",
  INVESTMENT_BANKING_OR_VENTURE: "Banca de inversión / Venture",
  INVESTMENT_MANAGEMENT_OR_HEDGE_FUND_OR_PRIVATE_EQUITY:
    "Gestión de inversiones / Hedge fund / Private equity",
  INVITE: "Invita",
  INVITE_ALREADY_ACCEPTED: "Esta invitación ya ha sido aceptada",
  INVITE_EXPIRED: "Invitación caducada",
  INVITE_EXPIRED_DESCRIPTION:
    "El vínculo a esta invitación ya no está disponible. Comuníquese con la persona que lo invitó para solicitar una nueva",
  INVITE_INVALID: "Invitación no válida",
  INVITE_INVALID_ACCOUNT:
    "Esta invitación no pertenece a la cuenta con la que inició sesión. Inicie sesión con su cuenta",
  INVITE_LOGIN_REQUIRED: "Necesario iniciar sesión.",
  INVITE_LOGIN_REQUIRED_DESCRIPTION:
    "Debes iniciar sesión para aceptar esta invitación",
  INVITE_NOT_FOUND_DESCRIPTION:
    "Nos disculpamos. No se encontró esta invitación. Asegúrate de que el vínculo de invitación que recibiste sea correcto",
  INVITE_UNKNOWN_DESCRIPTION:
    "Se produjo un error al cargar su invitación. Lamentamos las molestias",
  INVITES: "Invitaciones",
  INVOICE: "Factura",
  INVOICES: "Facturas",
  IS_EMPTY: "Esta vacio",
  IS_SUSPENDED: "está cancelado.",
  ISNT_EMPTY: "No está vacío",
  IT_DEPARTMENT: "IT",
  ITALIC: "Itálico",
  JANUARY: "Enero",
  JUDICIARY: "Judicial",
  JULY: "Julio",
  JUNE: "Junio",
  KEYBOARD_TO_NAVIGATE: "Usa tu teclado para navegar",
  LANGUAGES: "Idiomas",
  LAST: "Último",
  LAST_ACTIVITY: "Última actividad",
  LAST_CASES: "Últimos casos",
  LAST_CHANGE: "Ultimo cambio",
  LAST_MONTH: "El mes pasado",
  LAST_PASSWORD_CHANGE: "El último cambio de contraseña fue en",
  LAST_RECORDS: "Últimos registros",
  LAST_TASKS: "Últimas tareas",
  LAST_THREE_NUMBERS_OF_CARD:
    "Últimos tres números en el reverso de la tarjeta",
  LAST_TIME_REGISTRATION: "Últimos registros",
  LAST_UPDATE: "Última actualización",
  LATEST_REGISTRATIONS: "Últimos registros",
  LATITUDE: "Latitud",
  LAW_ENFORCEMENT: "Cumplimiento de la ley",
  LAW_PRACTICE_OR_LAW_FIRMS: "Práctica jurídica / despachos de abogados",
  LEGA_BASIS: "Base legal para procesar los datos de un contacto",
  LEGAL: "Legal",
  LEGAL_SERVICES: "Servicios legales",
  LEGISLATIVE_OFFICE: "Oficina legislativa",
  LEISURE_OR_TRAVEL: "Viaje de placer",
  LESSER: "Menor o igual",
  LESSER_EQUAL: "No es igual",
  LIBRARY: "Biblioteca",
  LINE_BREAK: "Salto de línea",
  LINK: "Enlace",
  LINKEDIN_URL: "URL de Linkedin",
  LOAD_MORE: "Cargar mais",
  LOCAL: "Local",
  LOCATION: "Ubicación",
  LOCATION_ADDRESS: "Ubicación y dirección",
  LOG: "Log",
  LOG_OUT: "Cerrar sesión",
  LOGIN_BACK: "Atrás para iniciar sesión",
  LOGIN_INVALID_CREDENTIALS:
    "Tu correo electrónico o contraseña son incorrectos",
  LOGIN_NO_ACCOUNT: "¿No tienes una cuenta?",
  LOGIN_REGISTER: "Regístrate",
  LOGIN_SET_VALID_EMAIL: "Establezca un correo electrónico válido",
  LOGIN_SET_VALID_PASSWORD: "Establezca una contraseña válida",
  LOGIN_UNKNOWN_ERROR:
    "Se produjo un error al iniciar sesión. Vuelva a intentarlo en algún momento",
  LOGISTICS: "Logística",
  LOGISTICS_DEPARTMENT: "Logística",
  LOGISTICS_OR_PROCUREMENT: "Logística / Adquisiciones",
  LOGO: "Logo",
  LOGO_FOR_REPORTS: "Logotipo para informes",
  LOGO_LINK: "Enlace del logotipo",
  LOGO_UPLOAD: "Subir logotipo",
  LOGOTYPE_DESCRIPTION_INPUT: "Arrastra el archivo o haz clic para navegar",
  LOGS: "Logs",
  LONGITUDE: "Longitud",
  LOSES: "Perdió",
  LOSS_DESCRIPTION: "Descripción de la pérdida",
  LOST: "Perdió",
  LOST_DEALS: "Negocios perdidas",
  LOST_DEALS_AMOUNTS: "Cantidades de transacciones perdidas",
  LOST_REASON: "Razone de pierda",
  LOST_REASONS: "Razones de pierda",
  LOST_REASONS_SUCCESS: "El motivo de la pérdida se guardó correctamente",
  LUXURY_GOODS_OR_JEWELRY: "Artículos de lujo / joyas",
  MACHINERY: "Maquinaria",
  MAIN_CONTACTS: "Contactos principales",
  MAINTENANCE: "Mantenimiento",
  MAINTENANCE_ONGOING_DESC:
    "El sistema se encuentra actualmente en mantenimiento, por lo que pueden ocurrir errores durante el proceso",
  MAINTENANCE_WARNING_DESC:
    "Se planificó un mantenimiento del sistema en {fromNow} ({date}), que durará {minutes} minutos. Durante la actualización, el sistema no estará disponible",
  MALE: "Masculino",
  MANAGEMENT_CONSULTING: "Consultoría de gestión",
  MANAGER: "Gerente",
  MANAGER_IS: "El gerente es",
  MANAGERS: "Gerentes",
  MANUAL: "Manual",
  MAPPING: "Cartografía",
  MARCH: "Marzo",
  MARITIME: "Marítimo",
  MARK_ALL_AS_READ: "Marcar todo como leído",
  MARK_AS_READ: "Marcar seleccionado como leído",
  MARK_AS_UNREAD: "Marcar seleccionados como no leídos",
  MARK_CONVERSARION_AS_CLOSED: "Cerrar conversaciones",
  MARK_CONVERSARION_AS_OPEN: "Abrir conversaciones",
  MARK_SINGLE_AS_READ: "Marcar como leído",
  MARK_SINGLE_AS_UNREAD: "Marcar como no leído",
  MARKET_RESEARCH: "Investigación de mercado",
  MARKETING: "Márketing",
  MARKETING_OR_ADVERTISING_OR_SALES: "Marketing / Publicidad / Ventas",
  MAX_BODY_LENGTH: "Max body length",
  MAX_CONTENT_LENGTH: "Max content length",
  MAX_NUMBER_OF_REDEEMS: "Número de veces que se puede canjear el cupón",
  MAX_REDIRECTS: "Max redirects",
  MAXIMIZE: "Maximizar",
  MAXIMIZE_DESCRIPTION: "Maximizar descripción",
  MAXIMUM_PERIOD_HOURS: "Máximo de horas por período",
  MAXIMUM_PERIOD_HOURS_ABRV: "Máx. h/período",
  MAY: "Mayo",
  ME: "Yo",
  MECHANICAL_OR_INDUSTRIAL_ENGINEERING: "Ingeniería industrial o mecánica",
  MEDIA_PRODUCTION: "Producción de medios",
  MEDICAL_EQUIPMENT: "Equipo medico",
  MEDICAL_PRACTICE: "Práctica médica",
  MENTAL_HEALTH_CARE: "Cuidado de la salud mental",
  MENU: "Menú",
  MENU_LAYOUT: "Diseño del menú",
  MERGE_CELLS: "Combinar Células",
  MERGE_CONTACTS: "Combinar contactos",
  MERGE_CONTACTS_ALTERED_CALLS_DESCRIPTION:
    "Llamadas asociadas y trasladadas a contacto",
  MERGE_CONTACTS_ALTERED_CASES_DESCRIPTION:
    "Tickets asociados y movidos al contacto",
  MERGE_CONTACTS_ALTERED_CONTRACTS_DESCRIPTION:
    "Contratos asociados y movidos a contactar",
  MERGE_CONTACTS_ALTERED_DEALS_DESCRIPTION:
    "Negocio asociado y trasladado a contacto",
  MERGE_CONTACTS_ALTERED_PROJECTS_DESCRIPTION:
    "Proyectos vinculados y movidos a contactar",
  MERGE_CONTACTS_ALTERED_SUBSCRIPTIONS_DESCRIPTION:
    "Suscripciones asociadas y movidas al contacto",
  MERGE_CONTACTS_ALTERED_TIMES_DESCRIPTION:
    "Tiempos asociados y movidos para el contacto",
  MESSAGE: "Mensaje",
  MESSAGE_HELP_MESSAGE: "Información sobre herramientas de texto",
  MESSAGES: "Mensajes",
  METHOD: "Metodo",
  MILITARY_INDUSTRY: "Industria militar",
  MIN_AGO: "hace min",
  MINIATURES: "Miniaturas",
  MINIMIZE: "Minimizar",
  MINIMIZE_DESCRIPTION: "Minimizar descripción",
  MINIMUM_BILLING_INTERVAL: "Intervalo de facturación mínimo (min)",
  MINIMUM_BILLING_INTERVAL_ABV: "Intervalo mínimo de facturación (min)",
  MINIMUM_INTERVAL: "Intervalo de facturación mínimo (min)",
  MINING_OR_METALS: "Minería / Metales",
  MINUTE: "Minuto",
  MOBILE_NUMBER: "Número de teléfono móvil",
  MOBILE_NUMBER_PREFIX: "Extensión de teléfono móvil",
  MODAL: "Modal",
  MODE: "Modo",
  MODEL: "Modelo",
  MODEL_BLANK: "Modelo en blanco",
  MODIFICATION: "Modificación",
  MODULE_ERROR: "Hubo un error al cargar el módulo",
  MODULES: "Modulos",
  MODULES_ACCESS: "Acceso a módulos",
  MODULES_NAME: "Nombre de los módulos",
  MONDAY: "Lunes",
  MONTH: "Mes",
  MONTH_AGO: "Hace un mes",
  MONTHLY: "Mensual",
  MONTHLY_PAYMENT: "Mensualidad",
  MONTHS: "Meses",
  MONTHS_AGO: "Hace meses",
  MORE: "Más",
  MORE_OPTIONS: "Mas opciones",
  MOTION_PICTURES_OR_FILM: "Películas / Cine",
  MOVE: "Moverse",
  MOVE_CLASSIFICATION: "Mover calificación",
  MULTIPLE_TIMERS: "Varios temporizadores",
  MUSEUMS_OR_INSTITUTIONS: "Museos / Instituciones",
  MUSIC: "Música",
  MX_RECORD: "Registro MX",
  MY_DEPARTMENTS: "Mis departamentos",
  N_INPUTS: "Entradas Nº",
  NAME: "Nombre",
  NAME_FILTER: "Nombre del filtro",
  NAME_MODULES: "Nombre de los módulos",
  NAME_OF_PIPELINE_STATUS: "Estados de canalización",
  NANOTECHNOLOGY: "Nanotecnología",
  NEW_CONVERSATION: "Nueva conversación",
  NEW_EMAIL_CONVERSATION: "Nueva conversación de correo electrónico",
  NEW_FOLDER: "Nueva grupo",
  NEW_FOLDER_TEXT: "Determine el color, el icono y el nombre de lo nuevo grupo",
  NEW_PASSWORD: "Nueva contraseña",
  NEWSPAPERS_OR_JOURNALISM: "Periódicos / Periodismo",
  NEXT: "Próximo",
  NEXT_PERIOD: "Siguiente periodo",
  NEXT_RENEWAL: "Próxima renovación",
  NIF: "IVA",
  NIF_VALIDATION_ERROR:
    "No se pudieron encontrar datos para este número fiscal",
  NIF_VALIDATION_NOT_FOUND:
    "No se pudieron encontrar datos para este número fiscal",
  NIF_VALIDATION_NOT_VALID: "Los datos de este número fiscal no son válidos",
  NO: "No",
  NO_ACCOUNT: "No hay registros de cuenta",
  NO_ACCOUNTS: "No hay cuentas asociadas",
  NO_ACTIVITY_LIST: "Lista de actividades vacía.",
  NO_API_KEYS: "No hay registros de claves API asociados",
  NO_ATTACHMENTS: "No hay archivos adjuntos",
  NO_AUTOMATION_LIST: "Lista de automatizações vacía",
  NO_BIILING_LIST: "No hay registros de facturación asociados",
  NO_BIRTHDAY_TODAY: "Nadie celebra su cumpleaños esta semana",
  NO_CALLS_LIST: "Lista de llamadas vacía",
  NO_CLASSIFICATION_LIST: "No hay registros de clasificaciones asociadas",
  NO_COMPANY: "No hay registros de empresas asociadas",
  NO_CONTACTS: "No tienes contactos asociados",
  NO_CONTRACTS: "No hay registros de contratos asociados",
  NO_CONTRACTS_LIST: "No hay registros de contratos asociadas",
  NO_DEPARTMENT: "No hay registros de departamentos asociados",
  NO_DOMAINS: "No hay registros de dominio de correo electrónico asociados",
  NO_EMAIL_RECEPTION: "No hay registros de emails asociados",
  NO_EMAIL_SIGNATURE:
    "No hay registros de firmas de correo electrónico asociados",
  NO_FILES: "No hay archivos",
  NO_FILTERED_FILTERS: "No hay filtros para su búsca.",
  NO_IMPORTS: "No hay registros de importación asociados",
  NO_INTERVENTION_LIST: "No hay registros de tiempo asociados",
  NO_LOGS: "No hay registros de logs asociados",
  NO_LOST_REASON_LIST: "No hay registros de motivos de pérdida asociados.",
  NO_MATCHES_FOUND: "No se encontraron coincidencias",
  NO_MODULES: "Parece que no tiene ningún módulo configurado en este momento",
  NO_MODULES_EXPANDED: "Comuníquese con la administración",
  NO_PERMISSION: "Actualmente no tiene permisos para acceder a esta vista",
  NO_PERMISSION_DISCLAIMER_DESCRIPTION:
    "Comuníquese con un administrador para acceder",
  NO_PERMISSION_DISCLAIMER_TITLE:
    "No tienes permiso para acceder a este contenido.",
  NO_PERMISSION_EXPANDED:
    "Si este error persiste, comuníquese con la administración",
  NO_PIPELINES_LIST: "No hay registros de pipelines asociados",
  NO_PROJECT_LIST: "No hay registros de proyectos asociados",
  NO_PROJECT_TYPES: "No hay registros de tipos de proyectos asociados",
  NO_QUEUE_LIST: "No se encontraron registros de cola",
  NO_RECORDS: "No hay registros",
  NO_SEARCH_RESULTS: "No hay resultado",
  NO_SOCIAL_ACCOUNT: "No hay registros de cuentas de redes sociales asociadas",
  NO_SOCIAL_POST_LIST: "No hay registros de publicaciones asociadas",
  NO_SUBSCRIPTIONS: "No tienes suscripciones asociadas",
  NO_SUBSCRIPTIONS_TYPE_RECORDS: "Lista de tipos de suscripción vacía",
  NO_TASK_LIST: "No hay registros de tareas asociados",
  NO_TASKS: "Sin tareas",
  NO_TEAMS: "No hay equipos",
  NO_TICKETS_LIST: "No hay registros de casos asociados",
  NO_USERS: "No hay usuarios",
  NO_WIDGETS: "No hay registros de widgets asociados",
  NON_PROFIT_OR_VOLUNTEERING: "Sin fines de lucro / Voluntariado",
  NONE: "Ninguna",
  NOT_APPLICABLE: "No aplica",
  NOT_CLASSIFIED: "no clasificado",
  NOT_CONTAINS: "No contiene",
  NOT_EQUAL: "Se inscribirá un total de {count} clientes en este workflow.",
  NOT_READ: "No leído",
  NOT_VALIDATED: "No validado",
  NOTE: "Nota",
  NOTE_ATTACHMENTS: "Adjuntos de notas",
  NOTES: "Notas",
  NOTIFICATION_APEARANCE_DESCRIPTON:
    "Mostrar el recuento y el círculo laranja cuando tiene notificaciones no leídas en la campana de Airdesk.",
  NOTIFICATION_APEARANCE_TITLE: "Apariencia",
  NOTIFICATION_BELL_DESCRIPTON:
    "Las notificaciones aparecen en un círculo laranja sobre el icono de la campana en la barra de navegación. Puede pulsar la campana en cualquier momento para ver sus notificaciones más recientes.",
  NOTIFICATION_BELL_TITLE: "Campana",
  NOTIFICATION_BROWSER_DESCRIPTON:
    "Las notificaciones del navegador web aparecen en su pantalla cuando no está activo en Airdesk, pero el sitio web está abierto en la ventana de su navegador.",
  NOTIFICATION_BROWSER_TITLE: "Navegador web",
  NOTIFICATION_EMAIL_DESCRIPTON:
    "Las notificaciones por correo electrónico se enviarán a su bandeja de entrada.",
  NOTIFICATION_EMAIL_TITLE: "Email",
  NOTIFICATION_MOBILE_DESCRIPTON:
    "Las notificaciones push y dentro de la aplicación se enviarán a su dispositivo móvil (iOS o Android).",
  NOTIFICATION_MOBILE_TITLE: "Mobile",
  NOTIFICATION_POPUP_DESCRIPTON:
    "Las notificaciones emergentes aparecen en su pantalla cuando está activo en Airdesk.",
  NOTIFICATION_POPUP_TITLE: "Pop-up",
  NOTIFICATION_SOUND_DESCRIPTON:
    "Reproducir sonido cuando se reciben nuevas notificaciones emergentes.",
  NOTIFICATION_SOUND_TITLE: "Sonido",
  NOTIFICATION_TOPIC: "Tema de notificaciones",
  NOTIFICATION_TOPIC_ACCOUNTS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_DELETED_TITLE: "Cuando se elimina una cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_MANAGER_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el administrador de cuentas",
  NOTIFICATION_TOPIC_ACCOUNTS_MANAGER_CHANGE_TITLE:
    "Cuando cambia el administrador de cuentas",
  NOTIFICATION_TOPIC_ACCOUNTS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique una cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_MODIFIED_TITLE: "Cuando se modifica una cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_CONTACT_DESCRIPTION:
    "Reciba una notificación cada vez que se cree un nuevo contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_CONTACT_TITLE:
    "Cuando se crea un nuevo contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se cree una nueva cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un nuevo seguidor a la cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_FOLLOWER_TITLE:
    "Cuando se agrega un nuevo seguidor a la cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que haya nuevos mensajes",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_MESSAGE_TITLE: "Cuando hay nuevos mensajes",
  NOTIFICATION_TOPIC_ACCOUNTS_NEW_TITLE: "Cuando se crea una nueva cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_ORIGINATED_DESCRIPTION:
    "Reciba una notificación cada vez que se origine una cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_ORIGINATED_TITLE:
    "Reciba una notificación cada vez que se origine una cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_CONTACT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_CONTACT_TITLE:
    "Cuando se elimina un contacto",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine a un seguidor de la cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_REMOVE_FOLLOWER_TITLE:
    "Reciba una notificación cada vez que se elimine a un seguidor de la cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de una cuenta",
  NOTIFICATION_TOPIC_ACCOUNTS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de una cuenta",
  NOTIFICATION_TOPIC_CALLS_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie la persona a la que está asignada la llamada",
  NOTIFICATION_TOPIC_CALLS_ASSIGN_TO_CHANGE_TITLE:
    "Cuando cambia la persona asignada a la llamada",
  NOTIFICATION_TOPIC_CALLS_ASSIGNED_TO_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se le asigne un cambio a un usuario",
  NOTIFICATION_TOPIC_CALLS_ASSIGNED_TO_CHANGE_TITLE:
    "Cuando se asigna un cambio a un usuario",
  NOTIFICATION_TOPIC_CALLS_BEFORE_END_DESCRIPTION:
    "Reciba una notificación cada vez que queden solo tres días hasta la fecha de finalización de la llamada",
  NOTIFICATION_TOPIC_CALLS_BEFORE_END_TITLE:
    "Cuando solo quedan tres días para la fecha de finalización de la llamada",
  NOTIFICATION_TOPIC_CALLS_CONTACT_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie el contacto al que está asignada la llamada",
  NOTIFICATION_TOPIC_CALLS_CONTACT_CHANGE_TITLE:
    "Cuando cambia el contacto asignado a la llamada",
  NOTIFICATION_TOPIC_CALLS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una llamada",
  NOTIFICATION_TOPIC_CALLS_DELETED_TITLE: "Cuando se elimina una llamada",
  NOTIFICATION_TOPIC_CALLS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que reciba un mensaje nuevo",
  NOTIFICATION_TOPIC_CALLS_MODIFIED_TITLE: "Cuando se modifica una llamada",
  NOTIFICATION_TOPIC_CALLS_NEW_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se cambia un departamento",
  NOTIFICATION_TOPIC_CALLS_NEW_DEPARTMENT_TITLE:
    "Cuando se cambia un departamento",
  NOTIFICATION_TOPIC_CALLS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se crea una llamada",
  NOTIFICATION_TOPIC_CALLS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_CALLS_NEW_FOLLOWER_TITLE: "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_CALLS_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que reciba un mensaje nuevo",
  NOTIFICATION_TOPIC_CALLS_NEW_MESSAGE_TITLE:
    "Cuando se recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_CALLS_NEW_TITLE: "Cuando se crea una llamada",
  NOTIFICATION_TOPIC_CALLS_ORIGINATED_DESCRIPTION:
    "Reciba una notificación cada vez que se origine una llamada",
  NOTIFICATION_TOPIC_CALLS_ORIGINATED_TITLE: "Cuando se origina una llamada",
  NOTIFICATION_TOPIC_CALLS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se cambia un equipo",
  NOTIFICATION_TOPIC_CALLS_REMOVE_DEPARTMENT_TITLE:
    "Cuando se cambia un equipo",
  NOTIFICATION_TOPIC_CALLS_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un seguidor",
  NOTIFICATION_TOPIC_CALLS_REMOVE_FOLLOWER_TITLE: "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_CALLS_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de una llamada",
  NOTIFICATION_TOPIC_CALLS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de una llamada",
  NOTIFICATION_TOPIC_CASES_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se le asigne un cambio a un usuario",
  NOTIFICATION_TOPIC_CASES_ASSIGN_TO_CHANGE_TITLE:
    "Cuando a un usuario se le asigna un cambio",
  NOTIFICATION_TOPIC_CASES_ASSIGN_USER_CHANGE_DESCRIPTION:
    "Recibir una notificación cada vez que se asigna un cambio a un usuario",
  NOTIFICATION_TOPIC_CASES_ASSIGN_USER_CHANGE_TITLE:
    "Cuando el cambio se asigna a un usuario",
  NOTIFICATION_TOPIC_CASES_DEPARTMENT_TEAM_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie un departamento / equipo",
  NOTIFICATION_TOPIC_CASES_DEPARTMENT_TEAM_CHANGE_TITLE:
    "Cuando se cambia un departamento / equipo",
  NOTIFICATION_TOPIC_CASES_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique un caso",
  NOTIFICATION_TOPIC_CASES_MODIFIED_TITLE: "Cuando se cambia un caso",
  NOTIFICATION_TOPIC_CASES_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se cree un caso",
  NOTIFICATION_TOPIC_CASES_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_CASES_NEW_FOLLOWER_TITLE: "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_CASES_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que reciba un mensaje nuevo",
  NOTIFICATION_TOPIC_CASES_NEW_MESSAGE_TITLE: "Cuando recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_CASES_NEW_TITLE: "Cuando se crea un caso",
  NOTIFICATION_TOPIC_CASES_ORIGINATED_DESCRIPTION:
    "Reciba una notificación cada vez que se origine un caso",
  NOTIFICATION_TOPIC_CASES_ORIGINATED_TITLE: "Cuando se origina un caso",
  NOTIFICATION_TOPIC_CASES_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un seguidor",
  NOTIFICATION_TOPIC_CASES_REMOVE_FOLLOWER_TITLE: "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_CASES_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie el estado de un caso",
  NOTIFICATION_TOPIC_CASES_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de un caso",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una clasificación",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_DELETED_TITLE:
    "Cuando se elimina una clasificación",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique una clasificación",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_MODIFIED_TITLE:
    "Cuando se cambia una clasificación",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se cree una nueva clasificación",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_NEW_TITLE:
    "Cuando se crea una nueva clasificación",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de una clasificación",
  NOTIFICATION_TOPIC_CLASSIFICATIONS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de una clasificación",
  NOTIFICATION_TOPIC_CONTACTS_ADD_ACCOUNT_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue el contacto a una cuenta",
  NOTIFICATION_TOPIC_CONTACTS_ADD_ACCOUNT_TITLE:
    "Cuando el contacto se agrega a una cuenta",
  NOTIFICATION_TOPIC_CONTACTS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un contacto",
  NOTIFICATION_TOPIC_CONTACTS_DELETED_TITLE: "Cuando se elimina un contacto",
  NOTIFICATION_TOPIC_CONTACTS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique un contacto",
  NOTIFICATION_TOPIC_CONTACTS_MODIFIED_TITLE: "Cuando se cambia un contacto",
  NOTIFICATION_TOPIC_CONTACTS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se cree un nuevo contacto",
  NOTIFICATION_TOPIC_CONTACTS_NEW_TITLE: "Cuando se crea un nuevo contacto",
  NOTIFICATION_TOPIC_CONTACTS_REMOVE_ACCOUNT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine el contacto de una cuenta",
  NOTIFICATION_TOPIC_CONTACTS_REMOVE_ACCOUNT_TITLE:
    "Cuando el contacto se elimina de una cuenta",
  NOTIFICATION_TOPIC_CONTACTS_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de un contacto",
  NOTIFICATION_TOPIC_CONTACTS_STATUS_CHANGE_TITLE:
    "Cuando cambia el estado de un contacto",
  NOTIFICATION_TOPIC_CONTRACTS_ASSIGNED_TO_CHANGE_DESCRIPTION:
    "Recibir una notificación cada vez que se asigna un cambio a un usuario",
  NOTIFICATION_TOPIC_CONTRACTS_ASSIGNED_TO_CHANGE_TITLE:
    "Cuando el cambio se asigna a un usuario",
  NOTIFICATION_TOPIC_CONTRACTS_BEFORE_CLOSE_DESCRIPTION:
    "Reciba una notificación cada vez que queden solo tres días hasta la fecha de finalización de un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_BEFORE_CLOSE_TITLE:
    "Cuando solo quedan tres días antes de la fecha de finalización de un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_COMPANY_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique la empresa",
  NOTIFICATION_TOPIC_CONTRACTS_COMPANY_CHANGE_TITLE:
    "Cuando la empresa se modifica",
  NOTIFICATION_TOPIC_CONTRACTS_CONTACTS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_CONTACTS_CHANGE_TITLE:
    "Cuando se cambia el contrato",
  NOTIFICATION_TOPIC_CONTRACTS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_DELETED_TITLE: "Cuando se elimina un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_END_DESCRIPTION:
    "Reciba una notificación cada vez que se rescinda un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_END_TITLE: "Cuando se rescinde un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_EXCEEDED_TIME_DESCRIPTION:
    "Reciba una notificación cada vez que un contrato supere el límite de tiempo",
  NOTIFICATION_TOPIC_CONTRACTS_EXCEEDED_TIME_TITLE:
    "Cuando un contrato supera el límite de tiempo",
  NOTIFICATION_TOPIC_CONTRACTS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Reciba una notificación cada vez que falte x tiempo para la finalización del contrato",
  NOTIFICATION_TOPIC_CONTRACTS_LEFT_TO_EXCEEDED_TITLE:
    "Cuando falta x tiempo para la finalización del contrato",
  NOTIFICATION_TOPIC_CONTRACTS_MANAGER_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie la persona responsable",
  NOTIFICATION_TOPIC_CONTRACTS_MANAGER_CHANGE_TITLE:
    "Cuando se cambia la persona responsable",
  NOTIFICATION_TOPIC_CONTRACTS_MAXED_HOURS_DESCRIPTION:
    "Reciba una notificación cada vez que un contrato supere el límite de tiempo",
  NOTIFICATION_TOPIC_CONTRACTS_MAXED_HOURS_TITLE:
    "Cuando un contrato excede el límite de tiempo",
  NOTIFICATION_TOPIC_CONTRACTS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_MODIFIED_TITLE: "Cuando se modifica un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un departamento",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DEPARTMENT_TITLE:
    "Cuando se agrega un departamento",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se cree un nuevo contrato",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_FOLLOWER_TITLE:
    "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un nuevo equipo",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TEAM_TITLE:
    "Cuando se agrega un nuevo equipo",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_TITLE: "Cuando se crea un nuevo contrato",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_USER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un nuevo usuario",
  NOTIFICATION_TOPIC_CONTRACTS_NEW_USER_TITLE:
    "Cuando se agrega un nuevo usuario",
  NOTIFICATION_TOPIC_CONTRACTS_ORIGINATED_DESCRIPTION:
    "Reciba una notificación cada vez que se origine un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_ORIGINATED_TITLE:
    "Cuando se origina un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un departamento",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_DEPARTMENT_TITLE:
    "Cuando se elimina un departamento",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un seguidor",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_FOLLOWER_TITLE:
    "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un nuevo equipo",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_TEAM_TITLE:
    "Cuando se elimina un nuevo equipo",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_USER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un nuevo usuario",
  NOTIFICATION_TOPIC_CONTRACTS_REMOVE_USER_TITLE:
    "Cuando se elimina un nuevo usuario",
  NOTIFICATION_TOPIC_CONTRACTS_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de un contrato",
  NOTIFICATION_TOPIC_CONTRACTS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de un contrato",
  NOTIFICATION_TOPIC_DEALS_BEFORE_CLOSE_DESCRIPTION:
    "Reciba una notificación cada vez que reciba un mensaje nuevo",
  NOTIFICATION_TOPIC_DEALS_BEFORE_CLOSE_TITLE: "Cuando recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_DEALS_CHANGE_CONTACT_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie un contacto",
  NOTIFICATION_TOPIC_DEALS_CHANGE_CONTACT_TITLE: "Cuando se cambia el contacto",
  NOTIFICATION_TOPIC_DEALS_CHANGE_RESPONSIBLE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie la persona responsable",
  NOTIFICATION_TOPIC_DEALS_CHANGE_RESPONSIBLE_TITLE:
    "Cuando se cambia la persona responsable",
  NOTIFICATION_TOPIC_DEALS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un negocio",
  NOTIFICATION_TOPIC_DEALS_DELETED_TITLE: "Cuando se elimina un negocio",
  NOTIFICATION_TOPIC_DEALS_END_DESCRIPTION:
    "Reciba una notificación cada vez que finalice un negocio",
  NOTIFICATION_TOPIC_DEALS_END_TITLE: "Cuando termina un trato",
  NOTIFICATION_TOPIC_DEALS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique un negocio",
  NOTIFICATION_TOPIC_DEALS_MODIFIED_TITLE: "Cuando se cambia un negocio",
  NOTIFICATION_TOPIC_DEALS_NEW_ATTR_DESCRIPTION:
    "Reciba una notificación cada vez que se asigne un cambio a una empresa",
  NOTIFICATION_TOPIC_DEALS_NEW_ATTR_TITLE:
    "Cuando se asigna un cambio a la empresa",
  NOTIFICATION_TOPIC_DEALS_NEW_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un departamento",
  NOTIFICATION_TOPIC_DEALS_NEW_DEPARTMENT_TITLE:
    "Cuando se agrega un departamento",
  NOTIFICATION_TOPIC_DEALS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se cree un nuevo negocio",
  NOTIFICATION_TOPIC_DEALS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_DEALS_NEW_FOLLOWER_TITLE: "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_DEALS_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que haya nuevos mensajes",
  NOTIFICATION_TOPIC_DEALS_NEW_MESSAGE_TITLE: "Cuando hay nuevos mensajes",
  NOTIFICATION_TOPIC_DEALS_NEW_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un equipo",
  NOTIFICATION_TOPIC_DEALS_NEW_TEAM_TITLE: "Cuando se agrega un equipo",
  NOTIFICATION_TOPIC_DEALS_NEW_TITLE: "Cuando se crea una nueva empresa",
  NOTIFICATION_TOPIC_DEALS_NEW_USER_DESCRIPTION:
    "Recibir una notificación cada vez que se agrega un usuario",
  NOTIFICATION_TOPIC_DEALS_NEW_USER_TITLE: "Cuando se agrega un usuario",
  NOTIFICATION_TOPIC_DEALS_ORIGINATED_DESCRIPTION:
    "Reciba una notificación cada vez que se origine un trato",
  NOTIFICATION_TOPIC_DEALS_ORIGINATED_TITLE: "Cuando se origina un trato",
  NOTIFICATION_TOPIC_DEALS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un departamento",
  NOTIFICATION_TOPIC_DEALS_REMOVE_DEPARTMENT_TITLE:
    "Cuando se elimina un departamento",
  NOTIFICATION_TOPIC_DEALS_REMOVE_FOLLOWER_DESCRIPTION:
    "Receba uma notificação cada vez que um seguidor é removido",
  NOTIFICATION_TOPIC_DEALS_REMOVE_FOLLOWER_TITLE: "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_DEALS_REMOVE_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un equipo",
  NOTIFICATION_TOPIC_DEALS_REMOVE_TEAM_TITLE: "Cuando se elimina un equipo",
  NOTIFICATION_TOPIC_DEALS_REMOVE_USER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un usuario",
  NOTIFICATION_TOPIC_DEALS_REMOVE_USER_TITLE: "Cuando se elimina un usuario",
  NOTIFICATION_TOPIC_DEALS_START_DESCRIPTION:
    "Reciba una notificación cada vez que comience un trato",
  NOTIFICATION_TOPIC_DEALS_START_TITLE: "Cuando comienza un negocio",
  NOTIFICATION_TOPIC_DEALS_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de un trato",
  NOTIFICATION_TOPIC_DEALS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de una empresa",
  NOTIFICATION_TOPIC_INBOX_CLOSED_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cierre un correo electrónico",
  NOTIFICATION_TOPIC_INBOX_CLOSED_MESSAGE_TITLE:
    "Cuando se cierra un correo electrónico",
  NOTIFICATION_TOPIC_INBOX_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un correo electrónico",
  NOTIFICATION_TOPIC_INBOX_DELETED_TITLE:
    "Cuando se elimina un correo electrónico",
  NOTIFICATION_TOPIC_INBOX_DELETED_TWO_DESCRIPTION:
    "Reciba una notificación por correo electrónico cada vez que se elimine un correo electrónico",
  NOTIFICATION_TOPIC_INBOX_DELETED_TWO_TITLE:
    "Cuando se elimina un correo electrónico",
  NOTIFICATION_TOPIC_INBOX_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que reciba un nuevo correo electrónico",
  NOTIFICATION_TOPIC_INBOX_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que se envíe un correo electrónico asociado a una conversación por correo electrónico.",
  NOTIFICATION_TOPIC_INBOX_NEW_MESSAGE_TITLE:
    "Cuando se envía un correo electrónico desde Airdesk asociado a una conversación de correo electrónico",
  NOTIFICATION_TOPIC_INBOX_NEW_TITLE:
    "Cuando se crea una nueva conversación por correo electrónico",
  NOTIFICATION_TOPIC_INBOX_OPEN_DESCRIPTION:
    "Reciba una notificación por correo electrónico cada vez que se crea una nueva conversarción por correo electrónico",
  NOTIFICATION_TOPIC_INBOX_OPEN_TITLE: "Cuando se abre un correo electrónico",
  NOTIFICATION_TOPIC_INBOX_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de la conversarción por correo electrónico",
  NOTIFICATION_TOPIC_INBOX_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de la bandeja de entrada",
  NOTIFICATION_TOPIC_INTERVENTIONS_ASSOCIATE_TO_CHANGE_DESCRIPTION:
    "Recibir una notificación cada vez que se asigna un cambio a un usuario",
  NOTIFICATION_TOPIC_INTERVENTIONS_ASSOCIATE_TO_CHANGE_TITLE:
    "Cuando el cambio se asigna a un usuario",
  NOTIFICATION_TOPIC_INTERVENTIONS_CONTACTS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se actualice un contacto",
  NOTIFICATION_TOPIC_INTERVENTIONS_CONTACTS_CHANGE_TITLE:
    "Cuando se cambia un contacto",
  NOTIFICATION_TOPIC_INTERVENTIONS_DELETED_DESCRIPTION:
    "Reciba una notificación cuando se elimine una hora",
  NOTIFICATION_TOPIC_INTERVENTIONS_DELETED_TITLE: "Cuando se quita un tiempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cuando se modifique una hora",
  NOTIFICATION_TOPIC_INTERVENTIONS_MODIFIED_TITLE:
    "Cuando se modifica una hora",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_DESCRIPTION:
    "Recibe una notificación cuando se crea una hora",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_FOLLOWER_TITLE:
    "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que se reciba un mensaje nuevo",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_MESSAGE_TITLE:
    "Cuando se recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_INTERVENTIONS_NEW_TITLE: "Cuando se crea un tiempo",
  NOTIFICATION_TOPIC_INTERVENTIONS_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un seguidor",
  NOTIFICATION_TOPIC_INTERVENTIONS_REMOVE_FOLLOWER_TITLE:
    "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_OWNER_ADD_COMPANY_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue una empresa",
  NOTIFICATION_TOPIC_OWNER_ADD_COMPANY_TITLE: "Cuando se agrega una empresa",
  NOTIFICATION_TOPIC_OWNER_ADD_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un departamento",
  NOTIFICATION_TOPIC_OWNER_ADD_DEPARTMENT_TITLE:
    "Cuando se agrega un departamento",
  NOTIFICATION_TOPIC_OWNER_ADD_SOCIAL_MEDIA_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue una cuenta de redes sociales",
  NOTIFICATION_TOPIC_OWNER_ADD_SOCIAL_MEDIA_TITLE:
    "Cuando se agrega una cuenta de redes sociales",
  NOTIFICATION_TOPIC_OWNER_ADD_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue una empresa",
  NOTIFICATION_TOPIC_OWNER_ADD_TEAM_TITLE: "Cuando se agrega un equipo",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CASES_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario cancele un caso",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CASES_TITLE:
    "Cuando un usuario cancela un caso",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CONTRACT_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario cancele un contrato",
  NOTIFICATION_TOPIC_OWNER_CANCELED_CONTRACT_TITLE:
    "Cuando un usuario cancela un contrato",
  NOTIFICATION_TOPIC_OWNER_CANCELED_DEAL_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario cancele un trato",
  NOTIFICATION_TOPIC_OWNER_CANCELED_DEAL_TITLE:
    "Cuando un usuario cancela un negocio",
  NOTIFICATION_TOPIC_OWNER_CANCELED_TASK_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario cancele una tarea",
  NOTIFICATION_TOPIC_OWNER_CANCELED_TASK_TITLE:
    "Cuando un usuario cancela una tarea",
  NOTIFICATION_TOPIC_OWNER_DAY_FOR_PAYMENT_DESCRIPTION:
    "Reciba una notificación cada vez que queden solo tres días para un pago",
  NOTIFICATION_TOPIC_OWNER_DAY_FOR_PAYMENT_TITLE:
    "Cuando solo quedan tres días para un pago",
  NOTIFICATION_TOPIC_OWNER_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una cuenta",
  NOTIFICATION_TOPIC_OWNER_DELETED_TITLE: "Cuando se elimina una cuenta",
  NOTIFICATION_TOPIC_OWNER_DISABLED_ACCOUNT_DESCRIPTION:
    "Reciba una notificación cada vez que se desactive una cuenta",
  NOTIFICATION_TOPIC_OWNER_DISABLED_ACCOUNT_TITLE:
    "Cuando una cuenta está desactivada",
  NOTIFICATION_TOPIC_OWNER_EXPIRED_INVITATION_DESCRIPTION:
    "Reciba una notificación cada vez que caduque una invitación",
  NOTIFICATION_TOPIC_OWNER_EXPIRED_INVITATION_TITLE:
    "Cuando caduca una invitación",
  NOTIFICATION_TOPIC_OWNER_INVITATION_ACCEPED_DESCRIPTION:
    "Reciba una notificación cada vez que se acepte una invitación",
  NOTIFICATION_TOPIC_OWNER_INVITATION_ACCEPED_TITLE:
    "Cuando se acepta una invitación",
  NOTIFICATION_TOPIC_OWNER_INVITATION_SEND_DESCRIPTION:
    "Reciba una notificación cada vez que se envíe una invitación",
  NOTIFICATION_TOPIC_OWNER_INVITATION_SEND_TITLE:
    "Cuando se envía una invitación",
  NOTIFICATION_TOPIC_OWNER_LOWER_DEAL_WIN_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario tenga ganancias comerciales bajas",
  NOTIFICATION_TOPIC_OWNER_LOWER_DEAL_WIN_TITLE:
    "Cuando un usuario tiene beneficios comerciales bajos",
  NOTIFICATION_TOPIC_OWNER_MADE_EXPORT_DESCRIPTION:
    "Reciba una notificación cada vez que el usuario realice una exportación",
  NOTIFICATION_TOPIC_OWNER_MADE_EXPORT_TITLE:
    "Cuando el usuario realiza una exportación",
  NOTIFICATION_TOPIC_OWNER_MISSING_DAILY_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario falte 8 horas al día",
  NOTIFICATION_TOPIC_OWNER_MISSING_DAILY_TITLE:
    "Cuando un usuario falta 8 horas al día",
  NOTIFICATION_TOPIC_OWNER_OPEN_CASES_ONE_MONTH_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario tenga casos abiertos durante más de 1 mes",
  NOTIFICATION_TOPIC_OWNER_OPEN_CASES_ONE_MONTH_TITLE:
    "Cuando un usuario tiene casos abiertos durante más de 1 mes",
  NOTIFICATION_TOPIC_OWNER_OPEN_DEAL_ONE_MONTH_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario tenga un negocio abierta durante más de 1 mes",
  NOTIFICATION_TOPIC_OWNER_OPEN_DEAL_ONE_MONTH_TITLE:
    "Cuando un usuario ha tenido un negocio abierta durante más de 1 mes",
  NOTIFICATION_TOPIC_OWNER_OPEN_TASK_ONE_MONTH_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario tenga una tarea abierta durante más de 1 mes",
  NOTIFICATION_TOPIC_OWNER_OPEN_TASK_ONE_MONTH_TITLE:
    "Cuando un usuario tiene una tarea abierta durante más de 1 mes",
  NOTIFICATION_TOPIC_OWNER_PAYMENT_SUCCESS_DESCRIPTION:
    "Reciba una notificación cada vez que el pago de una suscripción sea exitoso",
  NOTIFICATION_TOPIC_OWNER_PAYMENT_SUCCESS_TITLE:
    "Cuando el pago de una suscripción se realiza correctamente",
  NOTIFICATION_TOPIC_OWNER_PLAN_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie un plan",
  NOTIFICATION_TOPIC_OWNER_PLAN_CHANGE_TITLE: "Cuando se cambia un plan",
  NOTIFICATION_TOPIC_OWNER_REMOVE_COMPANY_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una empresa",
  NOTIFICATION_TOPIC_OWNER_REMOVE_COMPANY_TITLE:
    "Cuando se elimina una empresa",
  NOTIFICATION_TOPIC_OWNER_REMOVE_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un departamento",
  NOTIFICATION_TOPIC_OWNER_REMOVE_DEPARTMENT_TITLE:
    "Cuando se elimina un departamento",
  NOTIFICATION_TOPIC_OWNER_REMOVE_SOCIAL_MEDIA_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una cuenta de redes sociales",
  NOTIFICATION_TOPIC_OWNER_REMOVE_SOCIAL_MEDIA_TITLE:
    "Cuando se elimina una cuenta de redes sociales",
  NOTIFICATION_TOPIC_OWNER_REMOVE_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una empresa",
  NOTIFICATION_TOPIC_OWNER_REMOVE_TEAM_TITLE: "Cuando se elimina un equipo",
  NOTIFICATION_TOPIC_OWNER_TO_MUTCH_DEAL_LOST_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario pierda varias operaciones",
  NOTIFICATION_TOPIC_OWNER_TO_MUTCH_DEAL_LOST_TITLE:
    "Cuando un usuario ha perdido varias operaciones",
  NOTIFICATION_TOPIC_OWNER_TRANSFER_DESCRIPTION:
    "Reciba una notificación cada vez que se transfiera la responsabilidad",
  NOTIFICATION_TOPIC_OWNER_TRANSFER_TITLE:
    "Cuando se transfiere la responsabilidad",
  NOTIFICATION_TOPIC_OWNER_WORKLOAD_TASK_CASES_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario esté sobrecargado de tareas y casos.",
  NOTIFICATION_TOPIC_OWNER_WORKLOAD_TASK_CASES_TITLE:
    "Cuando un usuario está sobrecargado con tareas y casos",
  NOTIFICATION_TOPIC_PROJECTS_BEFORE_END_DESCRIPTION:
    "Reciba una notificación cada vez que queden solo tres días para la fecha de finalización del proyecto",
  NOTIFICATION_TOPIC_PROJECTS_BEFORE_END_TITLE:
    "Cuando solo quedan tres días para la fecha de finalización del proyecto",
  NOTIFICATION_TOPIC_PROJECTS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_DELETED_TITLE: "Cuando se elimina un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_END_DESCRIPTION:
    "Reciba una notificación cada vez que finalice un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_END_TITLE: "Cuando termina un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_ENDED_DESCRIPTION:
    "Reciba una notificación cada vez que finalice un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_ENDED_TITLE: "Cuando un proyecto está terminado",
  NOTIFICATION_TOPIC_PROJECTS_EXCEEDED_TIME_DESCRIPTION:
    "Reciba una notificación cada vez que el proyecto exceda el límite de tiempo",
  NOTIFICATION_TOPIC_PROJECTS_EXCEEDED_TIME_TITLE:
    "Cuando el proyecto excede el límite de tempo",
  NOTIFICATION_TOPIC_PROJECTS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Reciba una notificación cada vez que falte x tiempo para la finalización del contrato",
  NOTIFICATION_TOPIC_PROJECTS_LEFT_TO_EXCEEDED_TITLE:
    "Cuando quedan x tiempo para el final del contrato",
  NOTIFICATION_TOPIC_PROJECTS_MANAGER_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie la persona responsable",
  NOTIFICATION_TOPIC_PROJECTS_MANAGER_CHANGE_TITLE:
    "Cuando se cambia la persona responsable",
  NOTIFICATION_TOPIC_PROJECTS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_MODIFIED_TITLE: "Cuando se cambia un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un departamento",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DEPARTMENT_TITLE:
    "Cuando se cambia un departamento",
  NOTIFICATION_TOPIC_PROJECTS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se crea un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_PROJECTS_NEW_FOLLOWER_TITLE:
    "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_PROJECTS_NEW_MESSAGE_DESCRIPTION:
    "Recibe una notificación cada vez que se recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_PROJECTS_NEW_MESSAGE_TITLE:
    "Cuando se recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TEAM_DESCRIPTION:
    "Recibir una notificación cada vez que se agrega un equipo",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TEAM_TITLE: "Cuando se agrega un equipo",
  NOTIFICATION_TOPIC_PROJECTS_NEW_TITLE: "Cuando se crea un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_NEW_USER_DESCRIPTION:
    "Recibir una notificación cada vez que se agrega un usuario",
  NOTIFICATION_TOPIC_PROJECTS_NEW_USER_TITLE: "Cuando se agrega un usuario",
  NOTIFICATION_TOPIC_PROJECTS_ORIGINATED_DESCRIPTION:
    "Reciba una notificación cada vez que se origine un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_ORIGINATED_TITLE: "Cuando se origina un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un departamento",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_DEPARTMENT_TITLE:
    "Cuando se cambia un equipo",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un seguidor",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_FOLLOWER_TITLE:
    "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un equipo",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_TEAM_TITLE: "Cuando se elimina un equipo",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_USER_DESCRIPTION:
    "Recibir una notificación cada vez que se elimina un usuario",
  NOTIFICATION_TOPIC_PROJECTS_REMOVE_USER_TITLE: "Cuando se elimina un usuario",
  NOTIFICATION_TOPIC_PROJECTS_START_DESCRIPTION:
    "Reciba una notificación cada vez que comience un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_START_TITLE: "Cuando comienza un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_STARTED_DESCRIPTION:
    "Reciba una notificación cada vez que comience un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_STARTED_TITLE: "Cuando comienza un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_STATUS_CHANGE_DESCRIPTION:
    "Cuando se cambia el estado de un proyecto",
  NOTIFICATION_TOPIC_PROJECTS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de un proyecto",
  NOTIFICATION_TOPIC_SOCIALMEDIA_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_DELETED_TITLE:
    "Cuando se elimina una publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique una publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_MODIFIED_TITLE:
    "Cuando se modifica una publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se programe una nueva publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_NEW_TITLE:
    "Cuando se programa una nueva publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_PUBLISHED_DESCRIPTION:
    "Reciba una notificación cada vez que se publique una publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_PUBLISHED_TITLE:
    "Cuando se publica una publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_STATUS_CHANGE_DESCRIPTION:
    "Recibir una notificación cada vez que se modifique el estado de una publicación",
  NOTIFICATION_TOPIC_SOCIALMEDIA_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de una publicación",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_BEFORE_END_DESCRIPTION:
    "Reciba una notificación cada vez que queden solo tres días hasta la fecha de finalización de la suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_BEFORE_END_TITLE:
    "Cuando solo quedan tres días antes de la fecha de finalización de la suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_DELETED_TITLE:
    "Cuando se elimina una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_END_DESCRIPTION:
    "Reciba una notificación cada vez que finalice una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_END_TITLE:
    "Cuando se cancela una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MANAGER_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie la persona responsable",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MANAGER_CHANGE_TITLE:
    "Cuando se cambia la persona responsable",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MODIFIED_DESCRIPTION:
    "Reciba una notificación cada vez que se modifique una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_MODIFIED_TITLE:
    "Cuando se cambia una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DEPARTMENT_TITLE:
    "Cuando agrega un departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_DESCRIPTION:
    "Reciba una notificación cada vez que se cree una nueva suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_FOLLOWER_TITLE:
    "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que reciba un mensaje nuevo",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_MESSAGE_TITLE:
    "Cuando se recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un equipo",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TEAM_TITLE: "Cuando se agrega un equipo",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_TITLE:
    "Cuando se crea una nueva suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_USER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un nuevo usuario",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_NEW_USER_TITLE:
    "Cuando se agrega un nuevo usuario",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_DEPARTMENT_TITLE:
    "Cuando se elimina un departamento",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_FOLLOWER_TITLE:
    "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un equipo",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_TEAM_TITLE:
    "Cuando se elimina un equipo",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_USER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un usuario",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_REMOVE_USER_TITLE:
    "Cuando se elimina un usuario",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_RENEWAL_DESCRIPTION:
    "Reciba una notificación cada vez que se renueve una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_RENEWAL_TITLE:
    "Cuando se renueva una suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_STATUS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que cambie el estado de un suscripción",
  NOTIFICATION_TOPIC_SUBSCRIPTIONS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de un suscripción",
  NOTIFICATION_TOPIC_TASKS_ASSIGN_TO_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se asigne el cambio a un usuario",
  NOTIFICATION_TOPIC_TASKS_ASSIGN_TO_CHANGE_TITLE:
    "Cuando el cambio se asigna a un usuario",
  NOTIFICATION_TOPIC_TASKS_ASSOCIATE_TO_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se asocie un cambio con un usuario",
  NOTIFICATION_TOPIC_TASKS_ASSOCIATE_TO_CHANGE_TITLE:
    "Cuando el cambio está asociado con un usuario",
  NOTIFICATION_TOPIC_TASKS_BEFORE_END_DESCRIPTION:
    "Reciba una notificación cada vez que queden solo tres días hasta la fecha de finalización de la tarea",
  NOTIFICATION_TOPIC_TASKS_BEFORE_END_TITLE:
    "Cuando solo quedan tres días para la fecha de finalización de la tarea",
  NOTIFICATION_TOPIC_TASKS_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine una tarea",
  NOTIFICATION_TOPIC_TASKS_DELETED_TITLE: "Cuando se elimina una tarea",
  NOTIFICATION_TOPIC_TASKS_ENDED_DESCRIPTION:
    "Reciba una notificación cada vez que finalice una tarea",
  NOTIFICATION_TOPIC_TASKS_ENDED_TITLE: "Cuando termina una tarea",
  NOTIFICATION_TOPIC_TASKS_EXCEEDED_TIME_DESCRIPTION:
    "Reciba una notificación cada vez que la tarea exceda el límite de tiempo.",
  NOTIFICATION_TOPIC_TASKS_EXCEEDED_TIME_TITLE:
    "Cuando la tarea excede el límite de tiempo",
  NOTIFICATION_TOPIC_TASKS_LEFT_TO_EXCEEDED_DESCRIPTION:
    "Reciba una notificación cada vez que falte x tiempo para el final de la tarea",
  NOTIFICATION_TOPIC_TASKS_LEFT_TO_EXCEEDED_TITLE:
    "Cuando falta x tiempo para el final de la tarea",
  NOTIFICATION_TOPIC_TASKS_MODIFIED_DESCRIPTION:
    "Recibir una notificación cada vez que se modifica una tarea",
  NOTIFICATION_TOPIC_TASKS_MODIFIED_TITLE: "Cuando se modifica una tarea",
  NOTIFICATION_TOPIC_TASKS_NEW_DESCRIPTION:
    "Recibir una notificación cada vez que se crea una tarea",
  NOTIFICATION_TOPIC_TASKS_NEW_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un seguidor",
  NOTIFICATION_TOPIC_TASKS_NEW_FOLLOWER_TITLE: "Cuando se agrega un seguidor",
  NOTIFICATION_TOPIC_TASKS_NEW_MESSAGE_DESCRIPTION:
    "Reciba una notificación cada vez que reciba un mensaje nuevo",
  NOTIFICATION_TOPIC_TASKS_NEW_MESSAGE_TITLE:
    "Cuando se recibe un mensaje nuevo",
  NOTIFICATION_TOPIC_TASKS_NEW_TITLE: "Cuando se crea una tarea",
  NOTIFICATION_TOPIC_TASKS_ORIGINATED_DESCRIPTION:
    "Recibir una notificación cada vez que se origina una tarea",
  NOTIFICATION_TOPIC_TASKS_ORIGINATED_TITLE: "Cuando se origina una tarea",
  NOTIFICATION_TOPIC_TASKS_REMOVE_FOLLOWER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un seguidor",
  NOTIFICATION_TOPIC_TASKS_REMOVE_FOLLOWER_TITLE: "Cuando se quita un seguidor",
  NOTIFICATION_TOPIC_TASKS_STARTED_DESCRIPTION:
    "Reciba una notificación cada vez que comience una tarea",
  NOTIFICATION_TOPIC_TASKS_STARTED_TITLE: "Cuando comienza una tarea",
  NOTIFICATION_TOPIC_TASKS_STATUS_CHANGE_DESCRIPTION:
    "Recibir una notificación cada vez que se cambia el estado de una tarea",
  NOTIFICATION_TOPIC_TASKS_STATUS_CHANGE_TITLE:
    "Cuando se cambia el estado de una tarea",
  NOTIFICATION_TOPIC_USERS_ADD_AS_MANAGER_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un usuario como asignado",
  NOTIFICATION_TOPIC_USERS_ADD_AS_MANAGER_TITLE:
    "Cuando se agrega un usuario como asignado",
  NOTIFICATION_TOPIC_USERS_ADD_COMPANY_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un usuario a la empresa",
  NOTIFICATION_TOPIC_USERS_ADD_COMPANY_TITLE:
    "Cuando se agrega un usuario a la empresa",
  NOTIFICATION_TOPIC_USERS_ADD_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se agregue un departamento",
  NOTIFICATION_TOPIC_USERS_ADD_DEPARTMENT_TITLE:
    "Cuando se agrega un departamento",
  NOTIFICATION_TOPIC_USERS_ADD_TEAM_DESCRIPTION:
    "Recibir una notificación cada vez que se agrega un equipo",
  NOTIFICATION_TOPIC_USERS_ADD_TEAM_TITLE: "Cuando se agrega un equipo",
  NOTIFICATION_TOPIC_USERS_BIRTHDAY_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario celebre un cumpleaños",
  NOTIFICATION_TOPIC_USERS_BIRTHDAY_TITLE:
    "Cuando un usuario celebra su cumpleaños",
  NOTIFICATION_TOPIC_USERS_PASS_CHANGE_DESCRIPTION:
    "Reciba una notificación cada vez que se cambie una contraseña",
  NOTIFICATION_TOPIC_USERS_PASS_CHANGE_TITLE: "Cuando se cambia la contraseña",
  NOTIFICATION_TOPIC_USERS_REMOVE_AS_MANAGER_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine a un usuario como asignado",
  NOTIFICATION_TOPIC_USERS_REMOVE_AS_MANAGER_TITLE:
    "Cuando un usuario es eliminado como administrador",
  NOTIFICATION_TOPIC_USERS_REMOVE_COMPANY_DESCRIPTION:
    "Reciba una notificación cada vez que un usuario sea eliminado de la empresa.",
  NOTIFICATION_TOPIC_USERS_REMOVE_COMPANY_TITLE:
    "Cuando un usuario es eliminado de la empresa",
  NOTIFICATION_TOPIC_USERS_REMOVE_DEPARTMENT_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un departamento",
  NOTIFICATION_TOPIC_USERS_REMOVE_DEPARTMENT_TITLE:
    "Cuando se elimina un departamento",
  NOTIFICATION_TOPIC_USERS_REMOVE_TEAM_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un equipo",
  NOTIFICATION_TOPIC_USERS_REMOVE_TEAM_TITLE: "Cuando se elimina un equipo",
  NOTIFICATION_TOPIC_USERS_ROLE_CHANGE_DESCRIPTION:
    "Recibir una notificación cada vez que se cambia el rol de un usuario",
  NOTIFICATION_TOPIC_USERS_ROLE_CHANGE_TITLE:
    "Cuando se cambia el rol de un usuario",
  NOTIFICATIONS: "Notificaciones",
  NOTIFICATIONS_CONF:
    "¿Quieres habilitar las notificaciones en este dispositivo?",
  NOTIFICATIONS_READ: "Leído",
  NOTIFICATIONS_UNREAD: "No leído",
  NOVEMBER: "Noviembre",
  NR_BILLING: "No. Facturación",
  NR_ITEMS: "Número de items",
  NR_SALE_OPPORTUNITY: "Nº oportunidad de venta",
  NUMBERED_LIST: "Lista numerada",
  OCTOBER: "Octubre",
  OF: "de",
  OIL_OR_ENERGY_OR_SOLAR_OR_GREENTECH: "Petróleo / Energía / Solar / Greentech",
  OLD_PASSWORD: "Contraseña actual",
  ON_SITE: "En el sitio",
  ONCE: "Una vez",
  ONGOING_PROJECTS: "Proyectos en marcha",
  ONLINE_PUBLISHING: "Publicación en línea",
  ONLY_1_LINK_PER_SOCIAL_NETWORK: "Solo 1 enlace por red social",
  ONLY_MAX_FIVE_EMAILS:
    "Máximo de 5 invitados en esta etapa de registro, si desea agregar más usuarios, consulte:",
  ONLY_MAX_FIVE_EMAILS_PART_TWO: "configuración -> usuarios -> invitaciones",
  ONLY_ME: "Solo yo",
  OPEN: "Abierto",
  OPEN_ACCOUNTS: "Cuentas abiertas",
  OPEN_CASES: "Casos abiertos",
  OPEN_CONTRACTS: "Contratos abiertos",
  OPEN_CONTRACTS_PROGRESS: "Progreso de contratos abiertos",
  OPEN_DEAL: "Negocios abiertas",
  OPEN_DEALS: "Negocios abiertas",
  OPEN_DEALS_AMOUNTS: "Montos de acuerdos abiertos",
  OPEN_IN_APP: "Abrir en la aplicación",
  OPEN_PROJECT_ATRIBUTED: "Equipo - proyectos abiertos asignados",
  OPEN_PROJECTS: "Proyectos abiertos",
  OPEN_TASKS: "Tareas abiertas",
  OPEN_TASKS_PROGRESS: "Hora de tareas abiertas en curso",
  OPENIN_SCHEDULE: "Horario de apertura",
  OPENING_HOURS: "Horario de apertura",
  OPERATION: "Operador",
  OPERATIONS_DEPARTMENT: "Operaciones",
  OPERATOR: "Operador",
  OPTIONAL: "Opcional",
  OPTIONAL_INBOX_EMAIL: "Correo electrónico personalizado (opcional)",
  OR: "o",
  ORDER: "Orden",
  ORDER_BY: "Ordenar por",
  ORDERING: "Pedido",
  ORGANIZATION_NAME: "Nombre de la empresa",
  ORGANIZATION_NAME_INFO_DESC: "El nombre de su empresa",
  ORGANIZATION_REQUEST_GENERAL_ERROR:
    "Hubo un error al procesar su solicitud. Vuelva a intentarlo más tarde",
  ORGANIZATION_REQUEST_NO_DATA:
    "Para proceder, es necesario agregar una empresa",
  ORGANIZATION_REQUEST_NO_DATA_NO_PERMISSION:
    "Debe pertenecer a una empresa para continuar. Póngase en contacto con el administrador.",
  ORGANIZATION_REQUEST_NO_PERMISSION:
    "Se requieren permisos para realizar esta solicitud",
  ORGANIZATION_SAVE_SUCCESS: "Organización guardada correctamente",
  ORGANIZATIONS_SHORT_NAME: "Nombre corto de la empresa",
  ORIGIN: "Origen",
  ORIGIN_AND_STATUS: "Origen y estado",
  ORIGIN_IS: "El origen es",
  ORIGINATED: "Originada",
  OTHER_INDUSTRY: "Otra industria",
  OUT_OF_CONTRACT_HOURS_ABRV: "T. fuera de contrato",
  OUTSIDE_CONTRACT_HOURS: "Tiempo fuera de contrato",
  OUTSOURCING_OR_OFFSHORING: "Outsourcing / Offshoring",
  OVER: "Hasta",
  OVERALL_TASK_PERFORMANCE: "Rendimiento general de la tarea",
  OVERALL_TICKET_PERFORMANCE: "Rendimiento general del caso",
  OVERLAP_RULE: "Regla de superposición",
  OWNER: "Propietario",
  PACKAGE_OR_FREIGHT_DELIVERY: "Entrega de paquetes / fletes",
  PACKAGING_OR_CONTAINERS: "Embalaje / Contenedores",
  PAGE: "Página",
  PAPER_OR_FOREST_PRODUCTS: "Papel / Productos forestales",
  PARAMETERS: "Parámetros",
  PASSWORD: "Contraseña",
  PASSWORD_CHANGE: "Cambio de contraseña",
  PASSWORD_CHANGED_SUCCESS: "La contraseña se cambió correctamente",
  PASSWORD_STRENGH_MODERATE: "La contraseña está moderada",
  PASSWORD_STRENGH_STRONG: "La contraseña es segura",
  PASSWORD_STRENGH_VERY_STRONG: "La contraseña es muy segura",
  PASSWORD_STRENGH_WEAK:
    "Tu contraseña no es lo suficientemente segura. Tu contraseña debe tener al menos 8 caracteres, mayúsculas y minúsculas.",
  PASSWORDS_DONT_MATCH: "Las contraseñas no son iguales",
  PAUSES: "Pausas",
  PAY_AND_UPGRADE: "Pagar y actualizar",
  PAY_BY_CREDIT_CARD: "Pagar con tarjeta de crédito",
  PAYMENT_DETAILS: "Detalles del pago",
  PAYMENT_METHOD: "Método de pago",
  PAYMENT_METHODS: "Métodos de pago",
  PAYMENT_PENDING_DESCRIPTION: "Finalice su pago o cancele su suscripción",
  PAYMENT_PENDING_TITLE: "Su pago está pendiente",
  PAYMENT_SUMARY: "Resumen del pedido",
  PAYMENT_TOTAL: "Total",
  PAYMENTS_BILLED_MONTHLY_MESSAGE_FIRST_HALF:
    "La cantidad que se muestra es una simulación con los datos actuales de su espacio y solo se cobrará a partir de",
  PAYMENTS_BILLED_MONTHLY_MESSAGE_SECOND_HALF:
    "* El valor puede ser diferente si se cambia el número de usuarios y / o el almacenamiento",
  PENDING: "Pendiente",
  PER_PAGE: "Por página",
  PERCENTAGE: "Porcentaje",
  PERCENTAGE_HOURS: "Porcentaje de tiempo consumido ",
  PERCENTAGE_PERFORMANCE: "Porcentaje de rendimiento",
  PERFORMANCE: "Rendimiento",
  PERFORMANCE_ACCOUNT: "Rendimiento de cuentas",
  PERFORMING_ARTS: "Las artes escénicas",
  PERIOD_TOTAL: "Total periode",
  PERSONAL: "Personal",
  PERSONAL_DATA: "Información personal",
  PERSONALIZE_BLOCKS: "Personalizar bloques",
  PERSONALIZED: "Personalizado",
  PHARMACEUTICALS: "Productos farmacéuticos",
  PHILANTHROPY: "Filantropía",
  PHONE_CODES: "Cambiar códigos de teléfono",
  PHONE_NUMBER: "Número de teléfono",
  PHONE_NUMBER_PREFIX: "Extensión telefónica",
  PHOTOGRAPHY: "Fotografía",
  PICK_THE_MOST_IMPORTANT_TOPICS:
    "Elija los temas más importantes y cómo recibir las notificaciones.",
  PIN: "Alfiler",
  PINNED: "Fijado",
  PIPE_STATUS: "Estado de la tubería",
  PIPELINE: "Pipeline",
  PIPELINE_DELETE_MSG: "¿Quiere eliminar este pipeline?",
  PIPELINE_ERROR: "Error de canalización",
  PIPELINE_ERROR_DESC:
    "Es posible que la canalización seleccionada no exista o esté mal configurada",
  PIPELINE_STATUS: "Estado de la Pipeline",
  PLAN: "Plan",
  PLAN_ADMINISTRATION: "Administración",
  PLAN_ADVANCED: "Filtros avanzados",
  PLAN_ADVANCED_PERMISSION: "Permisos avanzados",
  PLAN_ANNUALLY: "Anualmente",
  PLAN_BASIC: "Pro",
  PLAN_BULK: "Exportación masiva de PDF",
  PLAN_BUSINESS: "Compañías",
  PLAN_BUSINESS_CONTRACTS_SUBSCRIPTIONS: "Negocios, contratos y suscripciones",
  PLAN_CALLS: "Llamadas y casos",
  PLAN_COMMENTS: "Comentarios",
  PLAN_COMPANY: "Empresa",
  PLAN_CONTACTS: "Contactos y clientes",
  PLAN_DASHBOARDS: "Paneles",
  PLAN_DEDICATED: "Gerente dedicado",
  PLAN_DEPARTMENTS: "Departamentos y equipos",
  PLAN_EARLY: "Acceso anticipado a nuevas funciones",
  PLAN_EXPORT: "Exportar",
  PLAN_EXPORT_CONTROL: "Control de exportación",
  PLAN_EXTRA_USER: "Usuario adicional",
  PLAN_FORMATION: "Entrenamiento (horas)",
  PLAN_FUNCTIONALITIES: "Caracteristicas",
  PLAN_GEOLOCATION: "Geolocalización y georreferenciación",
  PLAN_GEOLOCATION_SM: "Geolocalización",
  PLAN_MANAGEMENT: "Panel de administración",
  PLAN_MANDATORY: "Necesario",
  PLAN_MOST_POPULAR: "Más popular",
  PLAN_NOTIFICATIONS: "Notificaciónes de correo electrónico",
  PLAN_OF_SPACE: "del espacio",
  PLAN_ONBOARDING: "Precio de incorporación / instalación",
  PLAN_PRICE: "Precio",
  PLAN_PRICE_GIGA: "Precio por GB adicionales",
  PLAN_PRICING: "Precio y facturación personalizada",
  PLAN_PRIORITY: "Soporte prioritario",
  PLAN_PRO: "Negocio",
  PLAN_PROJECTS: "Proyectos y tareas",
  PLAN_REGISTRATION: "Registro de tiempo",
  PLAN_RENOVATION_DATE: "Fecha de renovación de la suscripción",
  PLAN_RENOVATION_PAYMENT_AMOUNT: "¿Cuánto se le cobrará?",
  PLAN_REPORTING: "Informes",
  PLAN_RULE_APPLICATION: "Regla de aplicación (planes)",
  PLAN_SPACE: "Límite de espacio (GB)",
  PLAN_STARTER: "Comienzo",
  PLAN_SUPPORT: "Apoyo",
  PLAN_TERM: "Término",
  PLAN_UPLOAD: "Subir archivo",
  PLAN_USE: "Utilizar",
  PLAN_USERS_LIMIT: "Límite de usuarios",
  PLAN_VERSION: "Historial de versiones",
  PLAN_WEB: "Aplicaciones web y móviles",
  PLANS: "Planes",
  PLASTICS: "Plástica",
  PLEASE_CONTACT_YOUR_ADMIN: "Póngase en contacto con el administrador",
  PLEASE_RECONECT: "Por favor, reconecta",
  POLITICAL_ORGANIZATION: "Organización política",
  POST_PAYMENT: "Pago posterior",
  POSTAL_CODE: "Código postal",
  POTENTIALLY_DANGEROUS: "Potencialmente perigoso",
  PREVIEW: "Previsualización",
  PREVIEW_TEST_RESULTS: "Resultados de la prueba",
  PREVIOUS: "Anterior",
  PREVIOUS_BILLING_PLAN: "Tu último plan fue",
  PRIMARY_DOMAIN: "Dominio principal",
  PRIMARY_OR_SECONDARY_EDUCATION: "Educación primaria / secundaria",
  PRINTING: "Impresión",
  PRIORITY: "Prioridad",
  PROCESSING: "En proceso",
  PRODUCT: "Producto",
  PROFESSIONAL_TRAINING: "Entrenamiento profesional",
  PROFILE: "Perfil",
  PROGRAM_DEVELOPMENT: "Desarrollo del programa",
  PROGRAMMING: "Programación",
  PROGRESS_CLOSED_TASKS: "Progreso promedio de tareas cerradas",
  PROGRESS_OPEN_PROJECTS: "Proyectos abiertos en curso",
  PROJECT: "Proyecto",
  PROJECT_ASSIGNED: "te asignó el proyecto",
  PROJECT_CREATION: "Creación de proyectos",
  PROJECT_DELETED: "eliminó el proyecto",
  PROJECT_DESCRIPTION:
    "Tener una visión global del desarrollo de los proyectos de su empresa",
  PROJECT_IS: "El proyecto es",
  PROJECT_NR: "Proyecto Nº",
  PROJECT_OVERIMPOSED_FOUND: "Hay superposición de proyectos.",
  PROJECT_TYPE: "Tipo de proyecto",
  PROJECT_TYPE_EQUALS: "El tipo de proyecto es igual a",
  PROJECT_TYPES: "Tipos de proyectos",
  PROJECT_UPDATE: "actualizó el proyecto",
  PROJECTS: "Proyectos",
  PROJECTS_ACTIVITY: "Proyectos - actividad",
  PROJECTS_ALMOST_EXCEEDED: "Proyectos: tiempo casi ha terminado",
  PROJECTS_BUDGET_RISK: "Proyectos - riesgo presupuestario",
  PROJECTS_CHANGED: "proyecto cambiados",
  PROJECTS_CLOSED: "Proyectos - cerrados",
  PROJECTS_DEADLINE_RISK: "Proyectos - riesgo de fecha límite",
  PROJECTS_DELETE_DELAY_DESCRIPTION: "Preparando para eliminar proyectos",
  PROJECTS_DELETE_DELAY_TITLE: "Eliminación del proyecto",
  PROJECTS_DELETE_DESCRIPTION: "Eliminando proyectos",
  PROJECTS_DELETE_TITLE: "Eliminación del proyecto",
  PROJECTS_EXCEEDED_HOURS: "Proyectos: tiempo excedido",
  PROJECTS_FAILED_CHANGED: "falló al cambiar",
  PROJECTS_INACTIVE: "Proyectos: inactivos",
  PROJECTS_NEAR_DEADLINE: "Proyectos: cerca de la fecha límite",
  PROJECTS_NOT_CHANGED: "proyecto sin cambios",
  PROJECTS_OPEN_EVOLUTION: "Evolución de la apertura de proyectos",
  PROJECTS_REPORT: "Informe de proyectos",
  PROJECTS_STATUS: "Estado de proyectos",
  PROJECTS_WORKLOAD: "Proyectos: carga de trabajo",
  PROMOTIONAL_CODE: "Codigo promocional",
  PROPERTY: "Propiedad",
  PROPERTY_SETTER: "Establecedor de propiedades",
  PROTOCOL: "Protocolo",
  PUBLIC: "Público",
  PUBLIC_RELATIONS_OR_PR: "Relaciones públicas / PR",
  PUBLIC_SAFETY: "Seguridad pública",
  PUBLICATION_DATE: "Fecha de publicación",
  PUBLICATION_NR: "Publicación Nº",
  PUBLICATION_REMOVE_MSG: "¿Quieres eliminar esta publicación?",
  PUBLICATIONS: "Publicaciones",
  PUBLICATIONS_SCHEDULE: "Calendario de publicaciones",
  PUBLISH_FROM: "Publicar desde",
  PUBLISH_NOW: "Publica ahora",
  PUBLISHED: "Publicado",
  PUBLISHING: "Publicación",
  PUBLISHING_DATE: "Fecha de publicación",
  PUBLISHING_FAIL: "Error de publicación",
  PUBLISHING_INDUSTRY: "Industria editorial",
  PUBLISHING_PERMISSION: "Permiso de publicación",
  PURCHASES_DEPARTMENT: "Compras",
  QUALITY: "Calidad",
  QUANTITY: "Cantidad",
  QUARTER: "Cuarto",
  QUERY_PARAMETERS: "Query parameters",
  QUEUE_LIST_EMPTY: "Lista de cola vacía",
  QUICK_ACTIONS: "Acciones rápidas",
  QUOTE_BLOCK: "Bloque de cotización",
  RAILROAD_MANUFACTURE: "Fabricación de ferrocarriles",
  RANCHING: "Ganadería",
  RATING: "Valoración",
  READ: "Leído",
  REAL_ESTATE_OR_MORTGAGE: "Bienes raíces e hipotecas",
  REALTIME_DISCONNECTED:
    "Estabas desconectado de las funcionalidades en tiempo real",
  REASON_INEFFICIENCY: "Razón de lo tiempo no imputable",
  REASON_TEXT: "Razón: {reason}",
  REASONS_LOSS: "Razones de la pérdida",
  RECEIVE_CALLS: "Llamadas recibidas",
  RECEPTION: "Bandeja de entrada",
  RECEPTION_ASSIGNED_TO: "Asignado a",
  RECEPTION_SIGNATURE: "Primero enviado",
  RECONNECT: "Reconectar",
  RECONNECTING: "Reconectando ...",
  RECORD: "Grabar",
  RECREATIONAL_FACILITIES_OR_SERVICES: "Instalaciones / servicios recreativos",
  RECURRENCE_CONFIGURATION: "Configuración de recurrencia",
  RECURRENCY_DAYS: "día(s)",
  RECURRENCY_EVERY: "Cada:",
  RECURRENCY_LIMIT_EXCEEDED:
    "Se superó el número máximo de tareas por repetición, la creación se ajustará para las primeras 100 tareas en los rangos de fechas deseados.",
  RECURRENCY_MONTH_WARNING:
    "Hay meses que tienen menos de {day} dias, en cuyo caso la tarea se creará en el último día del mes.",
  RECURRENCY_MONTHS: "mes(s)",
  RECURRENCY_ON: "En:",
  RECURRENCY_ORIGIN_DATE_WARNING:
    "Confirme las fechas de las tareas que se crearán.",
  RECURRENCY_WEEKS: "semana(s)",
  RECURRENCY_WILL_CREATE: "Se crearán <b>{count}</b> tareas recurrentes:",
  RECURRENCY_YEARS: "año(s)",
  RECURRENT_TASKS_CREATION: "Creando tareas recurrentes",
  RECURRENT_TASKS_CREATION_DELAY_DESCRIPTION:
    "Preparándose para crear tareas recurrentes",
  RECURRENT_TASKS_CREATION_DELAY_TITLE: "Tareas recurrentes",
  RECURRENT_TASKS_CREATION_DESCRIPTION: "Creando tareas recurrentes",
  RECURRENT_TASKS_CREATION_SUCCESS: "Tareas recurrentes creadas con éxito",
  RECURRENT_TASKS_CREATION_TITLE: "Tareas recurrentes",
  REDEEM_RULES: "Canjear reglas",
  REDEEMS: "Canjea",
  REDO: "Rehacer",
  REFERENCE: "Referencia",
  REFRESH: "Actualizar",
  REGION_STATE_DISCTRICT: "Región / Estado / Distrito",
  REGISTER_DESCRIPTION:
    "Para crear una nueva cuenta, complete los datos requeridos",
  REGISTER_EMAIL_EXISTS: "Este correo electrónico ya está en uso",
  REGISTER_ERROR_ALREADY_ACCEPTED: "La invitación ya fue aceptada",
  REGISTER_ERROR_EMAIL_EXISTS: "Ya existe Este email",
  REGISTER_ERROR_EMAIL_MISMATCH:
    "El correo electrónico no coincide con el correo electrónico de invitación",
  REGISTER_ERROR_INVITE_NOT_FOUND: "No se encontró la invitación",
  REGISTER_ERROR_MEMBERSHIP_ERROR: "Error de membresía",
  REGISTER_ERROR_NOT_VALID:
    "Los datos proporcionados no son válidos. Asegúrese de proporcionar un nombre, una contraseña y un correo electrónico válidos",
  REGISTER_ERROR_RESERVED_NAME: "El nombre está reservado",
  REGISTER_INVALID_EMAIL: "Establezca un correo electrónico válido",
  REGISTER_INVALID_EMAIL_OR_DUPLICATED:
    "Establezca un correo electrónico válido que no esté duplicado",
  REGISTER_INVALID_NAME: "Establezca un nombre válido",
  REGISTER_INVALID_PASSWORD: "Establezca una contraseña válida",
  REGISTER_UNKNOWN_ERROR: "No pudimos registrar su cuenta",
  REGISTER_WELCOME: "Bienvenido, crea una nueva cuenta",
  REGISTRATION: "Registro",
  REJECTED: "Rechazado",
  RELATED_ATTACHMENTS: "Adjuntos relacionados",
  RELIGIOUS_INSTITUTIONS: "Instituciones religiosas",
  REMOTE: "Remoto",
  REMOVAL: "Eliminación",
  REMOVE: "Eliminar",
  REMOVE_ALL: "Eliminar todo",
  REMOVE_ASSOCIATED_CARD_DESCRIPTION:
    "Cuando retira la tarjeta asociada, su suscripción no se renovará automáticamente y se cancelará tres días después del siguiente período de facturación",
  REMOVE_ASSOCIATED_CARD_TITLE: "Quitar tarjeta asociada",
  REMOVE_FORMAT: "Eliminar formato",
  REMOVE_LINK: "Remover enlace",
  REMOVE_TABLE: "Eliminar tabla",
  RENAME: "Cambiar nombre de vista",
  RENEWABLES_OR_ENVIRONMENT: "Renovables / Medio ambiente",
  RENEWAL: "Renovación",
  REORDER_VIEWS: "Ordenar vistas",
  REPEAT_ON_FAIL: "Volver a repetir si falla",
  REPEAT_ON_FAIL_COUNT: "Máximo de intentos de repetición",
  REPEATING: "Varias veces",
  REPEATS: "Repite",
  REPLACE_CARD: "Reemplazar tarjeta",
  REPLY: "Respuesta",
  REPORT: "Reporte",
  REPORT_ACCOUNT: "Informar a la cuenta",
  REPORT_CASES: "Informar casos",
  REPORT_CLIENT: "Informar al cliente",
  REPORTING: "Informes",
  REPORTS: "Informes",
  REPORTS_TIMES: "Informe de tiempos",
  REPUBLISH: "Republicar",
  REQUEST_BODY: "Cuerpo de la orden",
  REQUEST_HELP: "Solicitar asistencia",
  REQUEST_TYPE: "Tipo de orden",
  RESEARCH_INDUSTRY: "Industria de la investigación",
  RESET_EMAIL_ERROR: "Tuvimos un error al intentar restablecer su contraseña",
  RESET_EMAIL_ERROR_TRY_AGAIN: "Inténtelo de nuevo en un momento",
  RESET_EMAIL_INSERT_NEW_PASSWORD: "Establezca su nueva contraseña",
  RESET_EMAIL_INVALID_URL: "Esta URL ya no es válida",
  RESET_EMAIL_SENT_DESCRIPTION:
    "Siga las instrucciones del correo electrónico para completar el restablecimiento de la contraseña",
  RESET_EMAIL_SENT_TITLE: "Enviamos un correo electrónico a <b> {email} </b>",
  RESET_PASSWORD: "Restablecer su contraseña",
  RESOLVED: "Resuelto",
  RESOURCE: "Recurso",
  RESPONSABILITY: "Responsabilidad de",
  RESPONSE_BODY: "Cuerpo de respuesta",
  RESPONSE_CODE: "Código de respuesta",
  RESPONSE_FORMAT: "Formato de respuesta",
  RESPONSIBLE_DEAL: "Responsable del trato",
  RESPONSIBLE_DEAL_2: "Trato responsable",
  RESTAURANTS: "Restaurantes",
  RESTORE_ALL: "Restaura todo",
  RESTORE_CARDS: "Restaurar tarjetas",
  RESTORE_COLUMNS: "Restaurar columnas",
  RESTORE_KANBAN: "Restaurar kanban",
  RESTORE_MODULES: "Restaurar módulos",
  RESTORE_PIPELINE_STATUS: "Restaurar estados de canalización",
  RESTORE_STATES: "Restaurar estados",
  RETAIL_INDUSTRY: "Industria minorista",
  RETRY_PAYMENT: "Reintente el pago",
  RETURN: "Regreso",
  RETURN_PATH_RECORD: "Vía de retorno",
  ROADMAP: "Mapa vial",
  ROLE: "Papel",
  ROUNDING_MARGIN: "Margen de redondeo (min)",
  ROUNDING_MARGIN_ABV: "Margen derecho (min)",
  SALES: "Ventas",
  SALES_DEALS: "Negocios de ventas",
  SALESPERSON: "Comercial",
  SATURDAY: "Sábado",
  SAVE: "Salvar",
  SAVE_SUCCESS: "Guardado con éxito",
  SAVE_VIEW: "Guardar vista",
  SCHEDULE: "Calendario",
  SCHEDULE_TASKS: "Tareas programadas",
  SCHEDULED: "Programado",
  SCOPE: "Filtrar",
  SCORE: "Puntuación",
  SCREEN_RECORD_TOOLTIP: "Grabador de pantalla",
  SCRIPT: "Guiones",
  SEARCH: "Buscar",
  SEARCH_ASSOCIATION: "Buscar asociación",
  SEARCH_CASES_CALLS: "Buscar casos, llamadas, otros ...",
  SEARCH_CLIENT_NAME: "Buscar nombre de cliente, número de cliente, etc.",
  SEARCH_FUNCTIONALITIES: "Funciones de búsqueda",
  SEARCH_GIFS: "Buscar GIF",
  SEARCH_INBOX_PLACEHOLDER: "Buscar en la bandeja de entrada",
  SEARCH_MODULES: "Módulos de búsqueda",
  SEARCH_PIPELINE_STATUS: "Buscar estados de canalización",
  SEARCHING: "Buscando ...",
  SECOND: "Segundo",
  SECURITY: "Seguridad",
  SECURITY_OR_INVESTIGATIONS: "Seguridad / Investigaciones",
  SEE_AS: "Ver como",
  SEE_CHANGES: "Ver cambios",
  SEE_ORIGINAL: "Ver original",
  SELECT_A_PROPERTY: "Elija una propiedad",
  SELECT_ALL: "Seleccionar todo",
  SELECT_LANGUAGE: "Selecciona un idioma",
  SELECT_LEGAL_BASIS: "Seleccionar base legal",
  SELECT_NEW_PLAN_AFTER_TRIAL: "Seleccione un plan para su nueva suscripción",
  SELECT_ONE_PIPELINE: "Seleccione una canalización",
  SELECT_PAYMENT_METHOD: "Selecciona tu forma de pago",
  SELECT_PRIMARY: "Seleccionar principal",
  SELECT_PRIMARY_CONTACT: "Seleccione el contacto principal",
  SELECT_PRIMARY_CONTACT_DESCRIPTION:
    "Defina el contacto principal que se utilizará. Esta acción es irreversible, todos los registros se asociarán al contacto principal y los demás se borrarán de forma permanente",
  SELECT_PROPERTY: "Seleccionar propiedad",
  SELECT_SCHEDULE: "Seleccione un horario",
  SELECT_SOCIAL_NETWORK: "Seleccione una red social para conectarse",
  SELECT_STATUS: "Seleccionar estado",
  SELECT_TEST_TARGET: "Realizar una prueba",
  SELECT_WHAT_IMPORT: "Seleccione qué importar",
  SELECTED_COLUMNS: "Columnas seleccionadas",
  SELECTED_LANGUAGE: "Idioma seleccionado",
  SELECTED_MODULES: "Módulos seleccionados",
  SELECTED_ORIGIN: "Origen seleccionado:",
  SEMICONDUCTORS: "Semiconductores",
  SEND: "Enviar",
  SEND_EMAIL: "Enviar correo electrónico",
  SEND_INVITES: "Enviar invitaciones",
  SEND_INVITES_DESC:
    "Su espacio de trabajo de Airdesk está listo para ser utilizado. ¿Conoce a algún amigo o compañero de trabajo que le gustaría explorar Airdesk con usted?",
  SEND_INVITES_FORM_LABEL: "Dirección de correo electrónico",
  SEND_MAIL: "Enviar correo electrónico",
  SEND_NOTIFICATION: "Notificar contato",
  SENDING_DATE: "Fecha para enviar",
  SENT_BY: "Enviado por",
  SENT_DATE: "Fecha enviada",
  SENT_EMAIL: "Envió el correo electrónico",
  SENT_STATUS: "Estado del mensaje",
  SEPERATOR: "Separador",
  SEPTEMBER: "Septiembre",
  SERVICE: "Servicio",
  SERVICES: "Servicios",
  SESSION_ENDED: "Sesión terminado",
  SESSIONS_CLOSED_SUCCESS: "Las sesiones terminaron con éxito",
  SET_NEW_PASSWORD: "Establecer nueva contraseña",
  SETTINGS: "Configuración",
  SETTINGS_ACCESS: "Acceso a la configuración",
  SETUP: "Preparar",
  SHARE: "Compartir",
  SHARE_BUTTON_ALT: "Compartir",
  SHARE_ERROR_DESCRIPTION: "Tuvimos un error al compartir",
  SHARE_ERROR_TITLE: "Error al compartir",
  SHARE_SUCCESS_DESCRIPTION: "Acción exitosa",
  SHARE_SUCCESS_DESCRIPTION_ALT:
    "Se completó la acción tomada. {total} completado, {errors} fallado",
  SHARING_API_KEY: "Se está compartiendo el clave API {id}",
  SHARING_API_KEY_SUCCESS_DESCRIPTION:
    "Se completó la acción tomada. {total} completado, {errors} fallado",
  SHARING_API_KEY_SUCCESS_TITLE: "Se compartió el clave API {id}",
  SHARING_AUTOMATION_DESCRIPTION: "Se está compartiendo el workflow {id}",
  SHARING_AUTOMATION_SUCCESS_TITLE: "Se compartió el workflow {id}",
  SHARING_AUTOMATION_TITLE: "Se está compartiendo el workflow {id}",
  SHARING_BILLING_SUCCESS_TITLE: "Se ha compartido la clasificación {id}",
  SHARING_BILLING_TITLE: "Se está compartiendo la clasificación {id}",
  SHARING_CALL_SUCCESS_TITLE: "Se compartió la llamada {id}",
  SHARING_CALL_TITLE: "Se está compartiendo la llamada {id}",
  SHARING_CLIENT_SUCCESS_TITLE: "El cliente {id} fue compartido",
  SHARING_CLIENT_TITLE: "El cliente {id} está siendo compartido",
  SHARING_COMPANY_SUCCESS_TITLE: "La empresa {id} fue compartida",
  SHARING_COMPANY_TITLE: "La empresa {id} se está compartiendo",
  SHARING_CONTACT_SUCCESS_TITLE: "Se compartió el contacto {id}",
  SHARING_CONTACT_TITLE: "Se está compartiendo el contacto {id}",
  SHARING_CONTRACT_SUCCESS_TITLE: "Se compartió el contrato {id}",
  SHARING_CONTRACT_TITLE: "El contrato {id} se está compartiendo",
  SHARING_CONVERSATION: "La conversación se está compartiendo",
  SHARING_CONVERSATION_SUCCESS_TITLE: "La conversación se ha compartido",
  SHARING_DEAL_SUCCESS_TITLE: "Se compartió el negocio {id}",
  SHARING_DEAL_TITLE: "Se está compartiendo el negocio {id}",
  SHARING_DEPARTMENT_SUCCESS_TITLE: "El departamento {id} fue compartido",
  SHARING_DEPARTMENT_TITLE: "El departamento {id} se está compartiendo",
  SHARING_DESCRIPTION: "La acción realizada se está procesando",
  SHARING_EMAIL_SIGNATURE_SUCCESS_TITLE:
    "La firma de correo electrónico {id} se ha compartido",
  SHARING_EMAIL_SIGNATURE_TITLE:
    "Se está compartiendo la firma de correo electrónico {id}",
  SHARING_PROJECT_SUCCESS_TITLE: "El proyecto {id} fue compartido",
  SHARING_PROJECT_TITLE: "El proyecto {id} se está compartiendo",
  SHARING_SOCIAL_POST_SUCCESS_TITLE: "Se ha compartido la publicación {id}",
  SHARING_SOCIAL_POST_TITLE: "Se está compartiendo la publicación {id}",
  SHARING_SUBSCRIPTION_SUCCESS_TITLE: "Se compartió la suscripción {id}",
  SHARING_SUBSCRIPTION_TITLE: "Se está compartiendo la suscripción {id}",
  SHARING_TASK_SUCCESS_TITLE: "La tarea {id} fue compartida",
  SHARING_TASK_TITLE: "La tarea {id} se está compartiendo",
  SHARING_TEAM_SUCCESS_TITLE: "Se ha compartido la equipa {id}",
  SHARING_TEAM_TITLE: "Se está compartiendo la equipa {id}",
  SHARING_TICKET_SUCCESS_TITLE: "Se compartió el boleto {id}",
  SHARING_TICKET_TITLE: "El boleto {id} se está compartiendo",
  SHARING_TIMECHARGE_SUCCESS_TITLE: "Se compartió el registro de tiempo {id}",
  SHARING_TIMECHARGE_TITLE: "Se está compartiendo el registro de tiempo {id}",
  SHIPBUILDING: "Construcción naval",
  SHORT_NAME: "Nombre corto",
  SHORT_NAME_DES: "nombre corto",
  SHORT_NAME_INFORMATION:
    "El nombre corto es necesario para crear correos electrónicos en la bandeja de entrada. Ejemplo:",
  SHOW_ALL: "Mostrar todo",
  SHOW_LESS: "Ver menos",
  SHOW_MORE: "Ver más",
  SHOW_NOTES: "Ver notas",
  SIDEBAR: "Barra lateral",
  SIGN_IN: "Iniciar sesión",
  SIGNATURE: "Firma",
  SIGNATURE_BANNER: "Bandera",
  SIGNATURE_LOGO: "Logo",
  SIMULATION: "Simulación",
  SMALL_SCREEN_SUBTITLE:
    "Agranda la ventana del navegador o, para dispositivos móviles, descarga / abre la aplicación para Android/",
  SMALL_SCREEN_TITLE:
    "Tu pantalla es demasiado pequeña para trabajar con Airdesk",
  SNIPPET: "Retazo",
  SNIPPET_DESCRIPTION_AFTER:
    "etiqueta de la página de inicio para el cumplimiento y recopilar detalles",
  SNIPPET_DESCRIPTION_BEFORE: "Copie y pegue el fragmento antes",
  SNIPPET_TITLE: "Código de fragmento",
  SOCIAL_ACCOUNTS_REQUEST_NO_DATA:
    "Para continuar, debe agregar una cuenta de red social.",
  SOCIAL_ACCOUNTS_REQUEST_NO_DATA_NO_PERMISSION:
    "Se requiere una cuenta de red social para continuar. Comuníquese con el administrador.",
  SOCIAL_AUTHENTICATION: "Social: autenticación",
  SOCIAL_MEDIA: "Social",
  SOCIAL_MEDIA_ACCOUNTS: "Cuentas de redes sociales",
  SOCIALMEDIA: "Social",
  SORT_YOUR_VIEWS: "Ordena las vistas creadas",
  SOUND_AND_APEARANCE: "Sonido y apariencia",
  SPACE: "Espacio",
  SPACE_ENTERED: "entró en el espacio",
  SPACE_OPEN: "Abrir",
  SPACE_SETUP_DATA: "Datos del espacio de trabajo",
  SPACE_SETUP_DESCRIPTION:
    "Para poder personalizar su espacio de trabajo, primero necesitaremos cierta información. Puede cambiarla en cualquier momento en el futuro",
  SPACE_SETUP_NAME_FIELD: "Nombre del espacio de trabajo",
  SPACE_SETUP_NAME_FIELD_INFO_DESC:
    "Nombre de su espacio de trabajo en Airdesk (por ejemplo, nombre de la empresa / grupo de empresas)",
  SPACE_SETUP_TITLE: "Configura tu nuevo espacio de trabajo",
  SPACE_SUBSCRIPTION_WARNING: "Si continúa, se cancelará la suscripción actual",
  SPACE_UPDATE: "Espacio actualizado",
  SPACE_UPDATE_DOMAIN_CONFLICT: "El nombre curto seleccionado ya existe",
  SPACE_UPDATE_MSG: "La configuración del espacio se cambió correctamente",
  SPEECH_TO_TEXT: "Dictar",
  SPEECH_TO_TEXT_LANGUAGE: "Idioma",
  SPENT_HOURS: "Tiempo consumido ",
  SPENT_HOURS_ABRV: "T. consumido ",
  SPF_RECORD: "Registro SPF",
  SPLIT: "División",
  SPLIT_CELLS: "Partir Células",
  SPLIT_IN_BATCHES: "División por lotes",
  SPORTING_GOODS: "Artículos deportivos",
  SPORTS: "Deportes",
  STAFFING_OR_RECRUITING: "Dotación de personal / Reclutamiento",
  START: "Comienzo",
  START_CONVERSATION: "Enviar Email",
  START_DATE: "Fecha de inicio",
  START_DATE_NO_BIGGER_THEN_END:
    "La fecha de inicio no puede ser mayor que la fecha de finalización",
  STARTS_WITH: "Mayor",
  STATUS: "Estado",
  STATUS_CHANGED_SUCCESS: "El estado cambió correctamente",
  STATUS_CONTRACTS: "Contratos de estado",
  STATUS_EQUALS: "El estado es igual a",
  STATUS_PROJECTS: "Proyectos de estado",
  STEP: "Paso",
  STICKY: "Pegar",
  STORAGE: "Almacenamiento",
  STRIKETHROUGH: "Tachado",
  SUBJECT: "Tema",
  SUBJECT_HELP_MESSAGE: "Mensaje de asunto",
  SUBMIT: "Enviar",
  SUBMIT_AND_SEND: "Enviar invitación",
  SUBMIT_FEEDBACK: "Submeter feedback",
  SUBMIT_NOT_SEND: "Salvar",
  SUBSCRIBE: "Suscribir",
  SUBSCRIPTION: "Suscripción",
  SUBSCRIPTION_CANCELED: "Suscripción cancelada",
  SUBSCRIPTION_CREATION: "Creación de suscripciones",
  SUBSCRIPTION_DELETED: "eliminó la subscription",
  SUBSCRIPTION_EXPIRED: "Prueba vencida",
  SUBSCRIPTION_NOT_RENOVATED: "Suscripción con pago",
  SUBSCRIPTION_NR: "Nº subscrição",
  SUBSCRIPTION_OF_WORKSPACE: "La suscripción del espacio de trabajo",
  SUBSCRIPTION_OF_WORKSPACE_TRIAL_ENDED: "Tu prueba gratuita ha terminado",
  SUBSCRIPTION_TYPE: "Tipo de suscripción",
  SUBSCRIPTION_TYPES: "Tipos de suscripción",
  SUBSCRIPTION_WAS_CANCELED_DESCRIPTION:
    "Seleccione un plan para su nueva suscripción",
  SUBSCRIPTION_WAS_CANCELED_TITLE: "Tu suscripción ha sido cancelada",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION:
    "Debido a algunas fallas de pago, su suscripción ha sido cancelada. Seleccione un plan para su nueva suscripción",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION_PART_ONE:
    "Compruebe si tiene saldo suficiente o actualice su método de pago en",
  SUBSCRIPTION_WAS_NOT_RENOVATED_DESCRIPTION_PART_TWO:
    "días en que se cancelará su suscripción",
  SUBSCRIPTION_WAS_NOT_RENOVATED_TITLE: "Su suscripción no ha sido renovada",
  SUBSCRIPTIONS: "Suscripciones",
  SUBSCRIPTIONS_CHANGED: "suscripción cambiados",
  SUBSCRIPTIONS_DELETE_DELAY_DESCRIPTION:
    "Preparándose para eliminar suscripciones",
  SUBSCRIPTIONS_DELETE_DELAY_TITLE: "Eliminar suscripciones",
  SUBSCRIPTIONS_DELETE_DESCRIPTION: "Eliminando suscripciones",
  SUBSCRIPTIONS_DELETE_TITLE: "Eliminar suscripciones",
  SUBSCRIPTIONS_FAILED_CHANGED: "falló al cambiar",
  SUBSCRIPTIONS_NOT_CHANGED: "suscripción sin cambios",
  SUBSCRITION_CANCELED_SUCCESS: "Suscripción cancelada correctamente",
  SUBSCRITION_CANCELED_SUCCESS_DESCRIPT:
    "La suscripción no se renovará al final del período facturado",
  SUBTOTAL: "Total parcial",
  SUCCESS: "Éxito",
  SUCCESS_DELETED: "Eliminado correctamente",
  SUCCESS_MESSAGES: "Mensaje de éxito",
  SUCCESS_PAYMENT: "El pago se realizó correctamente",
  SUCCESS_PLAN_CHANGE: "Cambio de plan exitoso",
  SUCCESS_SAVED: "Grabado con éxito",
  SUCCESS_SUBMIT_MESSAGE: "Mensaje de confirmación de éxito",
  SUCCESSFULLY_CONCLUDED: "Cambiado correctamente",
  SUCCESSFULLY_RECORDED: "Grabado con éxito",
  SUMMARY: "Resumen",
  SUNDAY: "Domingo",
  SUPERMARKETS: "Supermercados",
  SUPPORT: "Apoyo",
  SUPPORT_REQUEST: "Solicitud de asistencia",
  SYSTEM: "Sistema",
  SYTEM_AIRDESK_EMAIL: "Correo electrónico de alias de Airdesk",
  TABLE_CALLS: "Cuadro de llamadas",
  TABLE_CONTRACTS: "Cuadro de Contratos",
  TABLE_DEALS: "Cuadro de ofertas",
  TABLE_PROJECTS: "Cuadro de proyectos",
  TABLE_SUBSCRIPTIONS: "Cuadro de suscripciones",
  TABLE_TASKS: "Cuadro de tareas",
  TABLE_TICKETS: "Cuadro de casos",
  TABLE_TIMES: "Cuadro de tiempos",
  TASK: "Tarea",
  TASK_ASSIGNED: "te asignó la tarea",
  TASK_ASSIGNED_TO: "asignado la tarea",
  TASK_CLOSING_TIME: "Equipo: tiempo medio de cierre de la tarea",
  TASK_CREATION: "Creación de tareas",
  TASK_DELETED: "eliminó la tarea",
  TASK_HISTORY: "Historial de tareas",
  TASK_IS: "La tarea es",
  TASK_LIST: "Lista de quehaceres",
  TASK_NR: "Tarea Nº",
  TASK_OVERIMPOSED_FOUND: "Hay superposición de tareas ou tiempos.",
  TASK_REMOVED: "eliminó su tarea",
  TASK_UPDATE: "actualizó la tarea",
  TASKS: "Tareas",
  TASKS_CHANGED: "tarefas cambiados",
  TASKS_DELETE_DELAY_DESCRIPTION: "Preparándose para eliminar tareas",
  TASKS_DELETE_DELAY_TITLE: "Eliminando tareas",
  TASKS_DELETE_DESCRIPTION: "Eliminando tareas",
  TASKS_DELETE_TITLE: "Eliminando tareas",
  TASKS_DESCRIPTION:
    "Realice un seguimiento del recuento de tareas, el tiempo de respuesta y más de su empresa",
  TASKS_FAILED_CHANGED: "falló al cambiar",
  TASKS_MULTIPLE: "Tareas (s)",
  TASKS_NOT_CHANGED: "tarefas sin cambios",
  TASKS_PERFORMANCE: "Rendimiento de las tareas",
  TASKS_PROGRESS: "Progreso de las tareas",
  TASKS_REPORT: "Informe de tareas",
  TASKS_STATUS: "Estado de las tareas",
  TAX_INCLUDED: "(incluye impuestos)",
  TEAM: "Equipo",
  TEAM_ACTIVITY: "Actividad del equipo",
  TEAM_CASE_AVERAGE: "Equipo: tiempo medio de cierre del caso",
  TEAM_DEPARTMENT: "Departamento del equipo",
  TEAM_IS: "El equipo es",
  TEAM_LOAD: "Equipo - carga de trabajo",
  TEAM_MEMBER: "Miembro del equipo",
  TEAM_NAME: "Nombre del equipo",
  TEAM_SAVE_SUCCESS: "Equipa guardada correctamente",
  TEAM_TASK_AVERAGE: "Equipa - tiempo medio de cierre de tareas",
  TEAM_WORKLOAD: "Equipo - carga de trabajo",
  TEAMS: "Equipos",
  TEAMS_CONTRIBUTORS: "Equipos / Colaboradores",
  TECHNICIANS: "Técnicos",
  TELECOMMUNICATIONS: "Telecomunicaciones",
  TEMPLATE: "Template",
  TEMPLATES: "Templates",
  TERMS_CONDITIONS: "Términos y condiciones",
  TEST: "Prueba",
  TEST_EMAIL_SEND_SUCCESS: "Email enviado con éxito",
  TEST_EMAIL_SIGNATURE: "Probar firma",
  TEST_EMAIL_SIGNATURE_DESCRIPTION:
    "Se enviará un email a su correo electrónico personal",
  TEXT: "Texto",
  TEXT_FIELD_IS_REQUIRED: "Escribe un mensaje",
  TEXT_LINK: "Enlace de texto",
  TEXTILES: "Textiles",
  THE_CALL_IS_CREATED: "Se crea la llamada.",
  THE_CASE_STATUS: "El estado del caso",
  THE_CLASSIFICATION_IS_CREATED: "Se crea la clasificación.",
  THE_CLIENT_IS_CREATED: "Se crea la cuenta.",
  THE_CLIENT_STATUS: "El estado de la cuenta",
  THE_CONTACT_IS_CREATED: "Se crea el contacto.",
  THE_CONTACT_STATUS: "El estado del contacto",
  THE_CONTRACT_IS_CREATED: "Se crea el contrato.",
  THE_DEAL_IS_CREATED: "Se crea el negocio.",
  THE_PROJECT_IS_CREATED: "Se crea el proyecto.",
  THE_PROJECT_STATUS: "El estado del proyecto",
  THE_SUBSCRIPTION_IS_CREATED: "Se crea la suscripción.",
  THE_TASK_IS_CREATED: "Se crea la tarea.",
  THE_TASK_STATUS: "El estado de la tarea",
  THE_TEAM: "El equipa",
  THE_TICKET_IS_CREATED: "Se crea el caso.",
  THE_TIME_IS_CREATED: "Se crea el tiempo.",
  THE_TIME_RECORD_STATUS: "El estado del registro de tiempo",
  THE_USER: "El usuario",
  THEME: "Tema",
  THINK_TANKS: "Think tanks",
  THIRD: "Tercero",
  THUMBNAIL: "Avatar",
  THURSDAY: "Jueves",
  TICKET_CLOSE: "Equipo: tiempo medio de cierre del caso",
  TICKET_CREATION: "Creación de casos",
  TICKET_ERROR_NO_CONTACT:
    "No hay ningún contacto de email para enviar la notificación",
  TICKET_FORM: "Formulario de caso",
  TICKET_HISTORY: "Historial de casos",
  TICKET_PERFORMANCE: "Rendimiento del caso",
  TICKET_PROGRESS: "Tiempo de casos abiertos en curso",
  TICKETS_CHANGED: "casos cambiados",
  TICKETS_DELETE_DELAY_DESCRIPTION: "Preparándose para eliminar casos",
  TICKETS_DELETE_DELAY_TITLE: "Eliminación de casos",
  TICKETS_DELETE_DESCRIPTION: "Eliminando casos",
  TICKETS_DELETE_TITLE: "Eliminación de casos",
  TICKETS_DESCRIPTION:
    "Realice un seguimiento del recuento de casos, el tiempo de respuesta y más de su empresa",
  TICKETS_FAILED_CHANGED: "falló al cambiar",
  TICKETS_MULTIPLE: "Casos)",
  TICKETS_NOT_CHANGED: "casos sin cambios",
  TIME: "Tiempo",
  TIME_CHARGES_MULTIPLE: "Cargo (s) de tiempo",
  TIME_CONTROL: "Cronógrafo",
  TIME_CONTROL_PERIODICITY: "Periodicidad del control de tiempo",
  TIME_EQUIVALENT: "Este módulo es igual que los registros de tiempo",
  TIME_IS: "El tiempo es",
  TIME_MONITORING: "Monitoreo de tiempo",
  TIME_NR: "Registro de tiempo Nº",
  TIME_RECORD: "Registro de tiempo",
  TIME_RECORDS: "Registros de tiempo",
  TIME_REGISTER: "Registros de tiempo",
  TIME_REGISTRATION: "Registros de tiempo",
  TIME_REPORT: "Informe de tiempo",
  TIME_SUMMARY: "Resumen de los últimos 9 días del registro de tiempo",
  TIME_TYPE: "Tipo de registro de tiempo",
  TIME_TYPE_EQUALS: 'Time type is equal to "',
  TIMEOUT: "Timeout",
  TIMEOUT_BETWEEN_FAIL: "Tiempo de espera entre fallas",
  TIMES: "Horas",
  TIMESHEET: "Hoja de horas",
  TIMESHEET_DESCRIPTION: "Consulta los horarios publicados por tus empleados",
  TIMETABLES_PREDEFINED: "Los horarios están predefinidos para el día elegido",
  TIMEZONE: "Zona horaria",
  TITLE: "Título",
  TO: "A",
  TO_BILL: "A facturar",
  TO_PLAN: "Planificar",
  TO_PUBLISH: "Para ser publicado",
  TOBACCO: "Tabaco",
  TODAY: "Hoy",
  TODO_ITEMS: "To do's",
  TODO_NR_ITEMS: "Número de artículos {items}",
  TODOS_NUMBER: "Numero de pasos",
  TOMOROW: "Mañana",
  TOP_ACCOUNTS: "Cuentas principales",
  TOTAL: "Total",
  TOTAL_HOURS: "Horas totales",
  TOTAL_PAUSES: "Pausas totales (minutos)",
  TOTAL_PAUSES_HOURS_ABRV: "Pausas totales",
  TOTAL_RECORDS: "Total de registros",
  TOTAL_VALUE: "Valor total",
  TOTALS: "Totales",
  TRANSACTION: "Transacción",
  TRANSFER: "Transferir",
  TRANSFER_OWNERSHIP: "Transferir propiedad",
  TRANSFER_OWNERSHIP_DESCRIPTION:
    "¿Estás seguro de que deseas transferir la propiedad del espacio de trabajo <b> {spaceName} </b> a {userName}?",
  TRANSFER_OWNERSHIP_SUBSCRIPTION_WARNING:
    "Para transferir la propiedad, debe cancelar su suscripción",
  TRANSFER_PROPERTY: "Transferir propiedad",
  TRANSFER_PROPERTY_TITLE: "Transferir la propiedad del espacio de trabajo",
  TRANSFER_PROPERTY_TITLE_DESCRIPTION:
    "Transferir este espacio de trabajo a otro usuario hará que pierda los privilegios de propietario",
  TRANSLATION_OR_LOCALIZATION: "Traducción / localización",
  TRANSPORTATION: "Transporte",
  TRAVEL: "Viajes",
  TRAVEL_TIME: "Tiempo de viaje (minutos)",
  TRAVEL_TIME_HOURS_ABRV: "Tiempo de viaje",
  TRAVELS: "Viajes",
  TREEVIEW_NO_CHILDREN: "No se crearon elementos",
  TRIAL_ENDED: "Prueba finalizada",
  TRIGGER_CONDITION: "Condición de activación",
  TRY_AGAIN: "Inténtalo de nuevo",
  TRY_AGAIN_IN_SOME_TIME: "Inténtelo de nuevo en algún momento",
  TUESDAY: "Martes",
  TWITTER_URL: "URL de Twitter",
  TYPE: "Tipo",
  TYPE_CONTRACT: "Tipo de contrato",
  TYPE_CONTRACT_EQUALS: "Tipo de contrato es igual a",
  TYPE_SUBSCRIPTION: "Tipo de suscripción",
  TYPE_SUBSCRIPTION_EQUALS: "El tipo de suscripción es igual a",
  TYPE_TO_SEARCH: "Escribe para buscar",
  TYPES: "Tipos",
  UNASSIGNED: "Sin asignar",
  UNCLASSIFIED: "Desclasificado",
  UNDERLINE: "subrayada",
  UNDO: "Deshacer",
  UNFOLLOW: "Dejar de seguir",
  UNIMPORTED_RECORDS: "Registros no importados",
  UNIQUE_USAGE_PER_SPACE: "Un solo uso por espacio",
  UNIT: "Unidad",
  UNLIMITED: "Ilimitado",
  UNMODIFIED_RECORDS: "Registros sin modificar",
  UNPIN: "Desprender",
  UNRESOLVED: "Irresoluto",
  UNSPLASH_TOOLTIP: "Stockphoto Unsplash",
  UNSTICKY: "Despegar",
  UPDATE: "Actualizar",
  UPDATE_BILLING_DATA: "Actualizar la información de facturación",
  UPDATE_BILLING_INFO_SUCCESS:
    "La información de facturación se actualizó correctamente",
  UPDATE_CALL: "El usuario actualizó la llamada",
  UPDATE_CASE: "El usuario actualizó el caso",
  UPDATE_DEAL: "El usuario actualizó el negocio",
  UPDATE_DNS_DATA: "Actualizar datos DNS",
  UPDATE_NOTIFICATION_MSG: "La configuración se cambió correctamente",
  UPDATE_PASSWORD: "Actualizar contraseña",
  UPDATE_PAYMENT_METHOD_WARNING_PART_ONE: "Actualiza tu método de pago",
  UPDATE_PAYMENT_METHOD_WARNING_PART_TWO: "días de cancelación",
  UPDATE_PAYMENT_SUCCESS: "Método de pago actualizado correctamente",
  UPDATE_TASK: "El usuario actualizó la tarea",
  UPDATE_USER_PERMISSION_SUCCESS: "Permisos actualizados",
  UPDATED_RECORDS: "Registros actualizados",
  UPDATING_USER_PERMISSION: "Se están actualizando los permisos",
  UPGRADE_PLAN: "Potenciar",
  UPGRADE_TO_BASIC: "a Pro",
  UPGRADE_TO_COMPANY: "a la empresa",
  UPGRADE_TO_PRO: "A negociar",
  UPGRADE_TO_STARTER: "para comenzar",
  UPLOAD: "Subir",
  UPLOAD_IMAGE: "Subir foto",
  UPLOAD_LOGO: "Cargar logotipo",
  UPLOAD_PHOTO: "Subir foto",
  URL: "Url",
  URL_BASE: "URL Base",
  URL_LINK: "Enlace URL",
  USE_DEFAULT: "Uso por defecto",
  USE_KEYBOARD: "Usa el teclado para navegar",
  USED: "consumido ",
  USED_COUNT: "No. de usos",
  USER: "Usuario",
  USER_AVATAR: "Avatar",
  USER_COMPANY: "Empresa",
  USER_DEPARTMENTS: "Departamentos",
  USER_EMAIL: "Correo electrónico",
  USER_EMPLOYEE_NUMBER: "Número de empleado",
  USER_EXNTENSION: "Extensión telefónica",
  USER_FULL_NAME: "Nombre completo",
  USER_NAME: "Nombre de usuario",
  USER_PERMISSION_ERROR_DESCRIPTION: "Hubo un error al actualizar los permisos",
  USER_PERMISSION_ERROR_TITLE: "Error de permisos",
  USER_PHONE: "Número de teléfono",
  USER_ROLE: "Papel",
  USER_SIGNATURE: "Firma del cliente",
  USER_TEAMS: "Equipos",
  USERS: "Usuarios",
  USERS_NUMBER: "Nº usuarios",
  UTILITIES: "Utilidades",
  VALIDATE_NIF: "Validar IVA",
  VALIDATED: "Validado",
  VALIDATION_COMPLETE: "Validación completa",
  VALIDITY: "Validez",
  VALUE: "Valor",
  VALUE_HOUR: "Valor por hora",
  VALUES: "Valores",
  VARIABLE_SELCTION: "Selector de variable",
  VENTURE_CAPITAL_OR_VC: "Capital de riesgo / VC",
  VERSION: "Versión",
  VERTICAL: "Vertical",
  VERY_BAD: "Muy mal,",
  VERY_GOOD: "Muy bien,",
  VETERINARY: "Veterinario",
  VIDEOS: "Grabaciones",
  VIEW: "Ver",
  VIEW_ALL: "Ver todo",
  VIEW_LOGS: "Ver logs",
  VIEWS: "Puntos de vista",
  VISUALIZE: "Avance",
  WAITING_PAYMENT: "En espera de pagamento",
  WAREHOUSING: "Almacenamiento",
  WARRANTY: "Garantía",
  WAS_CHANGED_TO: "se ha cambiado a",
  WAS_UPDATED: "fue actualizado",
  WE_APPRICIATE_YOUR_FEEDBACK:
    "Gracias por ponerte en contacto con {CompanyName} recientemente. Siempre nos esforzamos por mejorar y nos encantaría recibir sus comentarios sobre la experiencia.",
  WE_APPRICIATE_YOUR_PATIANCE: "Agradecemos su paciencia y apoyo,",
  WEBHOOK: "Webhook",
  WEBSITE: "Sitio web",
  WEBSITE_NAME_ERROR:
    "El sitio web no tiene un formato válido (https://domain.com)",
  WEBSITE_SNIPPET: "Fragmento de sitio web",
  WEDNESDAY: "Miércoles",
  WEED_LOVE_FEEDBACK: "Nos encantaría recibir tus comentarios",
  WEEK: "Semana",
  WEEKDAY: "Día de la semana",
  WEEKLY: "Semanal",
  WELCOME_ALL_READY: "¡Todo bien! Ve al panel de control",
  WELCOME_CLIENTS_CONTACTS_SUBTITLE: "Descubra cómo configurar contactos",
  WELCOME_DEALS_CONTRACTS_SUBTITLE:
    "Descubra cómo configurar el módulo empresarial",
  WELCOME_FIRST_TITLE: "Almacene y use sus datos comerciales",
  WELCOME_SECOND_TITLE:
    "Actualizar y tener más control sobre el sector empresarial",
  WELCOME_SERVICES_SUBTITLE: "Descubra cómo configurar los servicios",
  WELCOME_THIRD_TITLE: "Empiece por utilizar una amplia gama de servicios",
  WELCOME_USERNAME: "Dar una buena acogida",
  WELCOME_VIEW_LINK: "Pagina de bienvenidas",
  WHAT_DATES_TO_EXCLUDE: "¿Qué fechas excluir?",
  WHAT_TO_BE_NOTIFIED: "Notificaciones",
  WHERE: "Dónde",
  WHOLESALE: "Venta al por mayor",
  WHOS_NEXT: "¡¿Quién es el siguiente?!",
  WIDGET: "Widget",
  WIDGET_ALLOWED_DOMAINS_MSG: "El widget solo se permitirá en estos dominios",
  WIDGET_BUTTON_CONFIGURATE: "Botón configurar",
  WIDGET_DOMAINS: "El widget solo se permitirá en estos dominios",
  WIDGET_FEEDBACK_TICKET_CONFIGURATE:
    "Configurar comentarios - asignación de casos",
  WIDGET_FORM_CONFIG: "Configurar sujetos - asignación de casos",
  WIDGET_FORM_CONFIG_ERROR: "Es necesario agregar una asignación de caso",
  WIDGET_TAB_ERROR:
    "Quedan campos para completar en las pestañas marcadas. Verifique que haya completado todos los campos marcados en todos los idiomas seleccionados",
  WIDGETS: "Widgets",
  WINE_OR_SPIRITS: "Vinos / Licores",
  WIRELESS: "Inalámbrico",
  WITH_THE_SUBJECT: "con el sujeto",
  WON: "Ganho",
  WORK_HOURS: "Horario de trabajo",
  WORKFLOW: "Workflow",
  WORKFLOW_CURRENTLY_RUNNING:
    "La automatización se está ejecutando actualmente",
  WORKING_IN: "Trabajando en",
  WORKSPACE: "Seleccionar un espacio de trabajo",
  WORKSPACE_NAME: "Nombre del espacio de trabajo",
  WORKSPACE_SUBDOMAIN: "Dominio del espacio de trabajo",
  WORKSPACE_SUBDOMAIN_DESC:
    "Puede personalizar el subdominio de su espacio de trabajo. Puede utilizar el nombre de su empresa, por ejemplo. Una vez elegido, no se puede cambiar.",
  WORKSPACE_SUBSCRIPTION: "Suscripción al espacio de trabajo",
  WORKSPACE_TEXT: "Espacio de trabajo",
  WRITE_A_NEW_MESSAGE: "Escribe un mensaje nuevo",
  WRITE_MESSAGE_OR_DROP: "Escriba un mensaje nuevo y / o suelte archivos aquí",
  WRITE_NOTE_OR_DROP: "Escriba una nota nueva y / o suelte archivos aquí",
  WRITE_YOUR_EMAIL: "Escribe un nuevo correo electrónico",
  WRITING_OR_EDITING: "Escritura / Edición",
  YEAR: "Año",
  YEARLY: "Anualmente",
  YES: "Si",
  YESTERDAY: "Ayer",
  YOUR_COMPANY_DATA: "Datos de su empresa",
  YOUR_FEEDBACK: "Déjanos un comentario o testimonio",
  YOUTUBE_URL: "URL de YouTube",
  ZIP_CODE: "Códigos postal",
  SUBSCRIPTION_UPDATED: "actualizó el suscripción",
  DEAL_CREATED: "creó el negocio",
  SUBSCRIPTION_CREATED: "creó el suscripción",
  ACCOUNT_CREATED: "creó el cuenta",
  CONTRACT_CREATED: "creó el contracto",
  CASE_CREATED: "creó el caso",
  TASK_CREATED: "creó el tarea",
  CALL_CREATED: "creó el llamada",
  PROJECT_CREATED: "creó el proyectos",
  NOTIFICATION_TOPIC_CASES_DELETED_TITLE: "Cuando se elimina un caso",
  NOTIFICATION_TOPIC_CASES_DELETED_DESCRIPTION:
    "Reciba una notificación cada vez que se elimine un caso",
  TIMES_REPORTS: "Informe de tiempo",
  RENOVATE: "Reanudar",
  ADD_TEMPLATE: "Guardar como plantilla",
  USE_TEMPLATE: "Usar plantilla",
  NO_TEMPLATES: "No hay plantillas disponibles",
  SAVE_FORM: "Guardar formulario como plantilla",
  CHOOSE_A_TEMPLATE: "Elige una plantilla",
  TEMPLATE_APPLIED: "Plantilla eligida",
  DATA: "Datos",
  FINANCIAL_DATA: "Datos financeiros",
  RENAME_VIEW: "Renombrar vista",
  WARN_UNSAVED: "Aún no ha guardado sus cambios. ¿Seguro que quieres salir?",
  EXPORTS_REPORTS_DESCRIPTION:
    "Ver cada exportación generada por cada colaborador.",
  GIVEN_HOURS: "Tiempo consumido ",
  SESSION_CLOSED_SUCCESS: "La sesione has terminado con éxito",
  THE_DEAL_STATUS: "El estado del negocio",
  THE_CONTRACT_STATUS: "El estado del contrato",
  THE_CALL_STATUS: "El estado del llamada",
  THE_SUBSCRIPTION_STATUS: "El estado del suscripción",
  TIMECHARGE_DELETED: "eliminó el registro de tiempo",
  TIMECHARGE_CREATED: "creó el registro de tiempo",
  TIMECHARGE_UPDATED: "actualizó el registro de tiempo",
  BOOLEAN: "Verdadero o falso",
  STRING: "Texto",
  NUMBER: "Numero",
  CONFIRM_UNSUB_TITLE:
    "¿Está seguro de que desea deshabilitar las notificaciones para este artículo?",
  CONFIRM_UNSUB_DESCRIPTION:
    "Si hace clic en 'Confirmar', perderá el acceso a las conversaciones y archivos asociados, pero aún tendrá acceso a los detalles.",
  UPDATE_USER_ERROR_COMPANY_MANAGER:
    "El usuario está administrando al menos una empresa eliminada. Cambie el gerente de la compañía antes de eliminar la empresa. ",
  UPDATE_USER_ERROR_TEAM_MANAGER:
    "El usuario está administrando al menos un equipo eliminado. Cambie el gerente del equipo antes de eliminar el equipo.",
  UPDATE_USER_ERROR_DEPARTMENT_MANAGER:
    "El usuario está administrando al menos un departamento eliminado. Cambie el gerente del departamento antes de eliminar el departamento.",
  TEST_JSON: "Testar JSON",
  EMAIL_CONVERSATION_CREATED:
    "Se creó una nueva conversación de email con el asunto ",
  EMAIL_CONVERSATION_UPDATE:
    "actualizó la conversación de email con el asunto ",
  WARNING: "Aviso",
  ITEM_DELETED_WARNING: "Este elemento fue eliminado.",
  SIDEBAR_CONFIRM_EXIT:
    "Los cambios realizados se perderán. ¿Está seguro de que quiere salir?",
  VIEW_GRID: "Vista tabla",
  VIEW_CALENDAR: "Vista calendario",
  VIEW_TIMELINE: "Vista timeline",
  VIEW_BOARD: "Vista kanban",
  VIEW_CARDS: "Vista tarjeta",
  SHARE_WITH: "Compartir con",
  SEND_NOTIFICATION_TO: "Enviar notificaciones a",
  CLIENT_NOT_FOUND_OR_INVALID: "La cuenta no es válida o no se encuentra",
  CLIENT_NOT_IN_SPACE: "Cuenta no encontrada",
  CLIENT_IS_DISABLED: "Cuenta deshabilitada",
  PROJECT_NOT_FOUND_OR_INVALID: "El proyecto no es válido o no se encuentra",
  PROJECT_NOT_IN_SPACE: "Proyecto no encontrado",
  PROJECT_MUST_BE_OPEN: "El proyecto debe estar abierto.",
  TICKET_NOT_FOUND_OR_INVALID: "El caso no es válido o no se encuentra",
  TICKET_NOT_IN_SPACE: "caso no encontrado",
  TICKET_MUST_BE_OPEN: "el caso debe estar abierto",
  CALL_NOT_FOUND_OR_INVALID: "La llamada no es válida o no se encuentra",
  CALL_NOT_IN_SPACE: "llamada no encontrada",
  CALL_MUST_BE_OPEN: "La llamada debe estar abierta.",
  TASK_NOT_FOUND_OR_INVALID: "La tarea no es válida o no se encuentra",
  TASK_NOT_IN_SPACE: "tarea no encontrada",
  TASK_MUST_BE_OPEN: "La tarea debe estar abierta.",
  CONTRACT_NOT_FOUND_OR_INVALID: "El contrato no es válido o no se encuentra",
  CONTRACT_NOT_IN_SPACE: "Contrato no encontrado",
  CONTRACT_MUST_BE_OPEN: "El contrato debe estar abierto.",
  DEAL_NOT_FOUND_OR_INVALID: "El negocio no es válido o no se encuentra",
  DEAL_NOT_IN_SPACE: "negocio no encontrado",
  DEAL_MUST_BE_OPEN_WON: "El negocio debe estar abierto.",
  ORIGIN_IS_REQUIRED: "El origen es obligatorio",
  CLIENT_INVALID: "La cuenta no es válida",
  DEAL_INVALID: "El negocio no es válido",
  PROJECT_INVALID: "El proyecto no es válido",
  TICKET_INVALID: "El caso no es válido",
  CALL_INVALID: "La llamada no es válida",
  TASK_INVALID: "La tarea no es válida",
  CONTRACT_INVALID: "El contrato no es válido",
  WORKSPACE_LABEL: "Workspace",
  ADD_ANOTHER_SCHEDULE: "Añadir otro fecha de publicación",
  WORKSPACES: "Espacios de trabajo",
  VIEW_ALL_ITEMS: "Ver todos",
  ERROR_GETTING_GLOBAL_PERMISSION: "Error al obtener los permisos básicos",
  DISK_LIMIT_INVALID: "Tamaño de partición no válido",
  ERROR_DELETED_ACCOUNT_UNIQUE_PERMISSION:
    "Error al eliminar los permisos de la cuenta",
  ERROR_UPDATING_ACCOUNT_UNIQUE_PERMISSION:
    "Error al actualizar los permisos de la cuenta",
  ERROR_GETTING_GLOBAL_CLIENTS: "Error al obtener cuentas de espacio",
  ERROR_DELETED_ALL_CLIENTS_IN_SPACE: "Error al eliminar cuentas de espacio",
  ERROR_UPDATING_ALL_CLIENTS_IN_SPACE: "Error al actualizar cuentas de espacio",
  ERROR_GETTING_GLOBAL_DEALS: "Error al obtener negocios desde el espacio",
  ERROR_DELETED_ALL_DEALS_IN_SPACE: "Error al eliminar ofertas del espacio",
  ERROR_UPDATING_ALL_DEALS_IN_SPACE: "Error al actualizar ofertas de espacio",
  INFO_NEW_VERTICAL_BAR_ALERT_FROM_HORIZONTAL:
    "¡Pssst, prueba el nuevo menú vertical aquí y no querrás nada más! 🤫",
  INFO_NEW_VERTICAL_BAR_ALERT_FROM_VERTICAL:
    "¿Has notado algo diferente? ¡Excelente! Mejoramos el menú lateral 🥳",
  DOCUMENT_TYPES: "Tipos de documento",
  EMPTY_DOCUMENT_TYPES: "No hay tipos de documentos asociados.",
  DOCUMENT_TYPE: "Tipo de Documento",
  ADD_DOCUMENT_TYPE: "Añadir tipo de documento",
  REVIEW: "En revisión",
  FAILURE: "Falla",
  PUBLIC_DOCUMENT_TITLE: "Documento publico",
  PUBLIC_DOCUMENT_DESCRIPTION:
    "Todos, en este espacio de trabajo, pueden ver este documento",
  SOCIAL_POST: "Publicación",
  FONT_STYLE: "Estilo de fuente",
  PAGE_MARGIN: "Márgenes de página",
  SMALL: "Pequeña",
  NORMAL: "Normal",
  LARGE: "Largo",
  TOPICS: "Temas",
  DATA_TYPE_TITLE: "Tipo de datos",
  DATA_TYPE_DESCRIPTION: "Elige uno de los siguientes",
  DATA_PARAMETER_TITLE: "Parámetros",
  DATA_PARAMETER_DESCRIPTION: "Elija qué parámetros desea importar",
  CHECK_ALL: "Tudo",
  DATA_FILTER_TITLE: "Filtros y clasificación",
  DATA_FILTER_DESCRIPTION: "Elige los filtros que quieres aplicar",
  IMPORT_DATA: "Datos de importacion",
  STATUS_REASON: "Motivo del estado",
  STATUS_REASON_DESCRIPTION: "Descripción del motivo del estado",
  ADD_FILTERS: "Agregar filtros",
  EVERY: "Cada",
  INDIVIDUAL: "Individual",
  LOST_CANCELLED_REASON: "Motivo de pérdida/cancelación",
  LOST_CANCELLED_REASON_DESCRIPTION: "Descripción de pérdida/cancelación",
  CANCELLED_REASON: "Motivo de cancelación",
  CANCELLED_REASON_DESCRIPTION: "Descripción de la cancelación",
  SPENT_TIME: "Tiempo consumido ",
  ADD_DOCUMENT: "Agregar documento",
  TIME_SPENT_BEFORE_CONTRACT: "Tiempo consumido antes del inicio del contrato",
  TIME_SPENT_AFTER_CONTRACT:
    "Tiempo consumido después de la finalización del contrato",
  TIME_SPENT_OUTSIDE_CONTRACT: "Tiempo consumido fuera del contrato",
  ADD_FILTERS_AND_SORTING: "Agregar filtros y clasificación",
  VIEW_APPLIED_OPTIONS: "Ver opciones aplicadas",
  ITEMS: "Itens",
  RENOVATED: "Renovado",
  PAGE_BACKGROUND: "Fondo",
  EMAIL_CONVERSATION_NEW_EMAIL:
    "La conversación de correo electrónico con el asunto {EmailSub} ha recibido un nuevo correo electrónico.",
  CREATE_DOCUMENT: "Crear un documento",
  MISSING_RECORDS: "Registros de tiempo que faltan",
  DOCUMENT: "Documento",
  LAST_UPDATE_DATE: "Fecha de la última actualización",
  LAST_UPDATE_BY: "Actualizado por",
  CREATE_DOCUMENT_TEMPLATE: "Crear una plantilla de documento",
  ADD_DOC_TEMPLATE: "Añadir plantilla de documento",
  API_TYPE_INVALID: "El tipo de artículo no es válido",
  API_NAME_REQUIRED: "El nombre es obligatorio",
  API_ASSOCIATE_TO_INVALID: "Afiliación inválida",
  API_ORGANIZATION_INVALID: "La organización no es válida",
  API_PIPELINE_INVALID: "La tubería no es válida",
  API_PIPELINESTATUS_INVALID: "El estado de la tubería no es válido",
  API_VARIABLE_INVALID: "La variable no es válida",
  API_ASSIGNED_TO_INVALID: "Asignado a no es válido",
  API_INVALID_REQUEST_OR_REQUEST_LOCKED:
    "La solicitud no es válida o otro usuario está editando en ese momento",
  API_NOT_FOUND_OR_NO_PERMISSIONS: "El artículo no existe o no tiene permiso",
  API_NOT_FOUND_OR_NO_PERMISSIONS_DELETE:
    "El elemento no existe o no tiene permiso para borrarlo",
  API_FAILED_GETTING_TEMPLATE: "No se puede encontrar la plantilla dada",
  VARIABLES: "Variables",
  EMPTY: "Vacío",
  VARIABLES_DESCRIPTION: "Descripción de la variable",
  DOCUMENT_SAVE_SUCCESS: "Documento guardado con éxito",
  TEMPLATE_SAVE_SUCCESS: "Plantilla de documento guardada con éxito",
  EMAIL_NO_SUBJECT: "Sin tema",
  API_EMAIL_SUBJECT_INVALID: "El tema es obligatorio",
  CUSTOM_TAGS: "Variables personalizadas",
  SUBSCRIPTION_IS: "La suscripción es",
  LAST_CHANGE_DATE: "Fecha del último cambio",
  DOCUMENT_TYPE_EQUALS: "El tipo de documento es el mismo",
  DOC_TEMPLATE: "Plantilla de documento",
  DOC_TEMPLATE_NR: "Número de plantilla del documento",
  NO_DOC_TEMPLATES_LIST:
    "No hay registros de plantillas de documentos asociados.",
  EMPTY_DOC_TEMPLATES_LIST: "No hay modelos de documentos",
  BI_NUMBER: "Número de la tarjeta ciudadana",
  BI_LOCALE: "Lugar de emisión",
  BI_DATE: "Fecha de emisión",
  BI_EXPIRATION: "Fecha de caducidad",
  NISS: "NISS",
  EMPTY_CATEGORIES_LIST: "No hay categorías de usuarios",
  NO_CATEGORIES_LIST: "No hay registros de categorías de usuarios asociados.",
  EMPTY_INVITES_LIST: "No hay invitaciones de usuarios",
  NO_INVITES_LIST: "No hay registros de invitación de usuarios asociados.",
  CATEGORIES: "Categorías",
  DEFAULT_HOUR_COST: "Coste normal por hora",
  ADD_CATEGORY: "Crear Categoría",
  CODEERP: "Codígo ERP",
  API_NIF_INVALID: "Nif Inválido",
  API_NISS_INVALID: "NISS Inválido",
  API_COUNTRY_INVALID: "País Invalido",
  PRINT: "Imprimir",
  API_CATEGORY_INVALID: "Categoría inválida",
  API_UPDATE_USER_ERROR_COMPANY_MANAGER:
    "Eliminar el usuario de los gestores de la empresa primero",
  API_UPDATE_USER_ERROR_DEPARTMENT_MANAGER:
    "Eliminar el usuario de los gerentes de departamento primero",
  API_UPDATE_USER_ERROR_TEAM_MANAGER:
    "Eliminar el usuario de los gerentes de equipo primero",
  API_ORGANIZATION_DEPARMENT_TEAM_INVALID:
    "Empresa/Departamento/Equipo Inválido",
  API_EXPIRATIONDATE_INVALID:
    "Los datos de caducidad del documento no son válidos",
  API_ISSUEDATE_INVALID: "Los datos de emisión del documento no son válidos",
  API_SOCIALSECURITY_TOLARGE: "NISS Inválido - Demasiado grande",
  API_NIF_TOLARGE: "NIF Inválido - Demasiado grande",
  API_BILOCALE_TOLARGE: "Lugar de emisión BI - Demasiado grande",
  API_BINUMBER_TOLARGE: "Número de tarjeta BI - Demasiado grande",
  API_CODE_TOLARGE: "Código ERP - Demasiado grande",
  API_INVALID_REQUEST: "Solicitud inválida",
  API_EMAIL_REQUIRED: "Se requiere el correo electrónico",
  API_NAME_MAX_50: "Nombre de usuario demasiado grande",
  API_EMAIL_INVALID: "Email inválido",
  API_USER_ALREADY_IN_SPACE: "El usuario ya existe en el espacio",
  API_INVALID_INVITE_HAS_BLOCKED_USER: "El usuario está bloqueado",
  API_DEPARMENT_INVALID: "Departamento inválido",
  API_TEAM_INVALID: "Equipo inválido",
  API_SPACE_INVALID_OR_DELETED: "Espacio inválido o eliminado",
  API_INVITATION_FULL: "La lista de invitados está llena",
  API_ERROR_SEND_EMAIL:
    "Se ha producido un error al enviar el correo electrónico",
  API_INVITATION_NOT_FOUND: "Invitación no encontrada o borrada",
  EMPLOYEE_PERSONAL_INFORMATION: "Información personal del empleado",
  SOCIAL_SECURITY: "Número de la Seguridad Social",
  API_STANDARDCOST_TOLARGE: "Coste estándar - Demasiado grande",
  API_ORGANIZATION_REQUIRED: "Se necesita una empresa",
  PRIVATE_DATA: "Datos privados",
  API_INVITATION_INVALID: "Invitación inválida",
  JOINED_THE_SPACE: "Se incorporó con éxito al espacio ",
  CITIZEN_CARD: "Tarjeta de ciudadano",
  PASSPORT: "Pasaporte",
  DOCUMENT_STYLE: "Estilos",
  BIRTHDAY_FROM: "De",
  BIRTHDAY_TO: "a",
  EDITOR_IS_CLOSING_WARNING:
    "No detectamos cambios en este documento en los últimos 10 minutos. El documento se cerrará.",
  EDITOR_REFUSE_CLOSE: "Seguir editando",
  DOCUMENT_VERSIONS: "Historial de versiones",
  CONFIRM_RESTORE_VERSION_QUESTION:
    "Su documento actual será revertido a {Date}",
  RESTORE: "Restaurar",
  RESTORE_THIS_VERSION: "Restaurar esta versión",
  ORIGIN_DATA: "Datos de origen",
  API_POSTALWEBHOOK_NOT_FOUND:
    "No se ha encontrado el registro de Webhooks postales",
  API_INVALID_POSTAL_MODEL: "Modelo postal inválido",
  API_POSTALWEBHOOK_CAN_NOT_RETRY: "No se puede recuperar",
  IS_EDITING: "{Name} está editando",
  API_INVALID_REQUEST_YOUR_SESSION_EXPIRED: "Su sesión de edición ha expirado",
  COPY_URL: "Copiar URL",
  API_NOT_FOUND_OR_NO_PERMISSION: "No se encuentra o requiere permiso",
  DOCUMENT_IS_BEING_EDITED: "Este documento ya se está editando",
  SELECT_DOC_TEMPLATE: "Seleccione la plantilla del documento",
  NUMBER_TEMPLATES: "{num} templates",
  BLANK_DOCUMENT: "Documento en blanco",
  CREATED_SUCCEFULLY: "Creado con éxito",
  NO_DOCUMENTS_LIST: "Lista de documentos vacíos",
  EMPTY_DOCUMENTS_LIST: "No hay registros de documentos asociados",
  KNOWLEDGE_BASE: "Base de conocimientos",
  BILLABLE: "Contable",
  ACTIVE_CONTRACTS: "Contratos activos",
  CONCLUDED_CONTRACTS: "Contratos concluidos",
  ACTIVE_CONTRACTS_PROGRESS: "Evolución Contratos activos",
  TEMPLATE_CONFIRM_MESSAGE:
    "Los cambios realizados serán reemplazados. ¿Estás seguro de que quieres aplicar esta plantilla?",
  THIS_MONTH: "Este mes",
  ALLOW_FUTURE: "Permitir fechas futuras",
  TASK_TYPE: "Tipo de tarea",
  TASK_TYPES: "Tipos de tareas",
  ADD_TASK_TYPE: "Añadir tipo de tarea",
  EDIT_TASK_TYPE: "Editar tipo de tarea",
  TASK_TYPES_LIST_EMPTY: "Listado de tipo de tarea vacío.",
  TASK_TYPES_LIST_EMPTY_DESCRIPTION:
    "No hay registros de tipos de tareas asociados.",
  ELIMINATE_DOCUMENT_TYPE: "¿Desea eliminar este tipo de documento?",
  API_FAILED_LIST: "Se ha producido un error al recuperar la lista solicitada",
  API_REPEATED_RECORDS: "Error - Registros repetidos",
  API_INVALID_TYPE: "Error - Tipo inválido",
  API_FAILED_GETTING_DETAILS: "Se ha producido un error al obtener los datos",
  API_INVALID_COMPANY: "Error - Empresa no válida",
  INVALID_DEPARTMENT: "Error - Departamento inválido",
  API_INVALID_TEAM: "Error - Equipo inválido",
  API_DOCUMENT_TYPE_NOT_FOUND: "No se ha encontrado este tipo de documento",
  API_STATUS_INVALID: "Error - Estado inválido",
  API_COLLABORATOR_INVALID: "Error - Colaborador no válido",
  API_FOLLOWERS_INVALID: "Error - Seguidor inválido",
  API_TEMPLATE_TYPE_INVALID: "Error - Tipo de plantilla no válida",
  API_NAME_TOLARGE: "Nombre demasiado grande",
  API_DESCRIPTION_TOLARGE: "Descripción demasiado grande",
  API_CLIENT_INVALID: "Error - Cliente no válido",
  API_DEAL_INVALID: "Error - Acuerdo inválido",
  API_CONTRACT_INVALID: "Error - Contrato inválido",
  API_SUBSCRIPTION_INVALID: "Error - Suscripción no válida",
  API_TICKET_INVALID: "Error - Caso inválido",
  API_PROJECT_INVALID: "Error - Proyecto inválido",
  API_TASK_INVALID: "Error - Tarea no válida",
  API_TIMECHARGE_INVALID: "Error - Intervención no válida",
  API_INVALID_ASSOCIATION: "Servicio asociado no válido",
  API_FAIL_UPLOAD_FILES:
    "Se ha producido un error al cargar uno o varios archivos",
  API_DOCUMENT_TYPE_REQUIRED: "Falta el tipo de documento",
  TASK_TYPE_EQUALS: "El tipo de tarea es igual a",
  API_CAN_NOT_REMOVE_STATUS:
    "No es posible eliminar un estado de la tubería que ya está en uso",
  API_CAN_NOT_CHANGE_STATUS:
    "No es posible cambiar el estado de una tubería que ya está en uso",
  API_INVALID_PIPELINE_STATUS: "Error - Cambio de estado no válido",
  API_PIPELINE_STATUS_INVALID: "Error - Estado de la tubería inválido",
  PIPELINE_STATUS_CHANGE_DATE:
    "Fecha en que cambió el estado de la canalización",
  STATUS_CHANGE_DATE: "Fecha en que cambió el estado",
  LAST_PIPELINE_STATUS_ACCOUNT: "Quién cambió el último estado de canalización",
  LAST_PIPELINE_STATUS_DATE:
    "Fecha en que el estado de la canalización cambió por última vez",
  LAST_STATUS_ACCOUNT: "Quién hizo el último cambio de estado",
  LAST_STATUS_DATE: "Fecha en que el estado cambió por última vez",
  FILTER_NOT_NEXT_X_DAYS_PREVIEW: "No es en próximos {days} días de {field}",
  FILTER_NOT_LAST_X_DAYS_PREVIEW: "No es en últimos {days} días de {field}",
  FILTER_NOT_NEXT_DAYS: "no en los próximos días",
  FILTER_NOT_LAST_DAYS: "no en los últimos días",
  FILTER_LAST_DAYS: "en los últimos días",
  FILTER_NEXT_DAYS: "en los próximos días",
  API_TIMECHARGE_TYPE_INVALID: "Error - Tipo de tiempo no válido",
  DENIED_WARNING_NOTIFICATIONS:
    "Hemos detectado que su navegador está bloqueando las notificaciones.",
  WARNING_SEND_MAIL_MESSAGE:
    "Este informe solo se enviará si el contrato tiene un contacto activo con un correo electrónico asociado.",
  API_INVALID_EXPORT_TYPE: "Debe seleccionar un tipo de exportación.",
  API_CONTRACT_NOT_FOUND: "Contrato no encontrado",
  API_CONTRACT_HISTORY_ERROR:
    "Se ha producido un error al obtener la información para el informe",
  API_CONTRACT_CLIENT_ERROR:
    "No se puede encontrar el cliente relacionado con este contrato",
  API_ASSOCIATED_COMPANY: "Imposible encontrar una empresa asociada",
  API_ERROR_READ_PDF_CONTENT:
    "Error al adjuntar un PDF a un correo electrónico",
  API_ERROR_SENT_EMAIL:
    "Se ha producido un error al enviar el correo electrónico.",
  API_CONTACT_ASSOCIATED_WITH_EMAIL:
    "No hay ningún contacto asociado al correo electrónico",
  API_CONTACT_ASSOCIATED_INVALID: "Contacto de afiliado no válido",
  API_PIPELINE_TYPE_INVALID: "El tipo de pipeline no es válido",
  API_EXIST_INVALID_TICKETS: "Hay uno o más casos no válidos",
  API_INVALID_REQUEST_MAX_25: "No se pueden exportar más de 25 elementos",
  API_INVALID_DATA_REPORT: "Error al obtener los datos para el informe",
  API_CREATE_PDF_REPORT:
    "Se ha producido un error al crear el archivo adjunto para el correo electrónico",
  COST_CENTER: "Centro de costes",
  EMPTY_COST_CENTER_LIST: "No hay registros de centros de costes.",
  NO_COST_CENTER_LIST: "Ningún centro de costes disponible",
  ADD_COST_CENTER: "Añadir centro de coste",
  COST_CENTERS: "Centros de costes",
  PRODUCTIVITY_REPORT: "Informe de productividad",
  PRODUCTIVITY: "Productividad",
  PRODUCTIVITY_DESCRIPTION:
    "Consulte los totales de tiempo clasificados por sus empleados agrupados por centros de coste.",
  PROD_AVERAGE: "Media",
  API_INVALID_ACCOUNT: "Hay uno o más usuarios no válidos",
  API_COST_CENTER_REQUIRED: "Centro de costes obligatorio",
  API_NOT_FOUND_OR_NO_PERMISSIONS_EXPORT:
    "Contenido no encontrado o permiso de exportación no concedido",
  PRODUCTION_TOTAL_TIME: "Total Tiempos",
  PRODUCTION_AVERAGE_TIMES: "Media Tiempos",
  PRODUCTION_TOTAL_TRAVEL: "Total viajes",
  PRODUCTION_AVERAGE_TRAVEL: "Media de viajes",
  PRODUCTION_AVERAGE_FINAL: "Media final",
  PRODUCTION_TOTAL_TIME_ABV: "Total T.",
  PRODUCTION_AVERAGE_TIMES_ABV: "Média T.",
  PRODUCTION_TOTAL_TRAVEL_ABV: "Total V.",
  PRODUCTION_AVERAGE_TRAVEL_ABV: "Média V.",
  PRODUCTION_AVERAGE_FINAL_ABV: "Media F.",
  TRAVEL_TIMES: "Registros de viajes",
  TRAVEL_TIMES_DESCRIPTION:
    "Ver todas las entradas de tiempo que contienen viajes.",
  DESLOCATION_CONTRACT: "Incluye viajes",
  NEW_ITEMS_ADDDED_OR_UPDATED: "Se han añadido o editado nuevos elementos",
  DESCRIPTION_TOO_BIG:
    "Contenido del campo de descripción demasiado grande. Por favor, reduzca el tamaño del texto/imagen. Si tiene imágenes, adjúntelas como archivos adjuntos.",
  INTERNAL_DESCRIPTION_TOO_BIG:
    "Contenido del campo de descripción interno demasiado grande. Por favor, reduzca el tamaño del texto/imagen. Si tiene imágenes, adjúntelas.",
  TICKET_LIMIT: "Límite del caso",
  TICKETS_ASSIGNED: "Casos asociados",
  SUPPORT_EMAIL: "Email de Suporte",
  CONTINUE_TO_WEBSITE: "Continuar en la página",
  ANY_SITUATION: "Cualquier situación",
  INBOX_EMAIL_SENT_STATUS_DELAYED:
    "La entrega del correo electrónico se ha retrasado.",
  API_INVALID_TAGS: "Existen uno o más marcadores no válidos",
  API_REPEATED_TAG: "Ya existe un marcador con ese nombre",
  API_EMAIL_SUPPORT_EMAIL_INVALID:
    "No se puede enviar correo electrónico al correo electrónico de soporte",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_TO:
    "El correo electrónico de soporte no está permitido en el campo Para",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_CC:
    "El correo electrónico de soporte no está permitido en el campo CC",
  API_EMAIL_SUPPORT_EMAIL_INVALID_FIELD_BCC:
    "El correo electrónico de soporte no está permitido en el campo BCC",
  CASE_TAGS: "Etiquetas del caso",
  API_FAILED_CONNECTION: "No se pudo conectar al servicio",
  API_FAILED_CONNECTION_TO_EXPORT_PDF:
    "No se pudo conectar al servicio de exportación de PDF. Inténtalo de nuevo más tarde.",
  TAGS: "Etiquetas",
  TAG: "Etiqueta",
  CONVERSATION_REPLIED:
    "respondió en la conversación por correo electrónico con el asunto ",
  ADD_CASE_TAG: "Añadir etiqueta de caso",
  EDIT_CASE_TAG: "Editar etiqueta de caso",
  CASE_TAG: "Etiqueta de caso",
  EMAIL_CONVERSATION: "Conversa de email",
  CASE_CONVERSATIONS: "Conversas do Caso",
  CONVERSATION_NOTE_CREATED:
    "Añadio una nota en la conversación por correo electrónico con el asunto",
  NOTIFICATION_TOPIC_INBOX_NEW_INTERNAL_NOTE_TITLE:
    "Cuando se añade una nueva nota interna a la conversación por correo electrónico",
  NOTIFICATION_TOPIC_INBOX_NEW_INTERNAL_NOTE_DESCRIPTION:
    "Recibir una notificación cada vez que se asocie un nuevo mensaje interno a la conversación",
  THE_EMAIL_CONVERSATION_STATUS:
    "El estado de la conversación por correo electrónico",
  CASE_TAGS_LIST_EMPTY: "Listado de etiqueta de caso vacío.",
  CASE_TAGS_LIST_NO: "No hay registros de etioquetas de caso asociados.",
  API_FAILED_GENERATING_PDF:
    "Se ha producido un error al generar el PDF. Vuelva a intentarlo más tarde.",
  EMAIL_CONVERSATION_EXPORT: "Exportar em pdf conversa de email",
  GET_EXPORT_FILE_BY_ID: "Obtener archivo de exportación por Id",
  COMPLEMENTED_TIME: "Tiempo complementario",
  BUDGET_TIME: "Tiempo presupuestado",
  NOTE_CREATED_SUCCEFULLY: "Nota creada correctamente",
  API_ERROR_DUPLICATE_INBOUND_EMAIL:
    "Ya existe una bandeja de entrada de correo electrónico con este alias para esta empresa",
  PERSONAL_PRODUCTIVITY_DESCRIPTION:
    "Comprueba tus tiempos totales clasificados.",
  EMAIL_SIZE_TOO_BIG:
    "El tamaño de su correo electrónico es demasiado grande. Por favor, reduzca el tamaño del texto/imágenes/anexos",
  EXTERNAL_USER: "Usuario externo",
  API_TAG_INVALID_DISABLED_INBOUND_EMAIL:
    "No se puede desactivar una etiqueta que está asociada a un correo electrónico de ticket",
  PLAN_PUBLIC_API: "API Pública",
  API_DATE_INVALID_INTERVAL: "Las fechas están en el rango incorrecto",
  API_INVALID_MODEL: "Modelo inválido",
  API_ERROR_NO_DATA: "Procesamiento de datos fallido",
  API_FAILED_GENERATING_EXCEL: "No se pudo generar excel",
  HTTP_STATUS_REQUEST: "Solicitud estado Http",
  NOTIFICATION_TOPIC_INBOX_CONVERSATION_PARTICIPATION_TITLE:
    "Cuando se envía o recibe un correo electrónico en una conversación por correo electrónico en la que has participado anteriormente.",
  NOTIFICATION_TOPIC_INBOX_CONVERSATION_PARTICIPATION_DESCRIPTION:
    "Recibe una notificación cada vez que un correo electrónico o una nota interna se asocia con una conversación por correo electrónico en la que ha participado.",
  EMAIL_CONVERSATION_EMAIL_PERSONAL_RESPONSE:
    "Recibir notificación de respuesta",
  NOTIFICATION_EMAIL_CONVERSATION_PARTICIPATION:
    "Se actualizó la conversación por correo electrónico, en la que usted <b>participó</b>, con el asunto <b>{subject}</b>.",
  NOTIFICATION_TOPIC_INBOX_NEW_EMAIL_FROM_OUT_TITLE:
    "Cuando se recibe un correo electrónico asociado a una conversación por correo electrónico",
  NOTIFICATION_TOPIC_INBOX_NEW_EMAIL_FROM_OUT_DESCRIPTION:
    "Recibe una notificación cada vez que se reciba un correo electrónico asociado a una conversación de correo electrónico.",
  NOTIFICATION_TOPIC_INBOX_MODIFIED_TITLE:
    "Cuando se modifica una conversación por correo electrónico",
  NOTIFICATION_TOPIC_INBOX_MODIFIED_DESCRIPTION:
    "Recibe una notificación cada vez que se modifique una conversación de correo electrónico.",
  NOTIFICATION_EMAIL_CONVERSATION_PARTICIPATION_FROM_OUTSIDE:
    "La conversación de correo electrónico, en la que usted <b>participó</b>, con el asunto <b>{subject}</b> ha recibido un nuevo correo electrónico.",
  CONVERSATION_REPLIED_PARTICIPATION:
    "respondió en la conversación por correo electrónico, en la que usted <b>participó</b>, con el asunto <b>{subject}</b> ",
  API_ERROR_EMAIL_REFUSED:
    "El correo electrónico se ha enviado correctamente, pero el servidor de destino lo ha rechazado.",
  API_CLIENT_DELETED: "Cliente eliminado",
  API_ATTACHMENTS_TOLARGE:
    "El tamaño del archivo adjunto supera el límite permitido",
  DEAL_TAGS_LIST_EMPTY: "Listado de etiqueta de negocio vacío.",
  DEAL_TAGS_LIST_NO: "No hay registros de etioquetas de negocio asociados.",
  DEAL_TAG: "Etiqueta de negocio",
  ADD_DEAL_TAG: "Añadir etiqueta de negocio",
  EDIT_DEAL_TAG: "Editar etiqueta de negocio",
  ACCOUNT_TAGS_LIST_EMPTY: "Listados de etiquetas de cuenta vacíos.",
  ACCOUNT_TAGS_LIST_NO: "No hay registros de etiquetas de cuenta asociados.",
  ACCOUNT_TAG: "Etiqueta de cuenta",
  ADD_ACCOUNT_TAG: "Añadir etiqueta de cuenta",
  EDIT_ACCOUNT_TAG: "Editar etiqueta de cuenta",
  DEAL_TAGS: "Etiquetas de negocio",
  ACCOUNT_TAGS: "Etiquetas de cuenta",
  INVALID_TIME_VALUE_FOR_RENOVATION:
    "Valor no válido para el valor temporal de la renovación del contrato",
  INVALID_TIME_VALUE_FOR_HOUR_CONTROL:
    "Valor no válido para el valor temporal de la renovación del intervalo de horas",
  API_CLIENT_REQUIRED: "Cuenta necessaria",
  CONTRACT_TAG: "Marcador de contrato",
  ADD_CONTRACT_TAG: "Añadir marcador de contrato",
  EDIT_CONTRACT_TAG: "Editar marcador de contrato",
  CONTRACT_TAGS: "Marcador de contrato",
  CONTRACT_TAGS_LIST_EMPTY: "Lista vacía de marcadores de contrato.",
  CONTRACT_TAGS_LIST_NO:
    "No hay registros de marcadores de contrato asociados.",
  SUBSCRIPTION_TAGS: "Marcador de suscripción",
  SUBSCRIPTION_TAG: "Marcador de suscripción",
  ADD_SUBSCRIPTION_TAGS: "Añadir marcador de suscripción",
  EDIT_SUBSCRIPTION_TAGS: "Editar marcador de suscripción",
  SUBSCRIPTION_TAGS_LIST_EMPTY: "Lista de marcadores de suscripción vacía.",
  SUBSCRIPTION_TAGS_LIST_NO:
    "No hay registros de marcadores de suscripción asociados.",
  CALL_TAGS: "Marcadores de llamada",
  CALL_TAG: "Marcador de llamada",
  ADD_CALL_TAG: "Añadir marcador de llamada",
  EDIT_CALL_TAG: "Editar marcador de llamada",
  CALL_TAGS_LIST_EMPTY: "Lista de marcadores de llamadas vacía.",
  CALL_TAGS_LIST_NO: "No hay registros de marcador de llamadas asociados.",
  PROJECT_TAGS: "Marcadores de proyecto",
  PROJECT_TAG: "Marcador de proyecto",
  ADD_PROJECT_TAG: "Añadir marcador de proyecto",
  EDIT_PROJECT_TAG: "Editar marcador de proyecto",
  PROJECT_TAGS_LIST_EMPTY: "Listado de marcadores de proyecto vacío.",
  PROJECT_TAGS_LIST_NO: "No hay registros de marcadores de proyecto asociados.",
  TASK_TAGS: "Marcadores de tarea",
  TASK_TAG: "Etiqueta de tarea",
  ADD_TASK_TAG: "Añadir marcador de tarea",
  EDIT_TASK_TAG: "Editar marcador de tarea",
  TASK_TAGS_LIST_EMPTY: "Lista vacía de marcadores de tarea.",
  TASK_TAGS_LIST_NO: "No hay registros de marcadores de tareas asociados.",
  API_TICKET_MANAGER_INVALID:
    "El usuario 'Asignado a' no pertenece al 'Departamento/Equipo' indicado.",
  CANCELLED_CASES: "Casos cancelados",
  CREATE_CONVERSATION: "Crear conversación por correo electrónico",
  SEND_NOTIFICATION_WARNING:
    "¿Tiene intención de notificar al Cliente el estado de este caso?",
  SEND_DISABLED_EMAIL_MESSAGE: "Correo electrónico de airbot inactivo",
  API_TICKET_EMAIL_REPORT_SENT_SUCCESS:
    "Informe de estado enviado por correo electrónico con éxito",
  API_TICKET_EMAIL_REPORT_SENT_ERROR:
    "Se ha producido un error al enviar el informe de estado por correo electrónico",
  API_TICKET_EMAIL_REPORT_STARTED:
    "La solicitud se ha iniciado correctamente. Se le notificará cuando haya finalizado.",
  API_COMPANY_NOT_FOUND: "Empresa no encontrada.",
  API_DEPARTMENT_NOT_FOUND: "Departamento no encontrado",
  HAS_SUPPORT_EMAIL: "Tiene email de soporte",
  DOES_NOT_HAVE_SUPPORT_EMAIL: "No tiene email de soporte",
  API_STATUS_NAME_REQUIRED: "El nombre del estado es obligatorio.",
  API_STATUS_REQUIRED: "Es obligatorio introducir un estado.",
  API_PIPELINE_NAME_REQUIRED:
    "Introducir el nombre del Pipeline es obligatorio.",
  API_FEEDBACK_STATUS_INVALID:
    "Estado no válido para activar la retroalimentación.",
  API_EMAIL_TO_LARGE: "El contenido del email excedió el límite",
  API_STATUS_DETAIL_INVALID: "Motivo de anulación no válido",
  API_STATUS_DETAIL_DESCRIPTION_REQUIRED:
    "Descripción del motivo de anulación que falta",
  CHANGE_TAGS: "Cambiar Marcadores",
  REMOVE_TAGS: "Eliminar marcadores",
  CLIENTS_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  DEALS_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  CONTRACTS_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  SUBSCRIPTIONS_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  CALL_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  TICKETS_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  PROJECTS_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  TASKS_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  TIMES_NO_PERMISSION:
    "no se han modificado por no disponer del permiso necesario",
  API_PIPELINE_DISABLED: "Pipeline desativado",
  REPORT_EMAIL: "Informe de correo electrónico",
  CONTRACT_SIGNED: "Contrato firmado",
  SIGNED: "Firmado",
  IS_LOCKED: "Está bloqueado",
  FAILED_PASSWORD_ATTEMPTS: "Intentos fallidos de contraseña",
  UNLOCK: "Desbloquear",
  NOTIFICATION_SUCCESS: "Notificación enviada correctamente",
  ASK_NOTIFICATION_CASE: "¿Desea notificar este caso a la persona de contacto?",
  API_PERIOD_NO_CONTENT:
    "No hay información de marcador para el periodo seleccionado",
  SEND_CONTRACT_RENOVATION_WARNING:
    "¿Desea notificar al Cliente por correo electrónico la renovación de este contrato?",
  SEND_CONTRACT_RENOVATION_BUTTON: "Enviar aviso de renovación",
  API_CONTRACT_RENOVATION_ALERT_EMAIL_SENT_ERROR:
    "Se ha producido un error al enviar el correo electrónico de renovación de este contrato",
  API_CONTRACT_NEED_EMAIL_CONTACT:
    "El contrato debe tener una dirección de correo electrónico de contacto válida.",
  API_CONTRACT_RENOVATION_ALERT_EMAIL_SENT_SUCCESS:
    "Aviso de renovación de contrato enviado correctamente",
  EMAIL_SENT: "Email enviado",
  API_CONTRACT_NEED_OPV: "El contrato debe tener una OPV",
  BILLING_CLIENT: "Cliente de facturación",
  FINAL_CLIENT: "Cliente final",
  FINAL_CLIENT_INVALID: "Cliente final no válido",
  BILLING_CLIENT_INVALID: "El cliente de la factura no es válido",
  CLIENT_CODE_ERP_INVALID: "El ERP de lo cliente no es válido",
  DEAL_SETTINGS_NOT_FOUND: "Configurações do Negócio não foram encontradas",
  CONTRACT_SETTINGS_NOT_FOUND:
    "Configurações do contrato não foram encontradas",
  SUBSCRIPTION_SETTINGS_NOT_FOUND:
    "Configurações da subscrição não foram encontradas",
  CLIENT_ERPCODE_INVALID: "El código de cliente ERP ya existe",
  CONTRACT_ONLY_ONE_ORIGIN: "Los contratos sólo pueden tener 1 origen",
  INVALID_CREATION_STATUS:
    "El estado seleccionado no es válido para la creación",
  DOCUMENT_NUMBER: "Número de documento",
  API_DOCUMENTTYPE_REQUIRED: "El tipo de documento es obligatorio",
  VIEW_SAVING_ERROR: "Se ha producido un error al guardar la vista",
  API_INVALID_INTERVENTIONTYPE: "El tipo de tienpo no es válido"
};
