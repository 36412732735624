import { AccountSpace, Account } from "../Interfaces/EntityInterfaces";

export const defaultModules = {
  admin: false,
  adminEditBilling: false,
  adminEditCompanies: false,
  adminEditDepartments: false,
  adminEditReports: false,
  adminEditTeams: false,
  adminEditUsers: false,
  billing: false,
  calls: false,
  clients: false,
  configuration: false,
  contacts: false,
  contracts: false,
  helpdesk: false,
  interventions: false,
  owner: false,
  projects: false,
  tasks: false,
  tickets: false
};

export enum modulePermissions {
  Default = 0,
  ReadAll = 1,
  EditAll = 2,
  DeleteAll = 4,
  Import = 8,
  Export = 16,
  PrivateReadOnly = 32,
  PrivateEditOnly = 64,
  PrivateDeleteOnly = 128,
  Access = 256,
  TeamReadOnly = 512,
  TeamEditOnly = 1024,
  TeamDeleteOnly = 2048,
  DepartementReadOnly = 4096,
  DepartementEditOnly = 8192,
  DepartementDeleteOnly = 16384,
  OrganizationReadOnly = 32768,
  OrganizationEditOnly = 65536,
  OrganizationDeleteOnly = 131072
}

export enum moduleTypes {
  ticketType = "ticketType",
  userControl = "userControl",
  travelTimes = "travelTimes",
  SettingsDocumentType = "settingsDocumentType",
  spaceList = "spaceList",
  automationActivity = "automationActivity",
  receptionMessages = "receptionMessages",
  subscriptionCanceled = "subscriptionCanceled",
  subscriptionTrialEnd = "subscriptionTrialEnd",
  widgets = "widgets",
  cupons = "cupons",
  documents = "documents",
  docTemplates = "templates",
  imports = "imports",
  settingsSocial = "settingsSocial",
  socialPost = "socialPost",
  social = "social",
  checklistTemplate = "checklistTemplate",
  ineficientType = "ineficientType",
  subscriptions = "subscriptions",
  subscriptionTypes = "subscriptionTypes",
  emailDomains = "emailDomains",
  apiKeySettings = "apiKeySettings",
  settingsClient = "settingsClient",
  calls = "calls",
  tickets = "tickets",
  projects = "projects",
  tasks = "tasks",
  interventions = "interventions",
  classification = "classification",
  billing = "billing",
  deals = "deals",
  clients = "clients",
  contacts = "contacts",
  contracts = "contracts",
  organizations = "organizations",
  configurations = "configurations",
  departments = "departments",
  teams = "teams",
  users = "users",
  contractTypes = "contractTypes",
  interventionTypes = "interventionTypes",
  projectTypes = "projectTypes",
  cancelationReasons = "cancelationReasons",
  lostReasons = "lostReasons",
  settingsUsers = "settingsUsers",
  settingsTeams = "settingsTeams",
  settingsDepartments = "settingsDepartments",
  settingsOrganizations = "settingsOrganizations",
  settingsDeals = "settingsDeals",
  settingsCalls = "settingsCalls",
  settingsTickets = "settingsTickets",
  settingsWidget = "settingsWidget",
  settingsSpace = "settingsSpace",
  settingsTasks = "settingsTasks",
  settingsSubscriptions = "settingsSubscriptions",
  settingsProjectTasks = "settingsProjectTasks",
  settingsProjects = "settingsProjects",
  settingsTaskTypes = "settingsTaskTypes",
  settingsContracts = "settingsContracts",
  SettingsTimeCharge = "settingsInterventions",
  settingsInboundEmails = "settingsInboundEmails",
  settingsDomains = "settingsDomains",
  settingsDocuments = "settingsDocuments",
  reportsInterventions = "reportsInterventions",
  reportsTickets = "reportsTickets",
  reportsTasks = "reportsTasks",
  reportsDeals = "reportsDeals",
  reportsContracts = "reportsContracts",
  reportsProjects = "reportsProjects",
  reportsClassification = "reportsClassification",
  inboxEmails = "inboxEmails",
  emailConversations = "emailConversations",
  emailSignatures = "emailSignature",
  spaceLogs = "spaceLogs",
  automation = "automation",
  spaceFeedbackQueue = "spaceFeedbackQueue",
  spacePermissionQueue = "spacePermissionQueue",
  spaceBreadcrumbsQueue = "spaceBreadcrumbsQueue",
  spaceAutomationTriggerQueue = "spaceAutomationTriggerQueue",
  spaceAutomationEnrollQueue = "spaceAutomationEnrollQueue",
  spaceEnrollManagerQueue = "spaceEnrollManagerQueue",
  spaceAutomationEmailConversationExportQueue = "spaceAutomationEmailConversationExportQueue",
  spaceAllUserPermissionsQueue = "spaceAllUserPermissionsQueue",
  spaceWidgetEmailQueue = "spaceWidgetEmailQueue",
  folderSettings = "folderSettings",
  spaceNotificationsQueue = "spaceNotificationsQueue",
  spaceWidgetTicketImageQueue = "spaceWidgetTicketImageQueue",
  spaceUserCategories = "spaceUserCategories",
  emailWebhooks = "emailWebhooks",
  outboundEmailWebhooks = "outboundEmailWebhooks",
  spaceCostCenters = "spaceCostCenters",
  spaceTravelLogs = "spaceTravelLogs",
  reprocessingLogs = "reprocessingLogs"
}

export const accountPermissions = {
  Default: 0,
  ReadContacts: 1,
  ViewDealFinancialData: 2,
  ViewContractFinancialData: 4,
  ViewSubcriptionFinancialData: 8,
  ViewProjectFinancialData: 16,
  ViewUserPrivateData: 128
};

export const permissionsText = {
  0: "NONE",
  1: "ALL",
  2: "ALL",
  4: "ALL",
  // 8: "",
  16: "EXPORT",
  32: "ONLY_ME",
  64: "ONLY_ME",
  128: "ONLY_ME",
  256: "ACTIVE",
  512: "TEAM",
  1024: "TEAM",
  2048: "TEAM",
  4096: "DEPARTMENT",
  8192: "DEPARTMENT",
  16384: "DEPARTMENT",
  32768: "COMPANY",
  65536: "COMPANY",
  131072: "COMPANY"
};

export const accountRoles = {
  Default: 0,
  Admin: 1,
  Owner: 2
};

export const canEditModule = (Module: any) => {
  if (
    hasModulePermission(Module, modulePermissions.EditAll) ||
    hasModulePermission(Module, modulePermissions.DepartementEditOnly) ||
    hasModulePermission(Module, modulePermissions.OrganizationEditOnly) ||
    hasModulePermission(Module, modulePermissions.PrivateEditOnly) ||
    hasModulePermission(Module, modulePermissions.TeamEditOnly)
  )
    return true;
  else return false;
};

export const canReadModule = (Module: any) => {
  if (
    hasModulePermission(Module, modulePermissions.ReadAll) ||
    hasModulePermission(Module, modulePermissions.DepartementReadOnly) ||
    hasModulePermission(Module, modulePermissions.OrganizationReadOnly) ||
    hasModulePermission(Module, modulePermissions.PrivateReadOnly) ||
    hasModulePermission(Module, modulePermissions.TeamReadOnly)
  )
    return true;
  else return false;
};

export const canUserEditModule = (
  accountSpace: AccountSpace,
  moduleType: moduleTypes
) => {
  if (isAdmin(accountSpace)) return true;

  const { Modules } = accountSpace;

  const currentModule = Modules[moduleType];
  if (canEditModule(currentModule)) return true;
  else return false;
};

export const canUserReadModule = (
  accountSpace: AccountSpace,
  moduleType: moduleTypes
) => {
  if (isAdmin(accountSpace)) return true;

  const { Modules } = accountSpace;

  const currentModule = Modules[moduleType];
  if (canReadModule(currentModule)) return true;
  else return false;
};

export const hasModulePermission = (
  Module: number,
  Permission: modulePermissions
) => {
  if (!Module) return false;

  return Boolean(Module & Permission);
};

export const hasModuleAccess = (Module: number) =>
  hasModulePermission(Module, modulePermissions.Access);

export const toggleModulePermission = (
  Module: number,
  Permission: modulePermissions
) => {
  if (hasModulePermission(Module, Permission)) {
    return Module - Permission;
  } else {
    return Module + Permission;
  }
};

export const getDefaultPermission = (
  Module: number,
  permissions: modulePermissions[]
) => {
  let resolvedPermission = 0;
  for (const permission of permissions) {
    if (hasModulePermission(Module, permission)) {
      resolvedPermission = permission;
      break;
    }
  }
  return resolvedPermission;
};

export const hasAtLeastOneModuleAccess = (accountSpace: AccountSpace) => {
  if (isAdmin(accountSpace)) return true;

  const { Modules } = accountSpace;
  for (const moduleType in Modules) {
    if (Modules.hasOwnProperty(moduleType)) {
      const module = Modules[moduleType];
      if (hasModulePermission(module, modulePermissions.Access)) return true;
    }
  }
  return false;
};

export const hasAtLeastOneConfigurationModuleAccess = (
  accountSpace: AccountSpace
) => {
  if (isAdmin(accountSpace)) return true;

  const { Modules } = accountSpace;

  return hasModulePermission(Modules.configurations, modulePermissions.Access);

  // return (
  //   hasModulePermission(Modules.organizations, modulePermissions.Access) ||
  //   hasModulePermission(Modules.departments, modulePermissions.Access) ||
  //   hasModulePermission(Modules.users, modulePermissions.Access) ||
  //   hasModulePermission(Modules.teams, modulePermissions.Access)
  // );
};

// All methods expect a AccountSpace object (should always include Role & Modules)
export const isDeveloper = ({ IsDeveloper }: Account) => Boolean(IsDeveloper);
export const isSupport = ({ IsSupport }: Account) => Boolean(IsSupport);

export const isOwner = ({ Role }: AccountSpace) => Role === accountRoles.Owner;

export const isAdmin = (accountSpace: AccountSpace) =>
  isOwner(accountSpace) || accountSpace.Role === accountRoles.Admin;

export const hasBillingAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.billing, modulePermissions.Access);

export const hasEditImportPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.imports, modulePermissions.EditAll);

export const hasDealFinancialPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.SpacePermissions,
    accountPermissions.ViewDealFinancialData
  );

export const hasContractFinancialPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.SpacePermissions,
    accountPermissions.ViewContractFinancialData
  );
export const hasSubscriptionFinancialPermission = (
  accountSpace: AccountSpace
) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.SpacePermissions,
    accountPermissions.ViewSubcriptionFinancialData
  );
export const hasProjectFinancialPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.SpacePermissions,
    accountPermissions.ViewProjectFinancialData
  );

export const hasImportAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.imports, modulePermissions.Access);

export const hasCallsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.calls, modulePermissions.Access);

export const hasClientsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.clients, modulePermissions.Access);

export const hasClientsEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.clients);
export const hasDealsEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.deals);
export const hasContractsEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.contracts);

export const hasSubscriptionsEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.subscriptions);
export const hasCallEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.calls);
export const hasProjectEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.projects);
export const hasTicketEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.tickets);
export const hasTaskEditPermission = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) || canEditModule(accountSpace.Modules.tasks);

export const hasConfigurationAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.configuration,
    modulePermissions.Access
  );

export const hasContactsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.contacts, modulePermissions.Access);

export const hasDealsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.deals, modulePermissions.Access);

export const hasSubscriptionAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.subscriptions,
    modulePermissions.Access
  );

export const hasContractsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.contracts, modulePermissions.Access);

export const hasHelpdeskAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.helpdesk, modulePermissions.Access);

export const hasInterventionsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.interventions,
    modulePermissions.Access
  );

export const hasSocialMediaAcess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.socialPost,
    modulePermissions.Access
  );

export const hasWidgetsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.settingsWidget,
    modulePermissions.Access
  );

export const hasProjectsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.projects, modulePermissions.Access);

export const hasTasksAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.tasks, modulePermissions.Access);

export const hasTicketsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(accountSpace.Modules.tickets, modulePermissions.Access);

export const hasAnyReportsAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasInterventionReportAccess(accountSpace) ||
  hasTicketsReportAccess(accountSpace) ||
  hasTasksReportAccess(accountSpace) ||
  hasDealsReportAccess(accountSpace) ||
  hasContractsReportAccess(accountSpace) ||
  hasProjectsReportAccess(accountSpace) ||
  hasReportsProductivityAccess(accountSpace) ||
  hasReportsClassificationAccess(accountSpace);
export const hasInterventionReportAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.reportsInterventions,
    modulePermissions.Access
  );

export const hasTicketsReportAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.reportsTickets,
    modulePermissions.Access
  );

export const hasTasksReportAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.reportsTasks,
    modulePermissions.Access
  );

export const hasDealsReportAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.reportsDeals,
    modulePermissions.Access
  );

export const hasContractsReportAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.reportsContracts,
    modulePermissions.Access
  );

export const hasProjectsReportAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.reportsProjects,
    modulePermissions.Access
  );

export const hasReportsClassificationAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.Modules.reportsClassification,
    modulePermissions.Access
  );

export const hasReportsProductivityAccess = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  (hasModulePermission(
    accountSpace.Modules.reportsClassification,
    modulePermissions.Access
  ) &&
    hasModulePermission(
      accountSpace.Modules.reportsClassification,
      modulePermissions.ReadAll
    ));

export const canReadContacts = (accountSpace: AccountSpace) =>
  isAdmin(accountSpace) ||
  hasModulePermission(
    accountSpace.SpacePermissions,
    accountPermissions.ReadContacts
  );

export enum accessLevels {
  basic = "basic",
  advanced = "advanced",
  admin = "admin",
  // superAdmin: "superAdmin",
  owner = "owner"
}

export const getAccessLevel = (accountSpace: AccountSpace) => {
  if (isOwner(accountSpace)) return accessLevels.owner;

  if (isAdmin(accountSpace)) {
    return accessLevels.admin;
  }

  // if (hasConfigurationAccess(accountSpace)) return accessLevels.advanced;

  return accessLevels.basic;
};

//compares current user modules with a target user modules
export const canEditUser = (
  ownAccessLevel: accessLevels,
  targetAccessLevel: accessLevels
) => {
  //can i edit?
  if (
    ownAccessLevel === accessLevels.basic ||
    ownAccessLevel === accessLevels.advanced
  )
    return false;

  //can i edit this user?
  if (ownAccessLevel === targetAccessLevel) return true;

  if (targetAccessLevel === accessLevels.owner) return false;

  return true;
};

export const getFormmatedModules = (modules: any) => {
  //;
  let newModules: any = { ...defaultModules };

  if (modules) {
    for (const m of modules) {
      newModules[m] = true;
    }
  }

  return newModules;
};

export const getUserModules = (
  mapper: any,
  accountSpaces: any,
  spaceId: number,
  userId: number
) => {
  let accountSpaceId = mapper[spaceId][userId];
  return accountSpaces[accountSpaceId].Modules;
};

export const getUserAccountSpace = (
  mapper: any,
  accountSpaces: any,
  spaceId: number,
  userId: number
) => {
  let accountSpaceId = mapper[spaceId][userId];
  return accountSpaces[accountSpaceId];
};
