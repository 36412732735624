import React, { useRef } from "react";
import {
  useProjectType,
  usePipelineStatus,
  useCancelationReason
} from "../../../Hooks/EntityHooks";
import {
  faCalendarAlt,
  faClock,
  faUsers as faUsersLight,
  faClipboard,
  faHandshake,
  faFileAlt,
  faBuilding,
  faSuitcase,
  faUserTie,
  faUserPlus,
  faBusinessTime,
  faUserCog,
  faBell,
  faDrawCircle,
  faIdCardClip,
	faTag
} from "@fortawesome/pro-light-svg-icons";
import {
  EntityOverviewItem,
  EntityDescriptionOverviewItem,
  EntityOverviewRoundedItem,
  EntityOverviewPlaceholder,
  EntityOverviewChildrenProgress,
  CurrencyValuesAccordion
} from "../../../Components/EntityOverview/EntityOverview";
import RoundedBadge from "../../../Components/RoundedBadge/RoundedBadge";
import { GridColloredEndDateComponent } from "../../../Helpers/GridHelper";
import {
  MilSecToFormatedHourMinute,
  MilSecToHour
} from "../../../Helpers/MiscHelper";
import RelativeTime from "../../../Components/RelativeTime/RelativeTime";
import { ConnectedOrganizationProfile } from "../../../Components/Organizations/OrganizationProfile";
import {
  EntitySummaryAccordion,
  DescriptionOverview,
  EntitySummaryFilesAccordion,
  EntityPipelineHistoryAccordion,
	TagsOverViewSummary
} from "../../../Components/EntitySummaries/EntitySummaries";
import { EntityOverviewPipeline } from "../../../Components/EntityOverview/EntityOverviewPipeline";
import {
  faAnalytics,
  faUsers,
  faInfoSquare,
  faCity
} from "@fortawesome/pro-solid-svg-icons";
import SpentHoursPreview from "../../../Components/SpentHoursPreview/SpentHoursPreview";
import {
  ConnectedAccountsTeamsDepartmentsProfileStack,
  ConnectedAccountProfileStack
} from "../../../Components/Accounts/AccountProfileStack";
import { spaceProjectsSchema, spaceProjectTagsSchema } from "../../../config/schema";
import { ConnectedClientOrigin } from "../../Origin/ClientOrigin";
import { ConnectedContactPopup } from "../../Origin/ContactPopup";
import { ConnectedAccountPopup } from "../../Origin/AcountPopup";
import { useHoverEvents } from "../../Origin/Origin";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import { FormattedMessage } from "react-intl";
import { ProjectStatusTypes } from "../ProjectsStatusHelper";
import { hasProjectFinancialPermission } from "../../../Helpers/ModulesHelper";
import { useCurrentAccountSpace } from "../../../Contexts/UserContext";

const EstimatedTimeItem = ({ children, title, titleId, type, className }) => {
  const {
    onAnchorMouseEnter,
    onAnchorMouseLeave,
    isOpen,
    onPopupMouseEnter,
    onPopupMouseLeave
  } = useHoverEvents();

  const ref = useRef();

  return (
    <>
      <RoundedBadge
        onMouseEnter={onAnchorMouseEnter}
        onMouseLeave={onAnchorMouseLeave}
        innerRef={ref}
        className={className}
        type={type}
      >
        {children}
      </RoundedBadge>
      <InfoPopup
        anchorEl={ref.current}
        isOpen={isOpen}
        onMouseEnter={onPopupMouseEnter}
        onMouseLeave={onPopupMouseLeave}
      >
        {titleId ? <FormattedMessage id={titleId} /> : title}
      </InfoPopup>
    </>
  );
};

const EstimatedTimePreview = ({ EstimatedCost, SpentHours }) => {
  const isExtended = SpentHours > EstimatedCost;

  return (
    <>
      <EstimatedTimeItem titleId="BUDGET_TIME" className="d-inline-block mr-2">
        {MilSecToHour(EstimatedCost || 0, true)} Hs
      </EstimatedTimeItem>

      <EstimatedTimeItem
        titleId="SPENT_HOURS_ABRV"
        className="d-inline-block"
        type={isExtended ? "danger" : "success"}
      >
        {MilSecToHour(SpentHours || 0, true)} Hs
      </EstimatedTimeItem>
    </>
  );
};

const ProjectSummaryContent = ({ value }) => {
  const {
    Pipeline,
    PipelineStatus,
    StatusDetail,
    EstimatedCost,
    SpentHours,
    Creator,
    Manager,
		Tags,
    ProjectType: projectTypeId,
    SalesOpportunity,
    BeginDate,
    Company,
    Client,
    IndirectClient,
    Contact,
    ReceivedDate,
    NoTagDescription,
    Description,
    InternalDescription,
    ComplementaryTime,
    NoTagInternalDescription,
    Files,
    EstimatedCosts,
    CodeERP,
    Departments,
    Collaborators,
    Id,
    CanEdit,
    StatusDetailDescription,
    IsFollowing,
    Followers
  } = value;
  const currentAccountSpace = useCurrentAccountSpace();
  const PrivateFinancialPermission =
    hasProjectFinancialPermission(currentAccountSpace);
  const projectType = useProjectType(projectTypeId);
  const pipelineStatusObj = usePipelineStatus(PipelineStatus);
  const detail = useCancelationReason(StatusDetail);
  return (
    <>
      <EntitySummaryAccordion icon={faAnalytics} textId="STATUS">
        <EntityOverviewPipeline
          pipeline={Pipeline}
          pipelineStatus={PipelineStatus}
          statusDetail={StatusDetail}
        />

        {StatusDetail &&
          pipelineStatusObj.Status === ProjectStatusTypes.canceled && (
            <EntityOverviewRoundedItem
              icon={faDrawCircle}
              titleId="CANCELATION_REASON"
            >
              {detail.Name}
            </EntityOverviewRoundedItem>
          )}
        {StatusDetailDescription &&
          pipelineStatusObj.Status === ProjectStatusTypes.canceled && (
            <EntityDescriptionOverviewItem
              icon={faDrawCircle}
              titleId="CANCELATION_DESCRIPTION"
            >
              <DescriptionOverview
                Description={StatusDetailDescription}
                NoTagDescription={StatusDetailDescription}
              />
            </EntityDescriptionOverviewItem>
          )}
        {/* <EntityOverviewItem
            className="d-flex align-items-center"
            icon={faDrawCircle}
            title="Pipeline:"
          >
            <RoundedBadge className="text-truncate">{pipeline.Name}</RoundedBadge>
            <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
            <RoundedBadge className="text-truncate" type="success">
              {pipelineStatus.Name}
            </RoundedBadge>
          </EntityOverviewItem> */}
        <EntityOverviewItem icon={faCalendarAlt} titleId="CONCLUSION">
          <GridColloredEndDateComponent Data={value} />
        </EntityOverviewItem>

        <EntityOverviewItem icon={faBusinessTime} titleId="BUDGET_TIME">
          <EstimatedTimePreview
            EstimatedCost={EstimatedCost}
            SpentHours={SpentHours}
          />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faInfoSquare} textId={"DETAILS"}>

			<EntityOverviewRoundedItem icon={faTag} titleId="PROJECT_TAGS">
          <TagsOverViewSummary tags={Tags} schema={spaceProjectTagsSchema} />
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="START">
          <RelativeTime date={BeginDate} />
        </EntityOverviewRoundedItem>

        <EntityOverviewRoundedItem icon={faClipboard} titleId="TYPE">
          {projectType ? `${projectType.Type} - ${projectType.Name}` : "-"}
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem icon={faIdCardClip} titleId="CODEERP">
          {CodeERP ? CodeERP : "-"}
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem
          icon={faHandshake}
          titleId="NR_SALE_OPPORTUNITY"
        >
          {SalesOpportunity ? SalesOpportunity : "-"}
        </EntityOverviewRoundedItem>
        <EntityOverviewRoundedItem icon={faClock} titleId="SPENT_HOURS">
          {MilSecToFormatedHourMinute(SpentHours)}
        </EntityOverviewRoundedItem>
        <EntityOverviewItem icon={faAnalytics} titleId="PERFORMANCE">
          <SpentHoursPreview
            onlyPercentage
            SpentHours={SpentHours}
            EstimatedHours={EstimatedCost}
          />
        </EntityOverviewItem>

        {PrivateFinancialPermission && (
          <EntityOverviewItem icon={faBusinessTime} titleId="COMPLEMENTED_TIME">
            <EstimatedTimeItem
              titleId="COMPLEMENTED_TIME"
              className="d-inline-block mr-2"
            >
              {ComplementaryTime
                ? MilSecToHour(ComplementaryTime || 0, true) + " Hs"
                : "-"}
            </EstimatedTimeItem>
          </EntityOverviewItem>
        )}

        <EntityOverviewChildrenProgress schema={spaceProjectsSchema} id={Id} />

        <EntityDescriptionOverviewItem icon={faFileAlt} titleId="DESCRIPTION">
          <DescriptionOverview
            Description={Description}
            NoTagDescription={NoTagDescription}
          />
        </EntityDescriptionOverviewItem>

        <EntityDescriptionOverviewItem
          icon={faFileAlt}
          titleId="DESCRIPTION_PERSON_RESPONSIBLE_ABRV"
        >
          <DescriptionOverview
            Description={InternalDescription}
            NoTagDescription={NoTagInternalDescription}
          />
        </EntityDescriptionOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faCity} textId="ASSOCIATED">
        <EntityOverviewItem icon={faBuilding} titleId="COMPANY">
          <ConnectedOrganizationProfile dynamicFont organization={Company} />
        </EntityOverviewItem>
        <EntityOverviewRoundedItem icon={faCalendarAlt} titleId="ENTRY">
          <RelativeTime date={ReceivedDate} />
        </EntityOverviewRoundedItem>
        <EntityOverviewItem icon={faSuitcase} titleId="ACCOUNT">
          <ConnectedClientOrigin
            placement="right"
            dynamicFont
            client={Client || IndirectClient}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserTie} titleId="CONTACT">
          <ConnectedContactPopup
            placement="right"
            dynamicFont
            contact={Contact}
          />
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      <EntitySummaryAccordion icon={faUsers} textId="ASSIGNED_TO">
        <EntityOverviewItem icon={faUserPlus} titleId="CREATED_BY">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Creator}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUserCog} titleId="MANAGER">
          <ConnectedAccountPopup
            placement="right"
            dynamicFont
            account={Manager}
          />
        </EntityOverviewItem>
        <EntityOverviewItem icon={faUsersLight} titleId="ASSIGNED_TO">
          {Departments || Collaborators ? (
            <ConnectedAccountsTeamsDepartmentsProfileStack
              departments={Departments}
              accounts={Collaborators.Accounts}
              teams={Collaborators.Teams}
            />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
        <EntityOverviewItem icon={faBell} titleId="FOLLOWERS">
          {Followers && Followers.length > 0 ? (
            <ConnectedAccountProfileStack dynamicFont accounts={Followers} />
          ) : (
            <EntityOverviewPlaceholder rounded />
          )}
        </EntityOverviewItem>
      </EntitySummaryAccordion>

      {PrivateFinancialPermission && (
        <CurrencyValuesAccordion
          EstimatedCosts={EstimatedCosts}
          titleId="TOTAL_VALUE"
        />
      )}

      <EntitySummaryFilesAccordion
        CanEdit={CanEdit}
        IsFollowing={IsFollowing}
        Files={Files}
      />
      {/* <EntityPipelineHistoryAccordion
        endpoint={`Projects/${Id}/PipelineHistory`}
        id={Id}
        schema={spaceProjectsSchema}
      />
      <div className="mb-3"></div> */}
      <EntityPipelineHistoryAccordion
        endpoint={`history/project/${Id}/pipeline`}
        id={Id}
        schema={spaceProjectsSchema}
      />
    </>
  );
};

export default ProjectSummaryContent;
