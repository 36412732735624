import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
  useEffect,
  useLayoutEffect
} from "react";
import "./Reception.css";
import {
  useDetailAwareParams,
  useServerAwareState
} from "../../Components/CGrid/ServerGrid";
import { useLocation } from "react-router-dom";
import {
  spaceTicketsSchema,
  spaceEmailConversationSchema,
  spaceProjectsSchema,
  spaceTasksSchema,
  spaceClientSchema,
  spaceDealsSchema,
  spaceContractSchema,
  spaceSubscriptionSchema,
  spaceCallsSchema,
  spaceInterventionsSchema,
  accountSchema
} from "../../config/schema";
import { useSpaceQuery, useSchemaQuery } from "../../Helpers/IOClient";
import classnames from "classnames";
import { ReceptionSidebarNav } from "./ReceptionCompFilters";
import ReceptionCompMsgList from "./ReceptionCompMsgList";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { useEndlessScroll } from "../../Components/EndlessScrollPage/EndlessScrollPage";
import { fk } from "../../Helpers/SchemaHelper";
import {
  useConversationSync,
  useConversationCreationSync,
  useEmailStatusSync
} from "./Reception";
import { useConnectionId } from "../RealTime/RealTime";
import { Route, useRouteMatch, useParams, Switch } from "react-router-dom";
import { ReceptionEmailDetails } from "./ReceptionEmails/ReceptionEmails";
import { useEntityValue } from "../../Hooks/EntityHooks";
import EntitySummaryBreadcrumbs from "../../Components/EntitySummaries/EntitySummaryBreadcrumbs";
import { EntitySummaryHeader } from "../../Components/EntitySummaries/EntitySummaries";
import {
  useEntitySummarySidebar,
  usePageType
} from "../../Components/EntitySummaries/EntitySummariesHelper";
import { EntitySummaryMessages } from "../../Components/EntitySummaries/EntitySummaryMessages/Messages";
import { MessagesConnection } from "../../Components/EntitySummaries/EntitySummaryMessages/MessagesConnection";
import {
  projectSettings,
  taskSettings,
  clientSettings,
  dealSettings,
  contractSettings,
  subscriptionSettings,
  callSettings,
  ticketSettings,
  timeChargeSettings
} from "./ReceptionSettings";
import {
  createDealFilter,
  createTaskFilter,
  createTicketFilter,
  createUserFilter
} from "../../Helpers/FilterHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faUser
} from "@fortawesome/pro-light-svg-icons";
import { ReceptionBasicFilterEnum } from "./ReceptionServerAwareFilterList";
import { UserDropdown } from "../AdvancedMultiInputs";
import { FormattedMessage, useIntl } from "react-intl";
import { ConnectedAccountProfile } from "../../Components/Accounts/AccountProfile";
import {
  ReceptionResizerProvider,
  useReceptionResizer
} from "./ReceptionResizer";
import { buildODataFilter, buildODataOrderBy } from "../../Helpers/ODataHelper";
import EmptyListContainer from "../../Components/EmptyListContainer/EmptyListContainer";
import { SelectedArrMsgContext } from "../../Components/UnOrReadOptions/UnreadOptions";
import {
  InboxTypeEnum,
  ConversationTypeEnumFlags,
  EntityTypeNoteEnum,
  ConversationTypeEnum
} from "./ReceptionHelpers";
import { useMinimizeEvents } from "./ReceptionDetails";
import {
  DetailsSettingsContext,
  DetailsValueIdContext
} from "../../Components/DetailsView2/DetailsView2Helper";
import { useEntityReader } from "../../Helpers/EntityHelper";
import { useAirdeskTitle } from "../../Hooks/MiscHooks";
import { useDetailsEntityId } from "../../Components/DetailsView/DetailsView";
import PageHeader from "../../Components/Headers/PageHeader";
import { RealTimePageEnum } from "../RealTime/RealTimePages";
import { useUserEntitiesHub } from "../RealTime/RealTimeUser";
import { validate } from "uuid";
export const ConversationDataContext = React.createContext();

const sideWidth = 400;
const minimizeButtonWidth = 50;

const MinimizeButton = ({ className, style }) => {
  const { minimized, toggleMinimized, hasSpace } = useContext(MinimizeContext);

  return (
    <div
      className={classnames(
        "ar-reception-minimize-button text-primary  d-flex align-items-center justify-content-center",
        { "cursor-default": !hasSpace, "cursor-pointer": hasSpace },
        className
      )}
      style={style}
      onClick={toggleMinimized}
    >
      <FontAwesomeIcon
        size="lg"
        icon={minimized ? faChevronDoubleLeft : faChevronDoubleRight}
      />
    </div>
  );
};

export const MinimizeContext = React.createContext();

const minWidth = 1500;

const getHasWidth = (targetWidth = minWidth) => {
  const width = window.innerWidth;

  if (width < targetWidth) return false;

  return true;
};

const MinimizeContainer = ({ children }) => {
  const { hasSpace } = useContext(MinimizeContext);

  return (
    <div
      className={classnames("d-flex flex-1 justify-content-between of-hidden", {
        "ar-inbox-minimized": !hasSpace
      })}
    >
      {children}
    </div>
  );
};

const MinimizeProvider = ({ children, targetWidth }) => {
  const [minimized, setMinimized] = useState(false);

  const [hasSpace, setHasSpace] = useState(() => getHasWidth(targetWidth));

  const [widthMinimized, setWidthMinimized] = useState(true);

  useEffect(() => {
    const listener = () => {
      const hasWidth = getHasWidth(targetWidth);
      setHasSpace(hasWidth);
    };

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [targetWidth]);

  const value = useMemo(() => {
    const toggleMinimized = () => {
      if (hasSpace) setMinimized((m) => !m);
      else setWidthMinimized((m) => !m);
    };
    return {
      hasSpace,
      minimized: hasSpace ? minimized : widthMinimized,
      toggleMinimized
    };
  }, [hasSpace, minimized, widthMinimized]);

  const mountedRef = useRef();
  const resize = useReceptionResizer();

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      return;
    }
    const timeout = setTimeout(() => {
      resize();
    }, 400);

    return () => clearTimeout(timeout);
  }, [resize, minimized]);

  return (
    <MinimizeContext.Provider value={value}>
      {children}
    </MinimizeContext.Provider>
  );
};

const InnerReceptionEntityDetails = ({ settings, id }) => {
  const {
    SummaryComponent,
    schema,
    moduleType,
    TextComponent,
    noImage,
    ActionButton
  } = settings;

  const { loading, error } = useSchemaQuery(schema, id);

  const entity = useEntityValue(schema, id);

  const openForm = useEntitySummarySidebar();

  const handleEdit = () => {
    openForm({
      type: moduleType,
      props: {
        id: id,
        edit: true
      }
    });
  };

  useEntityReader(entity ? entity : undefined, schema, "IsMessageRead", true);
  useEntityReader(entity ? entity : undefined, schema, "IsRead", true);

  const { minimized } = useContext(MinimizeContext);

  const { handleMouseEnter, handleMouseLeave } = useMinimizeEvents();

  if (minimized)
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ width: 50 }}
        className="ar-reception-summary-container bg-white shadow d-flex justify-content-center"
      >
        <MinimizeButton />
      </div>
    );

  if (loading || error)
    return (
      <div
        style={{ width: sideWidth }}
        className="ar-reception-summary-container d-flex align-items-center justify-content-center bg-white shadow"
      >
        <LoaderSpinner size="sm" className="text-primary" />
      </div>
    );
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="bg-white ar-reception-summary-container h-100 shadow"
      style={{ width: sideWidth }}
    >
      <div
        style={{ width: sideWidth }}
        className="of-y-auto d-flex flex-column h-100"
      >
        <div className={classnames("breadcrumbs d-flex")}>
          <MinimizeButton />
          <EntitySummaryBreadcrumbs
            width={sideWidth - minimizeButtonWidth}
            schema={schema}
            id={id}
          />
        </div>
        <div className="mx-3 border-bottom"></div>
        <EntitySummaryHeader
          schema={schema}
          ActionButton={ActionButton}
          // OverviewHeader={OverviewHeader}
          // isEditing={isEditing}
          onEdit={entity.CanEdit && handleEdit}
          value={entity}
          noImage={noImage}
          TextComponent={TextComponent}
          // {...rest}
        />

        <SummaryComponent value={entity} />
      </div>
    </div>
  );
};

const ReceptionEntityDetails = ({ settings, id }) => {
  const { roomType, schema, type } = settings;

  const rId = Number(id);
  return (
    <>
      <MessagesConnection
        key={`${roomType}-${id}`}
        roomType={roomType}
        schema={schema}
        entityId={rId}
      >
        <EntitySummaryMessages
          className="flex-1 h-100"
          receptionInbox
          roomType={roomType}
          type={type}
          schema={schema}
          entityId={rId}
        />
      </MessagesConnection>
      <InnerReceptionEntityDetails settings={settings} id={rId} />
    </>
  );
};

function isGuid(value) {
  var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
  var match = regex.exec(value);
  return match != null;
}

const ReceptionDetails = () => {
  const { type, id } = useParams();
  // const { data, loading, error } = useSpaceQuery(
  //   `EmailConversation/${id}`,
  //   spaceEmailConversationSchema
  // );

  switch (Number(type)) {
    case InboxTypeEnum.Email:
      if (validate(id)) return <ReceptionEmailDetails id={id} />;
      else
        return (
          <div className="d-flex flex-1 justify-content-between of-hidden">
            <div className="w-100">
              <EmptyListContainer type="inbox_list">
                <div className="fw-bold fs-24 text-black ">
                  <FormattedMessage id={"EMPTY_ENTRY_RECEPTION"} />
                </div>
                {/* <div className="fs-18 text-black">
				<FormattedMessage id={"EMPTY_ENTRY_RECEPTION_SUBTEXT"} />
			</div> */}
              </EmptyListContainer>
            </div>
          </div>
        );

    case InboxTypeEnum.Client:
      return <ReceptionEntityDetails settings={clientSettings} id={id} />;

    case InboxTypeEnum.Deal:
      return <ReceptionEntityDetails settings={dealSettings} id={id} />;

    case InboxTypeEnum.Contract:
      return <ReceptionEntityDetails settings={contractSettings} id={id} />;

    case InboxTypeEnum.Subscription:
      return <ReceptionEntityDetails settings={subscriptionSettings} id={id} />;

    case InboxTypeEnum.Call:
      return <ReceptionEntityDetails settings={callSettings} id={id} />;

    case InboxTypeEnum.Project:
      return <ReceptionEntityDetails settings={projectSettings} id={id} />;

    case InboxTypeEnum.Ticket:
      return <ReceptionEntityDetails settings={ticketSettings} id={id} />;

    case InboxTypeEnum.Task:
      return <ReceptionEntityDetails settings={taskSettings} id={id} />;

    case InboxTypeEnum.Time:
      return <ReceptionEntityDetails settings={timeChargeSettings} id={id} />;

    default:
      return (
        <div className="d-flex flex-1 justify-content-between of-hidden">
          <div className="w-100">
            <EmptyListContainer type="inbox_list">
              <div className="fw-bold fs-24 text-black ">
                <FormattedMessage id={"EMPTY_ENTRY_RECEPTION"} />
              </div>
              {/* <div className="fs-18 text-black">
				<FormattedMessage id={"EMPTY_ENTRY_RECEPTION_SUBTEXT"} />
			</div> */}
            </EmptyListContainer>
          </div>
        </div>
      );
  }
};

// const FilterOptions = {
//   ALL: {
//     value: 0,
//   },
//   CALLS: {
//     value: 1,
//   },
//   CASES: {
//     value: 2,
//   },
//   PROJECTS: {
//     value: 3,
//   },
//   TASKS: {
//     value: 4,
//   },
//   // "Registo de Tempo": {
//   //   filter: "Intervention ne null"
//   // },
//   ACCOUNTS: {
//     value: 5,
//   },
//   // Contactos: {
//   //   filter: 6
//   // },
//   CONTRACTS: {
//     value: 6,
//   },
//   DEALS: {
//     value: 7,
//   },
// };

export const SummaryAttachmentsPreview = React.createContext();

export const ReceptionSchema = {
  // Accounts: many(accountSchema),
  // Manager: fk(accountSchema),
  // Contacts: many(spaceContactSchema),
  // Ticket: fk(spaceTicketsSchema),
  EmailConversation: fk(spaceEmailConversationSchema),
  Client: fk(spaceClientSchema),
  ExtraClient: fk(spaceClientSchema),
  Deal: fk(spaceDealsSchema),
  Contract: fk(spaceContractSchema),
  Subscription: fk(spaceSubscriptionSchema),
  Call: fk(spaceCallsSchema),
  Project: fk(spaceProjectsSchema),
  Ticket: fk(spaceTicketsSchema),
  Task: fk(spaceTasksSchema),
  TimeCharge: fk(spaceInterventionsSchema)
};

export const ResponseSchema = {
  d: {
    results: [ReceptionSchema]
  }
};

const TopValueSkip = 20;

export const ConversationMessagesFunctionContext = React.createContext();

const createFilterObj = (basicFilter) => {
  const converterBasicFilter = parseInt(basicFilter);
  const resolvedFilters = [];

  if (converterBasicFilter & ReceptionBasicFilterEnum.Read) {
    resolvedFilters.push({
      data: {
        titleId: "READ",
        field: "Conversation/IsRead",
        applyOnChange: true
      },
      field: "Conversation/IsRead",
      operator: "eq",
      value: 1
    });
  }
  if (converterBasicFilter & ReceptionBasicFilterEnum.Unread) {
    resolvedFilters.push({
      data: {
        titleId: "NOT_READ",
        field: "Conversation/IsRead",
        applyOnChange: true
      },
      field: "Conversation/IsRead",
      operator: "eq",
      value: 0
    });
  }
  if (converterBasicFilter & ReceptionBasicFilterEnum.Resolved) {
    resolvedFilters.push({
      data: {
        titleId: "RESOLVED",
        field: "Conversation/IsSolved",
        applyOnChange: true
      },
      field: "Conversation/IsSolved",
      operator: "eq",
      value: 1
    });
  }
  if (converterBasicFilter & ReceptionBasicFilterEnum.Unresolved) {
    resolvedFilters.push({
      data: {
        titleId: "UNRESOLVED",
        field: "Conversation/IsSolved",
        applyOnChange: true
      },
      field: "Conversation/IsSolved",
      operator: "eq",
      value: 0
    });
  }
  if (converterBasicFilter & ReceptionBasicFilterEnum.Unassigned) {
    resolvedFilters.push({
      data: {
        titleId: "UNASSIGNED",
        field: "Conversation/Assigned",
        applyOnChange: true
      },
      field: "Conversation/Assigned",
      operator: "eq",
      value: 0
    });
  }
  if (converterBasicFilter & ReceptionBasicFilterEnum.NotificationActive) {
    resolvedFilters.push({
      data: {
        titleId: "FOLLOWING",
        field: "Conversation/IsFollowing",
        applyOnChange: true
      },
      field: "Conversation/IsFollowing",
      operator: "eq",
      value: 1
    });
  }
  if (converterBasicFilter & ReceptionBasicFilterEnum.NotificationUnactive) {
    resolvedFilters.push({
      data: {
        titleId: "FOLLOWING",
        field: "Conversation/IsFollowing",
        applyOnChange: true
      },
      field: "Conversation/IsFollowing",
      operator: "eq",
      value: 0
    });
  }

  return resolvedFilters;
};

const createAssignedFilterObj = (id) => {
  return createUserFilter({
    value: id,
    titleId: "ASSIGNED_TO",
    field: "Conversation/AssignedId",
    operator: "eq",
    icon: <FontAwesomeIcon icon={faUser} />,
    data: {
      titleId: "ASSIGNED_TO",
      field: "Conversation/AssignedId",
      defaultValue: null,
      applyOnChange: true,
      icon: <FontAwesomeIcon icon={faUser} />,
      component: (props) => (
        <UserDropdown isPopup {...props} allAccounts={true} />
      ),
      DescriptionComponent: ({ item, value }) => {
        return (
          <div>
            <FormattedMessage id={"ASSIGNED_TO"} />{" "}
            <ConnectedAccountProfile maxWidth account={value} />
          </div>
        );
      },
      operator: "eq",
      getSchemaFromCompleteValue: (value) => {
        return accountSchema;
      },
      getNormalizationSchema: () => {
        return { Account: fk(accountSchema) };
      }
    }
  });
};

export const SelectedMsgContext = React.createContext();
export const SelectedAllMsgsContext = React.createContext();
export const ResetSelectContext = React.createContext();
export const SetResponseContext = React.createContext();

const CreateDetailsFilter = (Settings, Id) => {
  switch (Settings?.schema.name) {
    case "SpaceDeals":
      return createDealFilter({
        field: "Deal/Id",
        outboundFields: ["Deal/Id", "Deal_A/Id"],
        value: Id,
        operator: "eq",
        data: {
          titleId: "DEAL",
          field: "Deal/Id",
          outboundFields: ["Deal/Id", "Deal_A/Id"],
          operator: "eq"
        }
      });

    case "SpaceTickets":
      return createTicketFilter({
        field: "Ticket/Id",
        outboundFields: ["Ticket/Id", "Ticket_A/Id"],
        value: Id,
        operator: "eq",
        data: {
          titleId: "CASE",
          field: "Ticket/Id",
          outboundFields: ["Ticket/Id", "Ticket_A/Id"],
          operator: "eq"
        }
      });
    case "SpaceTasks":
      return createTaskFilter({
        field: "Task/Id",
        outboundFields: ["Task/Id", "Task_A/Id"],
        value: Id,
        operator: "eq",
        data: {
          titleId: "TASK",
          field: "Task/Id",
          outboundFields: ["Task/Id", "Task_A/Id"],
          operator: "eq"
        }
      });
    // case "SpaceInterventions":
    //   return createInterventionFilter({
    //     field: "TimeCharge/Id",
    //     value: Id,
    //     operator: "eq",
    //     data: {
    //       titleId: "TIME",
    //       field: "TimeCharge/Id",
    //       operator: "eq"
    //     }
    //   });
    // case "SpaceProjects":
    //   return createProjectFilter({
    //     field: "Project/Id",
    //     value: Id,
    //     operator: "eq",
    //     data: {
    //       titleId: "PROJECT",
    //       field: "Project/Id",
    //       operator: "eq"
    //     }
    //   });
    // case "SpaceCalls":
    //   return createCallFilter({
    //     field: "Call/Id",
    //     value: Id,
    //     operator: "eq",
    //     data: {
    //       titleId: "CALL",
    //       field: "Call/Id",
    //       operator: "eq"
    //     }
    //   });
    // case "SpaceContracts":
    //   return createContractFilter({
    //     field: "Contract/Id",
    //     value: Id,
    //     operator: "eq",
    //     data: {
    //       titleId: "CONTRACT",
    //       field: "Contract/Id",
    //       operator: "eq"
    //     }
    //   });
    default:
      return null;
  }
};

const getConversationType = (
  detailSettings,
  detailsEntityId,
  conversationTypes
) => {
  switch (detailSettings?.schema.name) {
    case "SpaceContacts":
      return 1;
    default:
      return detailsEntityId
        ? ConversationTypeEnumFlags.All
        : parseInt(conversationTypes) === 31
        ? 8
        : conversationTypes || ConversationTypeEnumFlags.EntityNotes;
  }
};

const regexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

const ReceptionComp = ({ detailsView }) => {
  //   const [filter] = useState("ALL");
  const [response, setResponse] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const pageRef = useRef();
  const [, setCount] = useState(0);
  const ReceptionAttachmentsPreviewRef = useRef();

  const [openedMessage, setOpenMessage] = useState(null);
  const [skip, setSkip] = useState(0);
  const { params, moduleType, onParamsChange } = useServerAwareState();

  const settings = useContext(DetailsSettingsContext);

  const detailSettings = useContext(DetailsSettingsContext);
  const detailsEntityId = useContext(DetailsValueIdContext);
  const disableDetailsFilter = useMemo(() => {
    switch (detailSettings?.schema.name) {
      case "SpaceDeals":
        return true;
      case "SpaceTickets":
        return true;
      case "SpaceTasks":
        return true;
      default:
        return false;
    }
  }, [detailSettings]);

  const resovledParams = useDetailAwareParams(
    params,
    moduleType,
    disableDetailsFilter
  );

  useLayoutEffect(() => {
    if (parseInt(resovledParams?.conversationTypes) === 31) {
      onParamsChange((oldP) => {
        const newP = { ...oldP };
        newP.conversationTypes = "8";
        return newP;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { search } = useLocation();

  const resolvedUrl = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const conversationTypes = searchParams.get("conversationTypes");
    const {
      basicFilter,
      filter: paramsFilter,
      $orderBy,
      ReceptionAssignedFilter,
      entityNoteType,
      query
    } = resovledParams;

    // if(settings)

    let resolvedFilters = basicFilter
      ? [...paramsFilter, ...createFilterObj(basicFilter)]
      : [...paramsFilter];

    if (detailsEntityId) {
      const detailsFilters = CreateDetailsFilter(
        detailSettings,
        detailsEntityId
      );
      if (detailsFilters) resolvedFilters.push(detailsFilters);
    }

    if (ReceptionAssignedFilter)
      resolvedFilters.push(createAssignedFilterObj(ReceptionAssignedFilter));

    const resolvedConversationType = getConversationType(
      detailSettings,
      detailsEntityId,
      conversationTypes
    );

    let baseUrl =
      parseInt(resolvedConversationType) === ConversationTypeEnumFlags.Email
        ? `Conversations/List?$top=${TopValueSkip}&$skip=${skip}&$inlinecount=all`
        : `conversations?$top=${TopValueSkip}&$skip=${skip}&$inlinecount=allpages&conversationType=${resolvedConversationType}&entityNoteType=${
            detailsEntityId ? 511 : entityNoteType || 511
          }`;

    if (resolvedFilters.length > 0) {
      const odataResolvedFilters = buildODataFilter(
        resolvedFilters,
        true,
        true
      );

      baseUrl += `&$filter=${odataResolvedFilters}`;
    }

    if ($orderBy && $orderBy.length > 0) {
      const resolvedOrderBy = buildODataOrderBy($orderBy);

      baseUrl += `&${resolvedOrderBy}`;
    }

    if (query) baseUrl += `&query=${query}`;
    // if (!filter) return baseUrl;
    // const resolvedOption = FilterOptions[filter];

    return `${baseUrl}`;
  }, [detailSettings, detailsEntityId, resovledParams, search, skip]);

  const conId = useConnectionId();
  const { loading, error } = useSpaceQuery(resolvedUrl, ResponseSchema, {
    onSuccess: ({ data }) => {
      if (data.d.results.length < TopValueSkip) setHasMore(false);
      setCount(data.d.__count);
      setResponse((r) => {
        const newR = [...r, ...data.d.results];
        let flags = {};
        let output = [];

        for (const e of newR) {
          if (flags[e.Id]) continue;
          flags[e.Id] = true;
          output.push(e);
        }

        return output;
      });
    },
    cache: false,
    headers: {
      ConnectionID: conId
    }
  });

  const entitiesHub = useUserEntitiesHub();
  const pageType = usePageType();

  useEffect(() => {
    if (entitiesHub && pageType && response?.length > 0) {
      const filteredResponse = [];
      for (const e of response) {
        if (e?.EmailConversation && !detailsView)
          filteredResponse.push(e.EmailConversation);
      }

      const connector = () => {
        try {
          entitiesHub.invoke("EnterView", pageType, filteredResponse);
        } catch (error) {
          console.log(error);
        }
      };

      const listener = () => {
        setTimeout(() => {
          connector();
        }, 150);
      };

      listener();
    }
  }, [detailsView, entitiesHub, pageType, response]);

  const builtRef = useRef(false);

  useEffect(() => {
    if (!builtRef.current) {
      return () => {
        if (entitiesHub && pageType) {
          entitiesHub.invoke("LeaveView", pageType);
        }
      };
    } else {
      builtRef.current = true;
    }
  }, [entitiesHub, pageType]);

  useConversationSync(setResponse, detailsView); //Criacao de convercas chat
  useConversationCreationSync(setResponse, detailsView); //Update de Conversas (LastNote)

  useEmailStatusSync();
  const fetchMore = useCallback(() => {
    setSkip((s) => s + TopValueSkip);
  }, []);
  useEndlessScroll({
    listen: hasMore && !loading && !error,
    onFetchMore: fetchMore,
    offsettMultiplier: 0.2,
    pageRef
  });

  const onMessageClick = useCallback((id) => {
    setOpenMessage(id);
  }, []);

  const match = useRouteMatch();

  const [SelectedMsg, setSelectedMsg] = useState([]);

  const handleSelectAll = useCallback(
    (e) => {
      const checkedState = e.target.checked;
      setSelectedMsg((t) => {
        if (!checkedState) {
          return [];
        } else {
          return response.map((e) => {
            let EnumType = null;
            for (const key in InboxTypeEnum) {
              if (Object.hasOwnProperty.call(e, key)) {
                EnumType = key;
              }
            }
            if (!EnumType) {
              EnumType = "Email";
            }
            return {
              type: InboxTypeEnum[EnumType],
              id: EnumType === "Email" ? e.EmailConversation : e[EnumType]
            };
          });
        }
      });
    },
    [response]
  );

  const handleSelection = useCallback((msg) => {
    setSelectedMsg((t) => {
      const selectedIndex = t.findIndex(
        (e) => e.id === msg.id && e.type === msg.type
      );
      if (selectedIndex !== -1) {
        const newArr = [...t];
        newArr.splice(selectedIndex, 1);
        return newArr;
      } else {
        const newArr = [...t];
        newArr.push(msg);
        return newArr;
      }
    });
  }, []);

  const resetSelection = useCallback(() => {
    setHasMore(true);
    setSelectedMsg([]);
    setSkip(0);
    setResponse([]);
  }, []);

  const hasSettings = Boolean(settings);
  const isInDetails = Boolean(useDetailsEntityId());

  let leftMaxWidth = hasSettings ? 1740 : 1500;
  let rightMaxWidth = hasSettings ? 1570 : 1350;

  if (isInDetails) {
    leftMaxWidth -= 240;
    rightMaxWidth -= 240;
  }

  const intl = useIntl();
  useAirdeskTitle(intl.formatMessage({ id: "RECEPTION" }));
  return (
    <ReceptionResizerProvider>
      <SummaryAttachmentsPreview.Provider
        value={ReceptionAttachmentsPreviewRef}
      >
        <SelectedArrMsgContext.Provider value={SelectedMsg}>
          <SelectedAllMsgsContext.Provider value={handleSelectAll}>
            <SelectedMsgContext.Provider value={handleSelection}>
              <ResetSelectContext.Provider value={resetSelection}>
                <SetResponseContext.Provider value={setResponse}>
                  <PageHeader
                    justViews
                    schema={!detailsView && spaceEmailConversationSchema}
                  >
                    <div className="h-100 w-100 d-flex position-relative overflow-hidden">
                      <div className="d-flex">
                        <MinimizeProvider targetWidth={leftMaxWidth}>
                          <ReceptionSidebarNav setResponse={setResponse} />
                          <ReceptionCompMsgList
                            Loading={loading || error}
                            ScrollRef={pageRef}
                            Messages={response}
                            OpenedId={openedMessage}
                            detailsView={detailsView}
                            HandleMessageClick={onMessageClick}
                          />
                        </MinimizeProvider>
                      </div>
                      <Switch>
                        <Route path={`${match.url}/:type/:id`}>
                          <MinimizeProvider targetWidth={rightMaxWidth}>
                            <MinimizeContainer>
                              <ReceptionDetails />
                            </MinimizeContainer>
                          </MinimizeProvider>
                        </Route>
                        <Route>
                          <div className="d-flex flex-1 justify-content-between of-hidden">
                            <div className="w-100">
                              <EmptyListContainer type="inbox_list">
                                <div className="fw-bold fs-24 text-black ">
                                  <FormattedMessage
                                    id={"EMPTY_ENTRY_RECEPTION"}
                                  />
                                </div>
                                {/* <div className="fs-18 text-black">
                              <FormattedMessage id={"EMPTY_ENTRY_RECEPTION_SUBTEXT"} />
                            </div> */}
                              </EmptyListContainer>
                            </div>
                          </div>
                        </Route>
                      </Switch>
                    </div>
                  </PageHeader>
                </SetResponseContext.Provider>
              </ResetSelectContext.Provider>
            </SelectedMsgContext.Provider>
          </SelectedAllMsgsContext.Provider>
        </SelectedArrMsgContext.Provider>
      </SummaryAttachmentsPreview.Provider>
    </ReceptionResizerProvider>
  );
};

export default ReceptionComp;
