import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSpace } from "../../Contexts/SpaceContext";
import {
  getAllFiltersBySchema,
  getSelectedFiltersBySchema
} from "../FilterList/AdvancedFilter/AdvancedFilterColors/AdvancedFilterColorsLs";
import querystring from "query-string";
import { ServerAwareViewsContext } from "./ServerAwareViews";
import { ServerAwareFavoritesContext } from "../../Containers/AppBar/ServerAwareFavorite";

const ServerAwareColorFilterUpdaterContext = React.createContext();

const getColorFilterDict = (spaceId) => {
  const lsValue = localStorage.getItem(`ar-color-filter-space-${spaceId}`);
  if (!lsValue) return {};
  const resolvedVal = JSON.parse(lsValue);
  return resolvedVal;
};

export const useServerAwareColorFilterUpdater = () =>
  useContext(ServerAwareColorFilterUpdaterContext);

const ServerAwareFiltersContext = React.createContext();

export const useServerAwareSelectedFilterColumns = () =>
  useContext(ServerAwareFiltersContext);

const updateSchemaDict = (schema, update, spaceId) => {
  const dict = getColorFilterDict(spaceId);

  const rDict = { ...dict };

  if (!rDict[schema.name]) rDict[schema.name] = update;
  else rDict[schema.name] = { ...rDict[schema.name], ...update };

  localStorage.setItem(
    `ar-color-filter-space-${spaceId}`,
    JSON.stringify(rDict)
  );
};

export const ServerAwareColorFilters = ({ schema, children }) => {
  const space = useSpace();

  const [selectedFilters, setSelectedFilters] = useState(() => {
    const filters = getAllFiltersBySchema(schema, space.Id);
    const filterDict = {};

    for (const filter of filters) {
      filterDict[filter.Id] = filter;
    }
    const selectedFilters = getSelectedFiltersBySchema(schema, space.Id);
    const undefinedItems = [];
    const resolvedArr = [];
    for (const filterId of selectedFilters) {
      if (!Boolean(filterDict[filterId])) undefinedItems.push(filterId);
      resolvedArr.push(filterDict[filterId]);
    }
    if (undefinedItems.length > 0) {
      const newSelectedItems = selectedFilters.filter(function (obj) {
        return undefinedItems.indexOf(obj) === -1;
      });
      updateSchemaDict(schema, { selectedFilters: newSelectedItems }, space.Id);
    }

    const filteredArr = resolvedArr.filter((e) => e !== undefined);

    return filteredArr;
  });

  const location = useLocation();
  const schemaViews = useContext(ServerAwareViewsContext);
  const FavoriteItem = useContext(ServerAwareFavoritesContext);
  const updateColorFilters = useCallback(() => {
    const filters = getAllFiltersBySchema(schema, space.Id);
    const filterDict = {};

    for (const filter of filters) {
      filterDict[filter.Id] = filter;
    }
    const newSelectedFilters = getSelectedFiltersBySchema(schema, space.Id);

    const resolvedArr = [];
    for (const filterId of newSelectedFilters) {
      resolvedArr.push(filterDict[filterId]);
    }

    setSelectedFilters(resolvedArr);
  }, [schema, space.Id]);

  const { search } = location;
  useEffect(() => {
    if (FavoriteItem) {
      const { colorFilters } = FavoriteItem.data;
      if (colorFilters) {
        let lsObj = getColorFilterDict(space.Id);
        if (
          JSON.stringify(colorFilters) !== JSON.stringify(lsObj[schema.name])
        ) {
          if (!lsObj[schema.name]) lsObj[schema.name] = colorFilters;
          else lsObj[schema.name] = { ...colorFilters, ...lsObj[schema.name] };

          localStorage.setItem(
            `ar-color-filter-space-${space.Id}`,
            JSON.stringify(lsObj)
          );
          updateColorFilters();
        }
      } else {
        let lsObji = getColorFilterDict(space.Id);
        if (lsObji[schema.name]) {
          delete lsObji[schema.name];
          localStorage.setItem(
            `ar-color-filter-space-${space.Id}`,
            JSON.stringify(lsObji)
          );
          updateColorFilters();
        }
      }
    } else if (schemaViews) {
      const queryObj = querystring.parse(search);
      const { viewPref } = queryObj;
      const lsIndex = schemaViews?.findIndex((e) => e.id === viewPref);
      const lsViews =
        lsIndex && lsIndex === -1 ? schemaViews[0] : schemaViews[lsIndex] || {};
      if (lsViews.colorFilters) {
        // const { colorFilters } = lsViews;
        let lsObjil = getColorFilterDict(space.Id);
        if (
          JSON.stringify(lsViews.colorFilters) !==
          JSON.stringify(lsObjil[schema.name])
        ) {
          if (!lsObjil[schema.name])
            lsObjil[schema.name] = lsViews.colorFilters;
          else
            lsObjil[schema.name] = {
              ...lsViews.colorFilters,
              ...lsObjil[schema.name]
            };

          localStorage.setItem(
            `ar-color-filter-space-${space.Id}`,
            JSON.stringify(lsObjil)
          );
          updateColorFilters();
        }
      } else {
        let lsObji = getColorFilterDict(space.Id);
        if (lsObji[schema.name]) {
          delete lsObji[schema.name];
          localStorage.setItem(
            `ar-color-filter-space-${space.Id}`,
            JSON.stringify(lsObji)
          );
          updateColorFilters();
        }
      }
    }
  }, [FavoriteItem, schema, schemaViews, search, space.Id, updateColorFilters]);

  return (
    <ServerAwareColorFilterUpdaterContext.Provider value={setSelectedFilters}>
      <ServerAwareFiltersContext.Provider value={selectedFilters}>
        {children}
      </ServerAwareFiltersContext.Provider>
    </ServerAwareColorFilterUpdaterContext.Provider>
  );
};
