import React, { useState } from "react";
import AirdeskTextSvg from "../../Components/Svg/AirdeskTextSvg";
import LoadableButton from "../../Components/Button/LoadableButton";
import AirdeskBackgroundSvg from "../../Components/Svg/AirdeskBackgroundSvg";
import { Link, Switch, Route } from "react-router-dom";
import { usePost, useQuery } from "../../Helpers/IOClient";
import { isEmail, isPasswordValid } from "../../Helpers/MiscHelper";
import LoaderSpinner from "../../Components/Loader/LoaderSpinner/LoaderSpinner";
import { Redirect } from "react-router-dom";
import MaintenanceIcon from "../Maintenance/MaintenanceIcon";
import LabeledInput from "../../Components/Input/LabeledInput";
import { FormattedMessage, useIntl } from "react-intl";
import { BasePageContainer } from "../BasicPageContainer/BasicPageContainer";
import { useToast } from "../../Components/Toast/ToastProvider";

const RecoveryForm = () => {
  const [recoverEmail, { data, loading, error }] =
    usePost("account/recoveries");
  const [email, setEmail] = useState("");
  const [isEmailValid, seIsEmailValid] = useState(true);

  const handleChange = (e) => {
    setEmail(e.target.value);
    seIsEmailValid(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isEmailValid = isEmail(email);
    if (!isEmailValid) return seIsEmailValid(false);

    recoverEmail({ email });
  };

  const buildBody = () => {
    const hasSubmmited = Boolean(data);

    if (!hasSubmmited)
      return (
        <React.Fragment>
          <MaintenanceIcon absolute />
          <div className="mb-3 text-center text-black fw-light">
            <FormattedMessage id={"EMAIL_PASSWORD_INSTRUCTIONS"} />
          </div>
          <LabeledInput
            className="mb-3"
            name="email"
            type="email"
            error={!isEmailValid && <FormattedMessage id={"EMAIL_CORRECT"} />}
            value={email}
            onChange={handleChange}
            text={<FormattedMessage id={"EMAIL"} />}
          />
          {error && (
            <div className="text-danger">
              <FormattedMessage id={"RESET_EMAIL_ERROR"} />
              <br />
              <FormattedMessage id={"RESET_EMAIL_ERROR_TRY_AGAIN"} />
            </div>
          )}
          <LoadableButton
            isLoading={loading}
            disabled={!isEmailValid}
            className="ssi-button-secondary mt-3"
          >
            <FormattedMessage id={"SEND"} />
          </LoadableButton>
          <div className="mt-3 text-center">
            <Link className="fs-12 text-uppercase" to="login">
              <FormattedMessage id={"LOGIN_BACK"} />
            </Link>
          </div>
        </React.Fragment>
      );

    return (
      <div className="text-center">
        <div className="mb-2">
          <FormattedMessage
            id={"RESET_EMAIL_SENT_TITLE"}
            values={{
              b: (...chunks) => <b>{chunks}</b>,
              email
            }}
          />
          {/* Enviamos um email a <b>{email}</b> */}
        </div>
        <div>
          <FormattedMessage id={"RESET_EMAIL_SENT_DESCRIPTION"} />
        </div>
        <div className="mt-2 text-center">
          <Link className="fs-12" to="login">
            <FormattedMessage id={"LOGIN_BACK"} />
          </Link>
        </div>
      </div>
    );
  };

  return (
    <form className="ssi-onboarding-page" onSubmit={handleSubmit}>
      <BasePageContainer>
        <AirdeskTextSvg className="mb-5 md mx-auto airdesk-logo" />
        <div className="mb-1 fs-32 text-center text-black">
          <FormattedMessage id={"RESET_PASSWORD"} />
        </div>
        {buildBody()}
      </BasePageContainer>
      <div className="image">
        <AirdeskBackgroundSvg className="center-el" />
      </div>
    </form>
  );
};

const RecoveryResetForm = (props) => {
  // console.log(props);
  const recoveryId = props.match.params.recoveryId;
  const recoveryUrl = `account/recoveries/${recoveryId}`;

  const defaultErrors = () => {
    return {
      password: "",
      password2: ""
    };
  };
  const [passwords, setPasswords] = useState({
    password: "",
    password2: ""
  });

  const [hasErrors, setHasErrors] = useState(false);

  const [errors, setErrors] = useState(defaultErrors);
  const createToast = useToast();
  const { loading: isLoadingRecovery, error: recoveryError } =
    useQuery(recoveryUrl);
  const intl = useIntl();
  const [
    resetEmail,
    { data: hasReset, loading: isResetting, error: resetError }
  ] = usePost(recoveryUrl, null, {
    onSuccess: () => {
      createToast({
        pos: "tm",
        type: "success",
        title: `${intl.formatMessage({ id: "PASSWORD_CHANGED_SUCCESS" })}`
      });
    }
  });

  const handleChange = (e) => {
    setErrors(defaultErrors);
    setHasErrors(false);
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    });
    // seIsEmailValid(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isPasswordValid(passwords.password)) {
      setHasErrors(true);
      setErrors({
        password: "A password deve ter um minimo de 5 caracteres"
      });
      return;
    }

    if (passwords.password !== passwords.password2) {
      setHasErrors(true);
      setErrors({
        password2: "As passwords não coincidem"
      });
      return;
    }

    resetEmail({ password: passwords.password });
  };

  const buildBody = () => {
    if (isLoadingRecovery)
      return <LoaderSpinner className="text-secondary mx-auto" size="sm" />;

    if (recoveryError)
      return (
        <div className="text-center">
          <FormattedMessage id={"RESET_EMAIL_INVALID_URL"} />
        </div>
      );

    if (hasReset) return <Redirect to="/login" />;

    return (
      <React.Fragment>
        <div className="mb-1 fs-32 text-center text-black">
          <FormattedMessage id={"RESET_PASSWORD"} />
        </div>
        <div className="mb-3 text-center text-black fw-light">
          <FormattedMessage id={"RESET_EMAIL_INSERT_NEW_PASSWORD"} />
        </div>
        <LabeledInput
          className="mb-3"
          name="password"
          type="password"
          error={errors.password}
          value={passwords.password}
          onChange={handleChange}
          text={<FormattedMessage id={"PASSWORD"} />}
        />
        <LabeledInput
          className="mb-4"
          name="password2"
          type="password"
          error={errors.password2}
          value={passwords.password2}
          onChange={handleChange}
          text={<FormattedMessage id={"CONFIRM_PASSWORD"} />}
        />
        {resetError && (
          <div className="text-danger mb-2">
            <FormattedMessage id={"RESET_EMAIL_ERROR"} />
            <br />
            <FormattedMessage id={"RESET_EMAIL_ERROR_TRY_AGAIN"} />
          </div>
        )}
        <LoadableButton
          isLoading={isResetting}
          disabled={hasErrors}
          className="ssi-button-secondary"
        >
          <FormattedMessage id={"SET_NEW_PASSWORD"} />
        </LoadableButton>
        <div className="mt-2 text-center">
          <Link className="fs-12" to="/login">
            <FormattedMessage id={"LOGIN_BACK"} />
          </Link>
        </div>
      </React.Fragment>
    );
  };

  return (
    <form className="ssi-onboarding-page" onSubmit={handleSubmit}>
      <BasePageContainer>
        <AirdeskTextSvg className="mb-5 md mx-auto airdesk-logo" />
        {buildBody()}
      </BasePageContainer>
      <div className="image">
        <AirdeskBackgroundSvg className="center-el" />
      </div>
    </form>
  );
};

const Recovery = () => {
  return (
    <Switch>
      <Route path="/recovery/:recoveryId" component={RecoveryResetForm} />
      <RecoveryForm />
    </Switch>
  );
};

export default Recovery;
