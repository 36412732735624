import React, { useMemo, useState } from "react";
import { FileIcon, download_file } from "../../../Files/Files";
import OfficePreview from "../../../FileUploader/OfficePreview";
import PdfPreview from "../../../FileUploader/PdfPreview";
import ImagePreview from "../../../FileUploader/ImagePreview";
import EmailPreview, {
  validadeFilePreview
} from "../../../FileUploader/EmailPreview";
import Modal from "../../../Modal/Modal";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faDownload } from "@fortawesome/pro-light-svg-icons";
import "../Messages.css";
import VideoPreview from "../../../FileUploader/VideoPreview";
import { downloadFile } from "../../../../Containers/Reception/EmailChatComponent";
import DumbFilesPreview from "../../../FileUploader/DumbFilesPreview";

const MessageFile = ({ file }) => {
  const { Extension, Name, Url } = file;

  const fullName = `${Name}${Extension}`;

  const [previewing, setPreviewing] = useState(false);

  const isImage = useMemo(() => isImageByExt(Extension), [Extension]);

  const handleClick = () => {
    if (validadeFilePreview(file)) {
      setPreviewing(true);
    } else {
      downloadFile(Url, fullName);
    }
  };

  return (
    <>
      <div
        className="ar-message-file cursor-pointer"
        style={{ minWidth: 130 }}
        onClick={handleClick}
      >
        {!isImage ? (
          <div className="border p-2 rounded d-flex align-items-center">
            <FileIcon extension={Extension} size="lg" className="mr-2" />
            <div title={fullName} className="text-black text-truncate flex-1">
              {fullName}
            </div>
          </div>
        ) : (
          <div>
            <img className="ar-message-file-image" src={Url} alt="" />
          </div>
        )}
      </div>
      <FilePreview
        open={previewing}
        file={file}
        closeModal={() => setPreviewing(false)}
      />
    </>
  );
};

const MessageFiles = ({ files }) => {
  return (
    <div className="d-flex align-items-center flex-wrap ar-message-files">
      {files.map((file, index) => {
        return <MessageFile file={file} key={index} />;
      })}
    </div>
  );
};

export default MessageFiles;

const FilePreviewContent = ({ file, onWidthChange }) => {
  const { Extension, Url } = file;
  switch (`${Extension.toLowerCase()}`) {
    case ".doc":
    case ".docx":
    case ".dotx":
    case ".xls":
    case ".xlr":
    case ".xlam":
    case ".xlsx":
    case ".xlt":
    case ".pptx":
    case ".pptm":
    case ".ppt":
    case ".xps":
    case ".pps":
    case ".ppam":
    case ".tif":
      return <OfficePreview src={Url + "&inline=false"} />;
    case ".pdf":
    case ".pdfa":
      return <PdfPreview src={Url + "&inline=true"} />;

    case ".gif":
    case ".jpg":
    case ".png":
    case ".jpeg":
    case ".bmp":
    case ".jfif":
    case ".svg":
    case ".webp":
      return (
        <ImagePreview
          handleWidthChange={onWidthChange}
          src={Url + "&inline=true"}
        />
      );
    case ".msg":
    case ".eml":
      return <EmailPreview File={file} />;
    case ".webm":
    case ".mp4":
      return (
        <VideoPreview
          src={Url + "&inline=false"}
          handleWidthChange={onWidthChange}
        />
      );
    case ".csv":
    case ".json":
    case ".txt":
      return <DumbFilesPreview src={Url} />;
    default:
      return null;
  }
};

const isImageByExt = (extension) => {
  switch (`${extension.toLowerCase()}`) {
    case ".gif":
    case ".jpg":
    case ".png":
    case ".jpeg":
    case ".bmp":
    case ".jfif":
    case ".svg":
    case ".webp":
      return true;

    default:
      return false;
  }
};

const splitter = (s) => {
  var middle = s.length / 2;

  return [s.substr(0, middle), s.substr(middle)];
};

export const FilePreview = ({ file, open, closeModal }) => {
  const { Extension, Name, Size, Url } = file || {};

  const isImage = useMemo(() => isImageByExt(Extension), [Extension]);
  let SplitText = [];
  if (file) {
    SplitText = splitter(file.Name + file.Extension);
  }
  const [width, setWidth] = useState("");

  return (
    <Modal
      // containerClassName=""
      className={classnames(
        "ar-modal-preview-file-content  d-flex flex-column p-1",
        {
          "ar-modal-preview-image-content": isImage,
          "ar-modal-preview-files-content-files": !isImage
        }
      )}
      isOpen={open}
      onClose={closeModal}
      extraCloseButton={
        <>
          <div
            className="ar-modal-preview-file-content-close-button"
            onClick={() => closeModal()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          {!Size && (
            <div
              style={{ top: 55 }}
              className="ar-modal-preview-file-content-close-button text-color-link"
              onClick={() => download_file(Url, Name + Extension)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </div>
          )}
        </>
      }
    >
      {/* {ModalFil} */}
      <FilePreviewContent onWidthChange={setWidth} file={file} />
      <div
        style={{ maxWidth: width }}
        className="align-items-center d-flex justify-content-between px-1 text-black"
      >
        <span
          title={Name}
          className="flex-1 mr-1"
          id="Span-With-Split-Text"
          data-content-start={SplitText[0]}
          data-content-end={SplitText[1]}
        ></span>

        {/* <div
          style={{ fontSize: 12 }}
          className="ar-fileUploader__imageContainer__Title"
        >
          {moment(this.state.PreviewModalFile.UploadDate).format(
            "DD/MM/YYYY HH:mm"
          )}
        </div> */}
      </div>
    </Modal>
  );
};
